import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddOnLaborContext from "../../../../context/addOnLabor/addOnLaborContext";
import { Table } from "antd";
import { EditOutlined } from "@ant-design/icons";

const UpgradesLabor = () => {
  const navigate = useNavigate();
  const addOnLaborContext = useContext(AddOnLaborContext);
  const {
    getStandardAddOnsLabor,
    addOnsStandardLabor,
    setCurrentStandardAddOnLabor,
    getShopAddOnsLabor,
    addOnsShopLabor,
    setCurrentShopAddOnLabor,
  } = addOnLaborContext;

  useEffect(() => {
    // setLoadingAddOnTrue();
    getStandardAddOnsLabor("upgrades");
    getShopAddOnsLabor("upgrades");
  }, []);

  const handleViewAddon = (labor) => {
    setCurrentShopAddOnLabor(labor);
    let standardLaborItem = addOnsStandardLabor.find((i) => i.name.value === labor.name.value);
    setCurrentStandardAddOnLabor(standardLaborItem);
    navigate("/labor/addons/update");
  };

  const handleStandardTotalLabor = (shopLabor) => {
    let standardLaborItem = addOnsStandardLabor.find((i) => i.name.value === shopLabor.name.value);
    return standardLaborItem.laborTotal;
  };

  const columns = [
    {
      title: "Name",
      width: "50%",
      render: (record) => <Fragment>{record.name.label}</Fragment>,
    },
    {
      title: "Labor Type",
      width: "20%",
      render: (record) => <Fragment>{record.laborType.label}</Fragment>,
    },
    {
      title: "Labor",
      width: "20%",
      render: (text, record) => (
        <span>
          {record.laborTotal &&
            record.laborTotal.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },

    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          {
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddon(record)}>
              View
            </button>
          }
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <Table
              loading={addOnsShopLabor ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              dataSource={addOnsShopLabor}
              rowKey="_id"
              bordered={true}
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpgradesLabor;
