import React, { useContext } from 'react';
import OrderContext from '../../../context/order/orderContext';
import NewInvoiceForm from '../../pages/invoices/NewInvoiceForm';
import { Modal } from 'antd';

const NewInvoiceModal = ({ setModalVisible, modalVisible }) => {
  const orderContext = useContext(OrderContext);
  const { currentOrder, statusChange, clearCurrentOrder } = orderContext;

  const handleModalCancel = () => {
    setModalVisible(false);
  };
  return (
    <Modal
      // title='Modal 1000px width'
      centered
      visible={modalVisible}
      // onOk={() => setModalVisible(false)}
      onCancel={handleModalCancel}
      bodyStyle={{ height: '80vh' }}
      width={1200}
      footer={null}
    >
      <NewInvoiceForm setModalVisible={setModalVisible} />
    </Modal>
  );
};

export default NewInvoiceModal;
