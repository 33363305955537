import React, { useReducer } from 'react'
import TrussContext from './trussContext'
import trussReducer from './trussReducer'
import axios from 'axios'

import { toast } from "react-toastify";

import {
    ADD_TRUSS,
    DELETE_TRUSS,
    CLEAR_TRUSSES,
    SET_CURRENT_TRUSS,
    CLEAR_CURRENT_TRUSS,
    UPDATE_TRUSS,
    FILTER_TRUSSES,
    CLEAR_FILTER_TRUSS,
    TRUSS_ERROR,
    GET_TRUSSES,
} from '../types'

const TrussState = props => {
    const initialState = {
        trusses: null,
        currentTruss: null,
        filteredTruss: null,
        error: null
    };

    const [state, dispatch] = useReducer(trussReducer, initialState)

    // get trusses
    const getTrusses = async () => {
        try {
            const res = await axios.get('/api/truss')

            dispatch({ type: GET_TRUSSES, payload: res.data })
        } catch (err) {
            dispatch({ type: TRUSS_ERROR})
            
        }
    }


    
    const addTruss = async truss => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/truss', {truss}, config)

            dispatch({ type: ADD_TRUSS, payload: res.data })
        } catch (err) {
            dispatch({ type: TRUSS_ERROR})
            
        }
    }


    
    const deleteTruss =   async id => {
        try {
            const res = await axios.delete(`/api/trusses/${id}`)

            dispatch({ type: DELETE_TRUSS, payload: id })
        } catch (err) {
            dispatch({ type: TRUSS_ERROR})
            
        }
    }


     // update truss
     const updateTruss = async truss => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/truss/update/${truss._id}`, truss, config)

            dispatch({ type: UPDATE_TRUSS, payload: res.data })
            toast.success(`Truss is updated`)
        } catch (err) {
            dispatch({ type: TRUSS_ERROR})
            toast.error(`Truss ${err}`)
        }
    }



 
    const clearTrusses = () => {
        dispatch({ type: CLEAR_TRUSSES })
    }


   
    const setCurrentTruss = truss => {
            dispatch({ type: SET_CURRENT_TRUSS, payload: truss })
    }


  
    const clearCurrentTruss = () => {
            dispatch({ type: CLEAR_CURRENT_TRUSS })
    }



  
    const filterTrusses = text => {
            dispatch({ type: FILTER_TRUSSES, payload: text })
    }



    const clearFilterTruss = () => {
        dispatch({ type: CLEAR_FILTER_TRUSS })
    }

    return (
        <TrussContext.Provider
            value={{
                trusses: state.trusses,
                currentTruss: state.currentTruss,
                filteredTruss: state.filteredTruss,
                error: state.error,
                getTrusses,
                addTruss,
                deleteTruss, 
                setCurrentTruss,
                clearCurrentTruss,
                updateTruss,
                filterTrusses, 
                clearFilterTruss,
                clearTrusses,
            }}>
             { props.children }
        </TrussContext.Provider>
    )


}

export default TrussState;