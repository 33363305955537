import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AuthContext from "../../../../context/auth/authContext";
import ProductContext from "../../../../context/product/productContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import OrderContext from "../../../../context/order/orderContext";
import { categories } from "../../../names/shedNames";
import Select from "react-select";

const InventoryOverview = () => {
  const navigate = useNavigate();
  const productContext = useContext(ProductContext);
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const orderContext = useContext(OrderContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const { getProductsByCategory, clearProducts, products, setLoadingProduct, loadingProduct } = productContext;
  const { getReportOrdersByCategory, orders, loadingOrders, clearOrders } = orderContext;
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [adjustedProducts, setAdjustedProducts] = useState();

  const setTheCategory = (theCategory) => {
    setCategory(theCategory);
  };

  useEffect(() => {
    clearProducts();
    getReportOrdersByCategory("InQueue");
    loadUser();
    getSettings();
  }, []);

  useEffect(() => {
    setLoading(true);
    const theProducts =
      (products &&
        orders &&
        products.map((product) => {
          let theCount = handleInQueueInventory(product);
          product.inQueueQuantity = theCount;
          return product;
        })) ||
      [];
    setAdjustedProducts(theProducts);
    setLoading(false);
  }, [products]);

  const handleInQueueInventory = (product) => {
    let itemCountArr = [];

    orders.forEach((order) => {
      let item = order.shedProShed.products.find((i) => i.product.title === product.title);
      item && itemCountArr.push(item.count);
    });

    orders.forEach((order) => {
      let item = order.shedProShed.trussOne.products.find((i) => i.product.title === product.title);
      let trussOneCountTotal = item && item.count * order.shedProShed.trussOneCount;
      item && itemCountArr.push(trussOneCountTotal);
    });

    orders.forEach((order) => {
      let item = order.shedProShed.trussTwo.products.find((i) => i.product.title === product.title);
      let trussTwoCountTotal = item && item.count * order.shedProShed.trussTwoCount;
      item && itemCountArr.push(trussTwoCountTotal);
    });

    orders.forEach((order) => {
      let item = order.shedProShed.door.products.find((i) => i.product.title === product.title);
      let doorCountTotal = item && item.count * order.shedProShed.doorCount;
      item && itemCountArr.push(doorCountTotal);
    });

    orders.forEach((order) => {
      order.addOns.forEach((addOn) => {
        let item = addOn.addOn.products.find((i) => i.product.title === product.title);
        let addOnItemCountTotal = item && item.count * addOn.quantity;
        item && itemCountArr.push(addOnItemCountTotal);
      });
    });

    let itemCountTotal = itemCountArr.reduce((s, t) => (s = s + t), 0);

    return itemCountTotal;
  };

  const handleGetFilteredProducts = () => {
    setLoading(true);
    getProductsByCategory(category.value);
  };

  const setExit = () => {
    clearOrders();
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-md-12 ">
            <button className="float-end btn btn-outline-danger " type="button" onClick={setExit}>
              Back
            </button>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  placeholder={"Select Category..."}
                  value={category}
                  options={categories}
                  onChange={setTheCategory}
                />
              </div>

              <div className="col-md-2">
                <button
                  className="ml-2 btn btn-outline-primary "
                  type="button"
                  disabled={(!category && true) || false}
                  onClick={handleGetFilteredProducts}
                >
                  Run Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          {loading || loadingOrders ? (
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading || loadingOrders}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            adjustedProducts && (
              <MaterialTable
                options={{
                  rowStyle: {
                    fontSize: "12px",
                  },
                  exportMenu: [
                    {
                      label: "Export PDF",
                      //// You can do whatever you wish in this function. We provide the
                      //// raw table columns and table data for you to modify, if needed.
                      // exportFunc: (cols, datas) => console.log({ cols, datas })
                      exportFunc: (cols, datas) => ExportPdf(cols, datas, "Orders/Labor"),
                    },
                    {
                      label: "Export CSV",
                      exportFunc: (cols, datas) => ExportCsv(cols, datas, "Orders/Labor"),
                    },
                  ],
                  pageSize: 20,
                  pageSizeOptions: [20, 40, 100],
                }}
                columns={[
                  { title: "Item", field: "title" },
                  {
                    title: "Ordered Quantity",
                    field: "onOrderQuantity",
                    render: (rowData) => rowData.onOrderQuantity && rowData.onOrderQuantity.toFixed(2),
                  },
                  {
                    title: "Received Quantity",
                    field: "recievedQuantity",
                    render: (rowData) => rowData.recievedQuantity && rowData.recievedQuantity.toFixed(2),
                  },
                  { title: "In Stock", field: "quantity", render: (rowData) => rowData && rowData.quantity.toFixed(2) },
                  {
                    title: "InQueue",
                    field: "inQueueQuantity",
                    render: (rowData) => rowData && rowData.inQueueQuantity.toFixed(2),
                  },
                ]}
                data={adjustedProducts && adjustedProducts}
                title="Inventory/Overview"
              />
            )
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default InventoryOverview;
