import React, { useState, Fragment } from "react";
import { DatePicker } from "antd";
import Select from "react-select";
import { orderReportStatus, shedNames } from "../../../names/shedNames";
const dateFormat = "MM/DD/YYYY";
const { RangePicker } = DatePicker;

export const dateOptions = [
  { label: "Date Finished", value: "dateFinished" },
  { label: "Date Ordered", value: "dateOrdered" },
];

const DateFiltersDetailLabor = ({ getOrdersByDateFilter, setLoadingOrders, settings }) => {
  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);
  const [value, setValue] = useState(null);
  const [status, setStatus] = useState();
  const [builder, setBuilder] = useState(null);
  const [dateStatus, setDateStatus] = useState({ label: "Date Finished", value: "dateFinished" });
  const [style, setStyle] = useState({ label: "All", value: "All" });

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  const handleDateSort = () => {
    setLoadingOrders(true);
    getOrdersByDateFilter(dates, status, dateStatus, style, builder);
  };

  const setOrderStatus = (theStatus) => {
    setStatus(theStatus);
  };

  const setDateOption = (dateOption) => {
    setDateStatus(dateOption);
  };

  const setStyleOption = (styleOption) => {
    setStyle(styleOption);
  };

  const setTheBuilder = (b) => {
    setBuilder(b);
  };

  const myBuilders =
    settings.builders &&
    settings.builders.map((builder) => {
      return { label: `${builder.builder}`, value: builder.builder };
    });

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-2">
          <RangePicker
            className="p-2"
            value={hackValue || value}
            // disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setValue(val)}
            onOpenChange={onOpenChange}
            format={dateFormat}
          />
        </div>

        <div className="col-md-2">
          <Select
            className="basic-single"
            classNamePrefix="select"
            // defaultValue={'All'}
            placeholder={"Select Builder..."}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={builder}
            options={myBuilders}
            onChange={setTheBuilder}
          />
        </div>

        <div className="col-md-2">
          <Select
            className="basic-single"
            classNamePrefix="select"
            // defaultValue={'All'}
            placeholder={"Select Status..."}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={status}
            options={orderReportStatus}
            onChange={setOrderStatus}
          />
        </div>

        <div className="col-md-2">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder={"Select Style..."}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={style}
            options={shedNames}
            onChange={setStyleOption}
          />
        </div>

        <div className="col-md-2">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder={"Select Status..."}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={dateStatus}
            options={dateOptions}
            onChange={setDateOption}
          />
        </div>

        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2 mt-2">
            <button
              className="btn btn-outline-primary"
              type="button"
              disabled={(!builder && !status && true) || false}
              onClick={handleDateSort}
            >
              Run Report
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DateFiltersDetailLabor;
