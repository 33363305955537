import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import ProductContext from '../../../context/product/productContext';
import AlertContext from '../../../context/alert/alertContext';
import MaterialOrderContext from '../../../context/materialOrder/materialOrderContext';
import RecieveItemIntoInventory from './RecieveItemIntoInventory';
import Select from 'react-select';
import { categories } from '../../names/shedNames';

const initialState = {
  title: '',
  sku: '',
  price: 0,
  freight: 0,
  quantity: 0,
  category: '',
  orderPoint: 0,
  orderPointMin: 0,
};

const ProductUpdate = () => {
  const navigate = useNavigate();
  // state
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const productContext = useContext(ProductContext);
  const alertContext = useContext(AlertContext);
  const materialOrderContext = useContext(MaterialOrderContext);
  const { loadUser } = authContext;
  const { updateProduct, currentProduct, clearCurrentProduct, clearFilterProduct } = productContext;
  const { clearMaterialOrders } = materialOrderContext;

  useEffect(() => {
    loadUser();
    setValues(currentProduct);
  }, [currentProduct]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProduct(values);
    clearFilterProduct();
    navigate(-1);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const setCategory = (event) => {
    setValues({ ...values, category: event.value });
  };

  const setExit = () => {
    navigate(-1);
    clearFilterProduct();
    clearMaterialOrders();
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-12'>
          <button className='float-end mt-2 mb-2 btn btn-outline-danger ' onClick={() => setExit()}>
            Exit
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-md-3'> </div>

              <div className='col-md-12 bidformbackground border border-dark' style={{ borderRadius: '15px' }}>
                <div className='form-group'>
                  <h4 className='text-center'>Update Item</h4>
                  <label className='mt-2'>Name</label>
                  <input type='text' name='title' className='form-control inputbg' value={values.title} onChange={handleChange} />
                </div>
                <div className='form-group'>
                  <label>SKU</label>
                  <input type='text' name='sku' className='form-control inputbg' value={values.sku} onChange={handleChange} />
                </div>

                <div className='form-group'>
                  <label>Category</label>
                  <Select
                    className='basic-single'
                    classNamePrefix='select'
                    defaultValue={values.category}
                    placeholder={values.category}
                    name={values.category}
                    value={values.category}
                    options={categories}
                    onChange={setCategory}
                  />
                </div>

                <div className='form-group'>
                  <label>Id</label>
                  <input
                    type='number'
                    step='0.01'
                    name='myId'
                    className='form-control inputbg'
                    value={values.myId}
                    readOnly
                    onChange={handleChange}
                  />
                </div>

                <div className='form-group'>
                  <label>Material Price</label>
                  <input type='number' step='0.01' name='subTotal' className='form-control inputbg' value={values.subTotal} onChange={handleChange} />
                </div>

                <div className='form-group'>
                  <label>Freight</label>
                  <input type='number' step='0.01' name='freight' className='form-control inputbg' value={values.freight} onChange={handleChange} />
                </div>

                <div className='form-group'>
                  <label>Total</label>
                  <input
                    type='number'
                    step='0.01'
                    name='price'
                    className='form-control inputbg'
                    value={+values.subTotal + +values.freight}
                    readOnly
                  />
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>Order Point Warning</label>
                      <input
                        type='number'
                        name='orderPoint'
                        step='0.01'
                        className='form-control inputbg'
                        value={values.orderPoint}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>Order Point</label>
                      <input
                        type='number'
                        name='orderPointMin'
                        step='0.01'
                        className='form-control inputbg'
                        value={values.orderPointMin}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className='form-group'>
                  <label>Quantity</label>
                  <input type='number' name='quantity' step='0.01' className='form-control inputbg' value={values.quantity} onChange={handleChange} />
                </div>

                <button className='btn btn-outline-info float-end mb-2'>Save</button>
                {/* {user && user.role === "admin" && (
                  <div className="buttons float-left mr-3">
                      <button className="btn btn-outline-info" type="button" onClick={e => handleUpdateAll(e)}>
                          Update All For Category
                      </button>
                  </div>
                )} */}
              </div>
            </div>
          </form>
        </div>

        <div className='col-md-8'>
          <RecieveItemIntoInventory currentProduct={currentProduct} />
        </div>
      </div>
    </div>
  );
};

export default ProductUpdate;
