import {
  ADD_SHEDLABOR,
  DELETE_SHEDLABOR,
  CLEAR_SHEDLABORS,
  SET_CURRENT_SHOP_SHEDLABOR,
  SET_CURRENT_STANDARD_SHEDLABOR,
  CLEAR_CURRENT_SHOP_SHEDLABOR,
  CLEAR_CURRENT_STANDARD_SHEDLABOR,
  UPDATE_SHEDLABOR,
  SHEDLABOR_ERROR,
  GET_SHOP_SHEDLABORS,
  GET_STANDARD_SHEDLABORS,
  SET_SHEDLABORS,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_SHOP_SHEDLABORS:
      return {
        ...state,
        shopShedsLabor: action.payload,
        loading: false,
      };
    case GET_STANDARD_SHEDLABORS:
      return {
        ...state,
        standardShedsLabor: action.payload,
        loading: false,
      };
    case ADD_SHEDLABOR:
      return {
        ...state,
        shedLabor: action.payload,
        loading: false,
      };
    case UPDATE_SHEDLABOR:
      return {
        ...state,
        shedsLabor: state.shedsLabor && state.shedsLabor.map((labor) => (labor._id === action.payload._id ? action.payload : labor)),
        loading: false,
      };
    case DELETE_SHEDLABOR:
      return {
        ...state,
        shedsLabor: state.shedsLabor.filter((labor) => labor._id !== action.payload),
        loading: false,
      };
    case CLEAR_SHEDLABORS:
      return {
        ...state,
        shopShedsLabor: null,
        standardShedsLabor: null,
        filteredShedLabor: null,
        error: null,
        currentShopShedLabor: null,
        currentStandardShedLabor: null,
      };
    case SET_CURRENT_SHOP_SHEDLABOR:
      return {
        ...state,
        currentShopShedLabor: action.payload,
      };
    case SET_CURRENT_STANDARD_SHEDLABOR:
      return {
        ...state,
        currentStandardShedLabor: action.payload,
      };

    case SET_SHEDLABORS:
      return {
        ...state,
        shedsLabor: action.payload,
      };
    case CLEAR_CURRENT_SHOP_SHEDLABOR:
      return {
        ...state,
        currentShopShedLabor: null,
      };
    case CLEAR_CURRENT_STANDARD_SHEDLABOR:
      return {
        ...state,
        currentStandardShedLabor: null,
      };

    case SHEDLABOR_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
