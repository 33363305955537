import React, { Fragment, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthContext from '../../../../context/auth/authContext';
import OrderContext from '../../../../context/order/orderContext';
import SettingsContext from '../../../../context/settings/settingsContext';
import DateFiltersDetail from './DateFiltersDetail';
import moment from 'moment';

const OrdersDetail = () => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const orderContext = useContext(OrderContext);
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const { getOrdersByDateFilter, orders, loadingOrders, setLoadingOrders, clearOrders } = orderContext;

  useEffect(() => {
    clearOrders();
    loadUser();
    getSettings();
  }, []);

  useEffect(() => {
    tableRef.current.dataManager.changePageSize(40);
  }, [orders]);

  const setExit = () => {
    navigate(-1);
  };

  const newOrders =
    (orders &&
      orders.map((order) => {
        return order;
      })) ||
    [];

  return (
    <Fragment>
      <div>
        <div className='row'>
          <div className='col-md-12 '>
            <button className='float-end btn btn-outline-danger ' type='button' onClick={setExit}>
              Back
            </button>
          </div>
        </div>
        <div className='row mt-4'>
          <DateFiltersDetail getOrdersByDateFilter={getOrdersByDateFilter} setLoadingOrders={setLoadingOrders} clearOrders={clearOrders} />
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-1'></div>
        <div className='col-md-10'>
          {loadingOrders ? (
            // <Spinner />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingOrders}>
              <CircularProgress color='inherit' />
            </Backdrop>
          ) : (
            <MaterialTable
              tableRef={tableRef}
              options={{
                rowStyle: {
                  fontSize: '12px',
                },
                exportMenu: [
                  {
                    label: 'Export PDF',
                    //// You can do whatever you wish in this function. We provide the
                    //// raw table columns and table data for you to modify, if needed.
                    // exportFunc: (cols, datas) => console.log({ cols, datas })
                    exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Orders/Detail'),
                  },
                  {
                    label: 'Export CSV',
                    exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Orders/Detail'),
                  },
                ],
                emptyRowsWhenPaging: false,
                pageSizeOptions: [40, 100, 200],
              }}
              columns={[
                { title: 'Serial #', field: 'serialNumber' },
                { title: 'Date Ordered', field: 'dateOrdered', render: (rowData) => moment(rowData.dateOrdered).format('MM/DD/YYYY') },
                {
                  title: 'Date Finished',
                  field: 'dateFinished',
                  render: (rowData) => rowData.dateFinished && moment(rowData.dateFinished).format('MM/DD/YYYY'),
                },
                { title: 'Customer', field: 'customer' },
                { title: 'Dealer', field: 'dealer.name.label' },
                {
                  title: 'Sale Price',
                  field: 'shopTotalSalePrice',
                  render: (rowData) =>
                    rowData.shopTotalSalePrice.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                },
                { title: 'Shop GP %', field: 'shopProfitPercent', render: (rowData) => rowData.shopProfitPercent.toFixed(2) },
                { title: 'Standard GP %', field: 'standardProfitPercent', render: (rowData) => rowData.standardProfitPercent.toFixed(2) },
              ]}
              data={newOrders && newOrders}
              title='Orders/Detail'
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default OrdersDetail;
