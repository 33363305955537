import React, { Fragment, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm } from 'antd';
import InvoiceContext from '../../../context/invoice/invoiceContext';

const PopConfirm = ({ record, msg, btnText, theStatus, handleStatusChange }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const invoiceContext = useContext(InvoiceContext);
  const { updateInvoiceStatus, statusChange, currentInvoice } = invoiceContext;

  const handleShowPopConfirm = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    handleStatusChange();
  };

  return (
    <Fragment>
      <Popconfirm
        title={msg}
        visible={visible}
        onConfirm={handleOk}
        // okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
        okText='Yes'
      ></Popconfirm>
      <button className='btn btn-outline-primary ' type='button' onClick={(e) => handleShowPopConfirm()}>
        {btnText}
      </button>
    </Fragment>
  );
};

export default PopConfirm;
