import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AuthContext from "../../../../context/auth/authContext";
import ProductContext from "../../../../context/product/productContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import MaterialOrderContext from "../../../../context/materialOrder/materialOrderContext";
import DateFiltersPurchaseOrders from "./DateFiltersPurchaseOrders";

const InventoryPurchased = () => {
  const navigate = useNavigate();
  const productContext = useContext(ProductContext);
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const materialOrderContext = useContext(MaterialOrderContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const { getProductsByCategory, clearProducts, products, setLoadingProduct, loadingProduct } = productContext;
  const { materialOrders, getMaterialOrdersForReport, setLoadingMaterialOrders, loadingMaterialOrders } = materialOrderContext;
  const [category, setCategory] = useState();

  const setTheCategory = (theCategory) => {
    setCategory(theCategory);
  };

  useEffect(() => {
    clearProducts();
    loadUser();
    getSettings();
  }, []);

  const handleOrderedInventory = (product) => {
    let itemOrderedArr = [];
    let itemRecievedArr = [];
    let itemPriceArr = [];
    materialOrders.forEach((order) => {
      let item = order.items.find((i) => i.product.value.title === product.title);
      item && order.recieved && itemRecievedArr.push(item.quantity);
      let itemPrice = item && item.newPrice * item.quantity;
      let itemFreight = item && item.quantity * item.newFreight;
      item && order.recieved && itemPriceArr.push(itemPrice + itemFreight);
      item && itemOrderedArr.push(item.quantity);
    });
    let theItem = {};
    let totalPrice = itemPriceArr.reduce((s, t) => (s = s + t), 0);
    let totalRecieved = itemRecievedArr.reduce((s, t) => (s = s + t), 0);
    let avgPrice = (totalPrice && +totalPrice / +totalRecieved) || 0;
    theItem.ordered = itemOrderedArr.reduce((s, t) => (s = s + t), 0);
    theItem.recieved = totalRecieved;
    theItem.avgPrice = avgPrice;

    return theItem;
  };

  const adjustedProducts =
    (products &&
      materialOrders &&
      products.map((product) => {
        let theItem = handleOrderedInventory(product);
        product.ordered = theItem.ordered;
        product.recieved = theItem.recieved;
        product.avgPrice = theItem.avgPrice;

        return product;
      })) ||
    [];

  const handleGetFilteredProducts = () => {
    setLoadingProduct(true);
    getProductsByCategory(category.value);
  };

  const setExit = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-md-12 ">
            <button className="float-end btn btn-outline-danger " type="button" onClick={setExit}>
              Back
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <DateFiltersPurchaseOrders
              category={category}
              setTheCategory={setTheCategory}
              getMaterialOrdersForReport={getMaterialOrdersForReport}
              setLoadingMaterialOrders={setLoadingMaterialOrders}
              handleGetFilteredProducts={handleGetFilteredProducts}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          {loadingMaterialOrders ? (
            // <div className='row'>
            //   <div className='col-md-12 text-center'>
            //     <Spinner />
            //   </div>
            // </div>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingMaterialOrders}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <MaterialTable
              options={{
                rowStyle: {
                  fontSize: "12px",
                },
                exportMenu: [
                  {
                    label: "Export PDF",
                    //// You can do whatever you wish in this function. We provide the
                    //// raw table columns and table data for you to modify, if needed.
                    // exportFunc: (cols, datas) => console.log({ cols, datas })
                    exportFunc: (cols, datas) => ExportPdf(cols, datas, "Orders/Labor"),
                  },
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) => ExportCsv(cols, datas, "Orders/Labor"),
                  },
                ],
                pageSize: 20,
                pageSizeOptions: [20, 40, 100],
              }}
              columns={[
                { title: "Item", field: "title" },
                { title: "Ordered", field: "ordered" },
                { title: "Recieved", field: "recieved" },
                {
                  title: "Avg Price",
                  field: "avgPrice",
                  render: (rowData) =>
                    rowData.avgPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    }),
                },
              ]}
              data={adjustedProducts && adjustedProducts}
              title="Inventory/Purchased"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default InventoryPurchased;
