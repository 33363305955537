import React, { Fragment, useState, useContext } from 'react';
import { Menu } from 'antd';
import { Outlet, Link } from 'react-router-dom';
import AddOnLaborContext from '../../../context/addOnLabor/addOnLaborContext';

const LaborNav = () => {
  const addOnLaborContext = useContext(AddOnLaborContext);
  const { clearAddOnsLabor } = addOnLaborContext;
  const [current, setCurrent] = useState();

  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  const handleSwitchAddOn = () => {
    clearAddOnsLabor();
  };

  return (
    <Fragment>
      <Menu className='mb-4 border' onClick={handleMenuChange} selectedKeys={[current]} mode='horizontal'>
        <Menu.Item key='addons'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/addons`}>
            AddOn Labor
          </Link>
        </Menu.Item>
        <Menu.Item key='sheds'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/sheds`}>
            Sheds Labor
          </Link>
        </Menu.Item>
      </Menu>

      <Outlet />
    </Fragment>
  );
};

export default LaborNav;
