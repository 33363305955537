import React from 'react';
import MaterialTable from '@material-table/core';

const TableLoading = ({ title }) => {
  return (
    <MaterialTable
      options={{
        rowStyle: {
          fontSize: '12px',
        },
        pageSize: 20,
        pageSizeOptions: [40, 100],
      }}
      columns={[]}
      data={[]}
      title={title}
    />
  );
};

export default TableLoading;
