import React, { useReducer } from "react";
import AlertContext from "./alertContext";
import alertReducer from "./alertReducer";
import { v4 as uuid } from "uuid";
import axios from "axios";

import { SET_ALERT, REMOVE_ALERT, GET_INVOICE_ALERT, ALERT_ERROR } from "../types";

const AlertState = (props) => {
  const initialState = {
    alerts: null,
    invoiceAlerts: [],
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);

  // set alert
  const setAlert = (msg, type, timeout = 5000) => {
    const id = uuid();
    dispatch({
      type: SET_ALERT,
      payload: { msg, type, id },
    });

    // setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };

  const getInvoiceAlerts = async () => {
    try {
      const res = await axios.get("/api/alert/unread").then((res) => {
        const myAlerts = res.data.map((alert) => {
          let newAlert = {};
          newAlert.type = "danger";
          newAlert.id = alert._id;
          newAlert.msg = alert.content;

          return newAlert;
        });
        dispatch({ type: GET_INVOICE_ALERT, payload: myAlerts });
      });
    } catch (err) {
      dispatch({ type: ALERT_ERROR });
    }
  };

  // update
  const updateInvoiceAlert = async (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(`/api/alert/update/read/${id}`, config);

      dispatch({ type: REMOVE_ALERT, payload: id });
    } catch (err) {
      dispatch({ type: ALERT_ERROR });
    }
  };

  return (
    <AlertContext.Provider
      value={{
        alerts: state.alerts,
        invoiceAlerts: state.invoiceAlerts,
        setAlert,
        getInvoiceAlerts,
        updateInvoiceAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
