import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import AddOnLaborContext from "./addOnLaborContext";
import addOnLaborReducer from "./addOnLaborReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_ADDONLABOR,
  DELETE_ADDONLABOR,
  CLEAR_ADDONLABORS,
  SET_CURRENT_SHOP_ADDONLABOR,
  SET_CURRENT_STANDARD_ADDONLABOR,
  CLEAR_CURRENT_ADDONLABOR,
  UPDATE_ADDONLABOR,
  SET_ADDONLABORS,
  ADDONLABOR_ERROR,
  GET_SHOP_ADDONLABORS,
  GET_STANDARD_ADDONLABORS,
} from "../types";

const AddOnLaborState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    addOnsShopLabor: null,
    addOnsStandardLabor: null,
    currentShopAddOnLabor: null,
    currentStandardAddOnLabor: null,
    error: null,
  };

  const [state, dispatch] = useReducer(addOnLaborReducer, initialState);

  const getShopAddOnsLabor = async (category) => {
    try {
      const res = await axios.get(`/api/addonlabor/${category}`);
      dispatch({ type: GET_SHOP_ADDONLABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDONLABOR_ERROR });
      toast.error(`Shed ${err}`);
    }
  };

  const getStandardAddOnsLabor = async (category) => {
    try {
      const res = await axios.get(`/api/addonlabor/standard/${category}`);
      dispatch({ type: GET_STANDARD_ADDONLABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDONLABOR_ERROR });
      toast.error(`Shed ${err}`);
    }
  };

  // add
  const addAddOnLabor = async (labor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/addonlabor", labor, config);
      dispatch({ type: ADD_ADDONLABOR, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDONLABOR_ERROR });
    }
  };

  // delete
  const deleteAddOnLabor = async (id) => {
    try {
      const res = await axios.delete(`/api/addonlabor/delete/${id}`);

      dispatch({ type: DELETE_ADDONLABOR, payload: id });
      toast.success(`AddOnLabor is deleted`);
    } catch (err) {
      dispatch({ type: ADDONLABOR_ERROR });
    }
  };

  // update
  const updateAddOnLabor = async (labor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(`/api/addonlabor/update/${labor._id}`, labor, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_ADDONLABOR, payload: res.data });
      navigate(-1);
      toast.success(`AddOn Labor is updated`);
    } catch (err) {
      dispatch({ type: ADDONLABOR_ERROR });
      toast.error(`AddOn Labor ${err}`);
    }
  };

  // clear
  const clearAddOnsLabor = () => {
    dispatch({ type: CLEAR_ADDONLABORS });
  };

  // set current
  const setCurrentShopAddOnLabor = (labor) => {
    dispatch({ type: SET_CURRENT_SHOP_ADDONLABOR, payload: labor });
  };
  const setCurrentStandardAddOnLabor = (labor) => {
    dispatch({ type: SET_CURRENT_STANDARD_ADDONLABOR, payload: labor });
  };

  const setAddOnsLabor = (labors) => {
    dispatch({ type: SET_ADDONLABORS, payload: labors });
  };

  // clear current
  const clearCurrentAddOnLabor = () => {
    dispatch({ type: CLEAR_CURRENT_ADDONLABOR });
  };

  return (
    <AddOnLaborContext.Provider
      value={{
        addOnsShopLabor: state.addOnsShopLabor,
        addOnsStandardLabor: state.addOnsStandardLabor,
        currentShopAddOnLabor: state.currentShopAddOnLabor,
        currentStandardAddOnLabor: state.currentStandardAddOnLabor,
        error: state.error,
        getShopAddOnsLabor,
        getStandardAddOnsLabor,
        setAddOnsLabor,
        addAddOnLabor,
        deleteAddOnLabor,
        setCurrentShopAddOnLabor,
        setCurrentStandardAddOnLabor,
        clearCurrentAddOnLabor,
        updateAddOnLabor,
        clearAddOnsLabor,
      }}
    >
      {props.children}
    </AddOnLaborContext.Provider>
  );
};

export default AddOnLaborState;
