import React, { Fragment } from "react";
import { useFieldArray } from "react-hook-form";

const NonInvAddOns = ({ nonInvAddOn, nonInvAddOnIndex, control, remove, setValue, getValues, register }) => {
  return (
    <Fragment>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control inputbg"
                  name={`nonInvAddOns[${nonInvAddOnIndex}].nonInvAddOn.name`}
                  {...register(`nonInvAddOns[${nonInvAddOnIndex}].nonInvAddOn.name`, { required: true })}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <input
                  className="form-control inputbg"
                  name={`nonInvAddOns[${nonInvAddOnIndex}].nonInvAddOn.type`}
                  {...register(`nonInvAddOns[${nonInvAddOnIndex}].nonInvAddOn.type`)}
                />
              </div>
            </div>

            <div className="col-md-1">
              <div className="form-group">
                <input
                  className="form-control inputbg"
                  type={"number"}
                  step={".01"}
                  name={`nonInvAddOns[${nonInvAddOnIndex}].nonInvAddOn.cost`}
                  {...register(`nonInvAddOns[${nonInvAddOnIndex}].nonInvAddOn.cost`, { required: true })}
                />
              </div>
            </div>

            <div className="col-md-1">
              <div className="form-group float-end">
                <button
                  className="btn btn-outline-danger mb-2"
                  type="button"
                  onClick={() => {
                    remove(nonInvAddOnIndex);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "nonInvAddOns",
  });

  return (
    <div className="container-fluid border mt-2" style={{ borderRadius: "15px" }}>
      <div className="row mt-2 ">
        <div className="col-md-6">
          <h5>Non Inventory AddOns</h5>
        </div>
        <div className="col-md-4">
          <h5>Type</h5>
        </div>
        <div className="col-md-1">
          <h5>Cost</h5>
        </div>
      </div>

      {fields.map((nonInvAddOn, nonInvAddOnIndex) => (
        <NonInvAddOns
          key={nonInvAddOn.id}
          nonInvAddOn={nonInvAddOn}
          nonInvAddOnIndex={nonInvAddOnIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      ))}
      <button
        className="btn btn-outline-info mb-2 mt-2  "
        onClick={(e) => {
          e.preventDefault();
          append({ append: 1 });
        }}
      >
        Add AddOn
      </button>
    </div>
  );
}
