import React, { useContext, useEffect } from "react";
import Alerts from "../layout/Alerts";
import AlertContext from "../../context/alert/alertContext";
import OrderContext from "../../context/order/orderContext";
import OrderChartOne from "../pages/home/OrderChartOne";

const Home = () => {
  const alertContext = useContext(AlertContext);
  const { getInvoiceAlerts, invoiceAlerts } = alertContext;
  const orderContext = useContext(OrderContext);
  const { getOrdersForChartOne, chartOneOrders } = orderContext;

  useEffect(() => {
    getInvoiceAlerts();
    getOrdersForChartOne();
  }, []);

  return (
    <div>
      <Alerts />
      <div className="rwo">
        <div className="col-md-6">{chartOneOrders && <OrderChartOne orders={chartOneOrders} />}</div>
      </div>
    </div>
  );
};

export default Home;
