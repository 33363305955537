import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';

import { useNavigate, useLocation } from 'react-router-dom';

const Login = () => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const { login, error, clearErrors, isAuthenticated } = authContext;

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }

    if (error === 'Invalid Credentials') {
      setAlert(error, 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated]);

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const { email, password } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    if (email === '' || password === '') {
      setAlert('Please fill in all fields', 'danger');
    } else {
      login({
        email,
        password,
      });
    }
  };

  return (
    <div className='container'>
      <div className='row'>
        <h1 className='text-center'>
          Account <span className='text-primary'>Login</span>
        </h1>
        <div className='row'>
          <div className='col-md-4'></div>
          <div className='col-md-4'>
            <form onSubmit={onSubmit}>
              <div className='row'>
                <label className='col-md-3 col-form-label'>Email:</label>
                <div className='col-md-9'>
                  <input className='form-control' type='email' name='email' value={email} onChange={onChange} required />
                </div>
              </div>
              <div className='row mt-4 mb-4'>
                <label className='col-md-3 col-form-label'>Password:</label>
                <div className='col-md-9'>
                  <input className='form-control' type='password' name='password' value={password} onChange={onChange} required />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-4'>
                  <input type='submit' value='Login' className='btn btn-primary btn-block' />
                </div>
                <div className='col-md-4'></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
