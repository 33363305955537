import React, { useReducer } from "react";
import CommentContext from "./commentContext";
import commentReducer from "./commentReducer";
import axios from "axios";
import { useInterval } from "../../hooks/useInterval";

import { toast } from "react-toastify";

import {
  ADD_COMMENT,
  DELETE_COMMENT,
  CLEAR_COMMENTS,
  UPDATE_COMMENT,
  GET_COMMENTS,
  COMMENT_ERROR,
  SET_COMMENT_DRAWER,
  GET_COMMENTS_FOR_ORDER,
} from "../types";

const CommentState = (props) => {
  const initialState = {
    comments: null,
    orderComments: null,
    commentDrawerVisible: false,
    loadingComment: true,
  };

  useInterval(async () => {
    try {
      const res = await axios.get("/api/comment/unread");

      dispatch({ type: GET_COMMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  }, 1800000);

  const [state, dispatch] = useReducer(commentReducer, initialState);

  // get unread
  const getComments = async () => {
    try {
      const res = await axios.get("/api/comment/unread");

      dispatch({ type: GET_COMMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  // get all
  const getAllComments = async () => {
    try {
      const res = await axios.get("/api/comment/all");

      dispatch({ type: GET_COMMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  const getCommentsForOrder = async (order) => {
    try {
      const res = await axios.get(`/api/comment/order`, {
        params: {
          orderId: order._id,
        },
      });

      dispatch({ type: GET_COMMENTS_FOR_ORDER, payload: res.data });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  const addComment = async (comment) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/comment/", { comment }, config);
      dispatch({ type: ADD_COMMENT, payload: res.data });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  const deleteComment = async (id) => {
    try {
      const res = await axios.delete(`/api/comment/delete/${id}`);

      dispatch({ type: DELETE_COMMENT, payload: id });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  // update
  const updateComment = async (comment) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(`/api/comment/update/read/${comment._id}`, comment, config);

      dispatch({ type: UPDATE_COMMENT, payload: comment });
      toast.success(`Comment is updated to read`);
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
      toast.error(`Comment is not updated`);
    }
  };

  const clearComments = () => {
    dispatch({ type: CLEAR_COMMENTS });
  };

  const setCommentDrawerVisible = (visible) => {
    dispatch({ type: SET_COMMENT_DRAWER, payload: visible });
  };

  return (
    <CommentContext.Provider
      value={{
        comments: state.comments,
        orderComments: state.orderComments,
        commentDrawerVisible: state.commentDrawerVisible,
        loadingComment: state.loadingComment,
        getComments,
        addComment,
        deleteComment,
        updateComment,
        clearComments,
        setCommentDrawerVisible,
        getCommentsForOrder,
        getAllComments,
      }}
    >
      {props.children}
    </CommentContext.Provider>
  );
};

export default CommentState;
