import {
    ADD_TRUSS,
    DELETE_TRUSS,
    CLEAR_TRUSSES,
    SET_CURRENT_TRUSS,
    CLEAR_CURRENT_TRUSS,
    UPDATE_TRUSS,
    FILTER_TRUSSES,
    CLEAR_FILTER_TRUSS,
    TRUSS_ERROR,
    GET_TRUSSES,
} from '../types'


export default ( state, action ) => {
    switch(action.type) {
        case GET_TRUSSES:
            return {
                ...state,
                trusses: action.payload,
                loading: false
            }
        case ADD_TRUSS:
            return {
                ...state,
                truss: action.payload,
                loading: false
            };
        case UPDATE_TRUSS:
            return {
                ...state,
                trusses: state.trusses.map(truss => truss._id === action.payload._id ? action.payload : truss),
                loading: false
            };
        case DELETE_TRUSS:
            return {
                ...state,
                trusses: state.trusses.filter(truss => truss._id !== action.payload),
                loading: false
            };
        case CLEAR_TRUSSES:
            return {
                ...state,
                trusses: null,
                filteredTruss: null,
                error: null,
                currentTruss: null,
            }
        case SET_CURRENT_TRUSS:
            return {
                ...state,
                currentTruss: action.payload
            };
        case CLEAR_CURRENT_TRUSS:
            return {
                ...state,
                currentTruss: null
            };
        case FILTER_TRUSSES:
            return {
                ...state,
                filteredTruss: state.trusses.filter(truss => {
                    const regex = new RegExp(`${action.payload}`, 'gi');
                    return truss.customer.value.name.match(regex) 
                })
            }
        case CLEAR_FILTER_TRUSS:
            return {
                ...state,
                filteredTruss: null
            };
        case TRUSS_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default: 
            return state;
    }
}