import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import OrderContext from '../../../../context/order/orderContext';
import AddComment from '../comments_alerts/AddComment';
import AddOnContext from '../../../../context/addon/addOnContext';
import OrderForm from './OrderForm';
import { Button, Space, Alert, Tag } from 'antd';
import Spinner from '../../../layout/Spinner';

const InQueueOrder = () => {
  const navigate = useNavigate();
  const orderContext = useContext(OrderContext);
  const { currentOrder, updateOrder, clearCurrentOrder, loadingOrders } = orderContext;
  const addOnContext = useContext(AddOnContext);
  const { getAddOns, allAddOns } = addOnContext;
  const [filteredAlerts, setFilteredAlerts] = useState([]);
 
  useEffect(() => {
    getAddOns();
    currentOrder &&
      setFilteredAlerts(
        currentOrder.alerts.filter((alert) => {
          if (alert.accepted === false) {
            return alert;
          }
        })
      );
  }, []);

  const handleAcceptAlert = (theAlert) => {
    theAlert.accepted = true;
    let theAlerts = currentOrder.alerts.map((alert) => (alert._id === theAlert._id ? theAlert : alert));
    setFilteredAlerts(
      theAlerts.filter((alert) => {
        if (alert.accepted === false) {
          return alert;
        }
      })
    );
    currentOrder.alerts = theAlerts;
    updateOrder(currentOrder);
  };

  // let filteredAlerts =
  //   currentOrder &&
  //   currentOrder.alerts.filter((alert) => {
  //     if (alert.accepted === false) {
  //       return alert;
  //     }
  //   });

  const setExit = () => {
    navigate(-1);
    clearCurrentOrder();
  };

  return (
    <Fragment>
      {loadingOrders ? (
        <div className='row'>
          <div className='col-md-12 text-center'>
            <Spinner />
          </div>
        </div>
      ) : (
        <div>
          <div className='row'>
            <div className='col-md-12'>
              <button className='float-end mb-2 mt-2 btn btn-outline-danger ' onClick={() => setExit()}>
                Back
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-1'></div>
            <div className='col-md-10'>
              {filteredAlerts &&
                filteredAlerts.map((p) => (
                  <Alert
                    key={p._id}
                    message={p.alert}
                    type='warning'
                    action={
                      <Space direction='vertical'>
                        <Button size='small' className='mb-2 btn btn-outline-primary ' onClick={() => handleAcceptAlert(p)}>
                          Accept
                        </Button>
                        {/* <Button size='small' danger type='ghost'>
                Decline
              </Button> */}
                      </Space>
                    }
                    // closable
                  />
                ))}
            </div>
          </div>

          <div className='row'>
            <div className='col-md-1'></div>
            <div className='col-md-10 '>
              <AddComment currentOrder={currentOrder} />
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-2'>
              <h5 className='float-end'>Status:</h5>
            </div>
            <div className='col-md-3'>
              <Tag className='mytag float-start ' color='green'>
                {currentOrder && currentOrder.status}
              </Tag>
            </div>
            <div className='col-md-3'>
              <h3 className='text-center'> Order Details</h3>
            </div>
            <div className='col-md-4'></div>
          </div>
          <div className='row '>
            <div className='col-md-1'></div>
            <div className='col-md-10 '>
              <OrderForm />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default InQueueOrder;
