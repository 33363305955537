import React, { useContext, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { addOnCategories, laborTypes } from '../../../names/shedNames';
import AddOnLaborContext from '../../../../context/addOnLabor/addOnLaborContext';
import AddOnContext from '../../../../context/addon/addOnContext';
import { Controller, useForm } from 'react-hook-form';

const initialState = {
  name: {
    label: '',
    value: '',
  },
  laborType: {
    label: '',
    value: '',
  },
  category: {
    label: '',
    value: '',
  },
  laborTotal: 0,
  standard: true,
};

const UpdateAddOnLabor = () => {
  const navigate = useNavigate();
  const addOnLaborContext = useContext(AddOnLaborContext);
  const { updateAddOnLabor, currentShopAddOnLabor } = addOnLaborContext;
  const addOnContext = useContext(AddOnContext);
  const { getAddOns, allAddOns } = addOnContext;

  useEffect(() => {
    getAddOns();
    reset(currentShopAddOnLabor);
  }, []);

  const addOnOptions =
    allAddOns &&
    allAddOns.map((addOn) => {
      return { label: `${addOn.name.label}`, value: addOn.name.value };
    });

  // destructure
  const { name, laborType, category, laborTotal } = initialState;
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    initialState,
  });

  const onSubmit = (values) => {
    // e.preventDefault();
    // addProduct(values);
    // navigate(-1);
    console.log(values);
    updateAddOnLabor(values);
  };

  const setCategory = (theCategory) => {
    setValue(`category`, theCategory);
  };

  const setType = (theType) => {
    setValue(`laborType`, theType);
  };

  const setName = (theName) => {
    setValue(`name`, theName);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 mb-4'>
            <button className='btn btn-outline-danger float-end' type='button' onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-md-1'> </div>

          <div className='col-md-4 bidformbackground border border-dark' style={{ borderRadius: '15px' }}>
            <h4 className='text-center mt-2'>Shop Labor Item</h4>

            <div className='form-group'>
              <label>Category</label>

              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={addOnCategories}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    onChange={(e) => setCategory(e)}
                  />
                )}
                placeholder={category}
                name={`category`}
                control={control}
                rules={{ required: true }}
              />
            </div>

            <div className='form-group'>
              <label>Type</label>
              <Controller
                render={({ field }) => (
                  <Select {...field} options={laborTypes} menuPortalTarget={document.body} menuPosition={'fixed'} onChange={(e) => setType(e)} />
                )}
                placeholder={laborType}
                name={`laborType`}
                control={control}
                rules={{ required: true }}
              />
            </div>

            <div className='form-group'>
              <label>Name</label>
              <Controller
                render={({ field }) => (
                  <Select {...field} options={addOnOptions} menuPortalTarget={document.body} menuPosition={'fixed'} onChange={(e) => setName(e)} />
                )}
                placeholder={name}
                name={`name`}
                control={control}
                rules={{ required: true }}
              />
            </div>

            <div className='form-group'>
              <label>Total Labor</label>
              <input className='form-control inputbg' name='laborTotal' type='number' step={0.01} {...register('laborTotal', { required: true })} />
            </div>

            <br />
            <button className='btn btn-outline-info mb-2 float-end'>Save</button>
          </div>
          <div className='col-md-2'></div>
          <StandardUpdateAddOnLabor />
        </div>
      </div>
    </form>
  );
};

const StandardUpdateAddOnLabor = () => {
  const addOnLaborContext = useContext(AddOnLaborContext);
  const { currentStandardAddOnLabor } = addOnLaborContext;

  useEffect(() => {
    reset(currentStandardAddOnLabor);
  }, []);

  // destructure
  const { name, laborType, category, laborTotal } = initialState;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    initialState,
  });

  return (
    <div className='col-md-4 bidformbackground border border-dark' style={{ borderRadius: '15px' }}>
      <h4 className='text-center mt-2'>Standard Labor Item</h4>

      <div className='form-group'>
        <label>Category</label>

        <Controller
          render={({ field }) => (
            <Select
              readOnly
              {...field}
              options={addOnCategories}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              // onChange={(e) => setCategory(e)}
            />
          )}
          placeholder={category}
          name={`category`}
          control={control}
          // rules={{ required: true }}
        />
      </div>

      <div className='form-group'>
        <label>Type</label>
        <Controller
          render={({ field }) => <Select readOnly {...field} options={laborTypes} menuPortalTarget={document.body} menuPosition={'fixed'} />}
          placeholder={laborType}
          name={`laborType`}
          control={control}
          // rules={{ required: true }}
        />
      </div>

      <div className='form-group'>
        <label>Name</label>
        <Controller
          render={({ field }) => <Select readOnly {...field} menuPortalTarget={document.body} menuPosition={'fixed'} />}
          placeholder={name}
          name={`name`}
          control={control}
          // rules={{ required: true }}
        />
      </div>

      <div className='form-group'>
        <label>Total Labor</label>
        <input
          readOnly
          className='form-control inputbg'
          name='laborTotal'
          type='number'
          step={0.01}
          {...register('laborTotal', { required: true })}
        />
      </div>
    </div>
  );
};

export default UpdateAddOnLabor;
