import React, { useState, useContext, useEffect } from "react";
import { Comment, Form, Button, List, Input } from "antd";
import CommentContext from "../../../../context/comment/commentContext";
import AuthContext from "../../../../context/auth/authContext";

// import axios from "axios";

// import io from "socket.io-client";
// const ENDPOINT = "http://localhost:5006";
// var socket, currentOrderCompare;

const { TextArea } = Input;

const CommentList = ({ orderComments }) => (
  <List
    className="myCommentBackground p-2"
    dataSource={orderComments}
    header={`${orderComments.length} ${orderComments.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={(item) => (
      <li>
        <Comment
          // actions={item.actions}
          author={item.author}
          content={item.content}
          datetime={item.datetime}
        />
      </li>
    )}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </>
);

const AddComment = ({ currentOrder }) => {
  const commentContext = useContext(CommentContext);
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { addComment, orderComments, getCommentsForOrder } = commentContext;
  // const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");

  // const [messages, setMessages] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [newMessage, setNewMessage] = useState("");
  // const [socketConnected, setSocketConnected] = useState(false);
  // const [typing, setTyping] = useState(false);
  // const [istyping, setIsTyping] = useState(false);
  // const [notification, setNotification] = useState();
  // const [fetchAgain, setFetchAgain] = useState();

  // const fetchMessages = async () => {
  //   if (!currentOrder) return;
  //   try {
  //     setLoading(true);
  //     const { data } = await axios.get(`/api/message/${currentOrder._id}`);
  //     setMessages(data);
  //     setLoading(false);
  //     socket.emit("join chat", currentOrder._id);
  //   } catch (error) {
  //     //  toast({
  //     //    title: "Error Occured!",
  //     //    description: "Failed to Load the Messages",
  //     //    status: "error",
  //     //    duration: 5000,
  //     //    isClosable: true,
  //     //    position: "bottom",
  //     //  });
  //     console.log("there is a error", error);
  //   }
  // };

  // const sendMessage = async () => {
  //   // if (event.key === "Enter" && newMessage) {
  //   // socket.emit("stop typing", currentOrder._id);
  //   try {
  //     let today = new Date();
  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //       },
  //     };
  //     setNewMessage("");
  //     const { data } = await axios.post(
  //       "/api/message",
  //       {
  //         order: currentOrder._id,
  //         author: user.name,
  //         content: value,
  //         commentFor: currentOrder.company,
  //         commentFrom: user,
  //         datetime: today.toLocaleString(),
  //       },
  //       config
  //     );
  //     socket.emit("new message", data);
  //     setMessages([...messages, data]);
  //   } catch (error) {
  //     // toast({
  //     //   title: "Error Occured!",
  //     //   description: "Failed to send the Message",
  //     //   status: "error",
  //     //   duration: 5000,
  //     //   isClosable: true,
  //     //   position: "bottom",
  //     // });
  //     console.log("there is a error", error);
  //   }
  //   // }
  // };

  useEffect(() => {
    // socket = io(ENDPOINT);
    // socket.emit("setup", user);
    // socket.on("connected", () => setSocketConnected(true));
    // socket.on("typing", () => setIsTyping(true));
    // socket.on("stop typing", () => setIsTyping(false));
    currentOrder && getCommentsForOrder(currentOrder);
  }, []);

  // useEffect(() => {
  //   // fetchMessages();
  //   currentOrderCompare = currentOrder;
  //   // eslint-disable-next-line
  // }, [currentOrder]);

  // useEffect(() => {
  //   socket.on("message recieved", (newMessageRecieved) => {
  //     if (
  //       !currentOrderCompare || // if chat is not selected or doesn't match current chat
  //       currentOrderCompare._id !== newMessageRecieved.order._id
  //     ) {
  //       if (!notification.includes(newMessageRecieved)) {
  //         setNotification([newMessageRecieved, ...notification]);
  //         setFetchAgain(!fetchAgain);
  //       }
  //     } else {
  //       setMessages([...messages, newMessageRecieved]);
  //     }
  //   });
  // });

  useEffect(() => {
    setValue("");
  }, [orderComments]);

  const handleSubmit = () => {
    if (!value) {
      return;
    }
    // sendMessage();
    let today = new Date();
    setSubmitting(true);
    addComment({
      content: value,
      datetime: today.toLocaleString(),
      author: user.name,
      order: currentOrder,
      commentFor: currentOrder.company,
      commentFrom: user,
    });
    setSubmitting(false);
    // setTimeout(() => {
    //   setSubmitting(false);
    //   setValue('');
    //   setComments([
    //     ...comments,
    //     {
    //       author: 'Han Solo',
    //       avatar: 'https://joeschmoe.io/api/v1/random',
    //       content: <p>{value}</p>,
    //       datetime: today.toLocaleString(),
    //     },
    //   ]);
    // }, 1000);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  orderComments &&
    orderComments.forEach((comment) => {
      comment.datetime = new Date(comment.datetime).toLocaleString();
    });

  return (
    <>
      {orderComments && orderComments.length > 0 && <CommentList orderComments={orderComments} />}
      <Comment
        className="myCommentBackground p-2"
        author={user.name}
        // avatar={<Avatar />}
        content={<Editor onChange={handleChange} onSubmit={handleSubmit} submitting={submitting} value={value} />}
      />
    </>
  );
};

export default AddComment;
