import React, { Fragment, useState, useContext } from 'react';
import { Menu } from 'antd';
import { Outlet, Link } from 'react-router-dom';
import AddOnLaborContext from '../../../../context/addOnLabor/addOnLaborContext';

const AddOnLabor = () => {
  const addOnLaborContext = useContext(AddOnLaborContext);
  const { clearAddOnsLabor } = addOnLaborContext;

  const [current, setCurrent] = useState();
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  const handleSwitchAddOn = () => {
    clearAddOnsLabor();
  };

  return (
    <Fragment>
      <Menu className='mb-2 border' onClick={handleMenuChange} selectedKeys={[current]} mode='horizontal'>
        <Menu.Item key='doors'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/addons/doors`}>
            Doors
          </Link>
        </Menu.Item>
        <Menu.Item key='windows'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/addons/windows`}>
            Windows
          </Link>
        </Menu.Item>
        <Menu.Item key='electric'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/addons/electric`}>
            Electric
          </Link>
        </Menu.Item>
        <Menu.Item key='exterior'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/addons/exterior`}>
            Exterior
          </Link>
        </Menu.Item>
        <Menu.Item key='interior'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/addons/interior`}>
            Interior
          </Link>
        </Menu.Item>
        <Menu.Item key='upgrades'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/addons/upgrades`}>
            Upgrades
          </Link>
        </Menu.Item>
        <Menu.Item key='extras'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/addons/extras`}>
            Extras
          </Link>
        </Menu.Item>
        <Menu.Item key='add'>
          <Link onClick={() => handleSwitchAddOn()} to={`/labor/addons/add`}>
            Add Labor Item
          </Link>
        </Menu.Item>
      </Menu>
      <Outlet />
    </Fragment>
  );
};

export default AddOnLabor;
