import React, { Fragment } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

const AddOns = ({ addOn, addOnIndex, control, remove, setValue, getValues, addOnOptions, register, settings }) => {
  const setTotals = () => {
    let theAddOns = getValues(`addOns`);
    let theCustomAddOns = getValues(`customAddOns`);
    let theBasePrice = getValues('basePrice');
    let theShopPercentOfBasePrice = getValues('shopPercentOfBuildingBasePrice');
    let theStorMorAddOnsTotal = theAddOns && theAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorAddOnTotalSalePrice || 0), 0);
    let theShopsAddOnsTotal = theAddOns && theAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopAddOnTotalSalePrice || 0), 0);
    let theStorMorCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorCustomAddOnsTotalSalePrice || 0), 0);
    let theShopsCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopCustomAddOnsTotalSalePrice || 0), 0);
    let theShopTotalCost = getValues('shopTotalCost');
    let theStandardTotalCost = getValues('standardTotalCost');
    let theShopTotal = theShopPercentOfBasePrice + theShopsAddOnsTotal + theShopsCustomAddOnsTotal;
    let theShopProfitDollars = theShopTotal - theShopTotalCost;
    let theStandardProfitDollars = theShopTotal - theStandardTotalCost;
    //
    // storMor cost and profit begins here
    let theStorMorTotalSalePrice = +theBasePrice + +theStorMorAddOnsTotal + +theStorMorCustomAddOnsTotal;
    let theDealerCommission = +theStorMorTotalSalePrice * (+settings.parentCompanySettings.dealerCommission / 100);
    let theSalesRepCommission = +theStorMorTotalSalePrice * (+settings.parentCompanySettings.salesRepCommission / 100);
    let theStorMorOverhead = +theStorMorTotalSalePrice * (+settings.parentCompanySettings.storMorOverhead / 100);
    let theStorMorTotalCost = +theShopTotal + +theDealerCommission + +theSalesRepCommission + +theStorMorOverhead;
    let theStorMorProfitDollars = +theStorMorTotalSalePrice - +theStorMorTotalCost;
    let theStorMorProfitPercent = +theStorMorProfitDollars / +theStorMorTotalSalePrice;
    setValue('storMorTotalSalePrice', theStorMorTotalSalePrice);
    setValue('dealerCommission', theDealerCommission);
    setValue('salesRepCommission', theSalesRepCommission);
    setValue('storMorOverhead', theStorMorOverhead);
    setValue('storMorTotalCost', theStorMorTotalCost);
    setValue('storMorProfitDollars', theStorMorProfitDollars);
    setValue('storMorProfitPercent', theStorMorProfitPercent);
    // storMor cost and profit ends here
    //

    setValue('shopTotalSalePrice', theShopTotal);
    setValue('shopProfitDollars', theShopProfitDollars);
    setValue('shopProfitPercent', (theShopProfitDollars / theShopTotal) * 100);
    setValue('storMorAddOnsTotalSalePrice', theStorMorAddOnsTotal);
    setValue('shopAddOnsTotalSalePrice', theShopsAddOnsTotal);

    setValue('standardProfitDollars', theStandardProfitDollars);
    setValue('standardProfitPercent', (theStandardProfitDollars / theShopTotal) * 100);
  };

  const setAddOnChange = (addon) => {
    let count = getValues(`addOns[${addOnIndex}].quantity`);
    setValue(`addOns[${addOnIndex}].shedProRetailPrice`, addon.value.retailPrice);
    setValue(`addOns[${addOnIndex}].shedProRetailPercent`, addon.value.retailPercent);
    setValue(`addOns[${addOnIndex}].addOn.shopPercent`, addon.value.shopPercent);
    setValue(`addOns[${addOnIndex}].shopAddOnTotalSalePrice`, addon.value.retailPrice * count * addon.value.shopPercent);
    setValue(`addOns[${addOnIndex}].storMorAddOnTotalSalePrice`, addon.value.retailPrice * count);
    setValue(`addOns[${addOnIndex}].addOn.name`, addon.value.name);
    setValue(`addOns[${addOnIndex}].addOn`, addon.value);
    setTotals();
  };

  // const setShopsAddOnPercent = (percent) => {
  //   let theAddOnsPrice = getValues(`addOns[${addOnIndex}].storMorAddOnTotalSalePrice`);
  //   setValue(`addOns[${addOnIndex}].shopAddOnTotalSalePrice`, theAddOnsPrice * percent);
  //   setValue(`addOns[${addOnIndex}].addOn.shopPercent`, percent);
  //   setTotals();
  // };

  // if price is based off of dollars using this function
  const setShopsAddOnPrice = (price) => {
    let theShopsAddOnPercent = getValues(`addOns[${addOnIndex}].addOn.shopPercent`);
    let theAddOnsCount = getValues(`addOns[${addOnIndex}].quantity`);
    let theShopAddOnTotalCost = getValues(`addOns[${addOnIndex}].shopAddOnTotalCost`);
    let theStandardAddOnTotalCost = getValues(`addOns[${addOnIndex}].standardAddOnTotalCost`);
    let theShopAddOnTotalSalePrice = price * +theAddOnsCount * (+theShopsAddOnPercent / 100);
    let theShopAddOnProfit = +theShopAddOnTotalSalePrice - +theShopAddOnTotalCost;
    let theStandardAddOnProfit = +theShopAddOnTotalSalePrice - +theStandardAddOnTotalCost;
    let theShopAddOnProfitPercent = ((+theShopAddOnProfit / +theShopAddOnTotalSalePrice) * 100).toFixed(2);
    let theStandardAddOnProfitPercent = ((+theStandardAddOnProfit / +theShopAddOnTotalSalePrice) * 100).toFixed(2);
    setValue(`addOns[${addOnIndex}].shopAddOnProfit`, theShopAddOnProfit);
    setValue(`addOns[${addOnIndex}].standardAddOnProfit`, theStandardAddOnProfit);
    setValue(`addOns[${addOnIndex}].shopAddOnProfitPercent`, theShopAddOnProfitPercent);
    setValue(`addOns[${addOnIndex}].standardAddOnProfitPercent`, theStandardAddOnProfitPercent);
    setValue(`addOns[${addOnIndex}].shedProRetailPrice`, price);
    setValue(`addOns[${addOnIndex}].storMorAddOnTotalSalePrice`, price * theAddOnsCount);
    setValue(`addOns[${addOnIndex}].shopAddOnTotalSalePrice`, theShopAddOnTotalSalePrice);
    setTotals();
  };

  // if price is based off percent using this function
  const setShopsAddOnPricePercent = (percent) => {
    let theBasePrice = getValues('basePrice');
    let theShopsAddOnPercent = getValues(`addOns[${addOnIndex}].addOn.shopPercent`);
    let theStorMorAddOnTotalSalePrice = +theBasePrice * (+percent / 100);
    let theShopAddOnTotalSalePrice = +theStorMorAddOnTotalSalePrice * (+theShopsAddOnPercent / 100);
    let theShopAddOnTotalCost = getValues(`addOns[${addOnIndex}].shopAddOnTotalCost`);
    let theShopAddOnProfit = +theShopAddOnTotalSalePrice - +theShopAddOnTotalCost;
    let theShopAddOnProfitPercent = ((+theShopAddOnProfit / +theShopAddOnTotalSalePrice) * 100).toFixed(2);
    // let theAddOnsCount = getValues(`addOns[${addOnIndex}].quantity`);
    setValue(`addOns[${addOnIndex}].shopAddOnProfit`, theShopAddOnProfit);
    setValue(`addOns[${addOnIndex}].shopAddOnProfitPercent`, theShopAddOnProfitPercent);
    setValue(`addOns[${addOnIndex}].shedProRetailPercent`, percent);
    setValue(`addOns[${addOnIndex}].storMorAddOnTotalSalePrice`, theStorMorAddOnTotalSalePrice);
    setValue(`addOns[${addOnIndex}].shopAddOnTotalSalePrice`, theShopAddOnTotalSalePrice);
    setTotals();
  };

  // const setShopsAddOnCount = (count) => {
  //   let theShopsAddOnPercent = getValues(`addOns[${addOnIndex}].addOn.shopPercent`);
  //   let theShopAddOnsRetailPrice = getValues(`addOns[${addOnIndex}].shedProRetailPrice`);
  //   // let theBasePrice = getValues('basePrice');
  //   // load settings
  //   // if non inv item find the matching one
  //   // figure cost for inv addons
  //   // if (addOn.addOn.type.value === 'sqft') {
  //   //   let theCost = addOn.addOn.retailPrice * count;
  //   //   setValue(`addOns[${addOnIndex}].shopAddOnTotalCost`, theCost);
  //   // } else if (addOn.addOn.type.value === 'percent') {
  //   //   let theCost = addOn.addOn.retailPercent * theBasePrice;
  //   //   setValue(`addOns[${addOnIndex}].shopAddOnTotalCost`, theCost);
  //   // } else {
  //   //   let theCost = addOn.addOn.retailPrice * count;
  //   //   setValue(`addOns[${addOnIndex}].shopAddOnTotalCost`, theCost);
  //   // }
  //   setValue(`addOns[${addOnIndex}].quantity`, count);
  //   setValue(`addOns[${addOnIndex}].storMorAddOnTotalSalePrice`, count * theShopAddOnsRetailPrice);
  //   setValue(`addOns[${addOnIndex}].shopAddOnTotalSalePrice`, count * +theShopAddOnsRetailPrice * (+theShopsAddOnPercent / 100));
  //   setTotals();
  // };

  return (
    <Fragment>
      <div className='row border rowdivider'>
        <div className='bglight'>
          <div className='row '>
            <div className='col-md-1'>
              <label className='float-end'>Name</label>
            </div>
            <div className='col-md-2'>
              <Controller
                render={({ field }) => (
                  <ReactSelect
                  
                    {...field}
                    options={addOnOptions}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    // onChange={(e) => setAddOnChange(e)}
                    isDisabled={true}
                  />
                )}
                
                key={addOn.id}
                name={`addOns[${addOnIndex}].addOn.name`}
                control={control}
              />
            </div>

            <div className='col-md-1'>
              <label className='float-end'>Count</label>
            </div>
            <div className='col-md-1'>
              <Controller
                render={({ field, name }) => (
                  <input
                    readOnly
                    type='number'
                    step='.01'
                    className='form-control inputfield'
                    name={name}
                    {...field}
                    // onChange={(e) => setShopsAddOnCount(e.target.value)}
                  />
                )}
                key={addOn.id}
                control={control}
                name={`addOns[${addOnIndex}].quantity`}
              />
            </div>

            {addOn.addOn && addOn.addOn.type.value === 'percent' ? (
              <Fragment>
                <div className=' col-md-1'>
                  <label className='float-end'>Percent</label>
                </div>
                <div className='col-md-2'>
                  <Controller
                    render={({ field, name }) => (
                      <input
                        readOnly
                        type='number'
                        step='.01'
                        className='form-control inputfield'
                        name={name}
                        {...field}
                        // onChange={(e) => setShopsAddOnPricePercent(e.target.value)}
                      />
                    )}
                    key={addOn.id}
                    control={control}
                    name={`addOns[${addOnIndex}].shedProRetailPercent`}
                  />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className=' col-md-1'>
                  <label className='float-end'>Price</label>
                </div>
                <div className='col-md-2'>
                  <Controller
                    render={({ field, name }) => (
                      <input
                        readOnly
                        type='number'
                        step='.01'
                        className='form-control inputfield'
                        name={name}
                        {...field}
                        // onChange={(e) => setShopsAddOnPrice(e.target.value)}
                      />
                    )}
                    key={addOn.id}
                    control={control}
                    name={`addOns[${addOnIndex}].shedProRetailPrice`}
                  />
                </div>
              </Fragment>
            )}

            <Fragment>
              <div className='col-md-2'>
                <label className='float-end'>StorMor Total</label>
              </div>
              <div className='col-md-2'>
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className='form-group form-control inputfield'
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={'$ '}
                      displayType='input'
                      decimalScale={2}
                      fixedDecimalScale='true'
                    />
                  )}
                  name={`addOns[${addOnIndex}].storMorAddOnTotalSalePrice`}
                  control={control}
                />
              </div>
            </Fragment>
          </div>

          {/* // the second row ends here */}

          <div className='row mt-2'>
            <div className='col-md-1 '>
              <label className='float-end'>Labor</label>
            </div>
            <div className='col-md-1 '>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control inputfield redbg'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={'$ '}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`addOns[${addOnIndex}].shopAddOnTotalLaborCost`}
                control={control}
              />
            </div>

            <div className='col-md-1 '>
              <label className='float-end'>Material</label>
            </div>
            <div className='col-md-1 '>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control inputfield redbg'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    displayType='input'
                    decimalScale={2}
                    prefix={'$ '}
                    fixedDecimalScale='true'
                  />
                )}
                name={`addOns[${addOnIndex}].shopAddOnTotalMaterialCost`}
                control={control}
              />
            </div>

            <div className='col-md-2'>
              <label className='float-end'>Shops %</label>
            </div>
            <div className='col-md-2'>
              <Controller
                render={({ field, name }) => (
                  <input
                    readOnly
                    type='number'
                    step={'.01'}
                    className='form-control inputfield'
                    name={name}
                    {...field}
                    // onChange={(e) => setShopsAddOnPercent(e.target.value)}
                  />
                )}
                key={addOn.id}
                control={control}
                name={`addOns[${addOnIndex}].addOn.shopPercent`}
              />
            </div>

            <div className='col-md-2'>
              <label className='float-end boldLabel'>Shops Total Sale Price</label>
            </div>
            <div className='col-md-2'>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control inputfield darkgreenbg'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={'$ '}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`addOns[${addOnIndex}].shopAddOnTotalSalePrice`}
                control={control}
              />
            </div>
          </div>

          {/* // the second third row starts here */}
          <div className='row mt-2'>
            <div className='col-md-1 '>
              <label className='float-end boldLabel'>Total Cost</label>
            </div>
            <div className='col-md-2 '>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control inputfield redbg'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={'$ '}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`addOns[${addOnIndex}].shopAddOnTotalCost`}
                control={control}
              />
            </div>

            <div className='col-md-1'></div>

            <div className='col-md-2'>
              <label className='float-end boldLabel'>Shops GP $</label>
            </div>
            <div className='col-md-2'>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control inputfield greenbg'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    displayType='input'
                    decimalScale={2}
                    prefix={'$ '}
                    fixedDecimalScale='true'
                  />
                )}
                name={`addOns[${addOnIndex}].shopAddOnProfit`}
                control={control}
              />
            </div>

            <div className='col-md-2'>
              <label className='float-end boldLabel'>Shops GP %</label>
            </div>
            <div className='col-md-2'>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control inputfield greenbg'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    // thousandSeparator={true}
                    displayType='input'
                    // decimalScale={2}
                    suffix={'%'}
                    fixedDecimalScale='true'
                  />
                )}
                name={`addOns[${addOnIndex}].shopAddOnProfitPercent`}
                control={control}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, addOnOptions, settings }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addOns',
  });

  return (
    <div className='container-fluid border mt-4' style={{ borderRadius: '8px' }}>
      {fields.map((addOn, addOnIndex) => (
        <AddOns
          key={addOn.id}
          addOn={addOn}
          addOnIndex={addOnIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          addOnOptions={addOnOptions}
          settings={settings}
        />
      ))}
      {/* <button
        className='btn btn-outline-info mb-2 mt-2  '
        onClick={(e) => {
          e.preventDefault();
          append({ append: 1 });
        }}
      >
        Add Addon
      </button> */}
    </div>
  );
}
