import React, { useReducer } from 'react'
import axios from 'axios'
import AuthContext from './authContext'
import authReducer from './authReducer'
import setAuthToken from '../../utils/SetAuthToken'
import { toast } from "react-toastify";

import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    LOAD_USERS,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS,
    CLEAR_SHEDS,

} from '../types'

const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        isAuthenticated: null,
        loading: true,
        user: null,
        error: null,
        users: null,
    };

    const [state, dispatch] = useReducer(authReducer, initialState)


    // load user
    const loadUser = async () => {
         // load token into global headers
         if(localStorage.token) {
             setAuthToken(localStorage.token);
         }


         try {
            const res = await axios.get('/api/auth');
            dispatch({ type: USER_LOADED, payload: res.data})
        } catch (err) {
            dispatch({ type: AUTH_ERROR })
        }
    }






     // load all users
    const loadAllUsers = async () => {
        try {
           const res = await axios.get('/api/users/allusers');
           dispatch({ type: LOAD_USERS, payload: res.data})
       } catch (err) {
           dispatch({ type: AUTH_ERROR })
       }
    }

    const copyEverything = async newUserId => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await axios.post('/api/users/copyeverything/', {newUserId}, config)
            toast.success(`Copy was successful`)
           
        } catch (err) {
            dispatch({ type: AUTH_ERROR})
            
        }  
    }







    // register user
    const register = async formData => {
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/users', formData, config)

            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data
            })
            loadUser()
        } catch (err) {
            dispatch({
                type: REGISTER_FAIL,
                payload: err.response.data.msg
            })
        }
    }


    // login user 
    const login = async formData => {
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/auth', formData, config)

            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            })
            loadUser()
        } catch (err) {
            dispatch({
                type: LOGIN_FAIL,
                payload: err.response.data.msg
            })
        }
    }


    // logout user
    const logout = () => {
      dispatch({ type: LOGOUT })
      dispatch({ type: CLEAR_SHEDS })
    }


    // clear errors
    const clearErrors = () => {
        dispatch({ type: CLEAR_ERRORS })
    }



    

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuthenticated: state.isAuthenticated,
                loading: state.loading,
                user: state.user,
                users: state.users,
                error: state.error,
                register,
                login,
                loadUser,
                logout,
                clearErrors,
                loadAllUsers,
                copyEverything,

            }}>
             { props.children }
        </AuthContext.Provider>
    )


}

export default AuthState;