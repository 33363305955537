import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import ShedLaborContext from "./shedLaborContext";
import shedLaborReducer from "./shedLaborReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_SHEDLABOR,
  DELETE_SHEDLABOR,
  CLEAR_SHEDLABORS,
  SET_CURRENT_SHOP_SHEDLABOR,
  CLEAR_CURRENT_SHOP_SHEDLABOR,
  SET_CURRENT_STANDARD_SHEDLABOR,
  CLEAR_CURRENT_STANDARD_SHEDLABOR,
  UPDATE_SHEDLABOR,
  SET_SHEDLABORS,
  SHEDLABOR_ERROR,
  GET_SHOP_SHEDLABORS,
  GET_STANDARD_SHEDLABORS,
} from "../types";

const ShedLaborState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    shopShedsLabor: null,
    standardShedsLabor: null,
    currentShopShedLabor: null,
    currentStandardShedLabor: null,
    error: null,
  };

  const [state, dispatch] = useReducer(shedLaborReducer, initialState);

  const getShopShedsLabor = async (style) => {
    try {
      const res = await axios.get(`/api/shedlabor/${style}`);
      dispatch({ type: GET_SHOP_SHEDLABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed ${err}`);
    }
  };

  const getStandardShedsLabor = async (style) => {
    try {
      const res = await axios.get(`/api/shedlabor/standard/${style}`);
      dispatch({ type: GET_STANDARD_SHEDLABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed ${err}`);
    }
  };

  // const getAllShedsLabor = async () => {
  //   try {
  //     const res = await axios.get(`/api/shedlabor`);
  //     dispatch({ type: GET_SHEDLABORS, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: SHEDLABOR_ERROR });
  //     toast.error(`Shed ${err}`);
  //   }
  // };

  // Bulk update
  const updateBulkShedLabor = async (values) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(`/api/shedlabor/all/update`, values, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      // dispatch({ type: UPDATE_SHEDLABOR, payload: res.data });
      dispatch({ type: CLEAR_SHEDLABORS });
      navigate(-1);
      toast.success(`Shed Labor is updated`);
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed Labor ${err}`);
    }
  };

  // add
  const addShedLabor = async (labor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/shedlabor", labor, config);
      dispatch({ type: ADD_SHEDLABOR, payload: res.data });
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
    }
  };

  // delete
  const deleteShedLabor = async (id) => {
    try {
      const res = await axios.delete(`/api/shedlabor/delete/${id}`);
      dispatch({ type: DELETE_SHEDLABOR, payload: id });
      toast.success(`ShedLabor is deleted`);
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
    }
  };

  // update
  const updateShedLabor = async (labor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shedlabor/update/${labor._id}`, labor, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_SHEDLABOR, payload: res.data });
      navigate(-1);
      toast.success(`Shed Labor is updated`);
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed Labor ${err}`);
    }
  };

  // clear
  const clearShedsLabor = () => {
    dispatch({ type: CLEAR_SHEDLABORS });
  };

  // set current
  const setCurrentShopShedLabor = (labor) => {
    dispatch({ type: SET_CURRENT_SHOP_SHEDLABOR, payload: labor });
  };

  // set current
  const setCurrentStandardShedLabor = (labor) => {
    dispatch({ type: SET_CURRENT_STANDARD_SHEDLABOR, payload: labor });
  };

  const setShedsLabor = (labors) => {
    dispatch({ type: SET_SHEDLABORS, payload: labors });
  };

  // clear current
  const clearCurrentShopShedLabor = () => {
    dispatch({ type: CLEAR_CURRENT_SHOP_SHEDLABOR });
  };

  // clear current
  const clearCurrentStandardShedLabor = () => {
    dispatch({ type: CLEAR_CURRENT_STANDARD_SHEDLABOR });
  };

  return (
    <ShedLaborContext.Provider
      value={{
        shopShedsLabor: state.shopShedsLabor,
        standardShedsLabor: state.standardShedsLabor,
        currentShopShedLabor: state.currentShopShedLabor,
        currentStandardShedLabor: state.currentStandardShedLabor,
        error: state.error,
        getShopShedsLabor,
        getStandardShedsLabor,
        updateBulkShedLabor,
        setShedsLabor,
        addShedLabor,
        deleteShedLabor,
        setCurrentShopShedLabor,
        setCurrentStandardShedLabor,
        clearCurrentShopShedLabor,
        clearCurrentStandardShedLabor,
        updateShedLabor,
        clearShedsLabor,
        // getAllShedsLabor,
        // updateAllSheds,
      }}
    >
      {props.children}
    </ShedLaborContext.Provider>
  );
};

export default ShedLaborState;
