import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
       
    },
    title: {
        width: '35%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    colors: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        
    },
   
    
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.title}>SKU</Text>
        <Text style={styles.title}>Serial#</Text>
        <Text style={styles.colors}>Colors</Text>
        
        
    </View>
  );
  
  export default InvoiceTableHeader