import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';

import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';
import SettingsState from './context/settings/SettingsState';
import OrderState from './context/order/OrderState';
import DoorState from './context/door/DoorState';
import MetalState from './context/metal/MetalState';
import ProductState from './context/product/ProductState';
import ShedState from './context/shed/ShedState';
import TrussState from './context/truss/TrussState';
import AddOnState from './context/addon/AddOnState';
import CommentState from './context/comment/CommentState';
import InvoiceState from './context/invoice/InvoiceState';
import VendorState from './context/vendor/VendorState';
import MetalOrderState from './context/metalOrder/MetalOrderState';
import MaterialOrderState from './context/materialOrder/MaterialOrderState';
import PaymentState from './context/payment/PaymentState';
import ShedLaborState from './context/shedLabor/ShedLaborState';
import AddOnLaborState from './context/addOnLabor/AddOnLaborState';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthState>
        <AlertState>
          <SettingsState>
            <DoorState>
              <MetalState>
                <ProductState>
                  <ShedState>
                    <TrussState>
                      <OrderState>
                        <AddOnState>
                          <CommentState>
                            <InvoiceState>
                              <VendorState>
                                <MetalOrderState>
                                  <MaterialOrderState>
                                    <PaymentState>
                                      <ShedLaborState>
                                        <AddOnLaborState>
                                          <Routes>
                                            <Route path='/*' element={<App />} />
                                          </Routes>
                                        </AddOnLaborState>
                                      </ShedLaborState>
                                    </PaymentState>
                                  </MaterialOrderState>
                                </MetalOrderState>
                              </VendorState>
                            </InvoiceState>
                          </CommentState>
                        </AddOnState>
                      </OrderState>
                    </TrussState>
                  </ShedState>
                </ProductState>
              </MetalState>
            </DoorState>
          </SettingsState>
        </AlertState>
      </AuthState>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
