import React, { useReducer } from 'react';
import MaterialOrderContext from './materialOrderContext';
import materialOrderReducer from './materialOrderReducer';
import axios from 'axios';

import { toast } from 'react-toastify';

import {
  ADD_MATERIALORDER,
  DELETE_MATERIALORDER,
  CLEAR_MATERIALORDERS,
  SET_CURRENT_MATERIALORDER,
  CLEAR_CURRENT_MATERIALORDER,
  UPDATE_MATERIALORDER,
  FILTER_MATERIALORDERS,
  CLEAR_FILTER_MATERIALORDER,
  MATERIALORDER_ERROR,
  GET_MATERIALORDERS,
  GET_RECIEVED_MATERIALORDERS,
  SET_LOADING_MATERIALORDERS,
} from '../types';

const MaterialOrderState = (props) => {
  const initialState = {
    materialOrders: null,
    currentMaterialOrder: null,
    filteredMaterialOrder: null,
    loadingMaterialOrders: false,
    error: null,
  };

  const [state, dispatch] = useReducer(materialOrderReducer, initialState);

  // get material Orders
  const getMaterialOrders = async () => {
    try {
      const res = await axios.get('/api/materialorder');

      dispatch({ type: GET_MATERIALORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: MATERIALORDER_ERROR });
    }
  };

  const getMaterialOrdersForReport = async (dates) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post('/api/materialorder/datefilter/report', { dates }, config);
      setLoadingMaterialOrders(false);
      dispatch({ type: GET_MATERIALORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: MATERIALORDER_ERROR });
    }
  };

  // get recieved material Orders
  const getRecievedMaterialOrders = async (product) => {
    try {
      const res = await axios.get(`/api/materialorder/recieved/${product._id}`);

      dispatch({ type: GET_RECIEVED_MATERIALORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: MATERIALORDER_ERROR });
    }
  };

  // add material Order
  const addMaterialOrder = async (materialOrder) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post('/api/materialorder', { materialOrder }, config);

      dispatch({ type: ADD_MATERIALORDER, payload: res.data });
      toast.success(`Material Order is created`);
    } catch (err) {
      dispatch({ type: MATERIALORDER_ERROR });
      toast.error(`Material Order ${err}`);
    }
  };

  // delete material Order
  const deleteMaterialOrder = async (materialOrder) => {
    try {
      const res = await axios.delete(`/api/materialorder/delete/${materialOrder._id}`);

      dispatch({ type: DELETE_MATERIALORDER, payload: materialOrder._id });
      toast.success(`Material Order is deleted`);
    } catch (err) {
      dispatch({ type: MATERIALORDER_ERROR });
      toast.error(`Material Order ${err}`);
    }
  };

  // update Material Order
  const updateMaterialOrder = async (materialOrder) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.put(`/api/materialorder/update/${materialOrder._id}`, materialOrder, config);

      dispatch({ type: UPDATE_MATERIALORDER, payload: materialOrder });
      toast.success(`Material Order is updated`);
    } catch (err) {
      dispatch({ type: MATERIALORDER_ERROR });
      toast.error(`Material Order ${err}`);
    }
  };

  // update Material Order Recieved
  const updateMaterialOrderRecieved = async (materialOrder) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.put(`/api/materialorder/update/recieved/${materialOrder._id}`, materialOrder, config);

      dispatch({ type: UPDATE_MATERIALORDER, payload: res.data });
      toast.success(`Material Order is updated`);
    } catch (err) {
      dispatch({ type: MATERIALORDER_ERROR });
      toast.error(`Material Order ${err}`);
    }
  };

  const clearMaterialOrders = () => {
    dispatch({ type: CLEAR_MATERIALORDERS });
  };

  const setCurrentMaterialOrder = (materialOrder) => {
    dispatch({ type: SET_CURRENT_MATERIALORDER, payload: materialOrder });
  };

  const clearCurrentMaterialOrder = () => {
    dispatch({ type: CLEAR_CURRENT_MATERIALORDER });
  };

  const filterMaterialOrders = (text) => {
    dispatch({ type: FILTER_MATERIALORDERS, payload: text });
  };

  const clearFilterMaterialOrder = () => {
    dispatch({ type: CLEAR_FILTER_MATERIALORDER });
  };

  const setLoadingMaterialOrders = (bool) => {
    dispatch({ type: SET_LOADING_MATERIALORDERS, payload: bool });
  };

  return (
    <MaterialOrderContext.Provider
      value={{
        materialOrders: state.materialOrders,
        currentMaterialOrder: state.currentMaterialOrder,
        filteredMaterialOrder: state.filteredMaterialOrder,
        loadingMaterialOrders: state.loadingMaterialOrders,
        error: state.error,
        getMaterialOrders,
        addMaterialOrder,
        deleteMaterialOrder,
        setCurrentMaterialOrder,
        clearCurrentMaterialOrder,
        updateMaterialOrder,
        updateMaterialOrderRecieved,
        filterMaterialOrders,
        clearFilterMaterialOrder,
        clearMaterialOrders,
        getRecievedMaterialOrders,
        setLoadingMaterialOrders,
        getMaterialOrdersForReport,
      }}
    >
      {props.children}
    </MaterialOrderContext.Provider>
  );
};

export default MaterialOrderState;
