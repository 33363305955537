import React, { Fragment } from 'react';
import RHFMetalOrderForm from './RHFMetalOrderForm';

const MetalOrderUpdate = () => {
  return (
    <Fragment>
      <div className='container-fluid '>
        <div className='row'>
          <div className='col-md-12'>
            <RHFMetalOrderForm />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MetalOrderUpdate;
