import React, { Fragment, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import AuthContext from '../../../../context/auth/authContext';
import SettingsContext from '../../../../context/settings/settingsContext';
import InvoiceContext from '../../../../context/invoice/invoiceContext';

const OpenInvoices = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const invoiceContext = useContext(InvoiceContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const { getOpenInvoices, setInvoiceLoading, invoiceLoading, invoices } = invoiceContext;

  useEffect(() => {
    setInvoiceLoading(true);
    getOpenInvoices();
    loadUser();
    getSettings();
  }, []);

  const adjustedInvoices =
    invoices &&
    invoices.map((invoice) => {
      invoice.amount = invoice.order.shopTotalSalePrice;
      return invoice;
    });

  const setExit = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className='row'>
          <div className='col-md-12 '>
            <button className='float-end btn btn-outline-danger ' type='button' onClick={setExit}>
              Back
            </button>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-1'></div>
        <div className='col-md-10'>
          {invoiceLoading ? (
            // <div className='row'>
            //   <div className='col-md-12 text-center'>
            //     <Spinner />
            //   </div>
            // </div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={invoiceLoading}>
              <CircularProgress color='inherit' />
            </Backdrop>
          ) : (
            adjustedInvoices && (
              <MaterialTable
                options={{
                  rowStyle: {
                    fontSize: '12px',
                  },
                  exportMenu: [
                    {
                      label: 'Export PDF',
                      //// You can do whatever you wish in this function. We provide the
                      //// raw table columns and table data for you to modify, if needed.
                      // exportFunc: (cols, datas) => console.log({ cols, datas })
                      exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Orders/Detail'),
                    },
                    {
                      label: 'Export CSV',
                      exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Orders/Detail'),
                    },
                  ],
                  pageSize: 20,
                  pageSizeOptions: [20, 40, 100],
                }}
                columns={[
                  { title: 'Invoice #', field: 'invoiceNumber' },
                  { title: 'Invoiced Date', field: 'invoicedDate', render: (rowData) => moment(rowData.invoicedDate).format('DD/MM/YYYY') },
                  { title: 'Status', field: 'status' },
                  {
                    title: 'Amount',
                    field: 'amount',
                    render: (rowData) =>
                      rowData.amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }),
                  },
                ]}
                data={adjustedInvoices}
                title='Open Invoices'
                renderSummaryRow={({ column, data }) => {
                  let invoiceTotal = data.reduce((agg, row) => agg + row.amount, 0);

                  return column.field === 'status'
                    ? {
                        value: 'Total',
                        style: { background: '#6ABAC9' },
                      }
                    : column.field === 'amount'
                    ? {
                        value: invoiceTotal.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }),
                        style: { background: '#E0F4F5' },
                      }
                    : {
                        value: null,
                        // style: { background: 'light gray' },
                      };
                }}
              />
            )
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default OpenInvoices;
