import React, { useState, useContext } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { shedNames } from '../../../names/shedNames';
import { sizes } from '../../../names/shedNames';
import ShedLaborContext from '../../../../context/shedLabor/shedLaborContext';

const UpdateShedLaborForm = () => {
  const navigate = useNavigate();
  const shedLaborContext = useContext(ShedLaborContext);
  const { updateShedLabor, currentShopShedLabor, clearCurrentShopShedLabor, clearCurrentStandardShedLabor } = shedLaborContext;
  const [values, setValues] = useState(currentShopShedLabor);

  // destructure
  const {
    style,
    size,
    buildBase,
    buildLaborPercent,
    buildLaborDollars,
    roofLaborPercent,
    roofLaborDollars,
    trussLaborDollars,
    doorLaborDollars,
    paintLaborPercent,
    paintLaborDollars,
    totalLabor,
  } = values;

  const handleSubmit = (e) => {
    e.preventDefault();
    updateShedLabor(values);
    clearCurrentStandardShedLabor();
    clearCurrentShopShedLabor();
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const setStyle = (event) => {
    setValues({ ...values, style: event.value });
  };

  const setSize = (event) => {
    setValues({ ...values, size: event.value });
  };

  const handleBuildBaseChange = (e) => {
    let newBuildBase = e.target.value;
    let newBuildDollars = (newBuildBase * buildLaborPercent).toFixed(2);
    let newRoofLabor = (newBuildBase * roofLaborPercent).toFixed(2);
    let newPaintLabor = (newBuildBase * paintLaborPercent).toFixed(2);

    let theTotal = +newBuildDollars + +newRoofLabor + +newPaintLabor + +trussLaborDollars + +doorLaborDollars;

    setValues({
      ...values,
      buildBase: newBuildBase,
      buildLaborDollars: newBuildDollars,
      roofLaborDollars: newRoofLabor,
      paintLaborDollars: newPaintLabor,
      totalLabor: theTotal,
    });
  };

  const handleBuildPercentChange = (e) => {
    let newBuildPercent = e.target.value;
    let newBuildLabor = (buildBase * newBuildPercent).toFixed(2);

    let theTotal = (+newBuildLabor + +doorLaborDollars + +roofLaborDollars + +trussLaborDollars + +paintLaborDollars).toFixed(2);

    setValues({
      ...values,
      buildLaborPercent: newBuildPercent,
      buildLaborDollars: newBuildLabor,
      totalLabor: theTotal,
    });
  };

  const handleRoofPercentChange = (e) => {
    let newRoofPercent = e.target.value;
    let newRoofLabor = (buildBase * newRoofPercent).toFixed(2);

    let theTotal = (+newRoofLabor + +doorLaborDollars + +buildLaborDollars + +trussLaborDollars + +paintLaborDollars).toFixed(2);

    setValues({
      ...values,
      roofLaborPercent: newRoofPercent,
      roofLaborDollars: newRoofLabor,
      totalLabor: theTotal,
    });
  };

  const handlePaintPercentChange = (e) => {
    let newPaintPercent = e.target.value;
    let newPaintLabor = (buildBase * newPaintPercent).toFixed(2);

    let theTotal = (+newPaintLabor + +doorLaborDollars + +buildLaborDollars + +trussLaborDollars + +roofLaborDollars).toFixed(2);

    setValues({
      ...values,
      paintLaborPercent: newPaintPercent,
      paintLaborDollars: newPaintLabor,
      totalLabor: theTotal,
    });
  };

  const handleTrussLaborChange = (e) => {
    let newTrussLabor = e.target.value;
    let theTotal = (+newTrussLabor + +buildLaborDollars + +roofLaborDollars + +doorLaborDollars + +paintLaborDollars).toFixed(2);

    setValues({
      ...values,
      trussLaborDollars: newTrussLabor,
      totalLabor: theTotal,
    });
  };

  const handleDoorLaborChange = (e) => {
    let newDoorLabor = e.target.value;
    let theTotal = (+newDoorLabor + +buildLaborDollars + +roofLaborDollars + +trussLaborDollars + +paintLaborDollars).toFixed(2);

    setValues({
      ...values,
      doorLaborDollars: newDoorLabor,
      totalLabor: theTotal,
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 mb-4'>
            <button className='btn btn-outline-danger float-end' type='button' onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-md-1'> </div>

          <div className='col-md-4 bidformbackground border border-dark' style={{ borderRadius: '15px' }}>
            <h4 className='text-center mt-2'>My Labor Item</h4>

            <div className='form-group'>
              <label>Style</label>
              <Select
                readOnly
                className='basic-single'
                classNamePrefix='select'
                defaultValue={style}
                placeholder={style}
                name={style}
                value={style}
                options={shedNames}
                onChange={setStyle}
                // defaultValue={{ label: 'utility', value: 'utility' }}
              />
            </div>

            <div className='form-group'>
              <label>Size</label>
              <Select
                readOnly
                className='basic-single'
                classNamePrefix='select'
                defaultValue={size}
                placeholder={size}
                name={size}
                value={size}
                options={sizes}
                onChange={setSize}
              />
            </div>

            <div className='form-group'>
              <label>Build Base</label>
              <input
                type='number'
                name='buildBase'
                step='0.000001'
                className='form-control inputbg'
                value={buildBase}
                onChange={handleBuildBaseChange}
              />
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Build Labor Percent</label>
                  <input
                    type='number'
                    name='buildLaborPercent'
                    step='0.01'
                    className='form-control inputbg'
                    value={buildLaborPercent}
                    onChange={handleBuildPercentChange}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Build Labor</label>
                  <input readOnly type='number' name='buildLaborDollars' step='0.001' className='form-control ' value={buildLaborDollars} />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Roof Labor Percent</label>
                  <input
                    type='number'
                    name='roofLaborPercent'
                    step='0.01'
                    className='form-control inputbg'
                    value={roofLaborPercent}
                    onChange={handleRoofPercentChange}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Roof Labor</label>
                  <input
                    readOnly
                    type='number'
                    name='roofLaborDollars'
                    step='0.01'
                    className='form-control '
                    value={roofLaborDollars}
                    // onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Paint Labor Percent</label>
                  <input
                    type='number'
                    name='paintLaborPercent'
                    step='0.01'
                    className='form-control inputbg'
                    value={paintLaborPercent}
                    onChange={handlePaintPercentChange}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Paint Labor</label>
                  <input
                    readOnly
                    type='number'
                    name='paintLaborDollars'
                    step='0.01'
                    className='form-control '
                    value={paintLaborDollars}
                    // onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label>Truss Labor</label>
                <input
                  type='number'
                  step='0.01'
                  name='trussLaborDollars'
                  className='form-control inputbg'
                  value={trussLaborDollars}
                  onChange={handleTrussLaborChange}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label>Door Labor</label>
                <input
                  type='number'
                  step='0.01'
                  name='doorLaborDollars'
                  className='form-control inputbg'
                  value={doorLaborDollars}
                  onChange={handleDoorLaborChange}
                />
              </div>
            </div>
            <div className='form-group'>
              <label>Total Labor</label>
              <input
                readOnly
                type='number'
                step='0.01'
                name='totalLabor'
                className='form-control inputbg'
                value={totalLabor}
                onChange={handleChange}
              />
            </div>

            <br />
            <button className='btn btn-outline-info mb-2 float-end'>Save</button>
          </div>

          <div className='col-md-2'> </div>

          <StandardShedLaborForm />
        </div>
      </div>
    </form>
  );
};

const StandardShedLaborForm = () => {
  const shedLaborContext = useContext(ShedLaborContext);
  const { currentStandardShedLabor } = shedLaborContext;

  const [standardValues, setStandardValues] = useState(currentStandardShedLabor);

  // destructure
  const {
    style,
    size,
    buildBase,
    buildLaborPercent,
    buildLaborDollars,
    roofLaborPercent,
    roofLaborDollars,
    trussLaborDollars,
    doorLaborDollars,
    paintLaborPercent,
    paintLaborDollars,
    totalLabor,
  } = standardValues;

  return (
    <div className='col-md-4 bidformbackground border border-dark' style={{ borderRadius: '15px' }}>
      <h4 className='text-center mt-2'>Standard Labor Item</h4>

      <div className='form-group'>
        <label>Style</label>
        <Select
          readOnly
          className='basic-single'
          classNamePrefix='select'
          defaultValue={style}
          placeholder={style}
          name={style}
          value={style}
          options={shedNames}
          // onChange={setStyle}
          // defaultValue={{ label: 'utility', value: 'utility' }}
        />
      </div>

      <div className='form-group'>
        <label>Size</label>
        <Select
          readOnly
          className='basic-single'
          classNamePrefix='select'
          defaultValue={size}
          placeholder={size}
          name={size}
          value={size}
          options={sizes}
          // onChange={setSize}
        />
      </div>

      <div className='form-group'>
        <label>Build Base</label>
        <input
          readOnly
          type='number'
          name='buildBase'
          step='0.000001'
          className='form-control inputbg'
          value={buildBase}
          // onChange={handleBuildBaseChange}
        />
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <div className='form-group'>
            <label>Build Labor Percent</label>
            <input
              readOnly
              type='number'
              name='buildLaborPercent'
              step='0.01'
              className='form-control inputbg'
              value={buildLaborPercent}
              // onChange={handleBuildPercentChange}
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label>Build Labor</label>
            <input readOnly type='number' name='buildLaborDollars' step='0.001' className='form-control ' value={buildLaborDollars} />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <div className='form-group'>
            <label>Roof Labor Percent</label>
            <input
              readOnly
              type='number'
              name='roofLaborPercent'
              step='0.01'
              className='form-control inputbg'
              value={roofLaborPercent}
              // onChange={handleRoofPercentChange}
            />
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label>Roof Labor</label>
            <input
              readOnly
              type='number'
              name='roofLaborDollars'
              step='0.01'
              className='form-control '
              value={roofLaborDollars}
              // onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <div className='form-group'>
            <label>Paint Labor Percent</label>
            <input
              readOnly
              type='number'
              name='paintLaborPercent'
              step='0.01'
              className='form-control inputbg'
              value={paintLaborPercent}
              // onChange={handlePaintPercentChange}
            />
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label>Paint Labor</label>
            <input
              readOnly
              type='number'
              name='paintLaborDollars'
              step='0.01'
              className='form-control '
              value={paintLaborDollars}
              // onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className='col-md-6'>
        <div className='form-group'>
          <label>Truss Labor</label>
          <input
            readOnly
            type='number'
            step='0.01'
            name='trussLaborDollars'
            className='form-control inputbg'
            value={trussLaborDollars}
            // onChange={handleTrussLaborChange}
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='form-group'>
          <label>Door Labor</label>
          <input
            readOnly
            type='number'
            step='0.01'
            name='doorLaborDollars'
            className='form-control inputbg'
            value={doorLaborDollars}
            // onChange={handleDoorLaborChange}
          />
        </div>
      </div>
      <div className='form-group'>
        <label>Total Labor</label>
        <input
          readOnly
          type='number'
          step='0.01'
          name='totalLabor'
          className='form-control inputbg'
          value={totalLabor}
          // onChange={handleChange}
        />
      </div>

      <br />
    </div>
  );
};

export default UpdateShedLaborForm;
