import React, { useReducer } from 'react';
import MetalContext from './metalContext';
import metalReducer from './metalReducer';
import axios from 'axios';

import { toast } from 'react-toastify';

import {
  ADD_METAL,
  DELETE_METAL,
  CLEAR_METALS,
  SET_CURRENT_METAL,
  CLEAR_CURRENT_METAL,
  UPDATE_METAL,
  FILTER_METALS,
  CLEAR_FILTER_METAL,
  METAL_ERROR,
  GET_METALS,
} from '../types';

const MetalState = (props) => {
  const initialState = {
    metals: null,
    currentMetal: null,
    filteredMetal: null,
    error: null,
  };

  const [state, dispatch] = useReducer(metalReducer, initialState);

  // get Metals
  const getMetals = async () => {
    try {
      const res = await axios.get('/api/metal');

      dispatch({ type: GET_METALS, payload: res.data });
    } catch (err) {
      dispatch({ type: METAL_ERROR });
    }
  };

  // get Metals By Zone
  const getMetalsByZone = async (zone) => {
    // console.log('here');
    try {
      const res = await axios.get(`/api/metal/zone/${zone}`);

      dispatch({ type: GET_METALS, payload: res.data });
    } catch (err) {
      dispatch({ type: METAL_ERROR });
    }
  };

  const addMetal = async (metal) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post('/api/metal', { metal }, config);

      dispatch({ type: ADD_METAL, payload: res.data });
    } catch (err) {
      dispatch({ type: METAL_ERROR });
    }
  };

  const deleteMetal = async (id) => {
    try {
      const res = await axios.delete(`/api/metals/${id}`);

      dispatch({ type: DELETE_METAL, payload: id });
    } catch (err) {
      dispatch({ type: METAL_ERROR });
    }
  };

  // update Metal
  const updateMetal = async (metal) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.put(`/api/metal/update/${metal._id}`, metal, config);

      dispatch({ type: UPDATE_METAL, payload: res.data });
      toast.success(`Metal Package is updated`);
    } catch (err) {
      dispatch({ type: METAL_ERROR });
      toast.error(`Metal Package ${err}`);
    }
  };

  const clearMetals = () => {
    dispatch({ type: CLEAR_METALS });
  };

  const setCurrentMetal = (metal) => {
    dispatch({ type: SET_CURRENT_METAL, payload: metal });
  };

  const clearCurrentMetal = () => {
    dispatch({ type: CLEAR_CURRENT_METAL });
  };

  const filterMetals = (text) => {
    dispatch({ type: FILTER_METALS, payload: text });
  };

  const clearFilterMetal = () => {
    dispatch({ type: CLEAR_FILTER_METAL });
  };

  return (
    <MetalContext.Provider
      value={{
        metals: state.metals,
        currentMetal: state.currentMetal,
        filteredMetal: state.filteredMetal,
        error: state.error,
        getMetals,
        getMetalsByZone,
        addMetal,
        deleteMetal,
        setCurrentMetal,
        clearCurrentMetal,
        updateMetal,
        filterMetals,
        clearFilterMetal,
        clearMetals,
      }}
    >
      {props.children}
    </MetalContext.Provider>
  );
};

export default MetalState;
