import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableRow from './InvoiceTableRow'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
       
        borderColor: '#bff0fd',
    },
});

  const InvoiceItemsTable = ({values}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableRow metalOrder={values.metalOrder} />
    </View>
  );
  
  export default InvoiceItemsTable