import React, { Fragment, useState, useEffect } from "react";
import { Menu } from "antd";
import { Outlet, Link, useNavigate } from "react-router-dom";

const AllInvoicesNav = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState("new");
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  const handleSwitchAddOn = () => {
    // clearAddOns();
  };

  useEffect(() => {
    navigate("/invoices/new");
  }, []);

  return (
    <Fragment>
      <Menu className="mb-4 border" onClick={handleMenuChange} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="new">
          <Link onClick={() => handleSwitchAddOn()} to={`/invoices/new`}>
            New
          </Link>
        </Menu.Item>
        <Menu.Item key="denied">
          <Link onClick={() => handleSwitchAddOn()} to={`/invoices/denied`}>
            Denied
          </Link>
        </Menu.Item>
        <Menu.Item key="adjusted">
          <Link onClick={() => handleSwitchAddOn()} to={`/invoices/adjusted`}>
            Adjusted
          </Link>
        </Menu.Item>
        <Menu.Item key="approved">
          <Link onClick={() => handleSwitchAddOn()} to={`/invoices/approved`}>
            Approved
          </Link>
        </Menu.Item>
        <Menu.Item key="paid">
          <Link onClick={() => handleSwitchAddOn()} to={`/invoices/paid`}>
            Paid
          </Link>
        </Menu.Item>
        <Menu.Item key="payments">
          <Link onClick={() => handleSwitchAddOn()} to={`/invoices/payments`}>
            Payments
          </Link>
        </Menu.Item>
      </Menu>

      <Outlet />
    </Fragment>
  );
};

export default AllInvoicesNav;
