import React, { useReducer } from 'react'
import DoorContext from './doorContext'
import doorReducer from './doorReducer'
import axios from 'axios'

import { toast } from "react-toastify";

import {
    ADD_DOOR,
    DELETE_DOOR,
    CLEAR_DOORS,
    SET_CURRENT_DOOR,
    CLEAR_CURRENT_DOOR,
    UPDATE_DOOR,
    FILTER_DOORS,
    CLEAR_FILTER_DOOR,
    DOOR_ERROR,
    GET_DOORS,
} from '../types'

const DoorReducer = props => {
    const initialState = {
        doors: null,
        currentDoor: null,
        filteredDoor: null,
        error: null
    };

    const [state, dispatch] = useReducer(doorReducer, initialState)

    // get Doors
    const getDoors = async () => {
        try {
            const res = await axios.get('/api/door')

            dispatch({ type: GET_DOORS, payload: res.data })
        } catch (err) {
            dispatch({ type: DOOR_ERROR})
            
        }
    }


   
    const addDoor = async door => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/door', {door}, config)

            dispatch({ type: ADD_DOOR, payload: res.data })
            toast.success(`Option is created`)
        } catch (err) {
            dispatch({ type: DOOR_ERROR})
            toast.error(`Option ${err}`)
        }
     
        
    }


   
    const deleteDoor =   async id => {
        try {
            const res = await axios.delete(`/api/door/${id}`)

            dispatch({ type: DELETE_DOOR, payload: id })
            toast.success(`Option is deleted`)
        } catch (err) {
            dispatch({ type: DOOR_ERROR})
            toast.error(`Option ${err}`)
        }
    }


     // update Door
     const updateDoor = async door => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/door/update/${door._id}`, door, config)

            dispatch({ type: UPDATE_DOOR, payload: res.data })
            toast.success(`Option is updated`)
        } catch (err) {
            dispatch({ type: DOOR_ERROR})
            toast.error(`Option ${err}`)
        }
    }



   
    const clearDoors = () => {
        dispatch({ type: CLEAR_DOORS })
    }


  
    const setCurrentDoor = door => {
            dispatch({ type: SET_CURRENT_DOOR, payload: door })
    }


   
    const clearCurrentDoor = () => {
            dispatch({ type: CLEAR_CURRENT_DOOR })
    }




    const filterDoors = text => {
            dispatch({ type: FILTER_DOORS, payload: text })
    }


 
    const clearFilterDoor = () => {
        dispatch({ type: CLEAR_FILTER_DOOR })
    }

    return (
        <DoorContext.Provider
            value={{
                doors: state.doors,
                currentDoor: state.currentDoor,
                filteredDoor: state.filteredDoor,
                error: state.error,
                getDoors,
                addDoor,
                deleteDoor, 
                setCurrentDoor,
                clearCurrentDoor,
                updateDoor,
                filterDoors, 
                clearFilterDoor,
                clearDoors,
            }}>
             { props.children }
        </DoorContext.Provider>
    )


}

export default DoorReducer;