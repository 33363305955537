import React, { Fragment } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

const CustomAddOns = ({ addOn, addOnIndex, control, setValue, getValues, register, settings }) => {
  const setTotals = () => {
    let theStorMorAddOnsTotal = getValues('storMorAddOnsTotalSalePrice')
    let theShopsAddOnsTotal = getValues('shopAddOnsTotalSalePrice')
    let theCustomAddOns = getValues(`customAddOns`);
    let theBasePrice = getValues('basePrice');
    let theShopPercentOfBasePrice = getValues('shopPercentOfBuildingBasePrice');
    let theStorMorCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorCustomAddOnsTotalSalePrice || 0), 0);
    let theShopsCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopCustomAddOnsTotalSalePrice || 0), 0);
      let theShopCustomAddOnMaterial =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopCustomAddOnTotalCost || 0), 0);
    let theShopTotalLabor = getValues('shopTotalLabor');
    let theShopShedMaterial = getValues('shopShedMaterialCost');
    let theShopAddOnMaterial = getValues('shopAddOnsMaterialCostTotal');
    // let theShopCustomAddOnMaterial = getValues('shopCustomAddOnsTotalCost');
    let theShopOverhead = getValues('shopOverhead');
    let theShopTotalCost = +theShopTotalLabor + +theShopShedMaterial + +theShopAddOnMaterial + +theShopCustomAddOnMaterial + +theShopOverhead;
    let theShopTotalSalePrice = theShopPercentOfBasePrice + theShopsAddOnsTotal + theShopsCustomAddOnsTotal;
    let theShopProfitDollars = theShopTotalSalePrice - theShopTotalCost;
    //
    // Standard Numbers begin here
    let theStandardTotalLabor = getValues('standardTotalLabor');
    let theStandardShedMaterial = getValues('standardShedMaterialCost');
    let theStandardAddOnMaterial = getValues('standardAddOnsMaterialCostTotal');
    // let theStandardCustomAddOnMaterial = getValues('standardCustomAddOnsTotalCost');
    let theStandardCustomAddOnMaterial =
    theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopCustomAddOnTotalCost || 0), 0);
    let theStandardOverhead = getValues('standardOverhead');
    let theStandardTotalCost =
      +theStandardTotalLabor + +theStandardShedMaterial + +theStandardAddOnMaterial + +theStandardCustomAddOnMaterial + +theStandardOverhead;
    let theStandardProfitDollars = theShopTotalSalePrice - theStandardTotalCost;
    //
    // storMor cost and profit begins here
    let theEscort = getValues('escort');
    let theHaul = getValues('haul');
    let theStorMorTotalSalePrice = +theBasePrice + +theStorMorAddOnsTotal + +theStorMorCustomAddOnsTotal;
    let theDealerCommission = +theStorMorTotalSalePrice * (+settings.parentCompanySettings.dealerCommission / 100);
    let theSalesRepCommission = +theStorMorTotalSalePrice * (+settings.parentCompanySettings.salesRepCommission / 100);
    let theStorMorOverhead = +theStorMorTotalSalePrice * (+settings.parentCompanySettings.storMorOverhead / 100);
    let theStorMorTotalCost = +theShopTotalSalePrice + +theDealerCommission + +theSalesRepCommission + +theStorMorOverhead + +theEscort + +theHaul;
    let theStorMorProfitDollars = +theStorMorTotalSalePrice - +theStorMorTotalCost;
    let theStorMorProfitPercent = +theStorMorProfitDollars / +theStorMorTotalSalePrice;
    setValue('storMorTotalSalePrice', theStorMorTotalSalePrice);
    setValue('dealerCommission', theDealerCommission);
    setValue('salesRepCommission', theSalesRepCommission);
    setValue('storMorOverhead', theStorMorOverhead);
    setValue('storMorTotalCost', theStorMorTotalCost);
    setValue('storMorProfitDollars', theStorMorProfitDollars);
    setValue('storMorProfitPercent', theStorMorProfitPercent);
    // storMor cost and profit ends here
    //
    setValue('shopTotalCost', theShopTotalCost);
    setValue('shopTotalSalePrice', theShopTotalSalePrice);
    setValue('shopProfitDollars', theShopProfitDollars);
    setValue('shopProfitPercent', (theShopProfitDollars / theShopTotalSalePrice) * 100);
    // Standard Stuff is here
    setValue('standardProfitDollars', theStandardProfitDollars);
    setValue('standardProfitPercent', (theStandardProfitDollars / theShopTotalSalePrice) * 100);

    setValue('storMorCustomAddOnsTotalSalePrice', theStorMorCustomAddOnsTotal);
    setValue('shopCustomAddOnsTotalSalePrice', theShopsCustomAddOnsTotal);
    setValue('storMorTotalSalePrice', theBasePrice + theStorMorAddOnsTotal + theStorMorCustomAddOnsTotal);
  };

  const setChecked = (value) => {
    setValue(`customAddOns[${addOnIndex}].storMorSpecific`, value);
    if (value) {
      setValue(`customAddOns[${addOnIndex}].shopCustomAddOnsTotalSalePrice`, 0);
      setTotals();
    } else {
      setValue(
        `customAddOns[${addOnIndex}].shopCustomAddOnsTotalSalePrice`,
        addOn.storMorCustomAddOnsTotalSalePrice * (addOn.shopCustomAddOnPercentOfRetail / 100)
      );
      setTotals();
    }
  };

  const setCustomAddOnPrice = (price) => {
    let theShopsCustomAddOnPercent = getValues(`customAddOns[${addOnIndex}].shopCustomAddOnPercentOfRetail`);
    setValue(`customAddOns[${addOnIndex}].storMorCustomAddOnsTotalSalePrice`, price);
    setValue(`customAddOns[${addOnIndex}].shopCustomAddOnsTotalSalePrice`, price * theShopsCustomAddOnPercent);
    setTotals();
  };

  const setShopsCustomAddOnPercent = (percent) => {
    let theCustomAddOnsPrice = getValues(`customAddOns[${addOnIndex}].storMorCustomAddOnsTotalSalePrice`);
    setValue(`customAddOns[${addOnIndex}].shopCustomAddOnsTotalSalePrice`, theCustomAddOnsPrice * (percent / 100));
    setValue(`customAddOns[${addOnIndex}].shopCustomAddOnPercentOfRetail`, percent);
    setTotals();
  };

  const setCustomAddOnMaterialCost = (cost) => {
    let theCustomAddOnsTotalCost = getValues(`shopCustomAddOnsTotalCost`);
    let theStandardCustomAddOnsTotalCost = getValues(`standardCustomAddOnsTotalCost`);

    let theOldCost = getValues(`customAddOns[${addOnIndex}].shopCustomAddOnTotalCost`);
    let theStandardOldCost = getValues(`customAddOns[${addOnIndex}].standardCustomAddOnTotalCost`);

    var num = Number(cost);
    let theCostDiff = num - parseFloat(theOldCost);
    let theStandardCostDiff = num - parseFloat(theStandardOldCost);

    setValue('shopCustomAddOnsTotalCost', +theCustomAddOnsTotalCost + +theCostDiff);
    setValue(`customAddOns[${addOnIndex}].shopCustomAddOnTotalCost`, num);
    // using the shops cost for standard cost also
    setValue('standardCustomAddOnsTotalCost', +theStandardCustomAddOnsTotalCost + +theStandardCostDiff);
    setValue(`customAddOns[${addOnIndex}].standardCustomAddOnTotalCost`, num);
    setTotals();
  };

  return (
    <Fragment>
      <div className='row border mb-2'>
        <div className='col-md-12 bglight'>
          <div className='row mb-2 mt-2 '>
            <div className='col-sm-3 '>
              <Controller
                render={({ field, name }) => <input className='form-control inputfield ' name={name} {...field} />}
                key={addOn.id}
                control={control}
                name={`customAddOns[${addOnIndex}].name`}
              />
            </div>
            <div className='col-sm-2'>
              <Controller
                render={({ value, field, onChange, name, ...restProps }) => (
                  <NumberFormat
                    // type='number'
                    customInput={Input}
                    className='form-group form-control inputfield'
                    style={{ fontSize: 16, padding: 5 }}
                    {...field}
                    name={`customAddOns[${addOnIndex}].storMorCustomAddOnsTotalSalePrice`}
                    thousandSeparator={true}
                    prefix={'$ '}
                    onValueChange={(i) => {
                      setCustomAddOnPrice(i.floatValue);
                    }}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                    isNumericString='true'
                    {...restProps}
                  />
                )}
                name={`customAddOns[${addOnIndex}].storMorCustomAddOnsTotalSalePrice`}
                control={control}
                defaultValue={0}
              />
            </div>
            <div className='col-sm-2'>
              <Controller
                render={({ field, name }) => (
                  <input
                    className='form-control inputfield'
                    name={name}
                    {...field}
                    type='number'
                    step={'.01'}
                    onChange={(e) => setShopsCustomAddOnPercent(e.target.value)}
                  />
                )}
                key={addOn.id}
                control={control}
                name={`customAddOns[${addOnIndex}].shopCustomAddOnPercentOfRetail`}
              />
            </div>
            <div className='col-sm-2'>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control inputfield'
                    style={{ fontSize: 16, padding: 5 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={'$ '}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`customAddOns[${addOnIndex}].shopCustomAddOnsTotalSalePrice`}
                control={control}
              />
            </div>
            <div className='col-sm-2'>
              {/* <Controller
                render={({ value, field, onChange, name, ...restProps }) => (
                  <NumberFormat
                    customInput={Input}
                    className='form-group form-control inputfield'
                    style={{ fontSize: 16, padding: 5 }}
                    {...field}
                    name={`customAddOns[${addOnIndex}].shopCustomAddOnTotalCost`}
                    thousandSeparator={true}
                    onValueChange={(i) => {
                      setCustomAddOnMaterialCost(i.floatValue);
                    }}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                    isNumericString='true'
                    {...restProps}
                  />
                )}
                name={`customAddOns[${addOnIndex}].shopCustomAddOnTotalCost`}
                control={control}
                defaultValue={0}
              /> */}
              <Controller
                render={({ field, name }) => (
                  <input
                    className='form-control inputfield'
                    name={name}
                    {...field}
                    type='number'
                    step={'.01'}
                    onChange={(e) => setCustomAddOnMaterialCost(e.target.value)}
                  />
                )}
                key={addOn.id}
                control={control}
                name={`customAddOns[${addOnIndex}].shopCustomAddOnTotalCost`}
              />
            </div>

            <div className='col-sm-1 form-check'>
              <Controller
                render={({ field, name }) => (
                  <input
                    className='form-check-input float-end mt-2'
                    type='checkbox'
                    name={name}
                    {...field}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  />
                )}
                control={control}
                name={`customAddOns[${addOnIndex}].storMorSpecific`}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, settings }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customAddOns',
  });

  const addOns = getValues('customAddOns');

  return (
    <div className='container-fluid border mt-4' style={{ borderRadius: '8px' }}>
      {addOns && addOns.length > 0 && (
        <div className='row'>
          <div className='col-md-3 text-center'>
            <label>Custom Addon Name</label>
          </div>
          <div className='col-md-2 text-center'>
            <label>Retail Price</label>
          </div>
          <div className='col-md-2 text-center'>
            <label>Shops %</label>
          </div>
          <div className='col-md-2 text-center'>
            <label>Shops % of Retail</label>
          </div>
          <div className='col-md-2 text-center'>
            <label>Material Cost</label>
          </div>
          <div className='col-md-1 text-center'>
            <label>StorMor Specific</label>
          </div>
        </div>
      )}

      {fields.map((addOn, addOnIndex) => (
        <CustomAddOns
          key={addOn.id}
          addOn={addOn}
          addOnIndex={addOnIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          settings={settings}
        />
      ))}
    </div>
  );
}
