import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import OrderContext from "../../../../context/order/orderContext";
import SettingsContext from "../../../../context/settings/settingsContext";
// import OrderFilter from '../../../../context/order/OrderFilter';
import GlobalOrderSearch from "../../../../context/order/GlobalOrderSearch";
import { Table } from "antd";
import Select from "react-select";
import DetailModal from "../DetailModal";

const CanceledOrders = () => {
  const navigate = useNavigate();
  const orderContext = useContext(OrderContext);
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const {
    getOrderById,
    // deleteOrder,
    getOrdersByCategory,
    orders,
    updateOrder,
    // filteredOrder,
    clearOrders,
    setLoadingOrders,
    loadingOrders,
    getOrderCount,
    orderCount,
    clearOrderCount,
    pagination,
    setPagination,
    setCurrentOrder,
    clearCurrentOrder,
    searchedOrder,
  } = orderContext;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    clearCurrentOrder();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    clearCurrentOrder();
  };
  const handleShowModal = (record) => {
    setCurrentOrder(record);
    showModal(true);
  };

  const setEditOrder = (order) => {
    getOrderById(order._id);
    navigate("/orders/view");
  };

  useEffect(() => {
    clearOrderCount();
    clearOrders();
    loadUser();
    getSettings();
    getOrdersByCategory("Canceled", { current: 1, pageSize: 10 });
    getOrderCount("Canceled");
  }, []);

  // const onInvoiceCheckedChange = (e, o) => {
  //   let checked = e.target.checked;

  //   let newRecord = { ...o, invoicedChecked: checked };
  //   updateOrder(newRecord);
  // };

  // const handleRemoveOrder = (order) => {
  //   Modal.confirm({
  //     title: 'Are you sure you want to delete this Order?',
  //     okText: 'Yes',
  //     okType: 'danger',
  //     onOk: () => {
  //       deleteOrder(order);
  //     },
  //   });
  // };

  const handleBuilderChange = (o, e) => {
    let newRecord = { ...o, builder: e };
    updateOrder(newRecord);
  };

  const options =
    settings &&
    settings.builders &&
    settings.builders.map((option) => {
      return { label: `${option.builder}`, value: option.builder };
    });

  const columns = [
    {
      title: `Serial Number`,
      render: (text, record, rowIndex) => (
        <button className="buttonLink" type="button" onClick={() => handleShowModal(record)}>
          {record.serialNumber}
        </button>
      ),
    },
    {
      title: "Ordered Date",
      render: (text, record) => <span>{new Date(record.dateOrdered).toLocaleDateString()}</span>,
    },

    {
      title: `Dealer`,
      render: (text, record, rowIndex) => <Fragment>{record.dealer.name.label}</Fragment>,
    },
    {
      title: `Customer`,
      render: (text, record) => <span>{record.customer}</span>,
    },

    {
      title: "Builder",
      render: (text, record) => (
        <Fragment>
          <Select
            className="basic-single"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            placeholder={record.builder}
            value={record.builder}
            name="builder"
            options={options}
            onChange={(e) => handleBuilderChange(record, e)}
          />
        </Fragment>
      ),
    },
    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <button className="btn btn-xs btn-outline-secondary mb-2" type="button" onClick={(e) => setEditOrder(record, e)}>
          View
        </button>
      ),
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    setLoadingOrders(true);
    getOrdersByCategory("Canceled", newPagination);
    setPagination(newPagination);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col text-center">
          <h4>{orderCount + " Canceled Orders"}</h4>

          {/* <OrderFilter /> */}
          <GlobalOrderSearch />

          <DetailModal isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />

          <Table
            // onRow={(record, rowIndex) => {
            //   return {
            //     onMouseOver: (event) => {
            //       handleRowClick(record, rowIndex);
            //     }, // click row
            //     onMouseLeave: (event) => {
            //       setShowClickedRow();
            //     },
            //   };
            // }}
            // pagination={{ pageSize: 200 }}
            pagination={pagination}
            // loading={!loadingOrders && orders ? false : true}
            columns={columns}
            // dataSource={filteredOrder && filteredOrder !== null ? filteredOrder : orders && orders}
            loading={(!loadingOrders && orders) || (!loadingOrders && searchedOrder) ? false : true}
            dataSource={(searchedOrder && searchedOrder) || (orders && orders)}
            rowKey="_id"
            bordered={true}
            onChange={handleTableChange}
            scroll={{ y: "60vh" }}
          />
        </div>
      </div>
    </div>
  );
};

export default CanceledOrders;
