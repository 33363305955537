import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  CLEAR_PRODUCTS,
  SET_CURRENT_PRODUCT,
  CLEAR_CURRENT_PRODUCT,
  UPDATE_PRODUCT,
  FILTER_PRODUCTS,
  CLEAR_FILTER_PRODUCT,
  PRODUCT_ERROR,
  GET_PRODUCTS,
  SET_LOADING_PRODUCT,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    case UPDATE_PRODUCT:
      const updatedProducts = state.products.map((product) => (product._id === action.payload._id ? action.payload : product));
      return {
        ...state,
        products: updatedProducts,
        loading: false,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter((product) => product._id !== action.payload),
        loading: false,
      };
    case CLEAR_PRODUCTS:
      return {
        ...state,
        products: null,
        filteredProduct: null,
        error: null,
        currentProduct: null,
      };
    case SET_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: action.payload,
      };
    case CLEAR_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: null,
      };
    case FILTER_PRODUCTS:
      return {
        ...state,
        filteredProduct: state.products.filter((product) => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          return product.sku.match(regex);
        }),
      };
    case CLEAR_FILTER_PRODUCT:
      return {
        ...state,
        filteredProduct: null,
      };
    case PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING_PRODUCT:
      return {
        ...state,
        loadingProduct: action.payload,
      };

    default:
      return state;
  }
};
