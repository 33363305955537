import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommentContext from "../../../context/comment/commentContext";
import OrderContext from "../../../context/order/orderContext";
import { Drawer, Button, Card } from "antd";

const CommentDrawer = () => {
  const navigate = useNavigate();
  const commentContext = useContext(CommentContext);
  const { commentDrawerVisible, setCommentDrawerVisible, getComments, getAllComments, comments, updateComment } = commentContext;
  const orderContext = useContext(OrderContext);
  const { setCurrentOrder } = orderContext;
  const [getAll, setGetAll] = useState(false);

  //   const showDrawer = () => {
  //     setCommentDrawerVisible(true);
  //   };

  const onClose = () => {
    setCommentDrawerVisible(false);
  };

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {}, [commentDrawerVisible]);
  useEffect(() => {}, [comments]);

  const handleViewOrder = (order) => {
    setCurrentOrder(order);
    setCommentDrawerVisible(false);
    navigate("/order/view");
  };

  const handleCommentRead = (comment) => {
    updateComment(comment);
  };

  const handleGetAllComments = () => {
    getAllComments();
    setGetAll(true);
  };

  const handleGetUnreadComments = () => {
    getComments();
    setGetAll(false);
  };

  return (
    <Fragment>
      {/* <Button type='primary' onClick={showDrawer}>
        Open
      </Button> */}
      <Drawer title="My Messages" placement="right" onClose={onClose} visible={commentDrawerVisible}>
        {!getAll && (
          <div className="row">
            <div className="col-md-12">
              <Button className="float-end" type="primary" ghost onClick={handleGetAllComments}>
                View All Messages
              </Button>
            </div>
          </div>
        )}
        {getAll && (
          <div className="row">
            <div className="col-md-12">
              <Button className="float-start" type="primary" ghost onClick={handleGetUnreadComments}>
                View New Messages
              </Button>
            </div>
          </div>
        )}
        {comments &&
          comments.map((p) => (
            <Card
              key={p._id}
              size="small"
              title={`From: ${p.author}`}
              extra={
                <div>
                  {!p.read && (
                    <a className="pr-1" href="#" onClick={() => handleCommentRead(p)}>
                      Mark Read
                    </a>
                  )}
                  <a href="#" onClick={() => handleViewOrder(p.order)}>
                    View
                  </a>
                </div>
              }
              style={{ width: 300 }}
            >
              <p>{`Order: ${p.order.serialNumber}`}</p>
              <p>{p.content}</p>
            </Card>
          ))}
      </Drawer>
    </Fragment>
  );
};

export default CommentDrawer;
