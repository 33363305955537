import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../../context/auth/authContext";
import ShedLaborContext from "../../../../../context/shedLabor/shedLaborContext";
import { Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const ShedColumns = ({ shopShedsLabor, standardShedsLabor }) => {
  const navigate = useNavigate();
  const shedLaborContext = useContext(ShedLaborContext);
  const { setCurrentShopShedLabor, setCurrentStandardShedLabor, deleteShedLabor, updateAllSheds } = shedLaborContext;

  const handleRemove = (id) => {
    let answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      deleteShedLabor(id);
    }
  };

  const handleEdit = (labor) => {
    setCurrentShopShedLabor(labor);
    let standardLaborItem = standardShedsLabor.find((i) => i.name === labor.name);
    setCurrentStandardShedLabor(standardLaborItem);
    navigate(`/labor/sheds/update`);
  };

  const handleStandardTotalLabor = (shopLabor) => {
    let standardLaborItem = standardShedsLabor.find((i) => i.name === shopLabor.name);
    return standardLaborItem.totalLabor;
  };

  const columns = [
    {
      title: "Size",
      dataIndex: "size",
      // fixed: 'left',
      defaultSortOrder: "ascend",
      sorter: (a, b) => parseInt(a.size.toUpperCase().replace("X", "1")) - parseInt(b.size.toUpperCase().replace("X", "1")),
    },
    {
      title: "Build Base",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return (
          <span>
            {record.buildBase.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
          </span>
        );
      },
    },

    {
      title: "Build Labor",
      render: (text, record) => {
        return (
          <span>
            {record.buildLaborDollars.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        );
      },
    },
    {
      title: "Roof Labor",
      render: (text, record) => (
        <span>
          {record.roofLaborDollars.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },

    {
      title: "Truss Labor",
      render: (text, record) => (
        <span>
          {record.trussLaborDollars.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },

    {
      title: "Door Labor",
      render: (text, record) => (
        <span>
          {record.doorLaborDollars.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },

    {
      title: "Paint Labor",
      render: (text, record) => (
        <span>
          {record.paintLaborDollars.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },

    {
      title: "Shop Total Labor",
      render: (text, record) => (
        <span>
          {record.totalLabor.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },

    {
      title: "Standard Total Labor",
      render: (text, record) => (
        <span>
          {standardShedsLabor &&
            handleStandardTotalLabor(record).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },

    {
      title: "Actions",
      fixed: "right",
      render: (text, record) => (
        <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleEdit(record)}>
          View
        </button>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleTest = () => {
    console.log("handle test");
    updateAllSheds();
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12"></div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/* <button className='btn btn-outline-info' onClick={handleTest}>
                  Do not use
                </button> */}
                <Link className="btn btn-outline-info mb-2" to="/labor/sheds/update/all">
                  Bulk Adjust
                </Link>
                {/* <Link className='btn btn-outline-info mb-2 float-end ' to='/labor/sheds/add'>
                  Add Labor
                </Link> */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Table
                  loading={shopShedsLabor ? false : true}
                  columns={columns}
                  dataSource={shopShedsLabor}
                  rowKey="_id"
                  bordered={true}
                  onChange={onChange}
                  scroll={{ y: "45vh" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShedColumns;
