export const shedNames = [
  { value: "Cabin", label: "Cabin" },
  { value: "Center Cabin", label: "Center-Cabin" },
  { value: "Center Lofted Cabin", label: "Center-Lofted-Cabin" },
  { value: "Cottage Shed", label: "Cottage-Shed" },
  { value: "Deluxe Cabin", label: "Deluxe-Cabin" },
  { value: "Deluxe Lofted Barn Cabin", label: "Deluxe-Lofted-Cabin" },
  { value: "Garage", label: "Garage" },
  { value: "Garden Shed", label: "Garden-Shed" },
  { value: "Lofted Barn", label: "Lofted-Barn" },
  { value: "Lofted Barn Cabin", label: "Lofted-Barn-Cabin" },
  { value: "Lofted Garage", label: "Lofted-Garage" },
  { value: "Metal Lofted Barn", label: "Metal-Lofted-Barn" },
  { value: "Metal Side Lofted Barn", label: "Metal-Side-Lofted-Barn" },
  { value: "Metal Side Utility", label: "Metal-Side-Utility" },
  { value: "Metal Utility", label: "Metal-Utility" },
  { value: "Side Cabin", label: "Side-Cabin" },
  { value: "Side Lofted Barn", label: "Side-Lofted-Barn" },
  { value: "Side Utility", label: "Side-Utility" },
  { value: "Utility", label: "Utility" },
];

export const roofColors = [
  { value: "Black", label: "Black" },
  { value: "Gray", label: "Gray" },
  { value: "Hunter", label: "Hunter" },
  { value: "Crimson Red", label: "Crimson Red" },
  { value: "Light Stone", label: "Light Stone" },
  { value: "Burgundy", label: "Burgundy" },
  { value: "Burnished Slate", label: "Burnished Slate" },
  { value: "Galvalume", label: "Galvalume" },
  { value: "Charcoal", label: "Charcoal" },
  { value: "Brilliant White", label: "Brilliant White" },
  { value: "Gallery Blue", label: "Gallery Blue" },
  { value: "Tan", label: "Tan" },
  { value: "Rustic Red", label: "Rustic Red" },
  { value: "Taupe", label: "Taupe" },
  { value: "Brown", label: "Brown" },
  { value: "Pewter", label: "Pewter" },
  { value: "Copper", label: "Copper" },
  { value: "Desert", label: "Desert" },
];

export const trimColors = [
  { value: "Chestnut", label: "Chestnut" },
  { value: "Red Mahogany", label: "Red Mahogany" },
  { value: "Driftwood", label: "Driftwood" },
  { value: "Dark Ebony", label: "Dark Ebony" },
  { value: "Scarlett Red", label: "Scarlett Red" },
  { value: "Olive Green", label: "Olive Green" },
  { value: "Slate Blue", label: "Slate Blue" },
  { value: "Sandstone", label: "Sandstone" },
  { value: "Super White", label: "Super White" },
  { value: "Cape Cod Gray", label: "Cape Cod Gray" },
  { value: "Belmont Blue", label: "Belmont Blue" },
  { value: "Pinnacle Red", label: "Pinnacle Red" },
  { value: "Bora Bora", label: "Bora Bora" },
  { value: "Nuture Green", label: "Nuture Green" },
  { value: "Peace Yellow", label: "Peace Yellow" },
  { value: "Navajo White", label: "Navajo White" },
  { value: "Navy Blue", label: "Navy Blue" },
  { value: "Buckskin", label: "Buckskin" },
  { value: "Avocado", label: "Avocado" },
  { value: "Red Delicious", label: "Red Delicious" },
  { value: "Barn Red", label: "Barn Red" },
  { value: "Charcoal", label: "Charcoal" },
  { value: "Taupe", label: "Taupe" },
  { value: "Gray", label: "Gray" },
  { value: "Pewter", label: "Pewter" },
  { value: "Smoke", label: "Smoke" },
  { value: "Brilliant White", label: "Brilliant White" },
  { value: "Light Stone", label: "Light Stone" },
  { value: "Brown", label: "Brown" },
  { value: "Burnished Slate", label: "Burnished Slate" },
  { value: "Rustic Red", label: "Rustic Red" },
  { value: "Hunter Green", label: "Hunter Green" },
  { value: "Black", label: "Black" },
  { value: "Burgundy", label: "Burgundy" },
  { value: "Gallery Blue", label: "Gallery Blue" },
  { value: "Crimson", label: "Crimson" },
  { value: "Galvanized", label: "Galvanized" },
  { value: "Tan", label: "Tan" },
  { value: "Colony Green", label: "Colony Green" },
  { value: "Desert Sand", label: "Desert Sand" },
  { value: "Pewter Gray", label: "Pewter Gray" },
];

export const sidingColors = [
  { value: "Chestnut", label: "Chestnut" },
  { value: "Red Mahogany", label: "Red Mahogany" },
  { value: "Driftwood", label: "Driftwood" },
  { value: "Dark Ebony", label: "Dark Ebony" },
  { value: "Scarlett Red", label: "Scarlett Red" },
  { value: "Olive Green", label: "Olive Green" },
  { value: "Slate Blue", label: "Slate Blue" },
  { value: "Sandstone", label: "Sandstone" },
  { value: "Super White", label: "Super White" },
  { value: "Cape Cod Gray", label: "Cape Cod Gray" },
  { value: "Belmont Blue", label: "Belmont Blue" },
  { value: "Pinnacle Red", label: "Pinnacle Red" },
  { value: "Bora Bora", label: "Bora Bora" },
  { value: "Nuture Green", label: "Nuture Green" },
  { value: "Peace Yellow", label: "Peace Yellow" },
  { value: "Navajo White", label: "Navajo White" },
  { value: "Navy Blue", label: "Navy Blue" },
  { value: "Buckskin", label: "Buckskin" },
  { value: "Avocado", label: "Avocado" },
  { value: "Red Delicious", label: "Red Delicious" },
  { value: "Barn Red", label: "Barn Red" },
  { value: "Charcoal", label: "Charcoal" },
  { value: "Taupe", label: "Taupe" },
  { value: "Gray", label: "Gray" },
  { value: "Pewter", label: "Pewter" },
  { value: "Smoke", label: "Smoke" },
  { value: "Brilliant White", label: "Brilliant White" },
  { value: "Light Stone", label: "Light Stone" },
  { value: "Brown", label: "Brown" },
  { value: "Burnished Slate", label: "Burnished Slate" },
  { value: "Rustic Red", label: "Rustic Red" },
  { value: "Hunter Green", label: "Hunter Green" },
  { value: "Black", label: "Black" },
  { value: "Burgundy", label: "Burgundy" },
  { value: "Gallery Blue", label: "Gallery Blue" },
  { value: "Crimson", label: "Crimson" },
  { value: "Galvanized", label: "Galvanized" },
  { value: "Tan", label: "Tan" },
  { value: "Colony Green", label: "Colony Green" },
  { value: "Desert Sand", label: "Desert Sand" },
  { value: "Pewter Gray", label: "Pewter Gray" },
];

export const allStatus = [
  { value: "New", label: "New" },
  { value: "Processing", label: "Processing" },
  { value: "Completed", label: "Completed" },
  { value: "Invoiced", label: "Invoiced" },
];

export const zones = [
  { value: "Z1", label: "Z1" },
  { value: "Z2", label: "Z2" },
  { value: "Z3", label: "Z3" },
  { value: "Z4", label: "Z4" },
];

export const orderStatus = [
  { value: "All", label: "All" },
  { value: "New", label: "New" },
  { value: "InQueue", label: "InQueue" },
  { value: "Building", label: "Building" },
  { value: "Finished", label: "Finished" },
  { value: "Invoiced", label: "Invoiced" },
];

export const orderReportStatus = [
  { value: "All", label: "All (Building,Finished,Invoiced)" },
  { value: "InQueue", label: "InQueue" },
  { value: "Building", label: "Building" },
  { value: "Finished", label: "Finished" },
  { value: "Invoiced", label: "Invoiced" },
];

export const addOnCategories = [
  { value: "deductions", label: "Deductions" },
  { value: "discounts", label: "Discounts" },
  { value: "doors", label: "Doors" },
  { value: "windows", label: "Windows" },
  { value: "electric", label: "Electric" },
  { value: "exterior", label: "Exterior" },
  { value: "interior", label: "Interior" },
  { value: "upgrades", label: "Upgrades" },
  { value: "extras", label: "Extras" },
];

export const categories = [
  { value: "All", label: "All" },
  { value: "Treated", label: "Treated" },
  { value: "White", label: "White" },
  { value: "Hardware", label: "Hardware" },
  { value: "Metal", label: "Metal" },
  { value: "Windows/Doors", label: "Windows/Doors" },
  { value: "Nails/Screws", label: "Nails/Screws" },
  { value: "Electrical", label: "Electrical" },
  { value: "Paint", label: "Paint" },
  { value: "Shingles", label: "Shingles" },
];

export const allTypes = [
  { value: "piece", label: "Piece" },
  { value: "percent", label: "Percent" },
  { value: "sqft", label: "SqFt" },
  { value: "linearFt", label: "Linear Ft" },
  { value: "shingles", label: "Shingles" },
];

export const allZones = [
  { value: "Z1", label: "Z1" },
  { value: "Z2", label: "Z2" },
  { value: "Z3", label: "Z3" },
  { value: "Z4", label: "Z4" },
];

export const laborTypes = [
  { value: "piece", label: "Piece" },
  { value: "percent", label: "Percent" },
  { value: "sqft", label: "SqFt" },
  { value: "linearFt", label: "Linear Ft" },
  { value: "shingles", label: "Shingles 32 SqFt" },
];

export const sizes = [
  { value: "8x10", label: "8x10" },
  { value: "8x12", label: "8x12" },
  { value: "8x14", label: "8x14" },
  { value: "8x16", label: "8x16" },
  { value: "8x18", label: "8x18" },
  { value: "8x20", label: "8x20" },
  { value: "10x10", label: "10x10" },
  { value: "10x12", label: "10x12" },
  { value: "10x14", label: "10x14" },
  { value: "10x16", label: "10x16" },
  { value: "10x18", label: "10x18" },
  { value: "10x20", label: "10x20" },
  { value: "10x22", label: "10x22" },
  { value: "10x24", label: "10x24" },
  { value: "12x12", label: "12x12" },
  { value: "12x14", label: "12x14" },
  { value: "12x16", label: "12x16" },
  { value: "12x18", label: "12x18" },
  { value: "12x20", label: "12x20" },
  { value: "12x22", label: "12x22" },
  { value: "12x24", label: "12x24" },
  { value: "12x26", label: "12x26" },
  { value: "12x28", label: "12x28" },
  { value: "12x30", label: "12x30" },
  { value: "12x32", label: "12x32" },
  { value: "12x34", label: "12x34" },
  { value: "12x36", label: "12x36" },
  { value: "12x38", label: "12x38" },
  { value: "12x40", label: "12x40" },
  { value: "12x42", label: "12x42" },
  { value: "12x44", label: "12x44" },
  { value: "12x46", label: "12x46" },
  { value: "12x48", label: "12x48" },
  { value: "12x50", label: "12x50" },
  { value: "12x52", label: "12x52" },
  { value: "14x16", label: "14x16" },
  { value: "14x18", label: "14x18" },
  { value: "14x20", label: "14x20" },
  { value: "14x22", label: "14x22" },
  { value: "14x24", label: "14x24" },
  { value: "14x26", label: "14x26" },
  { value: "14x28", label: "14x28" },
  { value: "14x30", label: "14x30" },
  { value: "14x32", label: "14x32" },
  { value: "14x34", label: "14x34" },
  { value: "14x36", label: "14x36" },
  { value: "14x38", label: "14x38" },
  { value: "14x40", label: "14x40" },
  { value: "14x42", label: "14x42" },
  { value: "14x44", label: "14x44" },
  { value: "14x46", label: "14x46" },
  { value: "14x48", label: "14x48" },
  { value: "14x50", label: "14x50" },
  { value: "14x52", label: "14x52" },
  { value: "14x56", label: "14x56" },
  { value: "14x60", label: "14x60" },
  { value: "16x20", label: "16x20" },
  { value: "16x22", label: "16x22" },
  { value: "16x24", label: "16x24" },
  { value: "16x26", label: "16x26" },
  { value: "16x28", label: "16x28" },
  { value: "16x30", label: "16x30" },
  { value: "16x32", label: "16x32" },
  { value: "16x34", label: "16x34" },
  { value: "16x36", label: "16x36" },
  { value: "16x38", label: "16x38" },
  { value: "16x40", label: "16x40" },
  { value: "16x42", label: "16x42" },
  { value: "16x44", label: "16x44" },
  { value: "16x46", label: "16x46" },
  { value: "16x48", label: "16x48" },
  { value: "16x50", label: "16x50" },
  { value: "16x52", label: "16x52" },
  { value: "16x56", label: "16x56" },
  { value: "16x60", label: "16x60" },
];

export const shedNamesByZone = [
  { value: "Cabin-Z1", label: "Cabin-Z1" },
  { value: "Cabin-Z2", label: "Cabin-Z2" },
  { value: "Cabin-Z3", label: "Cabin-Z3" },
  { value: "Cabin-Z4", label: "Cabin-Z4" },
  { value: "Center Cabin-Z1", label: "Center-Cabin-Z1" },
  { value: "Center Cabin-Z2", label: "Center-Cabin-Z2" },
  { value: "Center Cabin-Z3", label: "Center-Cabin-Z3" },
  { value: "Center Cabin-Z4", label: "Center-Cabin-Z4" },
  { value: "Center Lofted Cabin-Z1", label: "Center-Lofted-Cabin-Z1" },
  { value: "Center Lofted Cabin-Z2", label: "Center-Lofted-Cabin-Z2" },
  { value: "Center Lofted Cabin-Z3", label: "Center-Lofted-Cabin-Z3" },
  { value: "Center Lofted Cabin-Z4", label: "Center-Lofted-Cabin-Z4" },
  { value: "Cottage Shed-Z1", label: "Cottage-Shed-Z1" },
  { value: "Cottage Shed-Z2", label: "Cottage-Shed-Z2" },
  { value: "Cottage Shed-Z3", label: "Cottage-Shed-Z3" },
  { value: "Cottage Shed-Z4", label: "Cottage-Shed-Z4" },
  { value: "Deluxe Cabin-Z1", label: "Deluxe-Cabin-Z1" },
  { value: "Deluxe Cabin-Z2", label: "Deluxe-Cabin-Z2" },
  { value: "Deluxe Cabin-Z3", label: "Deluxe-Cabin-Z3" },
  { value: "Deluxe Cabin-Z4", label: "Deluxe-Cabin-Z4" },
  { value: "Deluxe Lofted Barn Cabin-Z1", label: "Deluxe-Lofted-Cabin-Z1" },
  { value: "Deluxe Lofted Barn Cabin-Z2", label: "Deluxe-Lofted-Cabin-Z2" },
  { value: "Deluxe Lofted Barn Cabin-Z3", label: "Deluxe-Lofted-Cabin-Z3" },
  { value: "Deluxe Lofted Barn Cabin-Z4", label: "Deluxe-Lofted-Cabin-Z4" },
  { value: "Garage-Z1", label: "Garage-Z1" },
  { value: "Garage-Z2", label: "Garage-Z2" },
  { value: "Garage-Z3", label: "Garage-Z3" },
  { value: "Garage-Z4", label: "Garage-Z4" },
  { value: "Garden Shed-Z1", label: "Garden-Shed-Z1" },
  { value: "Garden Shed-Z2", label: "Garden-Shed-Z2" },
  { value: "Garden Shed-Z3", label: "Garden-Shed-Z3" },
  { value: "Garden Shed-Z4", label: "Garden-Shed-Z4" },
  { value: "Lofted Barn-Z1", label: "Lofted-Barn-Z1" },
  { value: "Lofted Barn-Z2", label: "Lofted-Barn-Z2" },
  { value: "Lofted Barn-Z3", label: "Lofted-Barn-Z3" },
  { value: "Lofted Barn-Z4", label: "Lofted-Barn-Z4" },
  { value: "Lofted Barn Cabin-Z1", label: "Lofted-Barn-Cabin-Z1" },
  { value: "Lofted Barn Cabin-Z2", label: "Lofted-Barn-Cabin-Z2" },
  { value: "Lofted Barn Cabin-Z3", label: "Lofted-Barn-Cabin-Z3" },
  { value: "Lofted Barn Cabin-Z4", label: "Lofted-Barn-Cabin-Z4" },
  { value: "Lofted Garage-Z1", label: "Lofted-Garage-Z1" },
  { value: "Lofted Garage-Z2", label: "Lofted-Garage-Z2" },
  { value: "Lofted Garage-Z3", label: "Lofted-Garage-Z3" },
  { value: "Lofted Garage-Z4", label: "Lofted-Garage-Z4" },
  { value: "Metal Lofted Barn-Z1", label: "Metal-Lofted-Barn-Z1" },
  { value: "Metal Lofted Barn-Z2", label: "Metal-Lofted-Barn-Z2" },
  { value: "Metal Lofted Barn-Z3", label: "Metal-Lofted-Barn-Z3" },
  { value: "Metal Lofted Barn-Z4", label: "Metal-Lofted-Barn-Z4" },
  { value: "Metal Side Lofted Barn-Z1", label: "Metal-Side-Lofted-Barn-Z1" },
  { value: "Metal Side Lofted Barn-Z2", label: "Metal-Side-Lofted-Barn-Z2" },
  { value: "Metal Side Lofted Barn-Z3", label: "Metal-Side-Lofted-Barn-Z3" },
  { value: "Metal Side Lofted Barn-Z4", label: "Metal-Side-Lofted-Barn-Z4" },
  { value: "Metal Side Utility Barn-Z1", label: "Metal-Side-Utility-Barn-Z1" },
  { value: "Metal Side Utility Barn-Z2", label: "Metal-Side-Utility-Barn-Z2" },
  { value: "Metal Side Utility Barn-Z3", label: "Metal-Side-Utility-Barn-Z3" },
  { value: "Metal Side Utility Barn-Z4", label: "Metal-Side-Utility-Barn-Z4" },
  { value: "Metal Utility Barn-Z1", label: "Metal-Utility-Barn-Z1" },
  { value: "Metal Utility Barn-Z2", label: "Metal-Utility-Barn-Z2" },
  { value: "Metal Utility Barn-Z3", label: "Metal-Utility-Barn-Z3" },
  { value: "Metal Utility Barn-Z4", label: "Metal-Utility-Barn-Z4" },
  { value: "Side Cabin-Z1", label: "Side-Cabin-Z1" },
  { value: "Side Cabin-Z2", label: "Side-Cabin-Z2" },
  { value: "Side Cabin-Z3", label: "Side-Cabin-Z3" },
  { value: "Side Cabin-Z4", label: "Side-Cabin-Z4" },
  { value: "Side Lofted Barn-Z1", label: "Side-Lofted-Barn-Z1" },
  { value: "Side Lofted Barn-Z2", label: "Side-Lofted-Barn-Z2" },
  { value: "Side Lofted Barn-Z3", label: "Side-Lofted-Barn-Z3" },
  { value: "Side Lofted Barn-Z4", label: "Side-Lofted-Barn-Z4" },
  { value: "Side Utility-Z1", label: "Side-Utility-Z1" },
  { value: "Side Utility-Z2", label: "Side-Utility-Z2" },
  { value: "Side Utility-Z3", label: "Side-Utility-Z3" },
  { value: "Side Utility-Z4", label: "Side-Utility-Z4" },
  { value: "Utility-Z1", label: "Utility-Z1" },
  { value: "Utility-Z2", label: "Utility-Z2" },
  { value: "Utility-Z3", label: "Utility-Z3" },
  { value: "Utility-Z4", label: "Utility-Z4" },
];
