import React, { Fragment, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Checklist from './pdfChecklist/Checklist';
import ProductContext from '../../../context/product/productContext';

const ProductChecklistModal = ({ handleClose, handleShow, show, products }) => {
  const productContext = useContext(ProductContext);

  return (
    <>
      <Modal show={show} onHide={handleClose} size=''>
        <Modal.Header closeButton>
          <Modal.Title>Download Inventory Checklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div>
              <Fragment>
                <PDFDownloadLink
                  document={<Checklist data={products} />}
                  fileName={`Inventory_Checklist.pdf`}
                  className='btn btn-outline-info '
                  type='button'
                  // style={{
                  // textDecoration: "none",
                  // padding: "10px",
                  // color: "#ffffff",
                  // backgroundColor: "#033699",
                  // border: "1px solid #4a4a4a"
                  // }}
                >
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download Checklist')}
                </PDFDownloadLink>
              </Fragment>
              <button className='btn btn-outline-info float-end' type='button' onClick={handleClose}>
                Exit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductChecklistModal;
