import React, { useRef, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

const SummaryTable = ({ tableData }) => {
  const tableRef = useRef(null);
  useEffect(() => {
    tableRef.current.dataManager.changePageSize(20);
  }, []);

  return (
    <MaterialTable
      tableRef={tableRef}
      options={{
        rowStyle: {
          fontSize: "12px",
        },
        exportMenu: [
          {
            label: "Export PDF",
            //// You can do whatever you wish in this function. We provide the
            //// raw table columns and table data for you to modify, if needed.
            // exportFunc: (cols, datas) => console.log({ cols, datas })
            exportFunc: (cols, datas) => ExportPdf(cols, datas, "Orders/Labor"),
          },
          {
            label: "Export CSV",
            exportFunc: (cols, datas) => ExportCsv(cols, datas, "Orders/Labor"),
          },
        ],
        emptyRowsWhenPaging: false,
        pageSizeOptions: [40, 100],
      }}
      columns={[
        { title: "Model", field: "title", render: (rowData) => rowData.label },
        { title: "Quantity", field: "quantity" },
        {
          title: "Total Sale Price",
          field: "totalSalePrice",
          render: (rowData) =>
            rowData.totalSalePrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "GP ($)",
          field: "shopProfitDollars",
          render: (rowData) =>
            rowData.shopProfitDollars.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
        },

        {
          title: " GP Avg %",
          field: "avgProfitPercent",
          render: (rowData) => rowData.avgProfitPercent,
        },
        {
          title: "Standard GP ($)",
          field: "standardProfitDollars",
          render: (rowData) =>
            rowData.standardProfitDollars.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "Standard GP Avg %",
          field: "standardAvgProfitPercent",
          render: (rowData) => rowData.standardAvgProfitPercent,
        },
      ]}
      data={tableData}
      title="Orders/Summary"
      renderSummaryRow={({ column, data }) => {
        let theTotalSalePrice = data.reduce((agg, row) => agg + row.totalSalePrice, 0);
        let theTotalShopProfit = data.reduce((agg, row) => agg + row.shopProfitDollars, 0);
        let theTotalStandardProfit = data.reduce((agg, row) => agg + row.standardProfitDollars, 0);
        let theTotalAvgPercent = (theTotalShopProfit && (theTotalShopProfit / theTotalSalePrice) * 100).toFixed(2) || 0;
        let theStandardTotalAvgPercent =
          (theTotalStandardProfit && (theTotalStandardProfit / theTotalSalePrice) * 100).toFixed(2) || 0;

        return column.field === "title"
          ? {
              value: "Totals",
              style: { background: "#6ABAC9" },
            }
          : column.field === "quantity"
          ? {
              value: data.reduce((agg, row) => agg + row.quantity, 0),
              style: { background: "#E0F4F5" },
            }
          : column.field === "totalSalePrice"
          ? {
              value: theTotalSalePrice.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
              style: { background: "#E0F4F5" },
            }
          : column.field === "shopProfitDollars"
          ? {
              value: theTotalShopProfit.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
              style: { background: "#E0F4F5" },
            }
          : column.field === "avgProfitPercent"
          ? {
              value: theTotalAvgPercent,
              style: { background: "#E0F4F5" },
            }
          : column.field === "standardProfitDollars"
          ? {
              value: theTotalStandardProfit.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
              style: { background: "#E0F4F5" },
            }
          : column.field === "standardAvgProfitPercent"
          ? {
              value: theStandardTotalAvgPercent,
              style: { background: "#E0F4F5" },
            }
          : {
              value: 0,
              // style: { background: 'light gray' },
            };
      }}
    />
  );
};

export default SummaryTable;
