import React from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

const LaborTable = ({ newOrders }) => {
  console.log("this isthe labor orders", newOrders);
  return (
    <MaterialTable
      options={{
        rowStyle: {
          fontSize: "12px",
        },
        exportMenu: [
          {
            label: "Export PDF",
            //// You can do whatever you wish in this function. We provide the
            //// raw table columns and table data for you to modify, if needed.
            // exportFunc: (cols, datas) => console.log({ cols, datas })
            exportFunc: (cols, datas) => ExportPdf(cols, datas, "Orders/Labor"),
          },
          {
            label: "Export CSV",
            exportFunc: (cols, datas) => ExportCsv(cols, datas, "Orders/Labor"),
          },
        ],
        pageSize: 20,
        pageSizeOptions: [20, 40, 100],
      }}
      columns={[
        { title: "Serial #", field: "serialNumber" },
        // { title: 'Date Ordered', field: 'dateOrdered', render: (rowData) => moment(rowData.dateOrdered).format('MM/DD/YYYY') },
        // {
        //   title: 'Date Finished',
        //   field: 'dateFinished',
        //   render: (rowData) => rowData.dateFinished && moment(rowData.dateFinished).format('MM/DD/YYYY'),
        // },

        {
          title: "Builder Labor",
          field: "shopBuilderLabor",
          type: "numeric",
          render: (rowData) =>
            rowData.shopBuilderLabor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "Roof Labor",
          field: "shopRoofLabor",
          render: (rowData) =>
            rowData.shopRoofLabor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "Truss Labor",
          field: "shopTrussLabor",
          render: (rowData) =>
            rowData.shopTrussLabor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "Door Labor",
          field: "shopDoorLabor",
          render: (rowData) =>
            rowData.shopDoorLabor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "Paint Labor",
          field: "shopPaintLabor",
          render: (rowData) =>
            rowData.shopPaintLabor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "AddOn Labor",
          field: "shopAddOnsTotalLabor",
          render: (rowData) =>
            rowData.shopAddOnsTotalLabor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
        },
      ]}
      data={newOrders && newOrders}
      title="Orders/Labor"
      renderSummaryRow={({ column, data }) =>
        column.field === "shopBuilderLabor"
          ? {
              value: data
                .reduce((agg, row) => agg + row.shopBuilderLabor, 0)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
              style: { background: "#E0F4F5" },
            }
          : column.field === "serialNumber"
          ? {
              value: "Totals",
              style: { background: "#6ABAC9" },
            }
          : column.field === "shopRoofLabor"
          ? {
              value: data
                .reduce((agg, row) => agg + row.shopRoofLabor, 0)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
              style: { background: "#E0F4F5" },
            }
          : column.field === "shopTrussLabor"
          ? {
              value: data
                .reduce((agg, row) => agg + row.shopTrussLabor, 0)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
              style: { background: "#E0F4F5" },
            }
          : column.field === "shopDoorLabor"
          ? {
              value: data
                .reduce((agg, row) => agg + row.shopDoorLabor, 0)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
              style: { background: "#E0F4F5" },
            }
          : column.field === "shopPaintLabor"
          ? {
              value: data
                .reduce((agg, row) => agg + row.shopPaintLabor, 0)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
              style: { background: "#E0F4F5" },
            }
          : column.field === "shopAddOnsTotalLabor"
          ? {
              value: data
                .reduce((agg, row) => agg + row.shopAddOnsTotalLabor, 0)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
              style: { background: "#E0F4F5" },
            }
          : {
              value: 0,
              // style: { background: 'light gray' },
            }
      }
    />
  );
};

export default LaborTable;
