import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AuthContext from "../../../../context/auth/authContext";
import OrderContext from "../../../../context/order/orderContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import { shedNamesByZone } from "../../../names/shedNames";
import DateFilters from "./DateFilters";
import SummaryTable from "./SummaryTable";
import TableLoading from "./TableLoading";

const OrdersByModelSummary = () => {
  const navigate = useNavigate();
  const orderContext = useContext(OrderContext);
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const { getOrdersByDateFilter, orders, loadingOrders, setLoadingOrders, clearOrders } = orderContext;

  useEffect(() => {
    clearOrders();
    loadUser();
    getSettings();
  }, []);

  const handleModelUpdate = (model) => {
    function filterByModel(order) {
      if (model.value === order.model) {
        return order;
      }
    }
    let filteredOrders = orders.filter(filterByModel);
    let quantity = filteredOrders.length || 0;
    let totalSalePrice = filteredOrders.reduce((acc, curr) => acc + parseFloat(curr.shopTotalSalePrice || 0), 0);
    let shopProfit = filteredOrders.reduce((acc, curr) => acc + parseFloat(curr.shopProfitDollars || 0), 0);
    let avgProfitPercent = (quantity && (shopProfit / totalSalePrice) * 100).toFixed(2) || 0;

    let standardProfitDollars = filteredOrders.reduce((acc, curr) => acc + parseFloat(curr.standardProfitDollars || 0), 0);
    let standardAvgProfitPercent = (quantity && (standardProfitDollars / totalSalePrice) * 100).toFixed(2) || 0;

    let theModel = {};
    theModel.quantity = quantity;
    theModel.totalSalePrice = totalSalePrice;
    theModel.shopProfitDollars = shopProfit;
    theModel.avgProfitPercent = avgProfitPercent;
    theModel.standardProfitDollars = standardProfitDollars;
    theModel.standardAvgProfitPercent = standardAvgProfitPercent;
    return theModel;
  };

  const theTableData =
    orders &&
    shedNamesByZone.map((model) => {
      let theModel = handleModelUpdate(model);
      if (theModel.quantity === 0) {
        return null;
      } else {
        model.quantity = theModel.quantity;
        model.totalSalePrice = theModel.totalSalePrice;
        model.shopProfitDollars = theModel.shopProfitDollars;
        model.avgProfitPercent = theModel.avgProfitPercent;
        model.standardProfitDollars = theModel.standardProfitDollars;
        model.standardAvgProfitPercent = theModel.standardAvgProfitPercent;
        return model;
      }
    });

  // theTableData && console.log("this isthe data", theTableData);
  const tableData =
    theTableData &&
    theTableData.filter((n) => {
      return n != null;
    });

  // tableData && console.log("this isthe data", tableData);
  const setExit = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-md-12 ">
            <button className="float-end btn btn-outline-danger " type="button" onClick={setExit}>
              Back
            </button>
          </div>
        </div>
        <div className="row mt-4">
          <DateFilters
            getOrdersByDateFilter={getOrdersByDateFilter}
            setLoadingOrders={setLoadingOrders}
            clearOrders={clearOrders}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          {loadingOrders ? (
            // <div className='row text-center'>
            //   <Spinner />
            // </div>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingOrders}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : tableData === null ? (
            <TableLoading title={"Orders/Summary"} />
          ) : (
            <SummaryTable tableData={tableData} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default OrdersByModelSummary;
