import {
  ADD_ADDONLABOR,
  DELETE_ADDONLABOR,
  CLEAR_ADDONLABORS,
  CLEAR_CURRENT_ADDONLABOR,
  UPDATE_ADDONLABOR,
  ADDONLABOR_ERROR,
  SET_ADDONLABORS,
  GET_SHOP_ADDONLABORS,
  GET_STANDARD_ADDONLABORS,
  SET_CURRENT_SHOP_ADDONLABOR,
  SET_CURRENT_STANDARD_ADDONLABOR,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_SHOP_ADDONLABORS:
      return {
        ...state,
        addOnsShopLabor: action.payload,
        loading: false,
      };
    case GET_STANDARD_ADDONLABORS:
      return {
        ...state,
        addOnsStandardLabor: action.payload,
        loading: false,
      };
    case ADD_ADDONLABOR:
      return {
        ...state,
        addOnLabor: action.payload,
        loading: false,
      };
    case UPDATE_ADDONLABOR:
      return {
        ...state,
        addOnsLabor:
          state.addOnsLabor && state.addOnsLabor.map((labor) => (labor._id === action.payload._id ? action.payload : labor)),
        loading: false,
      };
    case DELETE_ADDONLABOR:
      return {
        ...state,
        addOnsLabor: state.addOnsLabor.filter((labor) => labor._id !== action.payload),
        loading: false,
      };
    case CLEAR_ADDONLABORS:
      return {
        ...state,
        addOnsShopLabor: null,
        addOnsStandardLabor: null,
        filteredAddOnLabor: null,
        error: null,
        currentAddOnLabor: null,
      };
    case SET_CURRENT_SHOP_ADDONLABOR:
      return {
        ...state,
        currentShopAddOnLabor: action.payload,
      };
    case SET_CURRENT_STANDARD_ADDONLABOR:
      return {
        ...state,
        currentStandardAddOnLabor: action.payload,
      };

    case SET_ADDONLABORS:
      return {
        ...state,
        addOnsLabor: action.payload,
      };
    case CLEAR_CURRENT_ADDONLABOR:
      return {
        ...state,
        currentShopAddOnLabor: null,
        currentStandardAddOnLabor: null,
      };

    case ADDONLABOR_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
