import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    display: "flex",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  styleRow: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",

    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  style: {
    width: "40%",
    borderRightColor: "#E2F6Fa",
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 5,
  },
  item: {
    width: "45%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 5,
  },
  quantity: {
    width: "15%",
    textAlign: "left",
    paddingLeft: 5,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  inventory: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 5,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const ProductTableRow = ({ values }) => {
  console.log("this is the values", values);
  const rows = values.map((product, i) => (
    <View style={styles.row} key={product._id + i}>
      <Text style={styles.item}>{product.title}</Text>
      <Text style={styles.quantity}>{product.onOrderQuantity}</Text>
      <Text style={styles.inventory}>{+product.quantity + +product.recievedQuantity}</Text>
      <Text style={styles.inventory}>-</Text>
    </View>
  ));

  return <Fragment>{rows}</Fragment>;
};

export default ProductTableRow;
