import React, { Fragment } from 'react';
import { useFieldArray } from 'react-hook-form';



const Builders = ({ builder, builderIndex, control, remove, setValue, getValues, register }) => {
 

  return (
    <Fragment>
      <div>
        <div className='col-md-12'>
          <div className='row mt-2'>
            <div className='col-md-5 '>
              <div className='form-group'>
              <input className='form-control inputbg' name={`builders[${builderIndex}].builder`} {...register(`builders[${builderIndex}].builder`, { required: true })} />
              </div>
            </div>

            {/* <div className='col-md-3'>
              <div className='form-group'>
                <Controller
                  render={({ field, name }) => <input readOnly className='form-control' name={name} {...field} />}
                  key={builder.id}
                  control={control}
                  name={`builders[${builderIndex}].builder.title`}
                />
              </div>
            </div> */}


  
            <div className='col-md-1'>
              <div className='form-group float-end'>
                <button
                  className='btn btn-outline-danger mb-2'
                  type='button'
                  onClick={() => {
                    remove(builderIndex);
                  
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};



export default function Fields({ control, register, setValue, getValues }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'builders',
  });

 

  return (
    <div className='container-fluid border mt-2' style={{ borderRadius: '15px' }}>
      <div className='row mt-2 '>
        <div className='col-md-5 text-center'>
          <h5>Builder</h5>
        </div>
        </div>

      {fields.map((builder, builderIndex) => (
        <Builders
          key={builder.id}
          builder={builder}
          builderIndex={builderIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
  
        />
      ))}
      <button
        className='btn btn-outline-info mb-2 mt-2  '
        onClick={(e) => {
          e.preventDefault();
          append({ append: 1 });
        }}
      >
        Add Builder
      </button>
    </div>
  );
}
