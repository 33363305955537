import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ProductContext from "../../../context/product/productContext";
import ProductFilter from "../../../context/product/ProductFilter";
// import OrderContext from '../../../context/order/orderContext';
import { Table } from "antd";
import ProductChecklistModal from "./ProductChecklistModal";
import { EditOutlined } from "@ant-design/icons";

const TreatedLumber = () => {
  const navigate = useNavigate();
  const productContext = useContext(ProductContext);
  // const orderContext = useContext(OrderContext);
  // const { getReportOrdersByCategory, orders, clearOrders } = orderContext;
  const {
    getProductsByCategory,
    deleteProduct,
    setCurrentProduct,
    products,
    filteredProduct,
    clearProducts,
    loadingProduct,
    updateInventoryLevels,
  } = productContext;

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    clearProducts();
    getProductsByCategory("Treated");
    // getReportOrdersByCategory('InQueue');
  }, []);

  useEffect(() => {}, [products]);

  const handleRemove = (product) => {
    let answer = window.confirm(`Are you sure you want to delete ${product.slug}?`);
    if (answer) {
      deleteProduct(product);
    }
  };

  const getColor = (p) => {
    if (p.quantity < p.orderPointMin) return "red";
    if (p.quantity < p.orderPoint) return "orange";

    return "";
  };

  const getTextColor = (p) => {
    if (p.quantity < p.orderPointMin) return "white";
    return "";
  };

  const handleViewProduct = (product) => {
    setCurrentProduct(product);
    navigate(`/product/update`);
  };

  // const handleInQueueInventory = (product) => {
  //   let itemCountArr = [];

  //   orders.forEach((order) => {
  //     let item = order.shedProShed.products.find((i) => i.product.title === product.title);
  //     item && itemCountArr.push(item.count);
  //   });

  //   orders.forEach((order) => {
  //     let item = order.shedProShed.trussOne.products.find((i) => i.product.title === product.title);
  //     let trussOneCountTotal = item && item.count * order.shedProShed.trussOneCount;
  //     item && itemCountArr.push(trussOneCountTotal);
  //   });

  //   orders.forEach((order) => {
  //     let item = order.shedProShed.trussTwo.products.find((i) => i.product.title === product.title);
  //     let trussTwoCountTotal = item && item.count * order.shedProShed.trussTwoCount;
  //     item && itemCountArr.push(trussTwoCountTotal);
  //   });

  //   orders.forEach((order) => {
  //     let item = order.shedProShed.door.products.find((i) => i.product.title === product.title);
  //     let doorCountTotal = item && item.count * order.shedProShed.doorCount;
  //     item && itemCountArr.push(doorCountTotal);
  //   });

  //   orders.forEach((order) => {
  //     order.addOns.forEach((addOn) => {
  //       if (addOn.name === 'SHINGLES UPGRADE W/ FELT') {
  //         let roofColor = order.roofColor;
  //         if (product.title === roofColor) {
  //           let item = addOn.addOn.products.find((i) => i.product.title === 'Shingles(1 bag)');
  //           let addOnItemCountTotal = item && item.count * addOn.quantity;
  //           item && itemCountArr.push(addOnItemCountTotal);
  //         }
  //       } else {
  //         let item = addOn.addOn.products.find((i) => i.product.title === product.title);
  //         let addOnItemCountTotal = item && item.count * addOn.quantity;
  //         item && itemCountArr.push(addOnItemCountTotal);
  //       }
  //     });
  //   });

  // let itemCountTotal = itemCountArr.reduce((s, t) => (s = s + t), 0);

  // return itemCountTotal;
  // };

  const handleClearInventory = () => {
    updateInventoryLevels();
  };

  const columns = [
    {
      title: "SKU",
      width: "15%",
      fixed: "left",
      render(text, record) {
        return {
          props: {
            style: {
              background: getColor(record),
              color: getTextColor(record),
            },
          },
          children: <div>{record.sku}</div>,
        };
      },
    },
    {
      title: "Name",
      width: "20%",
      render: (record) => <Fragment>{record.title}</Fragment>,
    },

    {
      title: "Ordered Inventory",
      width: "8%",
      render: (record) => <Fragment>{record.onOrderQuantity}</Fragment>,
    },
    {
      title: "Recieved Inventory",
      width: "8%",
      render: (record) => <Fragment>{record.recievedQuantity}</Fragment>,
    },
    {
      title: "Current Inventory",
      width: "8%",
      render: (record) => <Fragment>{record.quantity}</Fragment>,
    },
    // {
    //   title: 'InQueue Inventory',
    //   width: '8%',
    //   render: (text, record) => <span>{handleInQueueInventory(record)}</span>,
    // },

    {
      title: "Cost",
      width: "8%",
      render: (text, record) => (
        <span>
          {record.subTotal.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    {
      title: "Freight",
      width: "8%",
      render: (text, record) => (
        <span>
          {record.freight &&
            record.freight.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },
    {
      title: "Price",
      width: "8%",
      render: (text, record) => (
        <span>
          {record.price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    {
      title: "Inventory Current Total",
      width: "10%",
      render: (text, record) => (
        <span>
          {(record.price * record.quantity).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          {
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewProduct(record)}>
              View
            </button>
          }
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button className="mb-2 btn btn-outline-success float-end" onClick={() => handleShow()}>
                  Print Inventory Checklist
                </button>
                {/* <button className='mb-2 btn btn-outline-danger float-end' onClick={() => handleClearInventory()}>
                  Clear Inventory Levels
                </button> */}
              </div>
            </div>

            <ProductChecklistModal handleClose={handleClose} handleShow={handleShow} show={show} products={products} />

            <ProductFilter />

            <Table
              loading={!loadingProduct && products ? false : true}
              columns={columns}
              dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : products && products}
              rowKey="_id"
              bordered={true}
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TreatedLumber;
