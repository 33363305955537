import React, { Fragment, useContext } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import InvoiceContext from "../../../../context/invoice/invoiceContext";

const Invoices = ({ invoice, invoiceIndex, control, remove, setValue, getValues, invoiceOptions, register }) => {
  const invoiceContext = useContext(InvoiceContext);
  const { getInvoiceById } = invoiceContext;

  const handleViewInvoice = () => {
    getInvoiceById(invoice.invoice._id);
  };
  return (
    <Fragment>
      <div className="row">
        {/* <div className='col-md-4'></div> */}
        <div className="col-md-1 mt-2">
          <label className="float-end">Invoice #</label>
        </div>
        <div className="col-md-3 mt-1 mb-1">
          <Controller
            render={({ field, name }) => <input readOnly className="form-control inputfield" name={name} {...field} />}
            key={invoice.id}
            control={control}
            name={`invoices[${invoiceIndex}].invoice.invoiceNumber`}
          />
        </div>
        <div className="col-md-2 mt-1 mb-1">
          <button
            className="btn btn-sm btn-outline-info "
            onClick={(e) => {
              e.preventDefault();
              handleViewInvoice();
            }}
          >
            View Invoice
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, invoiceOptions }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "invoices",
  });

  return (
    <div className="container-fluid border mt-4" style={{ borderRadius: "8px" }}>
      {fields.map((invoice, invoiceIndex) => (
        <Invoices
          key={invoice.id}
          invoice={invoice}
          invoiceIndex={invoiceIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          invoiceOptions={invoiceOptions}
        />
      ))}
      {/* <button
        className='btn btn-outline-info mb-2 mt-2  '
        onClick={(e) => {
          e.preventDefault();
          append({ append: 1 });
        }}
      >
        Add invoice
      </button> */}
    </div>
  );
}
