import React, { Fragment, useState, useEffect } from "react";
import { Menu } from "antd";
import { Outlet, Link, useNavigate } from "react-router-dom";

const AllOrdersNav = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState("inqueue");
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  const handleSwitchAddOn = () => {
    // clearAddOns();
  };

  useEffect(() => {
    navigate("/orders/inqueue");
  }, []);

  return (
    <Fragment>
      <Menu className="mb-4 border" onClick={handleMenuChange} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="inqueue">
          <Link onClick={() => handleSwitchAddOn()} to={`/orders/inqueue`}>
            InQueue
          </Link>
        </Menu.Item>
        <Menu.Item key="building">
          <Link onClick={() => handleSwitchAddOn()} to={`/orders/building`}>
            Building
          </Link>
        </Menu.Item>
        <Menu.Item key="finished">
          <Link onClick={() => handleSwitchAddOn()} to={`/orders/finished`}>
            Finished
          </Link>
        </Menu.Item>
        <Menu.Item key="invoiced">
          <Link onClick={() => handleSwitchAddOn()} to={`/orders/invoiced`}>
            Invoiced
          </Link>
        </Menu.Item>
        <Menu.Item key="canceled">
          <Link onClick={() => handleSwitchAddOn()} to={`/orders/canceled`}>
            Canceled
          </Link>
        </Menu.Item>
      </Menu>

      <Outlet />
    </Fragment>
  );
};

export default AllOrdersNav;
