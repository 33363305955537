import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import MetalOrderContext from "../../../context/metalOrder/metalOrderContext";
import MetalContext from "../../../context/metal/metalContext";
import VendorContext from "../../../context/vendor/vendorContext";
import SettingsContext from "../../../context/settings/settingsContext";
import { useForm, Controller } from "react-hook-form";
import PakArray from "./RHFMetalOrderPakArray";
import { Modal } from "antd";
import MetalOrderModal from "./MetalOrderModal";
import ReactSelect from "react-select";

const defaultValues = {
  purchaseOrderNumber: "",
  metalOrder: [
    {
      value: {
        serialNumber: "",
        zone: {},
        title: "",
        products: [],
        roofColor: {
          label: "",
          value: "",
        },
        trimColor: {
          label: "",
          value: "",
        },
        sidingColor: {
          label: "",
          value: "",
        },
      },
    },
  ],
};

const RHFMetalOrderForm = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const metalOrderContext = useContext(MetalOrderContext);
  const metalContext = useContext(MetalContext);
  const vendorContext = useContext(VendorContext);
  const settingsContext = useContext(SettingsContext);
  const { loadUser } = authContext;
  const { getVendors, vendors } = vendorContext;
  const { getSettings, settings } = settingsContext;
  const { getMetalsByZone, metals } = metalContext;
  const { updateMetalOrder, currentMetalOrder, setCurrentMetalOrder, addMetalOrder, clearCurrentMetalOrder, clearMetals } =
    metalOrderContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    loadUser();
    getVendors();
    getSettings();

    currentMetalOrder && reset(currentMetalOrder);
  }, []);

  const onSubmit = (metal) => {
    Modal.confirm({
      title: "Save this Order?",
      okText: "Yes",
      okType: "primary",
      onOk: () => {
        if (!currentMetalOrder) {
          addMetalOrder(metal);
          setCurrentMetalOrder(metal);
          handleShow();
        } else {
          updateMetalOrder(metal);
          setCurrentMetalOrder(metal);
          handleShow();
        }
      },
    });
  };

  // const downloadPdf = () => {
  //   const values = getValues();

  //   if (!currentMetalOrder) {
  //     let answer = window.confirm(`Save & Generate PDF?`);
  //     if (answer) {
  //       setCurrentMetalOrder(values);
  //       setReady(true);
  //       addMetalOrder(values);
  //     }
  //   } else {
  //     setCurrentMetalOrder(values);
  //     setReady(true);
  //     updateMetalOrder(values);
  //   }
  // };

  const vendorOptions =
    vendors &&
    vendors.map((option) => {
      return { label: `${option.vendorName}`, value: option };
    });

  const setVendorChange = (e) => {
    setValue(`vendor`, e);
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentMetalOrder();
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    navigate(-1);
    clearCurrentMetalOrder();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Exit
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid bidformbackground  border border-dark " style={{ borderRadius: "15px" }}>
          <h3 className="text-center mt-2">Metal Order Form</h3>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="form-group">
                      <label>Vendor</label>
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            options={vendorOptions}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            onChange={(e) => setVendorChange(e)}
                          />
                        )}
                        name={`vendor`}
                        control={control}
                      />
                    </div>
                  </div>

                  <div className="col-md-3 mt-4">
                    <div className="form-group">
                      <label>Po#</label>
                      <input
                        className="form-control inputbg"
                        name="purchaseOrderNumber"
                        {...register("purchaseOrderNumber", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="col-md-1"></div>

                  {/* {ready ? (
                    <div className='col-md-2 mt-3'>
                      <PDFDownloadLink
                        className='btn btn-outline-info'
                        document={<Invoice data={currentMetalOrder && currentMetalOrder} settings={settings} />}
                        fileName={`${currentMetalOrder && currentMetalOrder.purchaseOrderNumber} metal_order.pdf`}
                        // style={{
                        // textDecoration: "none",
                        // padding: "10px",
                        // color: "#4a4a4a",
                        // backgroundColor: "#f2f2f2",
                        // border: "1px solid #4a4a4a"
                        // }}
                      >
                        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download Pdf')}
                      </PDFDownloadLink>
                    </div>
                  ) : (
                    <div className='col-md-2'></div>
                  )} */}

                  {/* <div className='col-md-2'>
                    <div className='buttons mt-3'>
                      <button className='btn btn-outline-primary' type='button' onClick={() => downloadPdf()}>
                        Save & Generate PDF
                      </button>
                    </div>
                  </div> */}

                  <div className="col-md-1">
                    <div className="buttons float-end  mr-3 mt-3">
                      <button className="btn btn-outline-primary " type="submit">
                        Save
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PakArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              getValues,
              metals,
              settings,
              getMetalsByZone,
              clearMetals,
            }}
          />
        </div>
      </form>
      {currentMetalOrder && <MetalOrderModal handleClose={handleClose} handleShow={handleShow} show={show} />}
    </div>
  );
};

export default RHFMetalOrderForm;
