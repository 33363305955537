import React, { Fragment, useEffect, useContext } from 'react';
import PaymentContext from '../../../../context/payment/paymentContext';
import { Table } from 'antd';

const Payments = () => {
  const paymentContext = useContext(PaymentContext);
  const { getPayments, payments, getPaymentById, clearPayments } = paymentContext;

  useEffect(() => {
    getPayments();
  }, []);

  const handleViewPayment = (payment) => {
    getPaymentById(payment._id);
  };

  const columns = [
    {
      title: `Shop`,

      defaultSortOrder: ['descend'],
      render: (text, record) => <span>{record.paymentTo.label}</span>,
    },
    {
      title: `Payment Number`,
      render: (text, record) => <span>{record.paymentNumber}</span>,
    },
    {
      title: `Payment Date`,
      sorter: (a, b) => a.paymentDate < b.paymentDate,
      defaultSortOrder: ['descend'],
      render: (text, record) => <span>{record.paymentDate && new Date(record.paymentDate).toLocaleDateString()}</span>,
    },

    {
      title: `Amount`,
      render: (text, record) => (
        <span>
          {record.shopsPaymentTotal &&
            record.shopsPaymentTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
        </span>
      ),
    },
    {
      title: `Overhead Total`,
      render: (text, record) => (
        <span>
          {record.shopsOverheadTotal &&
            record.shopsOverheadTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
        </span>
      ),
    },
    {
      title: `Labor Total`,
      render: (text, record) => (
        <span>
          {record.shopsLaborTotal &&
            record.shopsLaborTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
        </span>
      ),
    },
    {
      title: `Material Total`,
      render: (text, record) => (
        <span>
          {record.shopsMaterialTotalCost &&
            record.shopsMaterialTotalCost.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
        </span>
      ),
    },
    {
      title: `Profit $`,
      render: (text, record) => (
        <span>
          {record.shopsProfitDollarsTotal &&
            record.shopsProfitDollarsTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
        </span>
      ),
    },
    {
      title: `Profit %`,
      render: (text, record) => <span>{record.shopsAverageProfitPercent && record.shopsAverageProfitPercent} %</span>,
    },

    {
      title: 'Actions',
      align: 'center',
      render: (text, record) => (
        <Fragment>
          <div className='flexcontainer'>
            {/* <PopConfirm
              record={record}
              msg={'Approve Invoice?'}
              btnText={'Approve'}
              theStatus={'Approved'}
              myClasses={'btn btn-xs btn-outline-success'}
            />
            <PopConfirm record={record} msg={'Deny Invoice?'} btnText={'Deny'} theStatus={'Denied'} myClasses={'btn btn-xs btn-outline-danger'} /> */}

            <button className='btn btn-xs btn-outline-primary' type='button' onClick={(e) => handleViewPayment(record, e)}>
              View
            </button>
          </div>
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <div>
      <div className='row'>
        <div className='col-md-4'>{/* <ShopFilter /> */}</div>

        <div className='col-md-4 text-center'>
          <h4>Payments</h4>
        </div>
      </div>

      <div className='row'>
        <Table
          pagination={{ pageSize: 200 }}
          loading={payments ? false : true}
          columns={columns}
          dataSource={payments && payments}
          rowKey='_id'
          bordered={true}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Payments;
