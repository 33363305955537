import {
    ADD_METAL,
    DELETE_METAL,
    CLEAR_METALS,
    SET_CURRENT_METAL,
    CLEAR_CURRENT_METAL,
    UPDATE_METAL,
    FILTER_METALS,
    CLEAR_FILTER_METAL,
    METAL_ERROR,
    GET_METALS,
} from '../types'


export default ( state, action ) => {
    switch(action.type) {
        case GET_METALS:
            return {
                ...state,
                metals: action.payload,
                loading: false
            }
        case ADD_METAL:
            return {
                ...state,
                metal: action.payload,
                loading: false
            };
        case UPDATE_METAL:
            return {
                ...state,
                metals: state.metals.map(metal => metal._id === action.payload._id ? action.payload : metal),
                loading: false
            };
        case DELETE_METAL:
            return {
                ...state,
                metals: state.metals.filter(metal => metal._id !== action.payload),
                loading: false
            };
        case CLEAR_METALS:
            return {
                ...state,
                metals: null,
                filteredMetal: null,
                error: null,
                currentMetal: null,
            }
        case SET_CURRENT_METAL:
            return {
                ...state,
                currentMetal: action.payload
            };
        case CLEAR_CURRENT_METAL:
            return {
                ...state,
                currentMetal: null
            };
        case FILTER_METALS:
            return {
                ...state,
                filteredMetal: state.metals.filter(metal => {
                    const regex = new RegExp(`${action.payload}`, 'gi');
                    return metal.customer.value.name.match(regex) 
                })
            }
        case CLEAR_FILTER_METAL:
            return {
                ...state,
                filteredMetal: null
            };
        case METAL_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default: 
            return state;
    }
}