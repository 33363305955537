import React, { useState, useContext, useEffect } from "react";

import { Link } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import OrderContext from "../../../context/order/orderContext";
import SettingsContext from "../../../context/settings/settingsContext";

import { Menu } from "antd";

const UserNav = () => {
  const authContext = useContext(AuthContext);
  const { loadUser, user } = authContext;

  const orderContext = useContext(OrderContext);
  const { clearOrders } = orderContext;

  const settingsContext = useContext(SettingsContext);
  const { getSettings } = settingsContext;

  const [mode, setMode] = React.useState("inline");
  const [theme, setTheme] = React.useState("light");
  const [current, setCurrent] = useState();

  const { SubMenu } = Menu;

  useEffect(() => {
    loadUser();
  }, []);

  const handleMenuChange = (e) => {
    setCurrent(e.key);
    clearOrders();
    getSettings();
  };

  return (
    user && (
      <>
        <Menu
          style={{ width: 200 }}
          onClick={handleMenuChange}
          className="site-layout-background"
          mode={mode}
          selectedKeys={[current]}
          theme={theme}
        >
          <Menu.Item key="18">
            <Link to="/reports">Reports</Link>
          </Menu.Item>
          <Menu.Item key="19">
            <Link to={`/labor`}>Labor Base</Link>
          </Menu.Item>
          <Menu.Item key="1">
            <Link to={`/inventory`}>Inventory</Link>
          </Menu.Item>

          <Menu.Item key="20">
            <Link to="/addons">Add-ons</Link>
          </Menu.Item>

          <Menu.Item key="2">
            <Link to="/sheds">Sheds</Link>
          </Menu.Item>

          <SubMenu key="sub1" title="Purchases">
            <Menu.Item key="10">
              <Link to="/vendors">Vendors</Link>
            </Menu.Item>

            <Menu.Item key="11">
              <Link to="/metalorders">Metal</Link>
            </Menu.Item>

            <Menu.Item key="12">
              <Link to="/materialorders">Material</Link>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="4">
            <Link to="/orders">Orders</Link>
          </Menu.Item>

          <Menu.Item key="5">
            <Link to="/invoices">Invoices</Link>
          </Menu.Item>

          {/* <Menu.Item key='6'>
          <Link to='/suggestedretail'>Suggested Retail</Link>
        </Menu.Item> */}

          {/* <Menu.Item key='7'>
            <Link to='/help'>Help</Link>
          </Menu.Item> */}
        </Menu>
      </>
    )
  );
};

export default UserNav;
