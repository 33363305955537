import React, { Fragment } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

const AddOns = ({ addOn, addOnIndex, control, remove, setValue, getValues, addOnOptions, register }) => {
  const setTotals = () => {
    const theAddOns = getValues(`addOns`);
    const theCustomAddOns = getValues(`customAddOns`);
    const theBasePrice = getValues('basePrice');
    const theShopPercentOfBasePrice = getValues('shopPercentOfBuildingBasePrice');
    const theStorMorAddOnsTotal = theAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorAddOnTotalSalePrice || 0), 0);
    const theShopsAddOnsTotal = theAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopAddOnTotalSalePrice || 0), 0);
    const theStorMorCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorCustomAddOnsTotalSalePrice || 0), 0);
    const theShopsCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopCustomAddOnsTotalSalePrice || 0), 0);
    setValue('storMorAddOnsTotalSalePrice', theStorMorAddOnsTotal);
    setValue('shopAddOnsTotalSalePrice', theShopsAddOnsTotal);
    setValue('storMorTotalSalePrice', theBasePrice + theStorMorAddOnsTotal + theStorMorCustomAddOnsTotal);
    setValue('shopTotalSalePrice', theShopPercentOfBasePrice + theShopsAddOnsTotal + theShopsCustomAddOnsTotal);
  };
  const setAddOnChange = (addon) => {
    if(addon.value.type.value === 'percent'){
      let basePrice = getValues('basePrice')
      setValue(`addOns[${addOnIndex}].shedProRetailPercent`, addon.value.retailPercent);
      setValue(`addOns[${addOnIndex}].addOn.shopPercent`, addon.value.shopPercent);
      setValue(`addOns[${addOnIndex}].shopAddOnTotalSalePrice`, basePrice * (addon.value.retailPercent / 100 )* (addon.value.shopPercent / 100));
      setValue(`addOns[${addOnIndex}].storMorAddOnTotalSalePrice`, basePrice * (addon.value.retailPercent / 100));
      setValue(`addOns[${addOnIndex}].addOn.name`, addon.value.name);
      setValue(`addOns[${addOnIndex}].addOn`, addon.value);
      setTotals();
    }
    if(addon.value.type.value !== 'percent'){
      let count = getValues(`addOns[${addOnIndex}].quantity`);
      setValue(`addOns[${addOnIndex}].shedProRetailPrice`, addon.value.retailPrice);
      setValue(`addOns[${addOnIndex}].shedProRetailPercent`, addon.value.retailPercent);
      setValue(`addOns[${addOnIndex}].addOn.shopPercent`, addon.value.shopPercent);
      setValue(`addOns[${addOnIndex}].shopAddOnTotalSalePrice`, addon.value.retailPrice * count * addon.value.shopPercent);
      setValue(`addOns[${addOnIndex}].storMorAddOnTotalSalePrice`, addon.value.retailPrice * count);
      setValue(`addOns[${addOnIndex}].addOn.name`, addon.value.name);
      setValue(`addOns[${addOnIndex}].addOn`, addon.value);
      setTotals();
    }
  };

  return (
    <Fragment>
      <div className='row border mb-2 '>
        <div className='col-md-12 bglight'>
          <div className='row mt-1'>
            <div className='col-md-3'>
              <Controller
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={addOnOptions}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    onChange={(e) => setAddOnChange(e)}
                  />
                )}
                key={addOn.id}
                name={`addOns[${addOnIndex}].addOn.name`}
                control={control}
              />
            </div>

            <div className='col-md-1'>
              <Controller
                render={({ field, name }) => <input className='form-control' name={name} {...field} />}
                key={addOn.id}
                control={control}
                name={`addOns[${addOnIndex}].quantity`}
              />
            </div>
            {addOn.addOn && addOn.addOn.type.value === 'percent' ? (
              <div className='col-md-2'>
                {/* <Controller
                  render={({ field, name }) => <input className='form-control' name={name} {...field} />}
                  key={addOn.id}
                  control={control}
                  name={`addOns[${addOnIndex}].shedProRetailPercent`}
                /> */}
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className='form-group form-control'
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      suffix={' %'}
                      displayType='input'
                      decimalScale={2}
                      fixedDecimalScale='true'
                    />
                  )}
                  name={`addOns[${addOnIndex}].shedProRetailPercent`}
                  control={control}
                />
              </div>
            ) : (
              <div className='col-md-2'>
                {/* <Controller
                  render={({ field, name }) => <input className='form-control' name={name} {...field} />}
                  key={addOn.id}
                  control={control}
                  name={`addOns[${addOnIndex}].shedProRetailPrice`}
                /> */}
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className='form-group form-control'
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={'$ '}
                      displayType='input'
                      decimalScale={2}
                      fixedDecimalScale='true'
                    />
                  )}
                  name={`addOns[${addOnIndex}].shedProRetailPrice`}
                  control={control}
                />
              </div>
            )}

            <div className='col-md-2'>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={'$ '}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`addOns[${addOnIndex}].storMorAddOnTotalSalePrice`}
                control={control}
              />
            </div>

            <div className='col-md-2'>
              {/* <Controller
                render={({ field, name }) => <input className='form-control' name={name} {...field} />}
                key={addOn.id}
                control={control}
                name={`addOns[${addOnIndex}].addOn.shopPercent`}
              /> */}
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    suffix={' %'}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`addOns[${addOnIndex}].addOn.shopPercent`}
                control={control}
              />
            </div>

            <div className='col-md-2'>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={'$ '}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`addOns[${addOnIndex}].shopAddOnTotalSalePrice`}
                control={control}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, addOnOptions }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addOns',
  });

  const addOns = getValues('addOns');

  return (
    <div className='container-fluid border mt-4' style={{ borderRadius: '8px' }}>
      {addOns && addOns.length > 0 && (
        <div className='row'>
          <div className='col-md-3 text-center'>
            <h5 className='mt-2'>Addon Name</h5>
          </div>
          <div className='col-md-1 text-center'>
            <h5 className='mt-2'>Count</h5>
          </div>
          <div className='col-md-2 text-center'>
            <h5 className='mt-2'>Retail Price</h5>
          </div>
          <div className='col-md-2 text-center'>
            <h5 className='mt-2'>Total Price</h5>
          </div>
          <div className='col-md-2 text-center'>
            <h5 className='mt-2'>Shops %</h5>
          </div>
          <div className='col-md-2 text-center'>
            <h5 className='mt-2'>Shop Total</h5>
          </div>
        </div>
      )}
      {fields.map((addOn, addOnIndex) => (
        <AddOns
          key={addOn.id}
          addOn={addOn}
          addOnIndex={addOnIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          addOnOptions={addOnOptions}
        />
      ))}
    </div>
  );
}
