import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import ProductContext from '../../../context/product/productContext';
import TrussContext from '../../../context/truss/trussContext';
import { useForm, Controller } from 'react-hook-form';
import ItemArray from './RHFItemArray';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

const defaultValues = {
  products: [
    {
      product: '',
    },
  ],
};

const TrussesCreate = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const productContext = useContext(ProductContext);
  const trussContext = useContext(TrussContext);

  const { loadUser } = authContext;
  const { getProducts, products } = productContext;
  const { addTruss, updateTruss, currentTruss, clearCurrentTruss, clearTrusses } = trussContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    loadUser();
    getProducts();
    currentTruss && reset(currentTruss);
  }, []);

  const productOptions =
    products &&
    products.map((option) => {
      return { label: `${option.sku}`, value: option };
    });

  const onSubmit = (data) => {
    if (!currentTruss) {
      addTruss(data);
      navigate(-1);
    } else {
      updateTruss(data);
      navigate(-1);
      clearCurrentTruss();
    }
  };

  const setQuantity = (quantity) => {
    const products = getValues('products');

    const theProductCost = products && products.reduce((acc, curr) => acc + parseFloat(curr.total), 0);

    setValue(`quantity`, quantity);
    setValue(`price`, theProductCost * quantity);
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentTruss();
  };

  return (
    <div className='container-fluid pb-4'>
      <div className='row'>
        <div className='col-md-12'>
          {currentTruss && (
            <button className='float-end mb-2 mt-2 btn btn-outline-danger ' onClick={() => setExit()}>
              Exit
            </button>
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='container-fluid bidformbackground border border-dark ' style={{ borderRadius: '15px' }}>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-3 mt-4'>
                    <div className='form-group'></div>
                  </div>

                  <div className='col-md-8'></div>

                  <div className='col-md-1'>
                    <div className='buttons float-end  mr-3 mt-3'>
                      <button className='btn btn-outline-primary ' type='submit'>
                        Submit
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label>Name</label>
                      <input className='form-control inputbg' name='title' {...register('title', { required: true })} />
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label>SKU</label>
                      <input className='form-control inputbg' name='sku' {...register('sku', { required: true })} />
                    </div>
                  </div>

                  <div className='col-md-1'>
                    <div className='form-group'>
                      <label>In Stock</label>
                      <Controller
                        render={({ field, name }) => (
                          <input
                            className='form-control inputbg'
                            type='number'
                            name={name}
                            {...field}
                            onChange={(e) => {
                              const quantity = e.target.value;
                              setQuantity(quantity);
                            }}
                          />
                        )}
                        control={control}
                        name={`quantity`}
                        defaultValue={1}
                      />
                    </div>
                  </div>

                  <div className='col-md-2'></div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label>Truss Cost</label>
                      <Controller
                        render={({ field, name }) => (
                          <NumberFormat
                            readOnly
                            customInput={Input}
                            className='form-group form-control'
                            style={{ fontSize: 16, padding: 6 }}
                            {...field}
                            name={name}
                            thousandSeparator={true}
                            prefix={'$ '}
                            displayType='input'
                            decimalScale={2}
                            fixedDecimalScale='true'
                          />
                        )}
                        name='trussesTotal'
                        control={control}
                      />
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label>Total Inventory</label>
                      <Controller
                        render={({ field, name }) => (
                          <NumberFormat
                            readOnly
                            customInput={Input}
                            className='form-group form-control'
                            style={{ fontSize: 16, padding: 6 }}
                            {...field}
                            name={name}
                            thousandSeparator={true}
                            prefix={'$ '}
                            displayType='input'
                            decimalScale={2}
                            fixedDecimalScale='true'
                          />
                        )}
                        name='price'
                        control={control}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ItemArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              getValues,
              productOptions,
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default TrussesCreate;
