import React, { useState, Fragment } from 'react';
import { DatePicker } from 'antd';
import Select from 'react-select';
import { categories } from '../../../names/shedNames';
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

const DateFiltersPurchaseOrders = ({ getMaterialOrdersForReport, setLoadingMaterialOrders, category, setTheCategory, handleGetFilteredProducts }) => {
  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);
  const [value, setValue] = useState(null);
  const [status, setStatus] = useState({ label: 'All', value: 'All' });

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  const handleDateSort = () => {
    setLoadingMaterialOrders(true);
    handleGetFilteredProducts();
    getMaterialOrdersForReport(dates);
  };

  const setOrderStatus = (theStatus) => {
    setStatus(theStatus);
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col-md-2'>
          <RangePicker
            className='p-2'
            value={hackValue || value}
            // disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setValue(val)}
            onOpenChange={onOpenChange}
            format={dateFormat}
          />
        </div>

        <div className='col-md-2'>
          <Select
            className='basic-single'
            classNamePrefix='select'
            // defaultValue={'All'}
            placeholder={'Select Category...'}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            // menuPosition='fixed'
            //   isDisabled={isDisabled}
            //   isLoading={isLoading}
            // isClearable={isClearable}
            //   isRtl={isRtl}
            //   isSearchable={isSearchable}
            //   name={category}
            value={category}
            options={categories}
            onChange={setTheCategory}
          />
        </div>

        <div className='col-md-2'>
          <button className='ml-2 btn btn-outline-primary ' type='button' disabled={(!category && true) || false} onClick={handleDateSort}>
            Run Report
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default DateFiltersPurchaseOrders;
