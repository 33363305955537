import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AuthContext from "../../../../context/auth/authContext";
import ProductContext from "../../../../context/product/productContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import OrderContext from "../../../../context/order/orderContext";
import DateFilters from "./DateFilters";

const InventoryUsed = () => {
  const navigate = useNavigate();
  const productContext = useContext(ProductContext);
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const orderContext = useContext(OrderContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const { getProductsByCategory, clearProducts, products, setLoadingProduct, loadingProduct } = productContext;
  const { clearOrders, orders, getOrdersForUsedInventory, setLoadingOrders, loadingOrders } = orderContext;
  const [category, setCategory] = useState();

  orders && console.log("this isthe orders for inventory used", orders);
  const setTheCategory = (theCategory) => {
    setCategory(theCategory);
  };

  useEffect(() => {
    clearProducts();
    // clearOrders();
    loadUser();
    getSettings();
  }, []);

  const handleUsedInventory = (product) => {
    let itemCountArr = [];

    if (product.title === "CUSTOM PAINT COLOR") {
      orders.forEach((order) => {
        let theSidingPaintItem = order.sidingPaint && products.find((i) => i.title === order.sidingPaint.value.color);
        let theTrimPaintItem = order.trimPaint && products.find((i) => i.title === order.trimPaint.value.color);
        if (theSidingPaintItem === undefined) {
          order.sidingPaint && itemCountArr.push(order.sidingPaint.value.quantity);
        }
        if (theTrimPaintItem === undefined) {
          order.sidingPaint && itemCountArr.push(order.trimPaint.value.quantity / 5);
        }
      });
    }
    orders.forEach((order) => {
      if (order.sidingPaint && product.title === order.sidingPaint.value.color) {
        itemCountArr.push(order.sidingPaint.value.quantity);
      }
      if (order.trimPaint && product.title === order.trimPaint.value && order.trimPaint.value.color) {
        itemCountArr.push(order.trimPaint.value.quantity / 5);
      }
      let item = order.shedProShed.products.find((i) => i.product.title === product.title);
      item && itemCountArr.push(item.count);
    });

    orders.forEach((order) => {
      let item = order.shedProShed.trussOne.products.find((i) => i.product.title === product.title);
      let trussOneCountTotal = item && item.count * order.shedProShed.trussOneCount;
      item && itemCountArr.push(trussOneCountTotal);
    });

    orders.forEach((order) => {
      let item = order.shedProShed.trussTwo.products.find((i) => i.product.title === product.title);
      let trussTwoCountTotal = item && item.count * order.shedProShed.trussTwoCount;
      item && itemCountArr.push(trussTwoCountTotal);
    });

    orders.forEach((order) => {
      let item = order.shedProShed.door.products.find((i) => i.product.title === product.title);
      let doorCountTotal = item && item.count * order.shedProShed.doorCount;
      item && itemCountArr.push(doorCountTotal);
    });

    orders.forEach((order) => {
      order.addOns.forEach((addOn) => {
        if (addOn.addOn.name.label === "SHINGLES UPGRADE W/ FELT") {
          let roofColor = order.roofColor;
          if (product.title === roofColor) {
            let item = addOn.addOn.products.find((i) => i.product.title === "Shingles(1 bag)");
            let addOnItemCountTotal = item && item.count * addOn.quantity;
            item && itemCountArr.push(addOnItemCountTotal);
          }
        } else {
          let item = addOn.addOn.products.find((i) => i.product.title === product.title);
          let addOnItemCountTotal = item && item.count * addOn.quantity;
          item && itemCountArr.push(addOnItemCountTotal);
        }
        let item = addOn.addOn.products.find((i) => i.product.title === product.title);
        let addOnItemCountTotal = item && item.count * addOn.quantity;
        item && itemCountArr.push(addOnItemCountTotal);
      });
    });
    let theItem = {};
    theItem.used = itemCountArr.reduce((s, t) => (s = s + t), 0);
    return theItem;
  };

  const adjustedProducts =
    (products &&
      orders &&
      products.map((product) => {
        let theItem = handleUsedInventory(product);
        product.used = theItem.used.toFixed(2);
        return product;
      })) ||
    [];

  const handleGetFilteredProducts = () => {
    setLoadingProduct(true);
    getProductsByCategory(category.value);
  };

  const setExit = () => {
    clearOrders();
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-md-12 ">
            <button className="float-end btn btn-outline-danger " type="button" onClick={setExit}>
              Back
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <DateFilters
              category={category}
              setTheCategory={setTheCategory}
              getOrdersForUsedInventory={getOrdersForUsedInventory}
              setLoadingOrders={setLoadingOrders}
              handleGetFilteredProducts={handleGetFilteredProducts}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          {loadingOrders ? (
            // <div className='row'>
            //   <div className='col-md-12 text-center'>
            //     <Spinner />
            //   </div>
            // </div>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingOrders}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <MaterialTable
              options={{
                rowStyle: {
                  fontSize: "12px",
                },
                exportMenu: [
                  {
                    label: "Export PDF",
                    //// You can do whatever you wish in this function. We provide the
                    //// raw table columns and table data for you to modify, if needed.
                    // exportFunc: (cols, datas) => console.log({ cols, datas })
                    exportFunc: (cols, datas) => ExportPdf(cols, datas, "Orders/Labor"),
                  },
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) => ExportCsv(cols, datas, "Orders/Labor"),
                  },
                ],
                pageSize: 20,
                pageSizeOptions: [20, 40, 100],
              }}
              columns={[
                { title: "Item", field: "title" },
                { title: "Used", field: "used" },
              ]}
              data={adjustedProducts && adjustedProducts}
              title="Inventory/Used"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default InventoryUsed;
