import React, { useContext, useRef, useEffect } from 'react';
import ProductContext from './productContext';

const ProductFilter = () => {
  const productContext = useContext(ProductContext);
  const { filterProducts, clearFilterProduct, filteredProduct } = productContext;

  const text = useRef('');

  useEffect(() => {
    if (filteredProduct === null) {
      text.current.value = '';
    }
  });

  const onChange = (e) => {
    if (text.current.value !== '') {
      filterProducts(e.target.value);
    } else {
      clearFilterProduct();
    }
  };

  return (
    <div className='row'>
      <div className='col-md-4'>
        <form className='pb-2'>
          <input className='form-control' type='text' ref={text} placeholder='Search Products By SKU...' onChange={onChange} />
        </form>
      </div>
    </div>
  );
};

export default ProductFilter;
