import React, { Fragment, useState, useEffect } from "react";
import { Menu } from "antd";
import { Outlet, Link, useNavigate } from "react-router-dom";

const AllProducts = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState("treated");
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    navigate("/inventory/treated");
  }, []);

  return (
    <Fragment>
      <Menu className="mb-2 border" onClick={handleMenuChange} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="treated">
          <Link to={`/inventory/treated`}>Treated</Link>
        </Menu.Item>
        <Menu.Item key="white">
          <Link to={`/inventory/white`}>White</Link>
        </Menu.Item>
        <Menu.Item key="metal">
          <Link to={`/inventory/metal`}>Metal</Link>
        </Menu.Item>
        <Menu.Item key="hardware">
          <Link to={`/inventory/hardware`}>Hardware</Link>
        </Menu.Item>
        <Menu.Item key="windows-doors">
          <Link to={`/inventory/windows-doors`}>Windows/Doors</Link>
        </Menu.Item>
        <Menu.Item key="nails-screws">
          <Link to={`/inventory/nails-screws`}>Nails/Screws</Link>
        </Menu.Item>
        <Menu.Item key="electrical">
          <Link to={`/inventory/electrical`}>Electrical</Link>
        </Menu.Item>
        <Menu.Item key="paint">
          <Link to={`/inventory/paint`}>Paint</Link>
        </Menu.Item>
        <Menu.Item key="shingles">
          <Link to={`/inventory/shingles`}>Shingles</Link>
        </Menu.Item>
        <Menu.Item key="all">
          <Link to={`/inventory/all`}>All</Link>
        </Menu.Item>
      </Menu>
      <Outlet />
    </Fragment>
  );
};

export default AllProducts;
