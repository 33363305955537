import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthContext from '../../../../context/auth/authContext';
import PaymentContext from '../../../../context/payment/paymentContext';
import SettingsContext from '../../../../context/settings/settingsContext';
import DateFilters from './DateFilters';
import PaymentTable from './PaymentTable';
import TableLoading from '../orders/TableLoading';

const PaymentSummary = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const paymentContext = useContext(PaymentContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const { getPaymentsByDateFilter, payments, loadingPayments, setLoadingPayments, clearPayments } = paymentContext;

  useEffect(() => {
    clearPayments();
    loadUser();
    getSettings();
  }, []);

  const newPayments =
    payments &&
    payments.map((payment) => {
      return payment;
    });

  const setExit = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className='row'>
          <div className='col-md-12 '>
            <button className='float-end btn btn-outline-danger ' type='button' onClick={setExit}>
              Back
            </button>
          </div>
        </div>
        <div className='row mt-4'>
          <DateFilters getPaymentsByDateFilter={getPaymentsByDateFilter} setLoadingPayments={setLoadingPayments} />
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-1'></div>
        <div className='col-md-10'>
          {loadingPayments ? (
            // <div className='row text-center'>
            //   <Spinner />
            // </div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingPayments}>
              <CircularProgress color='inherit' />
            </Backdrop>
          ) : newPayments === null ? (
            <TableLoading title={'Payments/Summary'} />
          ) : (
            <PaymentTable newPayments={newPayments} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentSummary;
