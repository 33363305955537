import {
  ADD_COMMENT,
  UPDATE_COMMENT,
  CLEAR_COMMENTS,
  DELETE_COMMENT,
  GET_COMMENTS,
  COMMENT_ERROR,
  SET_COMMENT_DRAWER,
  GET_COMMENTS_FOR_ORDER,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
        loadingComment: false,
      };
    case GET_COMMENTS_FOR_ORDER:
      return {
        ...state,
        orderComments: action.payload,
        loadingComment: false,
      };
    case ADD_COMMENT:
      return {
        ...state,
        // comments: action.payload,
        orderComments: [...state.orderComments, action.payload],
        loadingComment: false,
      };
    case UPDATE_COMMENT:
      // const updatedComments = state.comments.map((comment) => (comment._id === action.payload._id ? action.payload : comment));
      return {
        ...state,
        // comments: updatedComments,
        comments: state.comments.filter((comment) => comment._id !== action.payload._id),
        loading: false,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        comments: state.comments.filter((comment) => comment._id !== action.payload),
        loading: false,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: null,
        filteredComment: null,
        error: null,
        currentComment: null,
      };

    case COMMENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_COMMENT_DRAWER:
      return {
        ...state,
        commentDrawerVisible: action.payload,
      };
    default:
      return state;
  }
};
