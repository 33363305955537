import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddOnContext from "../../../../context/addon/addOnContext";
import "antd/dist/antd.css";
import { Table } from "antd";

const Doors = () => {
  const navigate = useNavigate();
  const addOnContext = useContext(AddOnContext);
  const { getAddOnsByCategory, addOns, setCurrentAddOn, loadingAddOn, setLoadingAddOnTrue } = addOnContext;

  useEffect(() => {
    setLoadingAddOnTrue();
    getAddOnsByCategory("doors");
  }, []);

  // const handleRemove = (product) => {
  //   let answer = window.confirm(
  //     `Are you sure you want to delete ${product.slug}?`
  //   );
  //   if (answer) {
  //     deleteProduct(product);
  //   }
  // };

  const handleViewAddon = (addOn) => {
    setCurrentAddOn(addOn);
    navigate("/addons/update");
  };

  const columns = [
    {
      title: "Name",
      width: "50%",
      render: (record) => <Fragment>{record.name.label}</Fragment>,
    },
    {
      title: "Type",
      width: "20%",
      render: (record) => <Fragment>{record.type.label}</Fragment>,
    },
    {
      title: "Retail Price",
      width: "20%",
      render: (text, record) => (
        <span>
          {record.type.value === "percent" ? (
            <span>{record.retailPercent}%</span>
          ) : (
            record.retailPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          )}
        </span>
      ),
    },

    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          {
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddon(record)}>
              View
            </button>
          }
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <Table
              loading={!loadingAddOn ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              dataSource={addOns}
              rowKey="_id"
              bordered={true}
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Doors;
