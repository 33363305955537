import React from "react";

 
const Help = ({history}) => {

 
  return (
    <div className="container-fluid">
      <div className="row">
       

        <div className="col-md-12">
        
          
          
           
                
                <h5>Add Item Tab</h5>
                <p>In this page you can create new inventory items. Just type in the name, sku, and price. If freight is included in the price just put 0 in the freight blank. Quantity should be 1.</p>
                <p>Also there is a Create Metal Package, Create Trusses, and Create Doors tab on the top of this page, where you can create new components the same way.</p>
                <br />
                <h5>Inventory Tab</h5>
                <p>This is where all the inventory items are listed. Click on the green pencil icon to edit the name, price, sku, or freight. (<b>When you change the price or freight, the program will adjust all the Sheds, Metal packages, Trusses, and Doors by the difference</b>)</p>
                <br />
                <h5>Build A Shed</h5>
                <p>This page is to build new styles and different sizes of sheds. Select the style, type in the size,(example: 8x12) set the Retail Price, and type in the labor. Then start with selecting the Metal Package. Next select the Regular truss and the End Truss and type in the Count for both. Next select which door. Then start adding all the items and setting the count for the shed. The dropdown is searchable to speed the process up.</p>
                <br />
                <h5>My Sheds</h5>
                <p>This page shows all the sizes of the Style that is selected in the tab across the top. To edit and view the breakdown of the shed, click on the green pencil icon. This will show all current components that this shed is built from. If you want to change a items count, just enter a different number and click Update & Save button. If you would like to change one of the components, its possible to just change the name in the inventory page, or delete the current component and add the new one in. (<b>When adding a new component, click add to shed, then adjust the count to the right number.</b>) When your done making changes you want to save, Click the update and save. </p>
                <br />
                <h5>Suggested Retail</h5>
                <p>This page is here to show current gross profit % and a suggested retail price based on the % that you would like to make. Select the Style, type in the gross profit percent that you would like to make as a decimal, and the default % of retail is 65%. Then Click Figure Suggested Retail. (<b>When you change the style, you have to click the Figure Suggested Retail Button again</b>)</p>
                <br />
                <h5>If you have any questions, email me at matt@tru-tech.org or call at 337-499-0702 </h5>
            
        </div>
      </div>
    </div>
  );
};

export default Help;