import React, { useState, useContext, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import ShedLaborContext from '../../../../context/shedLabor/shedLaborContext';
import Spinner from '../../../layout/Spinner';

const initialState = {
  buildLaborPercent: 0,
  roofLaborPercent: 0,
  trussLaborDollars: 0,
  doorLaborDollars: 0,
  paintLaborPercent: 0,
};

const BulkUpdateShedLaborForm = () => {
  const navigate = useNavigate();
  const shedLaborContext = useContext(ShedLaborContext);
  const { updateBulkShedLabor } = shedLaborContext;
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);

  // destructure
  const { buildLaborPercent, roofLaborPercent, trussLaborDollars, doorLaborDollars, paintLaborPercent } = values;

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    updateBulkShedLabor(values);
  };

  const handleBuildPercentChange = (e) => {
    let newBuildPercent = e.target.value;

    setValues({
      ...values,
      buildLaborPercent: newBuildPercent,
    });
  };

  const handleRoofPercentChange = (e) => {
    let newRoofPercent = e.target.value;

    setValues({
      ...values,
      roofLaborPercent: newRoofPercent,
    });
  };

  const handlePaintPercentChange = (e) => {
    let newPaintPercent = e.target.value;

    setValues({
      ...values,
      paintLaborPercent: newPaintPercent,
    });
  };

  const handleTrussLaborChange = (e) => {
    let newTrussLabor = e.target.value;

    setValues({
      ...values,
      trussLaborDollars: newTrussLabor,
    });
  };

  const handleDoorLaborChange = (e) => {
    let newDoorLabor = e.target.value;

    setValues({
      ...values,
      doorLaborDollars: newDoorLabor,
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 mb-4'>
            <button className='btn btn-outline-danger float-end' type='button' onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
        <div className='row mt-4'>
          {loading === true ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className='col-md-3'> </div>
              <div className='col-md-4 bidformbackground border border-dark' style={{ borderRadius: '15px' }}>
                <h4 className='text-center mt-2'>Bulk Update Labor Items</h4>
                <p className='text-center'>
                  (Enter the difference that you want to change. <br></br> Example: Build Labor Percent = 1.5% and you desire 2% <br></br> Enter .5
                  for Build Labor Percent)
                </p>

                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>Build Labor Percent</label>
                      <input
                        type='number'
                        name='buildLaborPercent'
                        step='0.01'
                        className='form-control inputbg'
                        value={buildLaborPercent}
                        onChange={handleBuildPercentChange}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>Roof Labor Percent</label>
                      <input
                        type='number'
                        name='roofLaborPercent'
                        step='0.01'
                        className='form-control inputbg'
                        value={roofLaborPercent}
                        onChange={handleRoofPercentChange}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>Paint Labor Percent</label>
                      <input
                        type='number'
                        name='paintLaborPercent'
                        step='0.01'
                        className='form-control inputbg'
                        value={paintLaborPercent}
                        onChange={handlePaintPercentChange}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>Truss Labor</label>
                      <input
                        type='number'
                        step='0.01'
                        name='trussLaborDollars'
                        className='form-control inputbg'
                        value={trussLaborDollars}
                        onChange={handleTrussLaborChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label>Door Labor</label>
                    <input
                      type='number'
                      step='0.01'
                      name='doorLaborDollars'
                      className='form-control inputbg'
                      value={doorLaborDollars}
                      onChange={handleDoorLaborChange}
                    />
                  </div>
                </div>

                <br />
                <button className='btn btn-outline-info mb-2 float-end'>Save</button>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </form>
  );
};

export default BulkUpdateShedLaborForm;
