import React, { Fragment, useState } from 'react';
import { Menu } from 'antd';
import { Outlet, Link } from 'react-router-dom';

const ShedLabor = () => {
  const [current, setCurrent] = useState();
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  return (
    // <AllSheds />
    <Fragment>
      <Menu className='mb-2 border' onClick={handleMenuChange} selectedKeys={[current]} mode='horizontal'>
        <Menu.Item key='utility'>
          <Link to={`/labor/sheds/utility`}>Utility</Link>
        </Menu.Item>
        <Menu.Item key='side-utility'>
          <Link to={`/labor/sheds/side-utility`}>Side Utility</Link>
        </Menu.Item>
        <Menu.Item key='lofted-barn'>
          <Link to={`/labor/sheds/lofted-barn`}>Lofted Barn</Link>
        </Menu.Item>
        <Menu.Item key='side-lofted-barn'>
          <Link to={`/labor/sheds/side-lofted-barn`}>Side Lofted Barn</Link>
        </Menu.Item>
        <Menu.Item key='side-cabin'>
          <Link to={`/labor/sheds/side-cabin`}>Side Cabin</Link>
        </Menu.Item>
        <Menu.Item key='lofted-barn-cabin'>
          <Link to={`/labor/sheds/lofted-barn-cabin`}>Lofted Barn Cabin</Link>
        </Menu.Item>
        <Menu.Item key='cabin'>
          <Link to={`/labor/sheds/cabin`}>Cabin</Link>
        </Menu.Item>
        <Menu.Item key='deluxe-cabin'>
          <Link to={`/labor/sheds/deluxe-cabin`}>Deluxe Cabin</Link>
        </Menu.Item>
        <Menu.Item key='deluxe-lofted-cabin'>
          <Link to={`/labor/sheds/deluxe-lofted-cabin`}>Deluxe Lofted Cabin</Link>
        </Menu.Item>
        <Menu.Item key='center-cabin'>
          <Link to={`/labor/sheds/center-cabin`}>Center Cabin</Link>
        </Menu.Item>
        <Menu.Item key='center-lofted-cabin'>
          <Link to={`/labor/sheds/center-lofted-cabin`}>Center Lofted Cabin</Link>
        </Menu.Item>
        <Menu.Item key='cottage-shed'>
          <Link to={`/labor/sheds/cottage-shed`}>Cottage Shed</Link>
        </Menu.Item>
        <Menu.Item key='garden-shed'>
          <Link to={`/labor/sheds/garden-shed`}>Garden Shed</Link>
        </Menu.Item>
        <Menu.Item key='garage'>
          <Link to={`/labor/sheds/garage`}>Garage</Link>
        </Menu.Item>
        <Menu.Item key='lofted-garage'>
          <Link to={`/labor/sheds/lofted-garage`}>Lofted Garage</Link>
        </Menu.Item>
        <Menu.Item key='metal-utility'>
          <Link to={`/labor/sheds/metal-utility`}>Metal Utility</Link>
        </Menu.Item>
        <Menu.Item key='metal-side-utility'>
          <Link to={`/labor/sheds/metal-side-utility`}>Metal Side Utility</Link>
        </Menu.Item>
        <Menu.Item key='metal-lofted-barn'>
          <Link to={`/labor/sheds/metal-lofted-barn`}>Metal Lofted Barn</Link>
        </Menu.Item>
        <Menu.Item key='metal-side-lofted-barn'>
          <Link to={`/labor/sheds/metal-side-lofted-barn`}>Metal Side Lofted Barn</Link>
        </Menu.Item>
      </Menu>

      <Outlet />
    </Fragment>
  );
};

export default ShedLabor;
