import React from "react";
import MyLayout from "./MyLayout";
import setAuthToken from "./utils/SetAuthToken";
import { Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Login from "./components/auth/Login";
import RequireAuth from "./utils/RequireAuth";
import ProtectedRoutes from "./utils/RequireAuth";

import Utility from "./components/pages/shed/shedstyles/Utility";
import ProductCreate from "./components/pages/product/ProductCreate";
import ProductUpdate from "./components/pages/product/ProductUpdate";
import AllProducts from "./components/pages/product/AllProducts";
import AllSheds from "./components/pages/shed/AllSheds";
import RHFMetalOrderForm from "./components/pages/metal_purchase/RHFMetalOrderForm";
import MetalPurchaseOrders from "./components/pages/metal_purchase/MetalPurchaseOrders";
import AllVendors from "./components/pages/vendors/AllVendors";
import CreateVendor from "./components/pages/vendors/CreateVendor";
import MaterialOrderForm from "./components/pages/material_purchase/MaterialOrderForm";
import MaterialOrderUpdate from "./components/pages/material_purchase/MaterialOrderUpdate";
import MaterialPurchaseOrders from "./components/pages/material_purchase/MaterialPurchaseOrders";
import Settings from "./components/pages/settings/Settings";
import Help from "./components/pages/settings/Help";
import TreatedLumber from "./components/pages/product/TreatedLumber";
import WhiteLumber from "./components/pages/product/WhiteLumber";
import MetalProducts from "./components/pages/product/MetalProducts";
import Hardware from "./components/pages/product/Hardware";
import WindowsDoors from "./components/pages/product/WindowsDoors";
import NailsScrews from "./components/pages/product/NailsScrews";
import Shingles from "./components/pages/product/Shingles";
import Paint from "./components/pages/product/Paint";
import Electrical from "./components/pages/product/Electrical";
import AllTheProducts from "./components/pages/product/AllTheProducts";

import SideUtility from "./components/pages/shed/shedstyles/SideUtility";
import LoftedBarn from "./components/pages/shed/shedstyles/LoftedBarn";
import SideLoftedBarn from "./components/pages/shed/shedstyles/SideLoftedBarn";
import SideCabin from "./components/pages/shed/shedstyles/SideCabin";
import LoftedBarnCabin from "./components/pages/shed/shedstyles/LoftedBarnCabin";
import Cabin from "./components/pages/shed/shedstyles/Cabin";
import DeluxeCabin from "./components/pages/shed/shedstyles/DeluxeCabin";
import DeluxeLoftedCabin from "./components/pages/shed/shedstyles/DeluxeLoftedCabin";
import CenterCabin from "./components/pages/shed/shedstyles/CenterCabin";
import CenterLoftedCabin from "./components/pages/shed/shedstyles/CenterLoftedCabin";
import CottageShed from "./components/pages/shed/shedstyles/CottageShed";
import GardenShed from "./components/pages/shed/shedstyles/GardenShed";
import Garage from "./components/pages/shed/shedstyles/Garage";
import LoftedGarage from "./components/pages/shed/shedstyles/LoftedGarage";
import MetalUtility from "./components/pages/shed/shedstyles/MetalUtility";
import MetalSideUtility from "./components/pages/shed/shedstyles/MetalSideUtility";
import MetalLoftedBarn from "./components/pages/shed/shedstyles/MetalLoftedBarn";
import MetalSideLoftedBarn from "./components/pages/shed/shedstyles/MetalSideLoftedBarn";

import AllOrdersNav from "./components/pages/orders/AllOrdersNav";
import InQueueOrders from "./components/pages/orders/inQueue/InQueueOrders";
import BuildingOrders from "./components/pages/orders/building/BuildingOrders";
import InQueueOrder from "./components/pages/orders/inQueue/InQueueOrder";
import FinishedOrders from "./components/pages/orders/finished/FinishedOrders";
import InvoicedOrders from "./components/pages/orders/invoiced/InvoicedOrders";
import CanceledOrders from "./components/pages/orders/canceled/CanceledOrders";

import AllInvoicesNav from "./components/pages/invoices/AllInvoicesNav";
import NewInvoices from "./components/pages/invoices/new/NewInvoices";
import DeniedInvoices from "./components/pages/invoices/denied/DeniedInvoices";
import AdjustedInvoices from "./components/pages/invoices/adjusted/AdjustedInvoices";
import ApprovedInvoices from "./components/pages/invoices/approved/ApprovedInvoices";
import PaidInvoices from "./components/pages/invoices/paid/PaidInvoices";
import Payments from "./components/pages/invoices/payments/Payments";

import Order from "./components/pages/orders/orderForm/Order";

import NewInvoiceForm from "./components/pages/invoices/NewInvoiceForm";
import ViewInvoiceForm from "./components/pages/invoices/ViewInvoiceForm";

import ViewPaymentForm from "./components/pages/invoices/payments/ViewPaymentForm";

import Reports from "./components/pages/reports/Reports";

import LaborNav from "./components/pages/labor/LaborNav";
import AddOnLabor from "./components/pages/labor/addOnLabor/AddOnLabor";
import ShedLabor from "./components/pages/labor/shedLabor/ShedLabor";
import AddShedLaborForm from "./components/pages/labor/shedLabor/AddShedLaborForm";
import UpdateShedLaborForm from "./components/pages/labor/shedLabor/UpdateShedLaborForm";

import CabinLabor from "./components/pages/labor/shedLabor/shedLaborstyles/CabinLabor";
import CenterCabinLabor from "./components/pages/labor/shedLabor/shedLaborstyles/CenterCabinLabor";
import CenterLoftedCabinLabor from "./components/pages/labor/shedLabor/shedLaborstyles/CenterLoftedCabinLabor";
import CottageShedLabor from "./components/pages/labor/shedLabor/shedLaborstyles/CottageShedLabor";
import DeluxeCabinLabor from "./components/pages/labor/shedLabor/shedLaborstyles/DeluxeCabinLabor";
import DeluxeLoftedCabinLabor from "./components/pages/labor/shedLabor/shedLaborstyles/DeluxeLoftedCabinLabor";
import GarageLabor from "./components/pages/labor/shedLabor/shedLaborstyles/GarageLabor";
import GardenShedLabor from "./components/pages/labor/shedLabor/shedLaborstyles/GardenShedLabor";
import LoftedBarnLabor from "./components/pages/labor/shedLabor/shedLaborstyles/LoftedBarnLabor";
import LoftedBarnCabinLabor from "./components/pages/labor/shedLabor/shedLaborstyles/LoftedBarnCabinLabor";
import LoftedGarageLabor from "./components/pages/labor/shedLabor/shedLaborstyles/LoftedGarageLabor";
import MetalLoftedBarnLabor from "./components/pages/labor/shedLabor/shedLaborstyles/MetalLoftedBarnLabor";
import MetalSideLoftedBarnLabor from "./components/pages/labor/shedLabor/shedLaborstyles/MetalSideLoftedBarnLabor";
import MetalUtilityLabor from "./components/pages/labor/shedLabor/shedLaborstyles/MetalUtilityLabor";
import MetalSideUtilityLabor from "./components/pages/labor/shedLabor/shedLaborstyles/MetalSideUtilityLabor";
import SideCabinLabor from "./components/pages/labor/shedLabor/shedLaborstyles/SideCabinLabor";
import SideLoftedBarnLabor from "./components/pages/labor/shedLabor/shedLaborstyles/SideLoftedBarnLabor";
import SideUtilityLabor from "./components/pages/labor/shedLabor/shedLaborstyles/SideUtilityLabor";
import UtilityLabor from "./components/pages/labor/shedLabor/shedLaborstyles/UtilityLabor";

import DoorLabor from "./components/pages/labor/addOnLabor/DoorLabor";
import WindowLabor from "./components/pages/labor/addOnLabor/WindowLabor";
import ElectricLabor from "./components/pages/labor/addOnLabor/ElectricLabor";
import ExteriorLabor from "./components/pages/labor/addOnLabor/ExteriorLabor";
import InteriorLabor from "./components/pages/labor/addOnLabor/InteriorLabor";
import UpgradesLabor from "./components/pages/labor/addOnLabor/UpgradesLabor";
import ExtrasLabor from "./components/pages/labor/addOnLabor/ExtrasLabor";

import UpdateAddOnLabor from "./components/pages/labor/addOnLabor/UpdateAddOnLabor";
import MetalOrderUpdate from "./components/pages/metal_purchase/MetalOrderUpdate";
// import UpdateAddOnLaborForm from './components/pages/labor/addOnLabor/UpdateAddOnLaborForm';
import Add_AddOnLaborForm from "./components/pages/labor/addOnLabor/Add_AddOnLaborForm";
import BulkUpdateShedLaborForm from "./components/pages/labor/shedLabor/BulkUpdateShedLaborForm";

import OrdersDetail from "./components/pages/reports/orders/OrdersDetail";
import OrdersByModelSummary from "./components/pages/reports/orders/OrdersByModelSummary";
import InventoryOverview from "./components/pages/reports/inventory/InventoryOverview";
import InventoryUsed from "./components/pages/reports/inventory/InventoryUsed";
import InventoryPurchased from "./components/pages/reports/inventory/InventoryPurchased";
import OpenInvoices from "./components/pages/reports/invoices/OpenInvoices";
import PaymentSummary from "./components/pages/reports/payments/PaymentSummary";
import OrdersLabor from "./components/pages/reports/orders/OrdersLabor";

import ShedUpdate from "./components/pages/shed/ShedUpdate";

import AllAddOns from "./components/pages/addOns/AllAddOns";
import Deductions from "./components/pages/addOns/categories/Deductions";
import Discounts from "./components/pages/addOns/categories/Discounts";
import Doors from "./components/pages/addOns/categories/Doors";
import Windows from "./components/pages/addOns/categories/Windows";
import Electric from "./components/pages/addOns/categories/Electric";
import Exterior from "./components/pages/addOns/categories/Exterior";
import Interior from "./components/pages/addOns/categories/Interior";
import Upgrades from "./components/pages/addOns/categories/Upgrades";
import Extras from "./components/pages/addOns/categories/Extras";
import CreateAddOn from "./components/pages/addOns/CreateAddOn";
import PermissionDenied from "./components/layout/PermissionDenied";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/denied" element={<PermissionDenied />} />
      <Route element={<ProtectedRoutes roleRequired="subscriber" />}>
        <Route path="/" element={<MyLayout />}>
          <Route path="/" element={<Home />} />

          <Route path="/item/create" element={<ProductCreate />} />
          <Route path="/product" element={<ProductCreate />} />

          <Route path="/inventory" element={<AllProducts />}>
            <Route path="/inventory/treated" element={<TreatedLumber />} />
            <Route path="/inventory/white" element={<WhiteLumber />} />
            <Route path="/inventory/metal" element={<MetalProducts />} />
            <Route path="/inventory/hardware" element={<Hardware />} />
            <Route path="/inventory/windows-doors" element={<WindowsDoors />} />
            <Route path="/inventory/nails-screws" element={<NailsScrews />} />
            <Route path="/inventory/shingles" element={<Shingles />} />
            <Route path="/inventory/paint" element={<Paint />} />
            <Route path="/inventory/electrical" element={<Electrical />} />
            <Route path="/inventory/all" element={<AllTheProducts />} />
          </Route>

          <Route path="/addons" element={<AllAddOns />}>
            <Route path="/addons/deductions" element={<Deductions />} />
            <Route path="/addons/discounts" element={<Discounts />} />
            <Route path="/addons/Doors" element={<Doors />} />
            <Route path="/addons/windows" element={<Windows />} />
            <Route path="/addons/electric" element={<Electric />} />
            <Route path="/addons/exterior" element={<Exterior />} />
            <Route path="/addons/interior" element={<Interior />} />
            <Route path="/addons/upgrades" element={<Upgrades />} />
            <Route path="/addons/extras" element={<Extras />} />
            <Route path="/addons/create" element={<CreateAddOn />} />
            <Route path="/addons/update" element={<CreateAddOn />} />
          </Route>
          {/* <Route path='/inventory/metals' element={<AllMetals />} />
            <Route path='/inventory/trusses' element={<AllTrusses />} />
            <Route path='/inventory/doors' element={<AllDoors />} />
            <Route path='/inventory/addons' element={<AllAddOnsZ1 />}>
              <Route path='/inventory/addons/deductions' element={<Deductions />} />
              <Route path='/inventory/addons/discounts' element={<Discounts />} />
              <Route path='/inventory/addons/Doors' element={<Doors />} />
              <Route path='/inventory/addons/windows' element={<Windows />} />
              <Route path='/inventory/addons/electric' element={<Electric />} />
              <Route path='/inventory/addons/exterior' element={<Exterior />} />
              <Route path='/inventory/addons/interior' element={<Interior />} />
              <Route path='/inventory/addons/upgrades' element={<Upgrades />} />
              <Route path='/inventory/addons/extras' element={<Extras />} />
              <Route path='/inventory/addons/create' element={<CreateAddOn />} />
              <Route path='/inventory/addons/update' element={<CreateAddOn />} />
            </Route> */}

          <Route path="/product/update" element={<ProductUpdate />} />

          <Route path="/sheds" element={<AllSheds />}>
            <Route path="/sheds/utility" element={<Utility />} />
            <Route path="/sheds/side-utility" element={<SideUtility />} />
            <Route path="/sheds/lofted-barn" element={<LoftedBarn />} />
            <Route path="/sheds/side-lofted-barn" element={<SideLoftedBarn />} />
            <Route path="/sheds/side-cabin" element={<SideCabin />} />
            <Route path="/sheds/lofted-barn-cabin" element={<LoftedBarnCabin />} />
            <Route path="/sheds/cabin" element={<Cabin />} />
            <Route path="/sheds/deluxe-cabin" element={<DeluxeCabin />} />
            <Route path="/sheds/deluxe-lofted-cabin" element={<DeluxeLoftedCabin />} />
            <Route path="/sheds/center-cabin" element={<CenterCabin />} />
            <Route path="/sheds/center-lofted-cabin" element={<CenterLoftedCabin />} />
            <Route path="/sheds/cottage-shed" element={<CottageShed />} />
            <Route path="/sheds/garden-shed" element={<GardenShed />} />
            <Route path="/sheds/garage" element={<Garage />} />
            <Route path="/sheds/lofted-garage" element={<LoftedGarage />} />
            <Route path="/sheds/metal-utility" element={<MetalUtility />} />
            <Route path="/sheds/metal-side-utility" element={<MetalSideUtility />} />
            <Route path="/sheds/metal-lofted-barn" element={<MetalLoftedBarn />} />
            <Route path="/sheds/metal-side-lofted-barn" element={<MetalSideLoftedBarn />} />
          </Route>

          <Route path="/orders" element={<AllOrdersNav />}>
            <Route path="/orders/inqueue" element={<InQueueOrders />} />
            <Route path="/orders/building" element={<BuildingOrders />} />
            <Route path="/orders/finished" element={<FinishedOrders />} />
            <Route path="/orders/invoiced" element={<InvoicedOrders />} />
            <Route path="/orders/canceled" element={<CanceledOrders />} />
            <Route path="/orders/inqueue/view" element={<InQueueOrder />} />
            <Route path="/orders/view" element={<Order />} />
          </Route>
          <Route path="/order/view" element={<Order />} />

          <Route path="/invoices" element={<AllInvoicesNav />}>
            <Route path="/invoices/new" element={<NewInvoices />} />
            <Route path="/invoices/denied" element={<DeniedInvoices />} />
            <Route path="/invoices/adjusted" element={<AdjustedInvoices />} />
            <Route path="/invoices/approved" element={<ApprovedInvoices />} />
            <Route path="/invoices/paid" element={<PaidInvoices />} />
            <Route path="/invoices/payments" element={<Payments />} />
          </Route>

          <Route path="/labor" element={<LaborNav />}>
            <Route path="/labor/addons" element={<AddOnLabor />}>
              <Route path="/labor/addons/doors" element={<DoorLabor />} />
              <Route path="/labor/addons/windows" element={<WindowLabor />} />
              <Route path="/labor/addons/electric" element={<ElectricLabor />} />
              <Route path="/labor/addons/exterior" element={<ExteriorLabor />} />
              <Route path="/labor/addons/interior" element={<InteriorLabor />} />
              <Route path="/labor/addons/upgrades" element={<UpgradesLabor />} />
              <Route path="/labor/addons/extras" element={<ExtrasLabor />} />

              <Route path="/labor/addons/add" element={<Add_AddOnLaborForm />} />

              <Route path="/labor/addons/update" element={<UpdateAddOnLabor />} />
            </Route>

            <Route path="/labor/sheds" element={<ShedLabor />}>
              <Route path="/labor/sheds/utility" element={<UtilityLabor />} />
              <Route path="/labor/sheds/side-utility" element={<SideUtilityLabor />} />
              <Route path="/labor/sheds/lofted-barn" element={<LoftedBarnLabor />} />
              <Route path="/labor/sheds/side-lofted-barn" element={<SideLoftedBarnLabor />} />
              <Route path="/labor/sheds/side-cabin" element={<SideCabinLabor />} />
              <Route path="/labor/sheds/lofted-barn-cabin" element={<LoftedBarnCabinLabor />} />
              <Route path="/labor/sheds/cabin" element={<CabinLabor />} />
              <Route path="/labor/sheds/deluxe-cabin" element={<DeluxeCabinLabor />} />
              <Route path="/labor/sheds/deluxe-lofted-cabin" element={<DeluxeLoftedCabinLabor />} />
              <Route path="/labor/sheds/center-cabin" element={<CenterCabinLabor />} />
              <Route path="/labor/sheds/center-lofted-cabin" element={<CenterLoftedCabinLabor />} />
              <Route path="/labor/sheds/cottage-shed" element={<CottageShedLabor />} />
              <Route path="/labor/sheds/garden-shed" element={<GardenShedLabor />} />
              <Route path="/labor/sheds/garage" element={<GarageLabor />} />
              <Route path="/labor/sheds/lofted-garage" element={<LoftedGarageLabor />} />
              <Route path="/labor/sheds/metal-utility" element={<MetalUtilityLabor />} />
              <Route path="/labor/sheds/metal-side-utility" element={<MetalSideUtilityLabor />} />
              <Route path="/labor/sheds/metal-lofted-barn" element={<MetalLoftedBarnLabor />} />
              <Route path="/labor/sheds/metal-side-lofted-barn" element={<MetalSideLoftedBarnLabor />} />

              <Route path="/labor/sheds/update" element={<UpdateShedLaborForm />} />
              <Route path="/labor/sheds/add" element={<AddShedLaborForm />} />
              <Route path="/labor/sheds/update/all" element={<BulkUpdateShedLaborForm />} />
            </Route>
          </Route>

          <Route path="/invoice/new" element={<NewInvoiceForm />} />
          <Route path="/invoice/view" element={<ViewInvoiceForm />} />

          <Route path="/vendors" element={<AllVendors />} />
          <Route path="/vendor/create" element={<CreateVendor />} />
          <Route path="/vendor/update" element={<CreateVendor />} />

          <Route path="/metalorder/create" element={<RHFMetalOrderForm />} />
          <Route path="/metalorder/update" element={<MetalOrderUpdate />} />
          <Route path="/metalorders" element={<MetalPurchaseOrders />} />

          <Route path="/materialorders" element={<MaterialPurchaseOrders />} />
          <Route path="/materialorder/create" element={<MaterialOrderForm />} />
          <Route path="/materialorder/update" element={<MaterialOrderUpdate />} />

          <Route path="/settings" element={<Settings />} />

          <Route path="/sheds/utility" element={<Utility />} />

          <Route path="/payment/view" element={<ViewPaymentForm />} />

          <Route path="/reports" element={<Reports />} />
          <Route path="/reports/orders/detail" element={<OrdersDetail />} />
          <Route path="/reports/orders/summary" element={<OrdersByModelSummary />} />
          <Route path="/reports/inventory/overview" element={<InventoryOverview />} />
          <Route path="/reports/inventory/used" element={<InventoryUsed />} />
          <Route path="/reports/inventory/purchased" element={<InventoryPurchased />} />
          <Route path="/reports/invoices/open" element={<OpenInvoices />} />
          <Route path="/reports/payments/summary" element={<PaymentSummary />} />
          <Route path="/reports/orders/labor" element={<OrdersLabor />} />

          <Route path="/shed/update" element={<ShedUpdate />} />
          <Route path="/help" element={<Help />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
