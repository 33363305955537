import React, { Fragment, useState, useContext } from 'react';
import { Popconfirm } from 'antd';
import OrderContext from '../../../../context/order/orderContext';

const PopConfirm = ({ record, msg, btnText, theStatus }) => {
  const [visible, setVisible] = useState(false);
  const orderContext = useContext(OrderContext);
  const { updateOrderStatus, statusChange, updateOrderToBuilding } = orderContext;

  const handleShowPopConfirm = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    handleStatusChange();
  };

  const handleStatusChange = () => {
    if (theStatus === 'Building') {
      let newRecord = {
        ...record,
        status: theStatus,
      };
      updateOrderToBuilding(newRecord);
    } else if (theStatus === 'Finished') {
      let theDate = new Date();
      let newRecord = {
        ...record,
        status: theStatus,
        dateFinished: theDate,
      };
      updateOrderStatus(newRecord);
    } else {
      let newRecord = {
        ...record,
        status: theStatus,
      };
      updateOrderStatus(newRecord);
    }
    statusChange(record);
  };

  return (
    <Fragment>
      <Popconfirm
        title={msg}
        visible={visible}
        onConfirm={handleOk}
        // okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
        okText='Yes'
      ></Popconfirm>
      <button className='btn btn-xs  btn-outline-primary ' type='button' onClick={(e) => handleShowPopConfirm()}>
        {btnText}
      </button>
    </Fragment>
  );
};

export default PopConfirm;
