import React, { Fragment, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import CommentContext from '../../context/comment/commentContext';

import { Menu, Layout, Badge, Avatar } from 'antd';
import { AppstoreOutlined, SettingOutlined, BellOutlined } from '@ant-design/icons';

const { Header } = Layout;

const Navbar = () => {
  const [current, setCurrent] = useState();

  const commentContext = useContext(CommentContext);
  const { commentDrawerVisible, setCommentDrawerVisible, comments } = commentContext;

  const authContext = useContext(AuthContext);
  const { isAuthenticated, logout, user } = authContext;

  const onLogout = () => {
    logout();
  };

  useEffect(() => {}, [comments]);

  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  const handleShowComments = () => {
    setCommentDrawerVisible(true);
  };

  const authLinks = (
    <Header className=' myheader'>
      <div className='myheaderitem'>
        <h3>Shed Pro </h3>
      </div>

      <div className='myheaderitemtwo'>
        <h5>{user && user.name.toUpperCase()} </h5>
      </div>

      <Menu className='myheaderitem' onClick={handleMenuChange} selectedKeys={[current]} mode='horizontal' style={{ width: '200px' }}>
        <Menu.Item key='home' icon={<AppstoreOutlined />}>
          <Link className='mylink' to='/'>Home</Link>
        </Menu.Item>
        <Menu.Item key='settings' icon={<SettingOutlined />}>
          <Link className='mylink' to='/settings'>Settings</Link>
        </Menu.Item>

        <Menu.Item key='logout'>
          <a onClick={onLogout} href='#!'>
            <i className='fas fa-sign-out-alt'></i>
            <span className='hide-sm mylink'>Logout</span>
          </a>
        </Menu.Item>

        {comments && (
          <Menu.Item key='comments'>
            <a href='#' onClick={handleShowComments}>
              <Badge count={comments && comments.filter((comment) => comment.read !== true).length}>
                <Avatar
                  style={{
                    color: '#f56a00',
                    backgroundColor: '#fde3cf',
                  }}
                  icon={<BellOutlined />}
                />
              </Badge>
            </a>
          </Menu.Item>
        )}
      </Menu>
    </Header>
  );

  const guestLinks = (
    <Header className='header'>
      <Menu onClick={handleMenuChange} mode='horizontal' theme='light' style={{ width: '100px', float: 'right' }}>
        <Menu.Item key='login'>
          <Link to='/login'>Login</Link>
        </Menu.Item>
        {/* <Menu.Item key='register'>
            <Link to='/register'>Login</Link>
          </Menu.Item> */}
      </Menu>
    </Header>
  );

  return <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>;
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: 'Shed Pro',
};

export default Navbar;
