import React, { Fragment, useEffect, useContext } from "react";
import AuthContext from "../../../context/auth/authContext";
import MaterialOrderContext from "../../../context/materialOrder/materialOrderContext";
import ProductContext from "../../../context/product/productContext";
import { Checkbox, Table } from "antd";

const RecieveItemIntoInventory = ({ currentProduct }) => {
  const authContext = useContext(AuthContext);
  const materialOrderContext = useContext(MaterialOrderContext);
  const productContext = useContext(ProductContext);
  const { loadUser } = authContext;
  const { getRecievedMaterialOrders, materialOrders, updateMaterialOrder, clearMaterialOrders } = materialOrderContext;
  const { updateProduct, setCurrentProduct } = productContext;

  useEffect(() => {
    loadUser();
    getRecievedMaterialOrders(currentProduct);
  }, [currentProduct]);

  const onItemInventoryChange = (text, record, index) => {
    for (let i = 0; i < materialOrders.length; i++) {
      for (let o = 0; o < materialOrders[i].items.length; o++) {
        if (materialOrders[i].items[o].inInventory === false && materialOrders[i].items[o]._id === record._id) {
          // let materialOrder = {...materialOrders[i], items: {...materialOrders[i].items[o], inInventory: true}}
          let materialOrder = {
            ...materialOrders[i],
            items: materialOrders[i].items.map((item) =>
              item._id === record._id ? { ...item, inInventory: !item.inInventory } : item
            ),
          };
          updateMaterialOrder(materialOrder);
        }
      }
    }

    // const elementsIndex = p.items.findIndex(element => element._id == i._id )

    // p.items[elementsIndex] = {...p.items[elementsIndex], inInventory: !p.items[elementsIndex].inInventory}

    currentProduct = {
      ...currentProduct,
      quantity: currentProduct.quantity + record.quantity,
      recievedQuantity: currentProduct.recievedQuantity - record.quantity,
      subTotal: record.newPrice,
      freight: record.newFreight,
      price: record.newPrice + record.newFreight,
    };

    if (record.inInventory === false) {
      updateProduct(currentProduct);
      setCurrentProduct(currentProduct);
    }
  };

  function NestedTable() {
    const expandedRowRender = (record, index, indent, expanded) => {
      const columns = [
        {
          width: "20%",

          render: (record) => <Fragment></Fragment>,
        },
        {
          title: "Item",

          render: (record) => <Fragment>{record.product.value.title}</Fragment>,
        },
        {
          title: "Quantity",
          width: "10%",
          render: (record) => <Fragment>{record.quantity}</Fragment>,
        },
        {
          title: "Freight",
          width: "10%",
          render: (record) => <Fragment>{record && record.newFreight.toFixed(2)}</Fragment>,
        },
        {
          title: "Price",
          width: "10%",
          render: (record) => <Fragment>{record.newPrice}</Fragment>,
        },
        {
          title: "Receive",
          width: "10%",
          render: (text, record, index) => (
            <Fragment>
              {currentProduct._id === record.product.value._id && (
                <Checkbox checked={record.inInventory} onChange={(e) => onItemInventoryChange(text, record, index)} />
              )}
            </Fragment>
          ),
        },
      ];

      const data = record.items;

      return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const columns = [
      {
        title: "Purchase Order#",
        render: (record) => (
          <Fragment>
            <b>{record.purchaseOrderNumber}</b>
          </Fragment>
        ),
      },
    ];

    return (
      <Table
        className="components-table-demo-nested"
        columns={columns}
        expandable={{ expandedRowRender, columnWidth: "10%" }}
        dataSource={materialOrders}
        defaultExpandAllRows={true}
      />
    );
  }

  return (
    <div className="container mt-40 mb-40 overflow-x-auto tableContainer">
      <h4 className="text-center">Received Items</h4>
      <NestedTable />
    </div>
  );

  // return (
  //     <Fragment>
  //         <div>
  //                 <div className='container-fluid'>
  //                 <div className='row'>

  //                         <div className="col-md-12">

  //                             <div className="row">
  //                                 <div className="col-md-12">
  //                                     {/* { loading ? (<h4 className='text-danger text-center'>Loading...</h4>) : (<h4 className='text-center'>{theTitle}</h4>)} */}
  //                                     <h4 className='text-center'>Recieved Items</h4>
  //                                 </div>
  //                             </div>

  //                     <div className="row">
  //                         <div className="col-md-12">
  //                             <table className='table table-bordered'>
  //                                     <thead className='thead-light'>
  //                                         <tr>
  //                                             <th className='text-center' scope='col'>Purchase Order#</th>
  //                                             <th className='text-center' scope='col'>Item</th>
  //                                             <th className='text-center' scope='col'>Price</th>
  //                                             <th className='text-center' scope='col'>Freight</th>
  //                                             <th className='text-center' scope='col'>Count</th>
  //                                             <th className='text-center' scope='col'>Add</th>

  //                                         </tr>
  //                                     </thead>
  //                                     <tbody>
  //                                         {materialOrders && materialOrders.map((p) => <tr key={p._id} >
  //                                             <td className='text-center'>{p.purchaseOrderNumber}</td>

  //                                                 {p.items.map((i) => <Fragment key={i._id} >
  //                                                     <td className='text-center'>{i.product.title}</td>
  //                                                     <td className='text-center'>{i.newPrice}</td>
  //                                                     <td className='text-center'>{i.newFreight}</td>
  //                                                     <td className='text-center'>{i.quantity}</td>

  //                                                  {i.inInventory === true &&  <td width="5%"><Checkbox checked={i.inInventory} disabled onChange={(e) => onItemInventoryChange(e,p,i)}/></td>}     {/* disable this */}
  //                                                 {i.inInventory === false &&  <td width="5%"><Checkbox checked={i.inInventory} onChange={(e) => onItemInventoryChange(e,p,i)}/></td>}
  //                                                 </Fragment>)}

  //                                         </tr>)}
  //                                     </tbody>
  //                                 </table>
  //                             </div>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 </div>

  //         </div>
  //     </Fragment>
  // )
};

export default RecieveItemIntoInventory;
