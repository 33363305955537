import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Menu } from 'antd';
import { Outlet, Link } from 'react-router-dom';
import ShedContext from '../../../context/shed/shedContext';
import ProductContext from '../../../context/product/productContext';

const AllSheds = () => {
  const shedContext = useContext(ShedContext);
  const productContext = useContext(ProductContext);
  const { clearProducts } = productContext;
  const { clearSheds } = shedContext;
  const [current, setCurrent] = useState();
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    clearProducts();
    clearSheds();
  }, []);

  return (
    // <AllSheds />
    <Fragment>
      <Menu className='mb-2 border' onClick={handleMenuChange} selectedKeys={[current]} mode='horizontal'>
        <Menu.Item key='utility'>
          <Link to={`/sheds/utility`}>Utility</Link>
        </Menu.Item>
        <Menu.Item key='side-utility'>
          <Link to={`/sheds/side-utility`}>Side Utility</Link>
        </Menu.Item>
        <Menu.Item key='lofted-barn'>
          <Link to={`/sheds/lofted-barn`}>Lofted Barn</Link>
        </Menu.Item>
        <Menu.Item key='side-lofted-barn'>
          <Link to={`/sheds/side-lofted-barn`}>Side Lofted Barn</Link>
        </Menu.Item>
        <Menu.Item key='side-cabin'>
          <Link to={`/sheds/side-cabin`}>Side Cabin</Link>
        </Menu.Item>
        <Menu.Item key='lofted-barn-cabin'>
          <Link to={`/sheds/lofted-barn-cabin`}>Lofted Barn Cabin</Link>
        </Menu.Item>
        <Menu.Item key='cabin'>
          <Link to={`/sheds/cabin`}>Cabin</Link>
        </Menu.Item>
        <Menu.Item key='deluxe-cabin'>
          <Link to={`/sheds/deluxe-cabin`}>Deluxe Cabin</Link>
        </Menu.Item>
        <Menu.Item key='deluxe-lofted-cabin'>
          <Link to={`/sheds/deluxe-lofted-cabin`}>Deluxe Lofted Cabin</Link>
        </Menu.Item>
        <Menu.Item key='center-cabin'>
          <Link to={`/sheds/center-cabin`}>Center Cabin</Link>
        </Menu.Item>
        <Menu.Item key='center-lofted-cabin'>
          <Link to={`/sheds/center-lofted-cabin`}>Center Lofted Cabin</Link>
        </Menu.Item>
        <Menu.Item key='cottage-shed'>
          <Link to={`/sheds/cottage-shed`}>Cottage Shed</Link>
        </Menu.Item>
        <Menu.Item key='garden-shed'>
          <Link to={`/sheds/garden-shed`}>Garden Shed</Link>
        </Menu.Item>
        <Menu.Item key='garage'>
          <Link to={`/sheds/garage`}>Garage</Link>
        </Menu.Item>
        <Menu.Item key='lofted-garage'>
          <Link to={`/sheds/lofted-garage`}>Lofted Garage</Link>
        </Menu.Item>
        <Menu.Item key='metal-utility'>
          <Link to={`/sheds/metal-utility`}>Metal Utility</Link>
        </Menu.Item>
        <Menu.Item key='metal-side-utility'>
          <Link to={`/sheds/metal-side-utility`}>Metal Side Utility</Link>
        </Menu.Item>
        <Menu.Item key='metal-lofted-barn'>
          <Link to={`/sheds/metal-lofted-barn`}>Metal Lofted Barn</Link>
        </Menu.Item>
        <Menu.Item key='metal-side-lofted-barn'>
          <Link to={`/sheds/metal-side-lofted-barn`}>Metal Side Lofted Barn</Link>
        </Menu.Item>
      </Menu>

      <Outlet />
    </Fragment>
  );
};

export default AllSheds;
