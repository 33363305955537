import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import OrderContext from "../../../context/order/orderContext";
import InvoiceContext from "../../../context/invoice/invoiceContext";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "antd";
import moment from "moment";

const defaultValues = {
  invoiceNumber: 0,
  invoicedDate: moment(),
  order: {},
};

const NewInvoiceForm = ({ setModalVisible }) => {
  const navigate = useNavigate();

  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues,
  });
  const orderContext = useContext(OrderContext);
  const { currentOrder, statusChange, clearCurrentOrder } = orderContext;
  const invoiceContext = useContext(InvoiceContext);
  const { addInvoice } = invoiceContext;

  useEffect(() => {
    const serialArr = currentOrder && currentOrder.serialNumber.split("-");
    const theInvoiceNum = serialArr && serialArr[serialArr.length - 1];
    currentOrder && setValue("invoiceNumber", theInvoiceNum);
    currentOrder && setValue("order", currentOrder);
  }, [currentOrder]);

  const onSubmit = (data) => {
    setModalVisible(false);
    addInvoice(data);
    statusChange(data.order);
    clearCurrentOrder();
  };

  const handleViewOrder = () => {
    navigate("/order/view");
  };

  const dateFormat = "MM/DD/YYYY";

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid bidformbackground border  " style={{ borderRadius: "8px" }}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="form-group"></div>
                  </div>

                  <div className="col-md-7"></div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <h3 className="text-center">Invoice</h3>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">INVOICE #</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="invoiceNumber" {...register("invoiceNumber", { required: true })} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">DATE</label>
                      <div className="col-sm-9">
                        <Controller
                          render={({ field, name }) => (
                            <DatePicker format={dateFormat} className="form-control" name={name} {...field} />
                          )}
                          control={control}
                          name={`invoicedDate`}
                          defaultValue={moment()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">BILL TO:</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          name="billTo"
                          defaultValue={"StorMor"}
                          {...register("billTo", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <label>ITEM</label>
                  </div>
                  <div className="col-md-2">
                    <label>QTY</label>
                  </div>
                  <div className="col-md-2">
                    <label>RATE</label>
                  </div>
                  <div className="col-md-2">
                    <label>AMOUNT</label>
                  </div>
                </div>

                <div className="row border bglight mb-2 ">
                  <div className="col-md-6">
                    <Controller
                      render={({ field, name }) => <input readOnly className="form-control" name={name} {...field} />}
                      control={control}
                      name={`order.serialNumber`}
                    />
                  </div>

                  <div className="col-md-2">
                    <Controller
                      render={({ field, name }) => <input readOnly className="form-control" name={name} {...field} />}
                      control={control}
                      name={`quantity`}
                      defaultValue={1}
                    />
                  </div>

                  <div className="col-md-2">
                    <Controller
                      render={({ field, name }) => <input readOnly className="form-control" name={name} {...field} />}
                      control={control}
                      name={`order.shopTotalSalePrice`}
                    />
                  </div>

                  <div className="col-md-2">
                    <Controller
                      render={({ field, name }) => <input readOnly className="form-control" name={name} {...field} />}
                      control={control}
                      name={`order.shopTotalSalePrice`}
                    />
                  </div>
                </div>
                <div className="row mb-4 mt-4">
                  <div className="col-md-4">
                    <button className="btn btn-outline-danger " type="button" onClick={handleViewOrder}>
                      View Order
                      {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                    </button>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <div className="buttons float-end">
                      <button className="btn btn-outline-primary " type="submit">
                        Create Invoice
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewInvoiceForm;
