import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceContext from "./invoiceContext";
import invoiceReducer from "./invoiceReducer";
import axios from "axios";
import { toast } from "react-toastify";

import {
  ADD_INVOICE,
  DELETE_INVOICE,
  CLEAR_INVOICES,
  SET_CURRENT_INVOICE,
  CLEAR_CURRENT_INVOICE,
  UPDATE_INVOICE,
  FILTER_INVOICES,
  CLEAR_FILTER_INVOICE,
  INVOICE_ERROR,
  GET_INVOICES,
  STATUS_CHANGE_INVOICE,
  SET_INVOICE_LOADING,
} from "../types";

const InvoiceState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    invoices: null,
    currentInvoice: null,
    filteredInvoice: null,
    invoiceLoading: true,
    error: null,
  };

  const [state, dispatch] = useReducer(invoiceReducer, initialState);

  const getInvoicesByStatus = async (status) => {
    try {
      const res = await axios.get(`/api/invoice/${status}`);
      dispatch({ type: GET_INVOICES, payload: res.data });
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
    }
  };

  const getOpenInvoices = async () => {
    try {
      const res = await axios.get(`/api/invoice/report/open`);
      dispatch({ type: GET_INVOICES, payload: res.data });
      setInvoiceLoading(false);
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
    }
  };

  const getInvoiceById = async (id) => {
    try {
      const res = await axios.get(`/api/invoice/findbyid/${id}`);
      dispatch({ type: SET_CURRENT_INVOICE, payload: res.data });
      navigate("/invoice/view");
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
    }
  };

  const addInvoice = async (invoice) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/invoice", { invoice }, config);
      dispatch({ type: ADD_INVOICE, payload: res.data });
      toast.success(`Invoice is created`);
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
      toast.error(`Invoice ${err}`);
    }
  };

  const deleteInvoice = async (invoice) => {
    try {
      const res = await axios.delete(`/api/invoice/delete/${invoice._id}`);
      dispatch({ type: DELETE_INVOICE, payload: invoice._id });
      toast.success(`Invoice is deleted`);
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
      toast.error(`Invoice ${err}`);
    }
  };

  const updateInvoice = async (invoice) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/invoice/update/${invoice._id}`, invoice, config);
      dispatch({ type: UPDATE_INVOICE, payload: res.data });
      toast.success(`Invoice is updated`);
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
      toast.error(`Invoice ${err}`);
    }
  };

  const updateInvoiceStatus = async (invoice) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: STATUS_CHANGE_INVOICE, payload: invoice });
      const res = await axios.put(`/api/invoice/update/${invoice._id}`, invoice, config);
      navigate(-1);
      toast.success(`Invoice is updated`);
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
      toast.error(`Invoice ${err}`);
    }
  };

  const clearInvoices = () => {
    dispatch({ type: CLEAR_INVOICES });
  };

  const setCurrentInvoice = (invoice) => {
    dispatch({ type: SET_CURRENT_INVOICE, payload: invoice });
  };

  const clearCurrentInvoice = () => {
    dispatch({ type: CLEAR_CURRENT_INVOICE });
  };

  const filterInvoices = (text) => {
    dispatch({ type: FILTER_INVOICES, payload: text });
  };

  const clearFilterInvoice = () => {
    dispatch({ type: CLEAR_FILTER_INVOICE });
  };

  const statusChange = (invoice) => {
    dispatch({ type: STATUS_CHANGE_INVOICE, payload: invoice });
  };

  const setInvoiceLoading = (bool) => {
    dispatch({ type: SET_INVOICE_LOADING, payload: bool });
  };

  return (
    <InvoiceContext.Provider
      value={{
        invoices: state.invoices,
        currentInvoice: state.currentInvoice,
        filteredInvoice: state.filteredInvoice,
        error: state.error,
        invoiceLoading: state.invoiceLoading,
        getInvoicesByStatus,
        addInvoice,
        deleteInvoice,
        setCurrentInvoice,
        clearCurrentInvoice,
        updateInvoice,
        filterInvoices,
        clearFilterInvoice,
        clearInvoices,
        statusChange,
        updateInvoiceStatus,
        getInvoiceById,
        getOpenInvoices,
        setInvoiceLoading,
      }}
    >
      {props.children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceState;
