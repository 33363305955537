import React, { useState, useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import ProductContext from "../../../context/product/productContext";
import ProductCreateForm from "./forms/ProductCreateForm";
import { LoadingOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import DoorCreate from "../doors/DoorCreate";
import MetalCreate from "../metal/MetalCreate";
import TrussesCreate from "../trusses/TrussesCreate";

const initialState = {
  title: "",
  sku: "",
  price: 0,
  myId: 0,
  freight: 0,
  quantity: 0,
  category: "",
  orderPoint: 0,
  orderPointMin: 0,
};

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const ProductCreate = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const productContext = useContext(ProductContext);
  const { loadUser } = authContext;
  const { addProduct } = productContext;

  useEffect(() => {
    loadUser();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    addProduct(values);
    setValues(initialState);
    // navigate(-1);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const setCategory = (event) => {
    setValues({ ...values, category: event.value });
  };

  const setExit = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mt-2 mb-2 btn btn-outline-danger " onClick={() => setExit()}>
            Exit
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Tabs onChange={callback} type="card">
            <TabPane tab="Create Items" key="1">
              {loading ? (
                <LoadingOutlined className="text-danger h1" />
              ) : (
                <Fragment>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-3"> </div>
                    </div>
                  </div>
                </Fragment>
              )}
              <ProductCreateForm
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                setValues={setValues}
                setCategory={setCategory}
                values={values}
              />
            </TabPane>
            {/* <TabPane tab="Create Metal Package" key="2">
              <MetalCreate />
            </TabPane>
            <TabPane tab="Create Trussess" key="3">
              <TrussesCreate />
            </TabPane>
            <TabPane tab="Create Options" key="4">
              <DoorCreate />
            </TabPane> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ProductCreate;
