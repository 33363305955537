import React, { useState, Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { roofColors, trimColors, sidingColors, zones } from "../../names/shedNames";

const MetalPak = ({
  metalOrder,
  metalOrderIndex,
  control,
  remove,
  setValue,
  getValues,
  register,
  watch,
  errors,
  defaultValues,
  metals,
  settings,
  getMetalsByZone,
}) => {
  const metalPakOptions =
    metals &&
    metals.map((option) => {
      return { label: `${option.sku}`, value: option };
    });

  const setMetalOrderChange = (e) => {
    setValue(`metalOrder[${metalOrderIndex}].value.products`, e.value.products);
    setValue(`metalOrder[${metalOrderIndex}].value.label`, e.value.sku);
    setValue(`metalOrder[${metalOrderIndex}].value.title`, e.value.sku);
    setValue(`metalOrder[${metalOrderIndex}].value.serialNumber`, "");
  };

  const setSerialNumber = (serialNumber) => {
    setValue(`metalOrder[${metalOrderIndex}].value.serialNumber`, serialNumber);
  };

  const setZone = (theZone) => {
    setValue(`metalOrder[${metalOrderIndex}].zone`, theZone);
    // setValue(`metalOrder[${metalOrderIndex}].value.zone.label`, theZone.label);
    getMetalsByZone(theZone.value);
    setZoneSelected(false);
  };

  const [zoneSelected, setZoneSelected] = useState(true);

  metalOrder.value && setValue(`metalOrder[${metalOrderIndex}].value.label`, metalOrder.value.title);

  return (
    <Fragment>
      <div>
        <div className="container-fluid col-md-12 border border-dark mt-2" style={{ borderRadius: "15px" }}>
          <div className="row mt-2">
            <div className="col-md-2 ">
              <div className="form-group">
                <label>Zone</label>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      options={zones}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={(e) => setZone(e)}
                    />
                  )}
                  key={metalOrder.id}
                  name={`metalOrder[${metalOrderIndex}].zone`}
                  control={control}
                  defaultValue={settings.defaultZone}
                />
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="form-group">
                <label>Metal Package</label>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      isDisabled={!metalPakOptions}
                      options={metalPakOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={(e) => setMetalOrderChange(e)}
                    />
                  )}
                  key={metalOrder.id}
                  name={`metalOrder[${metalOrderIndex}].value`}
                  control={control}
                  defaultValue={metalOrder.value}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Serial #</label>
                <Controller
                  render={({ value, field, onChange, name, ...restProps }) => (
                    <input
                      className="form-control inputbg"
                      name={name}
                      onChange={(e) => {
                        const serialNum = e.target.value;
                        setSerialNumber(serialNum);
                      }}
                      required
                      {...restProps}
                      {...field}
                    />
                  )}
                  key={metalOrder.id}
                  control={control}
                  name={`metalOrder[${metalOrderIndex}].value.serialNumber`}
                />
              </div>
            </div>

            <div className="col-md-1 mt-4">
              <div className="form-group float-end">
                <button
                  className="btn btn-outline-danger mb-2"
                  type="button"
                  onClick={() => {
                    remove(metalOrderIndex);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 ">
              <div className="form-group">
                <label>Roof Color</label>
                <Controller
                  render={({ field, name }) => (
                    <ReactSelect
                      name={name}
                      {...field}
                      options={roofColors}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // onChange={(e) => setRoofColorChange(e)}
                    />
                  )}
                  key={metalOrder.id}
                  name={`metalOrder[${metalOrderIndex}].value.roofColor`}
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-3 ">
              <div className="form-group">
                <label>Trim Color</label>
                <Controller
                  render={({ field, name }) => (
                    <ReactSelect
                      name={name}
                      {...field}
                      options={trimColors}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // onChange={(e) => setTrimColorChange(e)}
                    />
                  )}
                  key={metalOrder.id}
                  name={`metalOrder[${metalOrderIndex}].value.trimColor`}
                  control={control}
                  defaultValue={metalOrder.value.trimColor}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label>Siding Color</label>
                <Controller
                  render={({ field, name }) => (
                    <ReactSelect
                      name={name}
                      {...field}
                      options={sidingColors}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // onChange={(e) => setSidingColorChange(e)}
                    />
                  )}
                  key={metalOrder.id}
                  name={`metalOrder[${metalOrderIndex}].value.sidingColor`}
                  control={control}
                />
              </div>
            </div>
          </div>

          <ItemArray
            nestIndex={metalOrderIndex}
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              getValues,
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, metals, settings, getMetalsByZone, clearMetals }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "metalOrder",
  });

  // const handleAddMetalPack = () => {
  //   clearMetals();
  // };

  return (
    <div className="container-fluid ">
      {fields.map((metalOrder, metalOrderIndex) => (
        <MetalPak
          key={metalOrder.id}
          metalOrder={metalOrder}
          metalOrderIndex={metalOrderIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          metals={metals}
          settings={settings}
          getMetalsByZone={getMetalsByZone}
        />
      ))}

      <button
        className="btn btn-outline-info mb-2 mt-2 "
        onClick={(e) => {
          e.preventDefault();
          append({ value: "0" });
          // handleAddMetalPack();
        }}
      >
        Add Metal Package
      </button>
    </div>
  );
}

const ItemArray = ({ control, register, setValue, getValues, productOptions, nestIndex }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `metalOrder[${nestIndex}].value.products`,
  });

  return (
    <div className="container-fluid " style={{ borderRadius: "15px" }}>
      {fields.map((product, productIndex) => (
        <Items
          key={product.id}
          product={product}
          productIndex={productIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          productOptions={productOptions}
          nestIndex={nestIndex}
        />
      ))}
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-11"></div>
          <div className="col-md-1">
            <button
              className="btn btn-outline-info"
              onClick={(e) => {
                e.preventDefault();
                append({ value: "0" });
              }}
            >
              Add Item
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Items = ({ product, productIndex, control, remove, setValue, nestIndex, metalOrder }) => {
  const setItemDescription = (description) => {
    setValue(`metalOrder[${nestIndex}].value.products[${productIndex}].description`, description);
  };

  const setItemTwoDescription = (descriptionTwo) => {
    setValue(`metalOrder[${nestIndex}].value.products[${productIndex}].descriptionTwo`, descriptionTwo);
  };

  const setItemCount = (count) => {
    setValue(`metalOrder[${nestIndex}].value.products[${productIndex}].count`, count);
  };

  return (
    <Fragment>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-3">
              <div className="form-group">
                <Controller
                  render={({ value, field, onChange, name, ...restProps }) => (
                    <input
                      className="form-control inputbg"
                      name={name}
                      onChange={(e) => {
                        const descriptionTwo = e.target.value;
                        setItemTwoDescription(descriptionTwo);
                      }}
                      {...restProps}
                      {...field}
                    />
                  )}
                  control={control}
                  name={`metalOrder[${nestIndex}].value.products[${productIndex}].descriptionTwo`}
                  defaultValue={product.descriptionTwo}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <Controller
                  render={({ value, field, onChange, name, ...restProps }) => (
                    <input
                      className="form-control inputbg"
                      name={name}
                      onChange={(e) => {
                        const description = e.target.value;
                        setItemDescription(description);
                      }}
                      {...restProps}
                      {...field}
                    />
                  )}
                  control={control}
                  name={`metalOrder[${nestIndex}].value.products[${productIndex}].description`}
                  defaultValue={product.description}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <Controller
                  render={({ value, field, onChange, name, ...restProps }) => (
                    <input
                      className="form-control inputbg"
                      type="number"
                      step="0.01"
                      name={name}
                      onChange={(e) => {
                        const count = e.target.value;
                        setItemCount(count);
                      }}
                      {...restProps}
                      {...field}
                    />
                  )}
                  control={control}
                  name={`metalOrder[${nestIndex}].value.products[${productIndex}].count`}
                  defaultValue={product.count}
                />
              </div>
            </div>

            <div className="col-md-1">
              <div className="form-group float-end">
                <button
                  className="btn btn-outline-danger mb-2"
                  type="button"
                  onClick={() => {
                    remove(productIndex);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
