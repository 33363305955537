import {
  ADD_SHED,
  DELETE_SHED,
  CLEAR_SHEDS,
  SET_CURRENT_SHED,
  CLEAR_CURRENT_SHED,
  UPDATE_SHED,
  FILTER_SHEDS,
  CLEAR_FILTER_SHED,
  SHED_ERROR,
  GET_SHEDS,
  SET_SHEDS,
  SET_SHED_ZONE,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_SHEDS:
      return {
        ...state,
        sheds: action.payload,
        loading: false,
      };
    case ADD_SHED:
      return {
        ...state,
        shed: action.payload,
        loading: false,
      };
    case UPDATE_SHED:
      return {
        ...state,
        sheds: state.sheds.map((shed) => (shed._id === action.payload._id ? action.payload : shed)),
        loading: false,
      };
    case DELETE_SHED:
      return {
        ...state,
        sheds: state.sheds.filter((shed) => shed._id !== action.payload),
        loading: false,
      };
    case CLEAR_SHEDS:
      return {
        ...state,
        sheds: null,
        filteredShed: null,
        error: null,
        currentShed: null,
      };
    case SET_CURRENT_SHED:
      return {
        ...state,
        currentShed: action.payload,
      };
    case SET_SHEDS:
      return {
        ...state,
        sheds: action.payload,
      };
    case CLEAR_CURRENT_SHED:
      return {
        ...state,
        currentShed: null,
      };
    case FILTER_SHEDS:
      return {
        ...state,
        filteredShed: state.sheds.filter((shed) => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          return shed.customer.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_SHED:
      return {
        ...state,
        filteredShed: null,
      };
    case SHED_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
