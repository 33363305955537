import React from 'react';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import moment from 'moment';

const PaymentTable = ({ newPayments }) => {
  return (
    <MaterialTable
      options={{
        rowStyle: {
          fontSize: '12px',
        },
        exportMenu: [
          {
            label: 'Export PDF',
            //// You can do whatever you wish in this function. We provide the
            //// raw table columns and table data for you to modify, if needed.
            // exportFunc: (cols, datas) => console.log({ cols, datas })
            exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Orders/Labor'),
          },
          {
            label: 'Export CSV',
            exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Orders/Labor'),
          },
        ],
        pageSize: 20,
        pageSizeOptions: [20, 40, 100],
      }}
      columns={[
        { title: 'Payment #', field: 'paymentNumber', render: (rowData) => rowData.paymentNumber },
        { title: 'Payment Date', field: 'paymentDate', render: (rowData) => moment(rowData.paymentDate).format('MM/DD/YYYY') },
        {
          title: 'Amount',
          field: 'shopsPaymentTotal',
          type: 'numeric',
          render: (rowData) =>
            rowData.shopsPaymentTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
        {
          title: 'GP ($)',
          field: 'shopsProfitDollarsTotal',
          type: 'numeric',
          render: (rowData) =>
            rowData.shopsProfitDollarsTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
        {
          title: 'GP %',
          field: 'shopsAverageProfitPercent',
          type: 'numeric',
          render: (rowData) => rowData.shopsAverageProfitPercent,
        },
        {
          title: 'Standard GP ($)',
          field: 'standardProfitDollarsTotal',
          type: 'numeric',
          render: (rowData) =>
            rowData.standardProfitDollarsTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
        {
          title: 'Standard GP %',
          field: 'standardAverageProfitPercent',
          type: 'numeric',
          render: (rowData) => rowData.standardAverageProfitPercent,
        },
      ]}
      data={newPayments && newPayments}
      title='Payments/Summary'
      renderSummaryRow={({ column, data }) => {
        let shopsPaymentTotal = data.reduce((agg, row) => agg + row.shopsPaymentTotal, 0);
        let shopsProfitDollarsTotal = data.reduce((agg, row) => agg + row.shopsProfitDollarsTotal, 0);
        let theTotalAvgPercent = (shopsProfitDollarsTotal && (shopsProfitDollarsTotal / shopsPaymentTotal) * 100).toFixed(2) || 0;
        let standardProfitDollarsTotal = data.reduce((agg, row) => agg + row.standardProfitDollarsTotal, 0);
        let theStandardTotalAvgPercent = (standardProfitDollarsTotal && (standardProfitDollarsTotal / shopsPaymentTotal) * 100).toFixed(2) || 0;

        return column.field === 'paymentNumber'
          ? {
              value: 'Totals',
              style: { background: '#6ABAC9' },
            }
          : column.field === 'paymentDate'
          ? {
              value: null,
              style: { background: '#E0F4F5' },
            }
          : column.field === 'shopsPaymentTotal'
          ? {
              value: shopsPaymentTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }),
              style: { background: '#E0F4F5' },
            }
          : column.field === 'shopsProfitDollarsTotal'
          ? {
              value: shopsProfitDollarsTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }),
              style: { background: '#E0F4F5' },
            }
          : column.field === 'shopsAverageProfitPercent'
          ? {
              value: theTotalAvgPercent,
              style: { background: '#E0F4F5' },
            }
          : column.field === 'standardProfitDollarsTotal'
          ? {
              value: standardProfitDollarsTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }),
              style: { background: '#E0F4F5' },
            }
          : column.field === 'standardAverageProfitPercent'
          ? {
              value: theStandardTotalAvgPercent,
              style: { background: '#E0F4F5' },
            }
          : {
              value: null,
              // style: { background: 'light gray' },
            };
      }}
    />
  );
};

export default PaymentTable;
