import React, { Fragment, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InvoiceContext from '../../../../context/invoice/invoiceContext';
import { Table } from 'antd';

const NewInvoices = () => {
  const navigate = useNavigate();
  const invoiceContext = useContext(InvoiceContext);
  const { getInvoicesByStatus, invoices, setCurrentInvoice, clearInvoices } = invoiceContext;

  useEffect(() => {
    clearInvoices();
    getInvoicesByStatus('New');
  }, []);

  const handleViewInvoice = (invoice) => {
    setCurrentInvoice(invoice);
    navigate('/invoice/view');
  };

  const columns = [
    {
      title: `Invoice Number`,
      render: (text, record) => <span>{record.invoiceNumber}</span>,
    },
    {
      title: `Invoiced Date`,
      render: (text, record) => <span>{record.invoicedDate && new Date(record.invoicedDate).toLocaleDateString()}</span>,
    },
    {
      title: `Amount`,
      render: (text, record) => (
        <span>
          {record.order &&
            record.order.shopTotalSalePrice.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
        </span>
      ),
    },

    {
      title: `Total Cost`,
      render: (text, record) => (
        <span>
          {record.order &&
            record.order.shopTotalCost.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
        </span>
      ),
    },
    {
      title: `Profit $`,
      render: (text, record) => (
        <span>
          {record.order &&
            record.order.shopProfitDollars.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
        </span>
      ),
    },
    {
      title: `Profit %`,
      render: (text, record) => <span>{record.order && record.order.shopProfitPercent.toFixed(2)}%</span>,
    },
    {
      title: 'Actions',
      width: '15%',
      render: (text, record) => (
        <Fragment>
          <button className='btn btn-xs btn-outline-secondary' type='button' onClick={(e) => handleViewInvoice(record, e)}>
            View Invoice
          </button>
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col text-center'>
          <h4>{invoices && invoices.length > 0 ? invoices.length + ' New Invoices' : 'No New Invoices'}</h4>

          {/* <OrderFilter /> */}
          <Table
            pagination={{ pageSize: 200 }}
            loading={invoices ? false : true}
            columns={columns}
            dataSource={invoices && invoices}
            rowKey='_id'
            bordered={true}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default NewInvoices;
