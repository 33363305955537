import React, { useReducer } from 'react';
import ProductContext from './productContext';
import productReducer from './productReducer';
import axios from 'axios';

import { toast } from 'react-toastify';

import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  CLEAR_PRODUCTS,
  SET_CURRENT_PRODUCT,
  CLEAR_CURRENT_PRODUCT,
  UPDATE_PRODUCT,
  FILTER_PRODUCTS,
  CLEAR_FILTER_PRODUCT,
  PRODUCT_ERROR,
  GET_PRODUCTS,
  SET_LOADING_PRODUCT,
} from '../types';

const ProductState = (props) => {
  const initialState = {
    products: null,
    currentProduct: null,
    filteredProduct: null,
    loadingProduct: false,
    error: null,
  };

  const [state, dispatch] = useReducer(productReducer, initialState);

  // get all products
  const getProducts = async () => {
    try {
      const res = await axios.get('/api/product');

      dispatch({ type: GET_PRODUCTS, payload: res.data });
    } catch (err) {
      dispatch({ type: PRODUCT_ERROR });
    }
  };

  // get products by category
  const getProductsByCategory = async (category) => {
    try {
      const res = await axios.get(`/api/product/category`, {
        params: {
          category: category,
        },
      });
      dispatch({ type: GET_PRODUCTS, payload: res.data });
    } catch (err) {
      dispatch({ type: PRODUCT_ERROR });
    }
  };

  const addProduct = async (product) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post('/api/product/', { product }, config);

      dispatch({ type: ADD_PRODUCT, payload: res.data });
    } catch (err) {
      dispatch({ type: PRODUCT_ERROR });
    }
  };

  const deleteProduct = async (id) => {
    try {
      const res = await axios.delete(`/api/product/delete/${id}`);

      dispatch({ type: DELETE_PRODUCT, payload: id });
    } catch (err) {
      dispatch({ type: PRODUCT_ERROR });
    }
  };

  // update Product
  const updateProduct = async (product) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.put(`/api/product/update/${product._id}`, product, config);

      dispatch({ type: UPDATE_PRODUCT, payload: product });
      toast.success(`Item is updated`);
    } catch (err) {
      dispatch({ type: PRODUCT_ERROR });
    }
  };

  // get inventory levels for all products
  const updateInventoryLevels = async () => {
    try {
      const res = await axios.get('/api/product/update/inventory');

      toast.success(`Inventory is updated`);
    } catch (err) {
      dispatch({ type: PRODUCT_ERROR });
    }
  };

  const clearProducts = () => {
    dispatch({ type: CLEAR_PRODUCTS });
  };

  const setCurrentProduct = (product) => {
    dispatch({ type: SET_CURRENT_PRODUCT, payload: product });
  };

  const clearCurrentProduct = () => {
    dispatch({ type: CLEAR_CURRENT_PRODUCT });
  };

  const filterProducts = (text) => {
    dispatch({ type: FILTER_PRODUCTS, payload: text });
  };

  const clearFilterProduct = () => {
    dispatch({ type: CLEAR_FILTER_PRODUCT });
  };

  const setLoadingProduct = (bool) => {
    dispatch({ type: SET_LOADING_PRODUCT, payload: bool });
  };

  return (
    <ProductContext.Provider
      value={{
        products: state.products,
        currentProduct: state.currentProduct,
        filteredProduct: state.filteredProduct,
        loadingProduct: state.loadingProduct,
        error: state.error,
        getProducts,
        getProductsByCategory,
        addProduct,
        deleteProduct,
        setCurrentProduct,
        clearCurrentProduct,
        updateProduct,
        filterProducts,
        clearFilterProduct,
        clearProducts,
        updateInventoryLevels,
        setLoadingProduct,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductState;
