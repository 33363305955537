import React, { Fragment, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Invoice from './pdf/Invoice';
import MetalOrderContext from '../../../context/metalOrder/metalOrderContext';
import SettingsContext from '../../../context/settings/settingsContext';

const MetalOrderModal = ({ handleClose, handleShow, show }) => {
  const metalOrderContext = useContext(MetalOrderContext);
  const settingsContext = useContext(SettingsContext);
  const { settings } = settingsContext;
  const { currentMetalOrder } = metalOrderContext;

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
        </Button> */}

      <Modal show={show} onHide={handleClose} size=''>
        <Modal.Header closeButton>
          <Modal.Title>Download PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div>
              <Fragment>
                <PDFDownloadLink
                  className='btn btn-outline-info'
                  document={<Invoice data={currentMetalOrder && currentMetalOrder} settings={settings} />}
                  fileName={`${currentMetalOrder && currentMetalOrder.purchaseOrderNumber} metal_order.pdf`}
                  // style={{
                  // textDecoration: "none",
                  // padding: "10px",
                  // color: "#4a4a4a",
                  // backgroundColor: "#f2f2f2",
                  // border: "1px solid #4a4a4a"
                  // }}
                >
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download Pdf')}
                </PDFDownloadLink>
              </Fragment>
              <button className='btn btn-outline-info float-end' type='button' onClick={handleClose}>
                Exit
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
                Save Changes
            </Button>
            </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default MetalOrderModal;
