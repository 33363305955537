import React, { useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import OrderAddOnArray from "./OrderAddOnArray";
import OrderCustomAddOnArray from "./OrderCustomAddOnArray";
import AddOnContext from "../../../../context/addon/addOnContext";
import OrderContext from "../../../../context/order/orderContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Input } from "antd";

const defaultValues = {};

const OrderForm = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch } = useForm({
    defaultValues,
  });
  const addOnContext = useContext(AddOnContext);
  const { getAddOns, allAddOns } = addOnContext;
  const orderContext = useContext(OrderContext);
  const { currentOrder, updateOrder, clearOrders } = orderContext;
  const settingsContext = useContext(SettingsContext);
  const { settings } = settingsContext;

  useEffect(() => {
    getAddOns();
    // console.log('this is the currentOrder', currentOrder && currentOrder);

    currentOrder && setValue("_id", currentOrder._id);
    currentOrder && setValue("serialNumber", currentOrder.serialNumber);
    currentOrder && setValue("model", currentOrder.model);
    currentOrder && setValue("size", currentOrder.size);
    currentOrder && setValue("type", currentOrder.type);
    currentOrder && setValue("customer", currentOrder.customer);
    currentOrder && setValue("dealer", currentOrder.dealer);
    currentOrder && setValue("dealerName", currentOrder.dealer.name.label);
    currentOrder && setValue("roofType", currentOrder.roofType);
    currentOrder && setValue("roofColor", currentOrder.roofColor);
    currentOrder && setValue("sidingType", currentOrder.sidingType);
    currentOrder && setValue("sidingPaint.value.color", currentOrder.sidingPaint.value.color);
    currentOrder && setValue("trimPaint.value.color", currentOrder.trimPaint.value.color);

    currentOrder && setValue("addOns", currentOrder.addOns);
    currentOrder && setValue("shopAddOnsTotalCost", currentOrder.shopAddOnsTotalCost);

    currentOrder && setValue("customAddOns", currentOrder.customAddOns);
    currentOrder && setValue("shopCustomAddOnsTotalCost", currentOrder.shopCustomAddOnsTotalCost);

    currentOrder && setValue("shedSuiteBuildingBasePrice", currentOrder.shedSuiteBuildingBasePrice);

    currentOrder && setValue("shopAddOnsTotalSalePrice", currentOrder.shopAddOnsTotalSalePrice);
    currentOrder && setValue("storMorAddOnsTotalSalePrice", currentOrder.storMorAddOnsTotalSalePrice);

    currentOrder && setValue("shopCustomAddOnsTotalSalePrice", currentOrder.shopCustomAddOnsTotalSalePrice);
    currentOrder && setValue("storMorCustomAddOnsTotalSalePrice", currentOrder.storMorCustomAddOnsTotalSalePrice);

    currentOrder && setValue("basePrice", currentOrder.basePrice);
    currentOrder && setValue("shopsShedPercent", currentOrder.shopsShedPercent);
    currentOrder && setValue("shopPercentOfBuildingBasePrice", currentOrder.shopPercentOfBuildingBasePrice);

    currentOrder && setValue("shopTotalSalePrice", currentOrder.shopTotalSalePrice);
    currentOrder && setValue("storMorTotalSalePrice", currentOrder.storMorTotalSalePrice);

    currentOrder && setValue("shopBuilderLabor", currentOrder.shopBuilderLabor);
    currentOrder && setValue("shopRoofLabor", currentOrder.shopRoofLabor);
    currentOrder && setValue("shopTrussLabor", currentOrder.shopTrussLabor);
    currentOrder && setValue("shopDoorLabor", currentOrder.shopDoorLabor);
    currentOrder && setValue("shopPaintLabor", currentOrder.shopPaintLabor);
    currentOrder && setValue("shopAddOnsTotalLabor", currentOrder.shopAddOnsTotalLabor);
    currentOrder && setValue("shopTotalLabor", currentOrder.shopTotalLabor);

    currentOrder && setValue("shopAddOnsMaterialCostTotal", currentOrder.shopAddOnsMaterialCostTotal);
    currentOrder && setValue("shopCustomAddOnsTotalCost", currentOrder.shopCustomAddOnsTotalCost);
    currentOrder && setValue("shopShedMaterialCost", currentOrder.shopShedMaterialCost);

    currentOrder && setValue("shopOverhead", currentOrder.shopOverhead);

    currentOrder && setValue("shopTotalCost", currentOrder.shopTotalCost);

    currentOrder && setValue("shopAddOnsCostTotal", currentOrder.shopAddOnsCostTotal);

    currentOrder && setValue("shopProfitDollars", currentOrder.shopProfitDollars);
    currentOrder && setValue("shopProfitPercent", currentOrder.shopProfitPercent);

    // Standard stuff is here
    currentOrder && setValue("standardTotalCost", currentOrder.standardTotalCost);
    currentOrder && setValue("standardTotalLabor", currentOrder.standardTotalLabor);
    currentOrder && setValue("standardShedMaterialCost", currentOrder.standardShedMaterialCost);
    currentOrder && setValue("standardAddOnsMaterialCostTotal", currentOrder.standardAddOnsMaterialCostTotal);
    currentOrder && setValue("standardCustomAddOnsTotalCost", currentOrder.standardCustomAddOnsTotalCost);
    currentOrder && setValue("standardOverhead", currentOrder.standardOverhead);

    // StorMor Profit and Cost starts here
    currentOrder && setValue("storMorDiscounts", currentOrder.storMorDiscounts);
    currentOrder && setValue("dealerCommission", currentOrder.dealerCommission);
    currentOrder && setValue("salesRepCommission", currentOrder.salesRepCommission);
    currentOrder && setValue("storMorOverhead", currentOrder.storMorOverhead);
    currentOrder && setValue("escort", currentOrder.escort);
    currentOrder && setValue("haul", currentOrder.haul);
    currentOrder && setValue("storMorTotalCost", currentOrder.storMorTotalCost);
    currentOrder && setValue("storMorProfitDollars", currentOrder.storMorProfitDollars);
    currentOrder && setValue("storMorProfitPercent", currentOrder.storMorProfitPercent * 100);
    // currentOrder && reset(currentOrder);
  }, []);

  // useEffect(() => {
  //  settings && currentOrder && reset(currentOrder);
  // }, []);

  const addOnOptions =
    allAddOns &&
    allAddOns.map((addon) => {
      return { label: `${addon.name.label}`, value: addon };
    });

  const onSubmit = (data) => {
    console.log("this is the form data", data);
    updateOrder(data);
    // clearCurrentOrder();
    clearOrders();
    navigate(-1);
  };

  const setShopsPercent = (percent) => {
    let theBasePrice = getValues("basePrice");
    let newShopsPercentOfBasePrice = theBasePrice * (percent / 100);
    setValue("shopsShedPercent", percent);
    setValue("shopPercentOfBuildingBasePrice", newShopsPercentOfBasePrice);
    setTotals();
  };

  const setBasePrice = (price) => {
    let theShopsShedPercent = getValues("shopsShedPercent");
    let theShopPercentOfBuildingBasePrice = price * (theShopsShedPercent / 100);

    setValue("shopPercentOfBuildingBasePrice", theShopPercentOfBuildingBasePrice);
    setValue("basePrice", price);
    setTotals();
  };

  const setTotals = () => {
    let theAddOns = getValues(`addOns`);
    let theCustomAddOns = getValues(`customAddOns`);
    let theBasePrice = getValues("basePrice");
    let theShopPercentOfBasePrice = getValues("shopPercentOfBuildingBasePrice");
    let theStorMorAddOnsTotal =
      theAddOns && theAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorAddOnTotalSalePrice || 0), 0);
    let theShopsAddOnsTotal =
      theAddOns && theAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopAddOnTotalSalePrice || 0), 0);
    let theStorMorCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorCustomAddOnsTotalSalePrice || 0), 0);
    let theShopsCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopCustomAddOnsTotalSalePrice || 0), 0);
    let theShopTotalCost = getValues("shopTotalCost");
    let theStandardTotalCost = getValues("standardTotalCost");
    let theShopTotal = theShopPercentOfBasePrice + theShopsAddOnsTotal + theShopsCustomAddOnsTotal;
    let theShopProfitDollars = theShopTotal - theShopTotalCost;
    let theStandardProfitDollars = theShopTotal - theStandardTotalCost;
    let theStorMorTotalSalePrice = +theBasePrice + +theStorMorAddOnsTotal + +theStorMorCustomAddOnsTotal;
    // dealerCommission
    // salesRepCommission
    // storMorOverhead
    // storMorTotalCost
    // storMorProfitDollars
    // storMorProfitPercent
    let theDealerCommission = +theStorMorTotalSalePrice * (+settings.parentCompanySettings.dealerCommission / 100);
    let theSalesRepCommission = +theStorMorTotalSalePrice * (+settings.parentCompanySettings.salesRepCommission / 100);
    let theStorMorOverhead = +theStorMorTotalSalePrice * (+settings.parentCompanySettings.storMorOverhead / 100);
    let theStorMorTotalCost = +theShopTotal + +theDealerCommission + +theSalesRepCommission + +theStorMorOverhead;
    let theStorMorProfitDollars = +theStorMorTotalSalePrice - +theStorMorTotalCost;
    let theStorMorProfitPercent = +theStorMorProfitDollars / +theStorMorTotalSalePrice;
    setValue("storMorTotalSalePrice", theStorMorTotalSalePrice);
    setValue("dealerCommission", theDealerCommission);
    setValue("salesRepCommission", theSalesRepCommission);
    setValue("storMorOverhead", theStorMorOverhead);
    setValue("storMorTotalCost", theStorMorTotalCost);
    setValue("storMorProfitDollars", theStorMorProfitDollars);
    setValue("storMorProfitPercent", theStorMorProfitPercent);
    setValue("shopTotalSalePrice", theShopTotal);
    setValue("shopProfitDollars", theShopProfitDollars);
    setValue("shopProfitPercent", (theShopProfitDollars / theShopTotal) * 100);
    setValue("standardProfitDollars", theStandardProfitDollars);
    setValue("standardProfitPercent", (theStandardProfitDollars / theShopTotal) * 100);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="mysize">
        <div className="container-fluid bidformbackground border  " style={{ borderRadius: "8px" }}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="form-group"></div>
                  </div>

                  <div className="col-md-7"></div>

                  <div className="col-md-2">
                    <div className="buttons float-end  mr-3 mt-3">
                      <button className="btn btn-outline-primary " type="submit">
                        Update & Save
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <h4>ShedSuite Details</h4>
                  </div>
                  {/* <div className='col-md-2'></div>
                  <div className='col-md-5'>
                    <h4>ShedPro Details</h4>
                  </div> */}
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Serial #</label>
                      <div className="col-sm-9">
                        <input className="form-control inputfield" name="serialNumber" readOnly {...register("serialNumber")} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Roof Type</label>
                      <div className="col-sm-9">
                        <input className="form-control inputfield" name="roofType" readOnly {...register("roofType")} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Type</label>
                      <div className="col-sm-9">
                        <input className="form-control inputfield" name="type" readOnly {...register("type")} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Roof Color</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control inputfield input-sm"
                          name="roofColor"
                          readOnly
                          {...register("roofColor")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Model</label>
                      <div className="col-sm-9">
                        <input className="form-control inputfield" name="model" readOnly {...register("model")} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Siding Type</label>
                      <div className="col-sm-9">
                        <input className="form-control inputfield" name="sidingType" readOnly {...register("sidingType")} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Size</label>
                      <div className="col-sm-9">
                        <input className="form-control inputfield" name="size" readOnly {...register("size")} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Siding Color</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control inputfield"
                          name="sidingPaint.value.color"
                          readOnly
                          {...register("sidingPaint.value.color")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Dealer</label>
                      <div className="col-sm-9">
                        <input className="form-control inputfield" name="dealerName" readOnly {...register("dealerName")} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Trim Color</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control inputfield"
                          name="trimPaint.value.color"
                          readOnly
                          {...register("trimPaint.value.color")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Customer</label>
                      <div className="col-sm-9">
                        <input className="form-control inputfield" name="customer" readOnly {...register("customer")} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <OrderAddOnArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              addOnOptions,
              settings,
            }}
          />
          <OrderCustomAddOnArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              settings,
            }}
          />
          <div className="row mt-2 mb-2">
            <div className="col-md-4">
              <h4 className="text-center">Totals</h4>
            </div>

            <div className="col-md-1 col-form-label">
              <label className="float-end">Shops %</label>
            </div>
            <div className="col-md-1">
              <Controller
                render={({ field, name }) => (
                  <input
                    className="form-control inputfield"
                    type="number"
                    name={name}
                    step=".01"
                    {...field}
                    onChange={(e) => {
                      const percent = e.target.value;
                      setShopsPercent(percent);
                    }}
                  />
                )}
                control={control}
                name={`shopsShedPercent`}
                defaultValue={0.65}
              />
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <div className="row mb-3">
                {/* <div className='col-sm-2 col-form-label'>
                  <label className='float-end'>Labor Total</label>
                </div>
                <div className='col-sm-2'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control inputfield'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`shopTotalLabor`}
                    control={control}
                  />
                </div> */}

                <div className="col-sm-2 col-form-label">
                  <label className="float-end">Base Retail Price</label>
                </div>
                <div className="col-sm-2">
                  <Controller
                    render={({ value, field, onChange, name, ...restProps }) => (
                      <NumberFormat
                        customInput={Input}
                        className="form-group form-control inputfield"
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={`basePrice`}
                        thousandSeparator={true}
                        prefix={"$ "}
                        onValueChange={(i) => {
                          setBasePrice(i.floatValue);
                        }}
                        displayType="input"
                        decimalScale={2}
                        fixedDecimalScale="true"
                        isNumericString="true"
                        {...restProps}
                      />
                    )}
                    name={`basePrice`}
                    control={control}
                    defaultValue={0}
                  />
                </div>
                <div className="col-sm-2 col-form-label">
                  <label className="float-end">Shop Base Shed Total</label>
                </div>
                <div className="col-sm-2">
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className="form-group form-control inputfield"
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={"$ "}
                        displayType="input"
                        decimalScale={2}
                        fixedDecimalScale="true"
                      />
                    )}
                    name={`shopPercentOfBuildingBasePrice`}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <div className="row mb-3">
                {/* <div className='col-sm-1 col-form-label'>
                  <label className='float-end'>Overhead</label>
                </div>
                <div className='col-sm-2'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control inputfield'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`shopOverhead`}
                    control={control}
                  />
                </div> */}

                <div className="col-sm-2 col-form-label">
                  <label className="float-end">Addons Total</label>
                </div>
                <div className="col-sm-2">
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className="form-group form-control inputfield"
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={"$ "}
                        displayType="input"
                        decimalScale={2}
                        fixedDecimalScale="true"
                      />
                    )}
                    name={`storMorAddOnsTotalSalePrice`}
                    control={control}
                  />
                </div>

                <div className="col-sm-2 col-form-label">
                  <label className="float-end">Shop AddOns Total</label>
                </div>
                <div className="col-sm-2">
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className="form-group form-control inputfield"
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={"$ "}
                        displayType="input"
                        decimalScale={2}
                        fixedDecimalScale="true"
                      />
                    )}
                    name={`shopAddOnsTotalSalePrice`}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <div className="row mb-2">
                {/* <div className='col-sm-1 col-form-label'>
                  <label className='float-end'>Addons</label>
                </div>
                <div className='col-sm-2'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control inputfield'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`shopAddOnsCostTotal`}
                    control={control}
                  />
                </div> */}

                <div className="col-sm-2 col-form-label">
                  <label className="float-end">Custom Addons Total</label>
                </div>
                <div className="col-sm-2">
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className="form-group form-control inputfield"
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={"$ "}
                        displayType="input"
                        decimalScale={2}
                        fixedDecimalScale="true"
                      />
                    )}
                    name={`storMorCustomAddOnsTotalSalePrice`}
                    control={control}
                  />
                </div>

                <div className="col-sm-2 col-form-label">
                  <label className="float-end">Shop Custom Total</label>
                </div>
                <div className="col-sm-2">
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className="form-group form-control inputfield"
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={"$ "}
                        displayType="input"
                        decimalScale={2}
                        fixedDecimalScale="true"
                      />
                    )}
                    name={`shopCustomAddOnsTotalSalePrice`}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <div className="row mb-2">
                {/* <div className='col-sm-1 col-form-label'>
                  <label className='float-end'>Custom</label>
                </div>
                <div className='col-sm-2'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control inputfield'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`shopCustomAddOnsTotalCost`}
                    control={control}
                    defaultValue={0}
                  />
                </div> */}

                <div className="col-sm-2 col-form-label">
                  <label className="float-end">Total</label>
                </div>
                <div className="col-sm-2">
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className="form-group form-control inputfield"
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={"$ "}
                        displayType="input"
                        decimalScale={2}
                        fixedDecimalScale="true"
                      />
                    )}
                    name={`storMorTotalSalePrice`}
                    control={control}
                  />
                </div>
                <div className="col-sm-2 col-form-label">
                  <label className="float-end">Shop Grand Total</label>
                </div>
                <div className="col-sm-2">
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className="form-group form-control inputfield"
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={"$ "}
                        displayType="input"
                        decimalScale={2}
                        fixedDecimalScale="true"
                      />
                    )}
                    name={`shopTotalSalePrice`}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Shed Labor</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopBuilderLabor`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Roof Labor</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopRoofLabor`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Truss Labor</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopTrussLabor`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Door Labor</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopDoorLabor`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Paint Labor</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopPaintLabor`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">AddOn Labor</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopAddOnsTotalLabor`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Total Labor</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopTotalLabor`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2 mt-4">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Shed Material</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopShedMaterialCost`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2 ">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">AddOn Material</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopAddOnsMaterialCostTotal`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Custom AddOn Material</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopCustomAddOnsTotalCost`}
                control={control}
                // defaultValue={0}
              />
            </div>
          </div>

          <div className="row mb-2 mt-4">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Overhead</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopOverhead`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Total Cost</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopTotalCost`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2 mt-4">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Gross Profit $</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopProfitDollars`}
                control={control}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-8"></div>
            <div className="col-sm-2 col-form-label">
              <label className="float-end">Gross Profit %</label>
            </div>
            <div className="col-sm-2">
              <Controller
                render={({ field, name, value }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control inputfield"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    suffix={" %"}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`shopProfitPercent`}
                control={control}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrderForm;
