import {
    ADD_VENDOR,
    DELETE_VENDOR,
    CLEAR_VENDORS,
    SET_CURRENT_VENDOR,
    CLEAR_CURRENT_VENDOR,
    UPDATE_VENDOR,
    FILTER_VENDORS,
    CLEAR_FILTER_VENDOR,
    VENDOR_ERROR,
    GET_VENDORS,
} from '../types'


export default ( state, action ) => {
    switch(action.type) {
        case GET_VENDORS:
            return {
                ...state,
                vendors: action.payload,
                loading: false
            }
        case ADD_VENDOR:
            return {
                ...state,
                vendors: [action.payload, ...state.vendors],
                loading: false
            };
        case UPDATE_VENDOR:
            return {
                ...state,
                vendors: state.vendors.map(vendor => vendor._id === action.payload._id ? action.payload : vendor),
                loading: false
            };
        case DELETE_VENDOR:
            return {
                ...state,
                vendors: state.vendors.filter(vendor => vendor._id !== action.payload),
                loading: false
            };
        case CLEAR_VENDORS:
            return {
                ...state,
                vendors: null,
                filteredVendor: null,
                error: null,
                currentVendor: null,
            }
        case SET_CURRENT_VENDOR:
            return {
                ...state,
                currentVendor: action.payload
            };
        case CLEAR_CURRENT_VENDOR:
            return {
                ...state,
                currentVendor: null
            };
        case FILTER_VENDORS:
            return {
                ...state,
                filteredVendor: state.vendors.filter(vendor => {
                    const regex = new RegExp(`${action.payload}`, 'gi');
                    return vendor.customer.value.name.match(regex) 
                })
            }
        case CLEAR_FILTER_VENDOR:
            return {
                ...state,
                filteredVendor: null
            };
        case VENDOR_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default: 
            return state;
    }
}