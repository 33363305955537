import React, { Fragment, useState, useContext } from "react";
import { Menu, Button } from "antd";
import { Outlet, Link, useNavigate } from "react-router-dom";
import AddOnContext from "../../../context/addon/addOnContext";

const AllAddOns = () => {
  const navigate = useNavigate();
  const addOnContext = useContext(AddOnContext);
  const { currentAddOn, clearAddOns } = addOnContext;
  const [current, setCurrent] = useState();
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  const handleCreateAddOn = () => {
    navigate("/addons/create");
  };

  const handleSwitchAddOn = () => {
    clearAddOns();
  };

  return (
    <Fragment>
      <Menu className="mb-2 border" onClick={handleMenuChange} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="deductions">
          <Link onClick={() => handleSwitchAddOn()} to={`/addons/deductions`}>
            Deductions
          </Link>
        </Menu.Item>
        <Menu.Item key="discounts">
          <Link onClick={() => handleSwitchAddOn()} to={`/addons/discounts`}>
            Discounts
          </Link>
        </Menu.Item>
        <Menu.Item key="doors">
          <Link onClick={() => handleSwitchAddOn()} to={`/addons/doors`}>
            Doors
          </Link>
        </Menu.Item>
        <Menu.Item key="windows">
          <Link onClick={() => handleSwitchAddOn()} to={`/addons/windows`}>
            Windows
          </Link>
        </Menu.Item>
        <Menu.Item key="electric">
          <Link onClick={() => handleSwitchAddOn()} to={`/addons/electric`}>
            Electric
          </Link>
        </Menu.Item>
        <Menu.Item key="exterior">
          <Link onClick={() => handleSwitchAddOn()} to={`/addons/exterior`}>
            Exterior
          </Link>
        </Menu.Item>
        <Menu.Item key="interior">
          <Link onClick={() => handleSwitchAddOn()} to={`/addons/interior`}>
            Interior
          </Link>
        </Menu.Item>
        <Menu.Item key="upgrades">
          <Link onClick={() => handleSwitchAddOn()} to={`/addons/upgrades`}>
            Upgrades
          </Link>
        </Menu.Item>
        <Menu.Item key="extras">
          <Link onClick={() => handleSwitchAddOn()} to={`/addons/extras`}>
            Extras
          </Link>
        </Menu.Item>
      </Menu>

      <Fragment>
        {/* {!currentAddOn && (
          <div className="row mb-2">
            <div className="col-md-12">
              <Button className="float-end" type="primary" onClick={handleCreateAddOn}>
                Create Addon
              </Button>
            </div>
          </div>
        )} */}
      </Fragment>
      <Outlet />
    </Fragment>
  );
};

export default AllAddOns;
