import React, { Fragment, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthContext from '../../../../context/auth/authContext';
import OrderContext from '../../../../context/order/orderContext';
import SettingsContext from '../../../../context/settings/settingsContext';
import DateFiltersDetailLabor from './DateFiltersDetailLabor';
import LaborTable from './LaborTable';
import TableLoading from './TableLoading';

const OrdersLabor = () => {
  const navigate = useNavigate();
  const orderContext = useContext(OrderContext);
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const { getOrdersByDateFilter, orders, loadingOrders, setLoadingOrders, clearOrders } = orderContext;

  useEffect(() => {
    clearOrders();
    loadUser();
    getSettings();
  }, []);

  const setExit = () => {
    navigate(-1);
  };

  const newOrders =
    orders &&
    orders.map((order) => {
      return order;
    });

  return (
    <Fragment>
      <div>
        <div className='row'>
          <div className='col-md-12 '>
            <button className='float-end btn btn-outline-danger ' type='button' onClick={setExit}>
              Back
            </button>
          </div>
        </div>
        <div className='row mt-4'>
          <DateFiltersDetailLabor getOrdersByDateFilter={getOrdersByDateFilter} setLoadingOrders={setLoadingOrders} clearOrders={clearOrders} settings={settings} />
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-1'></div>
        <div className='col-md-10'>
          {loadingOrders ? (
            // <Spinner />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingOrders}>
              <CircularProgress color='inherit' />
            </Backdrop>
          ) : newOrders === null ? (
            <TableLoading title={'Orders/Labor'} />
          ) : (
            <LaborTable newOrders={newOrders} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default OrdersLabor;
