import React, { Fragment, useContext } from "react";
import { Modal } from "react-bootstrap";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./material_pdf/Invoice";
import MaterialOrderContext from "../../../context/materialOrder/materialOrderContext";
import SettingsContext from "../../../context/settings/settingsContext";

const MaterialOrderConfirmedModal = ({ handleClose, handleShow, show }) => {
  const materialOrderContext = useContext(MaterialOrderContext);
  const { currentMaterialOrder } = materialOrderContext;

  const settingsContext = useContext(SettingsContext);
  const { settings } = settingsContext;

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
        </Button> */}

      <Modal show={show} onHide={handleClose} size="">
        <Modal.Header closeButton>
          <Modal.Title>Download PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div>
              <Fragment>
                <PDFDownloadLink
                  document={<Invoice data={currentMaterialOrder && currentMaterialOrder} settings={settings} />}
                  fileName={`${currentMaterialOrder && currentMaterialOrder.purchaseOrderNumber} material_order.pdf`}
                  className="btn btn-outline-info "
                  type="button"
                  // style={{
                  // textDecoration: "none",
                  // padding: "10px",
                  // color: "#ffffff",
                  // backgroundColor: "#033699",
                  // border: "1px solid #4a4a4a"
                  // }}
                >
                  {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download Pdf")}
                </PDFDownloadLink>
              </Fragment>
              <button className="btn btn-outline-info float-end" type="button" onClick={handleClose}>
                Exit
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
                Save Changes
            </Button>
            </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default MaterialOrderConfirmedModal;
