import React, { Fragment, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import VendorContext from '../../../context/vendor/vendorContext';
import { Link } from 'react-router-dom';
import { Table, Modal } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

const AllVendors = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const vendorContext = useContext(VendorContext);
  const { loadUser } = authContext;
  const { getVendors, vendors, deleteVendor, setCurrentVendor, clearVendors } = vendorContext;

  useEffect(() => {
    loadUser();
    getVendors();
  }, []);

  const handleRemoveVendor = (vendor) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Vendor?',
      okText: 'Yes',
      okType: 'danger',
      onOk: () => {
        deleteVendor(vendor);
        clearVendors();
        getVendors();
      },
    });
  };

  const handleViewVendor = (e, vendor) => {
    e.preventDefault();
    setCurrentVendor(vendor);
    navigate(`/vendor/update/`);
  };

  const columns = [
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
    },

    {
      title: 'Vendor Email',
      dataIndex: 'email',
    },

    {
      title: 'Vendor Address',
      dataIndex: 'address',
    },
    {
      title: 'Actions',
      dataIndex: 'slug',
      key: 'slug',
      render: (text, record) => (
        <Fragment>
          <DeleteOutlined onClick={() => handleRemoveVendor(record)} className='text-danger  ' style={{ fontSize: '18px', paddingLeft: '15px' }} />
          <EyeOutlined onClick={(e) => handleViewVendor(e, record)} className='text-primary ' style={{ fontSize: '20px', paddingLeft: '15px' }} />
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-12'>
                  {/* { loading ? (<h4 className='text-danger text-center'>Loading...</h4>) : (<h4 className='text-center'>{theTitle}</h4>)} */}
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <Link className='btn btn-outline-info float-end mt-2 ' to='/vendor/create'>
                    Add New
                  </Link>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <Table
                    loading={vendors ? false : true}
                    pagination={{ pageSize: 40 }}
                    columns={columns}
                    dataSource={vendors}
                    rowKey='_id'
                    bordered={true}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllVendors;
