import React, { Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";
import { Input } from "antd";

const Items = ({ product, productIndex, control, remove, setValue, getValues }) => {
  // const setTotals = () => {
  //   const products = getValues("products");
  //   const theProductCost = products && products.reduce((acc, curr) => acc + parseFloat(curr.total || 0), 0);
  //   setValue(`totalCost`, theProductCost);
  // };

  // const setItemCount = (count) => {
  //   const price = getValues(`products[${productIndex}].product.price`);
  //   setValue(`products[${productIndex}].count`, count);
  //   setValue(`products[${productIndex}].total`, count * price);
  //   setTotals();
  // };

  // const onRemoveItem = () => {
  //   setTotals();
  // };

  // const setItemChange = (e) => {
  //   setValue(`products[${productIndex}].product`, e.value);
  //   // setValue(`products[${productIndex}].product.title`, e.value.title);
  //   setValue(`products[${productIndex}].product.label`, e.value.title);
  //   setValue(`products[${productIndex}].count`, 0);
  //   setValue(`products[${productIndex}].total`, 0);
  //   setValue(`products[${productIndex}].product.price`, e.value.price);
  //   setTotals();
  // };

  // const totalFunction = () => {
  //   const price = getValues(`products[${productIndex}].product.price`);
  //   const count = getValues(`products[${productIndex}].count`);
  //   return price * count;
  // };

  return (
    <Fragment>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-5 ">
              <div className="form-group">
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      readOnly
                      {...field}
                      // options={productOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // onChange={(e) => setItemChange(e)}
                    />
                  )}
                  key={product.id}
                  name={`products[${productIndex}].product`}
                  control={control}
                />
              </div>
            </div>

            {/* <div className='col-md-3'>
              <div className='form-group'>
                <Controller
                  render={({ field, name }) => <input readOnly className='form-control' name={name} {...field} />}
                  key={product.id}
                  control={control}
                  name={`products[${productIndex}].product.title`}
                />
              </div>
            </div> */}

            <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      readOnly
                      className="form-control inputbg"
                      type="number"
                      name={name}
                      {...field}
                      // onChange={(e) => {
                      //   const count = e.target.value;
                      //   setItemCount(count);
                      // }}
                    />
                  )}
                  key={product.id}
                  control={control}
                  name={`products[${productIndex}].count`}
                />
              </div>
            </div>

            <div className="col-md-2">
              {/* <div className="form-group">
                <Controller
                  render={({ field, onChange, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                    />
                  )}
                  name={`products[${productIndex}].product.price`}
                  control={control}
                />
              </div> */}
            </div>

            <div className="col-md-2">
              {/* <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                      value={totalFunction()}
                    />
                  )}
                  name={`products[${productIndex}].total`}
                  control={control}
                />
              </div> */}
            </div>

            <div className="col-md-1">
              <div className="form-group float-end">
                {/* <button
                  className="btn btn-outline-danger mb-2"
                  type="button"
                  onClick={() => {
                    remove(productIndex);
                    onRemoveItem();
                  }}
                >
                  Delete
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

let renderCount = 0;

export default function Fields({ control, register, setValue, getValues }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  renderCount++;

  return (
    <div className="container-fluid border border-dark" style={{ borderRadius: "15px" }}>
      <div className="row mt-2 ">
        <div className="col-md-5 text-center">
          <h5>Item</h5>
        </div>
        {/* <div className='col-md-3 text-center'>
          <h5>Name</h5>
        </div> */}
        <div className="col-md-2 text-center">
          <h5>Quantity</h5>
        </div>
        {/* <div className="col-md-2 text-center">
          <h5>Item Cost</h5>
        </div>
        <div className="col-md-2 text-center">
          <h5>Total Item Cost</h5>
        </div>
        <div className="col-md-1">
          <h5>Actions</h5>
        </div> */}
      </div>

      {fields.map((product, productIndex) => (
        <Items
          key={product.id}
          product={product}
          productIndex={productIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          // productOptions={productOptions}
        />
      ))}
      {/* <button
        className="btn btn-outline-info mb-2 mt-2  "
        onClick={(e) => {
          e.preventDefault();
          append({ append: 1 });
        }}
      >
        Add Item
      </button> */}
    </div>
  );
}
