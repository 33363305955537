import React, { Fragment, useEffect, useContext } from 'react';
import AuthContext from '../../../../context/auth/authContext';
import ShedContext from '../../../../context/shed/shedContext';
import SettingsContext from '../../../../context/settings/settingsContext';
import ShedColumns from './ShedColumns';

const LoftedBarn = () => {
  const authContext = useContext(AuthContext);
  const shedContext = useContext(ShedContext);
  const settingsContext = useContext(SettingsContext);
  const { settings } = settingsContext;
  const { loadUser } = authContext;
  const { getSheds, sheds, clearSheds } = shedContext;

  useEffect(() => {
    clearSheds();
    loadUser();
    getSheds('lofted barn', settings.defaultZone);
  }, []);

  const theTitle = 'Lofted-Barn';

  return (
    <Fragment>
      <div>
        <ShedColumns sheds={sheds} theTitle={theTitle} />
      </div>
    </Fragment>
  );
};

export default LoftedBarn;
