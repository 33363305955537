import React, { useReducer } from "react";
import SettingsContext from "./settingsContext";
import settingsReducer from "./settingsReducer";
import axios from "axios";

import { toast } from "react-toastify";

import { SET_SETTINGS, UPDATE_SETTINGS, GET_SETTINGS, SETTINGS_ERROR } from "../types";

const SettingsState = (props) => {
  const initialState = {
    settings: null,
    error: null,
  };

  const [state, dispatch] = useReducer(settingsReducer, initialState);

  // get settings
  const getSettings = async () => {
    try {
      const res = await axios.get("/api/settings");

      dispatch({ type: GET_SETTINGS, payload: res.data });
    } catch (err) {
      dispatch({ type: SETTINGS_ERROR });
      toast.error(`Settings ${err}`);
    }
  };

  const deleteInventoryAndLaborItems = async () => {
    try {
      const res = await axios.get("/api/settings/delete/inventorylabor");

      // dispatch({ type: GET_SETTINGS, payload: res.data })
      toast.error(`Inventory and Labor Items are deleted`);
    } catch (err) {
      dispatch({ type: SETTINGS_ERROR });
      toast.error(`Settings ${err}`);
    }
  };

  // set settings
  const setSettings = async (settings) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post("/api/settings", settings, config);

      dispatch({ type: SET_SETTINGS, payload: res.data });
    } catch (err) {
      dispatch({ type: SETTINGS_ERROR });
      toast.error(`Settings ${err}`);
    }
  };

  // update settings
  const updateSettings = async (settings) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(`/api/settings/update/${settings._id}`, settings, config);

      dispatch({ type: UPDATE_SETTINGS, payload: res.data });
      toast.success(`Settings are updated`);
    } catch (err) {
      dispatch({ type: SETTINGS_ERROR });
      toast.error(`Settings ${err}`);
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: state.settings,
        error: state.error,
        getSettings,
        setSettings,
        updateSettings,
        deleteInventoryAndLaborItems,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsState;
