import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InQueueOrderAddOnArray from './InQueueOrderAddOnArray';
import CustomOrderAddOnArray from './CustomOrderAddOnArray';
import AddOnContext from '../../../../context/addon/addOnContext';
import OrderContext from '../../../../context/order/orderContext';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

const defaultValues = {};

const InQueueOrderForm = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });
  const addOnContext = useContext(AddOnContext);
  const orderContext = useContext(OrderContext);
  const { currentOrder, loadingOrders, updateOrder, clearOrders} = orderContext;
  const { getAddOns, allAddOns } = addOnContext;

  useEffect(() => {
    getAddOns();
    currentOrder && reset(currentOrder);
    // currentOrder && setValue('_id', currentOrder._id);
    // currentOrder && setValue('serialNumber', currentOrder.serialNumber);
    // currentOrder && setValue('model', currentOrder.model);
    // currentOrder && setValue('size', currentOrder.size);
    // currentOrder && setValue('type', currentOrder.type);
    // currentOrder && setValue('customerName', currentOrder.customerName);
    // currentOrder && setValue('soldByDealer', currentOrder.soldByDealer);
    // currentOrder && setValue('roofType', currentOrder.roofType);
    // currentOrder && setValue('roofColor', currentOrder.roofColor);
    // currentOrder && setValue('sidingType', currentOrder.sidingType);
    // currentOrder && setValue('sidingColor', currentOrder.sidingColor);
    // currentOrder && setValue('trimColor', currentOrder.trimColor);
    // currentOrder && setValue('addOns', currentOrder.addOns);
    // currentOrder && setValue('customAddOns', currentOrder.customAddOns);
    // currentOrder && setValue('shedProShed.retailPrice', currentOrder.shedProShed.retailPrice);
    // currentOrder && setValue('shedSuiteBuildingBasePrice', currentOrder.shedSuiteBuildingBasePrice);

    // currentOrder && setValue('shopAddOnsTotalSalePrice', currentOrder.shopAddOnsTotalSalePrice);
    // currentOrder && setValue('storMorAddOnsTotalSalePrice', currentOrder.storMorAddOnsTotalSalePrice);

    // currentOrder && setValue('shopCustomAddOnsTotalSalePrice', currentOrder.shopCustomAddOnsTotalSalePrice);
    // currentOrder && setValue('storMorCustomAddOnsTotalSalePrice', currentOrder.storMorCustomAddOnsTotalSalePrice);

    // currentOrder && setValue('basePrice', currentOrder.basePrice);
    // currentOrder && setValue('shopsShedPercent', currentOrder.shopsShedPercent);
    // currentOrder && setValue('shopPercentOfBuildingBasePrice', currentOrder.shopPercentOfBuildingBasePrice);
    // currentOrder && setValue('shopTotalSalePrice', currentOrder.shopTotalSalePrice);
    // currentOrder && setValue('storMorTotalSalePrice', currentOrder.storMorTotalSalePrice);
  }, []);

  const addOnOptions =
    allAddOns &&
    allAddOns.map((addon) => {
      return { label: `${addon.name.label}`, value: addon };
    });

  const onSubmit = (data) => {
    console.log('this is the form data', data);
    updateOrder(data);
    clearOrders();
    navigate(-1);

  };

  const setExit = () => {
    navigate(-1);
  };

  const setShopsPercent = (percent) => {
    const theBasePrice = getValues('basePrice');
    const newShopsPercentOfBasePrice = theBasePrice * percent;
    setValue('shopsShedPercent', percent);
    setValue('shopPercentOfBuildingBasePrice', newShopsPercentOfBasePrice);
    setTotals();
  };

  const setTotals = () => {
    const theAddOns = getValues(`addOns`);
    const theCustomAddOns = getValues(`customAddOns`);
    const theBasePrice = getValues('basePrice');
    const theShopPercentOfBasePrice = getValues('shopPercentOfBuildingBasePrice');
    const theStorMorAddOnsTotal = theAddOns && theAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorAddOnTotalSalePrice || 0), 0);
    const theShopsAddOnsTotal = theAddOns && theAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopAddOnTotalMaterialCost || 0), 0);
    const theStorMorCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorCustomAddOnsTotalSalePrice || 0), 0);
    const theShopsCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopCustomAddOnsTotalSalePrice || 0), 0);
    setValue('storMorTotalSalePrice', theBasePrice + theStorMorAddOnsTotal + theStorMorCustomAddOnsTotal);
    setValue('shopTotalSalePrice', theShopPercentOfBasePrice + theShopsAddOnsTotal + theShopsCustomAddOnsTotal);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='container-fluid bidformbackground border  ' style={{ borderRadius: '8px' }}>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-3 mt-4'>
                    <div className='form-group'></div>
                  </div>

                  <div className='col-md-7'></div>

                  <div className='col-md-2'>
                    <div className='buttons float-end  mr-3 mt-3 mb-4'>
                      <button className='btn btn-outline-primary ' type='submit'>
                        Update & Save
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Serial #</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='serialNumber' {...register('serialNumber', { required: true })} />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-2'></div>

                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Roof Type</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='roofType' {...register('roofType', { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Type</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='type' {...register('type', { required: true })} />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-2'></div>

                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Roof Color</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='roofColor' {...register('roofColor', { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Model</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='model' {...register('model', { required: true })} />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-2'></div>

                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Siding Type</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='sidingType' {...register('sidingType', { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Size</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='size' {...register('size', { required: true })} />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-2'></div>

                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Siding Color</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='sidingPaint.value.color' {...register('sidingPaint.value.color', { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      {/* <label className='col-sm-3 col-form-label'>Base Price</label>
                      <div className='col-sm-9'>
                        <input
                          className='form-control'
                          name='shedSuiteBuildingBasePrice'
                          {...register('shedSuiteBuildingBasePrice', { required: true })}
                        />
                      </div> */}
                    </div>
                  </div>

                  <div className='col-md-2'></div>

                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Trim Color</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='trimPaint.value.color' {...register('trimPaint.value.color', { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Customer</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='customer' {...register('customer', { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>Dealer</label>
                      <div className='col-sm-9'>
                        <input className='form-control' name='dealer.name.label' {...register('dealer.name.label', { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <InQueueOrderAddOnArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              getValues,
              addOnOptions,
            }}
          />
          <CustomOrderAddOnArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              getValues,
            }}
          />
          <div className='row mt-2 mb-2'>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
              <h4 className='text-center'>Totals</h4>
            </div>

            <div className='col-md-1 col-form-label'>
              <label className='float-end'>Shops %</label>
            </div>
            <div className='col-md-1'>
              <Controller
                render={({ field, name }) => (
                  <input
                    className='form-control'
                    type='number'
                    name={name}
                    step='.01'
                    {...field}
                    onChange={(e) => {
                      const percent = e.target.value;
                      setShopsPercent(percent);
                    }}
                  />
                )}
                control={control}
                name={`shopsShedPercent`}
                // defaultValue={0.65}
              />
            </div>
            <div className='col-md-2'></div>
          </div>

          <div className='row '>
            <div className='col-md-5'></div>
            <div className='col-md-7'>
              <div className='row mb-3'>
                <div className='col-sm-3 col-form-label'>
                  <label className='float-end'>Base Retail Price</label>
                </div>
                <div className='col-sm-3'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`basePrice`}
                    control={control}
                  />
                </div>
                <div className='col-sm-3 col-form-label'>
                  <label className='float-end'>Shop Total</label>
                </div>
                <div className='col-sm-3'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`shopPercentOfBuildingBasePrice`}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row '>
            <div className='col-md-5'></div>
            <div className='col-md-7'>
              <div className='row mb-3'>
                <div className='col-sm-3 col-form-label'>
                  <label className='float-end'>Addons Total</label>
                </div>
                <div className='col-sm-3'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`storMorAddOnsTotalSalePrice`}
                    control={control}
                  />
                </div>

                {/* <div className='col-sm-2 col-form-label'>
                  <label className='float-end'>Shops %</label>
                </div>
                <div className='col-sm-1'>
                  <Controller
                    render={({ field, name }) => (
                      <input
                        className='form-control'
                        type='number'
                        name={name}
                        {...field}
                        onChange={(e) => {
                          const price = e.target.value;
                          // setRetailPrice(price);
                        }}
                      />
                    )}
                    control={control}
                    name={`shed.value.retailPrice`}
                    defaultValue={0.75}
                  />
                </div> */}
                <div className='col-sm-3 col-form-label'>
                  <label className='float-end'>Shop Total</label>
                </div>
                <div className='col-sm-3'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`shopAddOnsTotalSalePrice`}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row '>
            <div className='col-md-5'></div>
            <div className='col-md-7'>
              <div className='row mb-2'>
                <div className='col-sm-3 col-form-label'>
                  <label className='float-end'>Custom Addons Total</label>
                </div>
                <div className='col-sm-3'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`storMorCustomAddOnsTotalSalePrice`}
                    control={control}
                  />
                </div>

                {/* <div className='col-sm-2 col-form-label'>
                  <label className='float-end'>Shops %</label>
                </div>
                <div className='col-sm-1'>
                  <Controller
                    render={({ field, name }) => (
                      <input
                        className='form-control'
                        type='number'
                        name={name}
                        {...field}
                        onChange={(e) => {
                          const price = e.target.value;
                          // setRetailPrice(price);
                        }}
                      />
                    )}
                    control={control}
                    name={`shed.value.retailPrice`}
                    defaultValue={0.75}
                  />
                </div> */}
                <div className='col-sm-3 col-form-label'>
                  <label className='float-end'>Shop Total</label>
                </div>
                <div className='col-sm-3'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`shopCustomAddOnsTotalSalePrice`}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row '>
            <div className='col-md-5'></div>
            <div className='col-md-7'>
              <div className='row mb-2'>
                <div className='col-sm-3 col-form-label'>
                  <label className='float-end'>Total</label>
                </div>
                <div className='col-sm-3'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`storMorTotalSalePrice`}
                    control={control}
                  />
                </div>
                <div className='col-sm-3 col-form-label'>
                  <label className='float-end'>Shop Grand Total</label>
                </div>
                <div className='col-sm-3'>
                  <Controller
                    render={({ field, name }) => (
                      <NumberFormat
                        readOnly
                        customInput={Input}
                        className='form-group form-control'
                        style={{ fontSize: 16, padding: 6 }}
                        {...field}
                        name={name}
                        thousandSeparator={true}
                        prefix={'$ '}
                        displayType='input'
                        decimalScale={2}
                        fixedDecimalScale='true'
                      />
                    )}
                    name={`shopTotalSalePrice`}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InQueueOrderForm;
