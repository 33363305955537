import React, { Fragment, useEffect, useContext } from 'react';
import AuthContext from '../../../../../context/auth/authContext';
import ShedLaborContext from '../../../../../context/shedLabor/shedLaborContext';
import ShedColumns from './ShedColumns';

const CenterCabinLabor = () => {
  const authContext = useContext(AuthContext);
  const { loadUser } = authContext;
  const shedLaborContext = useContext(ShedLaborContext);
  const { getShopShedsLabor, getStandardShedsLabor, shopShedsLabor, standardShedsLabor, clearShedsLabor } = shedLaborContext;

  useEffect(() => {
    loadUser();
    clearShedsLabor();
    getShopShedsLabor('center-cabin');
    getStandardShedsLabor('center-cabin');
  }, []);

  const theTitle = 'Center-Cabin';

  return (
    <Fragment>
      <div>
        <ShedColumns shopShedsLabor={shopShedsLabor} standardShedsLabor={standardShedsLabor} theTitle={theTitle} />
      </div>
    </Fragment>
  );
};

export default CenterCabinLabor;
