import React, { Fragment } from 'react';
import CreateShed from './CreateShed';
const ShedUpdate = () => {
  return (
    <Fragment>
      <div className='container-fluid '>
        <CreateShed />
      </div>
    </Fragment>
  );
};

export default ShedUpdate;
