import React, { useState } from "react";
import Select from "react-select";

import { categories } from "../../../names/shedNames";

const { Option } = Select;

const ProductCreateForm = ({ handleSubmit, handleChange, setValues, setCategory, values }) => {
  // destructure
  const { title, sku, myId, category, price, freight, quantity, orderPoint, orderPointMin } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3"> </div>

          <div className="col-md-4 bidformbackground border border-dark" style={{ borderRadius: "15px" }}>
            <h4 className="text-center mt-2">Create Item</h4>
            <div className="form-group">
              <label className="mt-2">Name</label>
              <input type="text" name="title" className="form-control inputbg" value={title} onChange={handleChange} />
            </div>

            <div className="form-group">
              <label>SKU</label>
              <input type="text" name="sku" className="form-control inputbg" value={sku} onChange={handleChange} />
            </div>

            <div className="form-group">
              <label>Category</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={category}
                placeholder={category}
                name={category}
                value={category}
                options={categories}
                onChange={setCategory}
              />
            </div>

            <div className="form-group">
              <label>Id</label>
              <input type="number" name="myId" className="form-control inputbg" value={myId} onChange={handleChange} />
            </div>

            <div className="form-group">
              <label>Price</label>
              <input
                type="number"
                name="price"
                step="0.000001"
                className="form-control inputbg"
                value={price}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Freight</label>
              <input
                type="number"
                name="freight"
                step="0.000001"
                className="form-control inputbg"
                value={freight}
                onChange={handleChange}
              />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Order Point Warning</label>
                  <input
                    type="number"
                    name="orderPoint"
                    step="0.01"
                    className="form-control inputbg"
                    value={orderPoint}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Order Point</label>
                  <input
                    type="number"
                    name="orderPointMin"
                    step="0.01"
                    className="form-control inputbg"
                    value={orderPointMin}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Quantity</label>
              <input
                type="number"
                step="0.01"
                name="quantity"
                className="form-control inputbg"
                value={quantity}
                onChange={handleChange}
              />
            </div>

            <br />
            <button className="btn btn-outline-info">Save</button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProductCreateForm;
