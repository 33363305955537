export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_INVOICE_ALERT = "SET_INVOICE_ALERT";
export const GET_INVOICE_ALERT = "GET_INVOICE_ALERT";
export const ALERT_ERROR = "ALERT_ERROR";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOAD_USERS = "LOAD_USERS";
export const COPY = "COPY";

export const SET_SETTINGS = "SET_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const GET_SETTINGS = "GET_SETTINGS";
export const SETTINGS_ERROR = "SETTINGS_ERROR";

export const ADD_DOOR = "ADD_DOOR";
export const DELETE_DOOR = "DELETE_DOOR";
export const CLEAR_DOORS = "CLEAR_DOORS";
export const SET_CURRENT_DOOR = "SET_CURRENT_DOOR";
export const CLEAR_CURRENT_DOOR = "CLEAR_CURRENT_DOOR";
export const UPDATE_DOOR = "UPDATE_DOOR";
export const FILTER_DOORS = "FILTER_DOORS";
export const CLEAR_FILTER_DOOR = "CLEAR_FILTER_DOOR";
export const DOOR_ERROR = "DOOR_ERROR";
export const GET_DOORS = "GET_DOORS";

export const ADD_INVOICE = "ADD_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const CLEAR_INVOICES = "CLEAR_INVOICES";
export const SET_CURRENT_INVOICE = "SET_CURRENT_INVOICE";
export const CLEAR_CURRENT_INVOICE = "CLEAR_CURRENT_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const FILTER_INVOICES = "FILTER_INVOICES";
export const CLEAR_FILTER_INVOICE = "CLEAR_FILTER_INVOICE";
export const INVOICE_ERROR = "INVOICE_ERROR";
export const GET_INVOICES = "GET_INVOICES";
export const STATUS_CHANGE_INVOICE = "STATUS_CHANGE_INVOICE";
export const SET_INVOICE_LOADING = "SET_INVOICE_LOADING";

export const ADD_MATERIALORDER = "ADD_MATERIALORDER";
export const DELETE_MATERIALORDER = "DELETE_MATERIALORDER";
export const CLEAR_MATERIALORDERS = "CLEAR_MATERIALORDERS";
export const SET_CURRENT_MATERIALORDER = "SET_CURRENT_MATERIALORDER";
export const CLEAR_CURRENT_MATERIALORDER = "CLEAR_CURRENT_MATERIALORDER";
export const UPDATE_MATERIALORDER = "UPDATE_MATERIALORDER";
export const FILTER_MATERIALORDERS = "FILTER_MATERIALORDERS";
export const CLEAR_FILTER_MATERIALORDER = "CLEAR_FILTER_MATERIALORDER";
export const MATERIALORDER_ERROR = "MATERIALORDER_ERROR";
export const GET_MATERIALORDERS = "GET_MATERIALORDERS";
export const GET_RECIEVED_MATERIALORDERS = "GET_RECIEVED_MATERIALORDERS";
export const SET_LOADING_MATERIALORDERS = "SET_LOADING_MATERIALORDERS";

export const ADD_METAL = "ADD_METAL";
export const DELETE_METAL = "DELETE_METAL";
export const CLEAR_METALS = "CLEAR_METALS";
export const SET_CURRENT_METAL = "SET_CURRENT_METAL";
export const CLEAR_CURRENT_METAL = "CLEAR_CURRENT_METAL";
export const UPDATE_METAL = "UPDATE_METAL";
export const FILTER_METALS = "FILTER_METALS";
export const CLEAR_FILTER_METAL = "CLEAR_FILTER_METAL";
export const METAL_ERROR = "METAL_ERROR";
export const GET_METALS = "GET_METALS";

export const ADD_METALORDER = "ADD_METALORDER";
export const DELETE_METALORDER = "DELETE_METALORDER";
export const CLEAR_METALORDERS = "CLEAR_METALORDERS";
export const SET_CURRENT_METALORDER = "SET_CURRENT_METALORDER";
export const CLEAR_CURRENT_METALORDER = "CLEAR_CURRENT_METALORDER";
export const UPDATE_METALORDER = "UPDATE_METALORDER";
export const FILTER_METALORDERS = "FILTER_METALORDERS";
export const CLEAR_FILTER_METALORDER = "CLEAR_FILTER_METALORDER";
export const METALORDER_ERROR = "METALORDER_ERROR";
export const GET_METALORDERS = "GET_METALORDERS";

export const ADD_ORDER = "ADD_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const CLEAR_ORDERS = "CLEAR_ORDERS";
export const SET_CURRENT_ORDER = "SET_CURRENT_ORDER";
export const CLEAR_CURRENT_ORDER = "CLEAR_CURRENT_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const FILTER_ORDERS = "FILTER_ORDERS";
export const CLEAR_FILTER_ORDER = "CLEAR_FILTER_ORDER";
export const ORDER_ERROR = "ORDER_ERROR";
export const GET_ORDERS = "GET_ORDERS";
export const STATUS_CHANGE_ORDER = "STATUS_CHANGE_ORDER";
export const SET_LOADING_ORDERS = "SET_LOADING_ORDERS";
export const GET_ORDER_COUNT = "GET_ORDER_COUNT";
export const CLEAR_ORDER_COUNT = "CLEAR_ORDER_COUNT";
export const SET_PAGINATION = "SET_PAGINATION";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const SET_SEARCHED_ORDER = "SET_SEARCHED_ORDER";
export const CLEAR_SEARCHED_ORDER = "CLEAR_SEARCHED_ORDER";
export const SET_SERIAL_NUMBER_SEARCH = " SET_SERIAL_NUMBER_SEARCH";
export const GET_ORDERS_CHARTONE = "GET_ORDERS_CHARTONE";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT";
export const CLEAR_CURRENT_PRODUCT = "CLEAR_CURRENT_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const CLEAR_FILTER_PRODUCT = "CLEAR_FILTER_PRODUCT";
export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_LOADING_PRODUCT = "SET_LOADING_PRODUCT";

export const ADD_SHED = "ADD_SHED";
export const DELETE_SHED = "DELETE_SHED";
export const CLEAR_SHEDS = "CLEAR_SHEDS";
export const SET_CURRENT_SHED = "SET_CURRENT_SHED";
export const CLEAR_CURRENT_SHED = "CLEAR_CURRENT_SHED";
export const UPDATE_SHED = "UPDATE_SHED";
export const FILTER_SHEDS = "FILTER_SHEDS";
export const CLEAR_FILTER_SHED = "CLEAR_FILTER_SHED";
export const SHED_ERROR = "SHED_ERROR";
export const GET_SHEDS = "GET_SHEDS";
export const SET_SHEDS = "SET_SHEDS";

export const ADD_TRUSS = "ADD_TRUSS";
export const DELETE_TRUSS = "DELETE_TRUSS";
export const CLEAR_TRUSSES = "CLEAR_TRUSSES";
export const SET_CURRENT_TRUSS = "SET_CURRENT_TRUSS";
export const CLEAR_CURRENT_TRUSS = "CLEAR_CURRENT_TRUSS";
export const UPDATE_TRUSS = "UPDATE_TRUSS";
export const FILTER_TRUSSES = "FILTER_TRUSSES";
export const CLEAR_FILTER_TRUSS = "CLEAR_FILTER_TRUSS";
export const TRUSS_ERROR = "TRUSS_ERROR";
export const GET_TRUSSES = "GET_TRUSSES";

export const ADD_VENDOR = "ADD_VENDOR";
export const DELETE_VENDOR = "DELETE_VENDOR";
export const CLEAR_VENDORS = "CLEAR_VENDORS";
export const SET_CURRENT_VENDOR = "SET_CURRENT_VENDOR";
export const CLEAR_CURRENT_VENDOR = "CLEAR_CURRENT_VENDOR";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const FILTER_VENDORS = "FILTER_VENDORS";
export const CLEAR_FILTER_VENDOR = "CLEAR_FILTER_VENDOR";
export const VENDOR_ERROR = "VENDOR_ERROR";
export const GET_VENDORS = "GET_VENDORS";

export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const COMMENT_ERROR = "COMMENT_ERROR";
export const GET_COMMENTS = "GET_COMMENTS";
export const SET_COMMENT_DRAWER = "SET_COMMENT_DRAWER";
export const GET_COMMENTS_FOR_ORDER = "GET_COMMENTS_FOR_ORDER";

export const ADD_ADDON = "ADD_ADDON";
export const DELETE_ADDON = "DELETE_ADDON";
export const CLEAR_ADDONS = "CLEAR_ADDONS";
export const SET_CURRENT_ADDON = "SET_CURRENT_ADDON";
export const CLEAR_CURRENT_ADDON = "CLEAR_CURRENT_ADDON";
export const UPDATE_ADDON = "UPDATE_ADDON";
export const FILTER_ADDONS = "FILTER_ADDONS";
export const CLEAR_FILTER_ADDON = "CLEAR_FILTER_ADDON";
export const ADDON_ERROR = "ADDON_ERROR";
export const GET_ADDONS = "GET_ADDONS";
export const SET_LOADING_ADDON_TRUE = "SET_LOADING_ADDON_TRUE";
export const GET_ALL_ADDONS = "GET_ALL_ADDONS";

export const ADD_PAYMENT = "ADD_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const CLEAR_PAYMENTS = "CLEAR_PAYMENTS";
export const SET_CURRENT_PAYMENT = "SET_CURRENT_PAYMENT";
export const CLEAR_CURRENT_PAYMENT = "CLEAR_CURRENT_PAYMENT";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const FILTER_PAYMENTS = "FILTER_PAYMENTS";
export const CLEAR_FILTER_PAYMENT = "CLEAR_FILTER_PAYMENT";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const STATUS_CHANGE_PAYMENT = "STATUS_CHANGE_PAYMENT";
export const FILTER_PAYMENTS_BY_SHOP = "FILTER_PAYMENTS_BY_SHOP";
export const SET_LOADING_PAYMENTS = "SET_LOADING_PAYMENTS";

export const ADD_SHEDLABOR = "ADD_SHEDLABOR";
export const DELETE_SHEDLABOR = "DELETE_SHEDLABOR";
export const CLEAR_SHEDLABORS = "CLEAR_SHEDLABORS";
export const SET_CURRENT_SHOP_SHEDLABOR = "SET_CURRENT_SHOP_SHEDLABOR";
export const SET_CURRENT_STANDARD_SHEDLABOR = "SET_CURRENT_STANDARD_SHEDLABOR";
export const CLEAR_CURRENT_SHOP_SHEDLABOR = "CLEAR_CURRENT_SHOP_SHEDLABOR";
export const CLEAR_CURRENT_STANDARD_SHEDLABOR = "CLEAR_CURRENT_STANDARD_SHEDLABOR";
export const UPDATE_SHEDLABOR = "UPDATE_SHEDLABOR";
export const SHEDLABOR_ERROR = "SHEDLABOR_ERROR";
export const GET_SHOP_SHEDLABORS = "GET_SHOP_SHEDLABORS";
export const GET_STANDARD_SHEDLABORS = "GET_STANDARD_SHEDLABORS";
export const SET_SHEDLABORS = "SET_SHEDLABORS";

export const ADD_ADDONLABOR = "ADD_ADDONLABOR";
export const DELETE_ADDONLABOR = "DELETE_ADDONLABOR";
export const CLEAR_ADDONLABORS = "CLEAR_ADDONLABORS";
export const SET_CURRENT_SHOP_ADDONLABOR = "SET_CURRENT_SHOP_ADDONLABOR";
export const SET_CURRENT_STANDARD_ADDONLABOR = "SET_CURRENT_STANDARD_ADDONLABOR";
export const CLEAR_CURRENT_ADDONLABOR = "CLEAR_CURRENT_ADDONLABOR";
export const UPDATE_ADDONLABOR = "UPDATE_ADDONLABOR";
export const ADDONLABOR_ERROR = "ADDONLABOR_ERROR";
export const GET_SHOP_ADDONLABORS = "GET_SHOP_ADDONLABORS";
export const GET_STANDARD_ADDONLABORS = "GET_STANDARD_ADDONLABORS";
export const SET_ADDONLABORS = "SET_ADDONLABORS";
