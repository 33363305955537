import React from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        display: 'flex',

    },
    row: {
        flexDirection: 'row',
        
    },
  

    boxone: {
        paddingTop:20,
        order:1,
        flex:2,
        
        fontFamily: 'Helvetica',
        
    },
   
      boxtwo: {
        paddingTop:20,
        order: 2,
        flex: 1,
        justifyContent: 'flex-end', 
           
      },
    textrow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft:10,
        marginTop:2,
    },
    textrowtwo: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft:10,
        marginTop:2,
        fontFamily: 'Helvetica-Bold',
    },
    
    image: {
        width: "75px",
        height: "75px",
        padding: 5,
        
      },
  });


  const BillTo = ({values, settings}) => {

    const {createdAt} = values
    const theDate = new Date();

    const date = new Date(createdAt && createdAt || theDate).toLocaleDateString()

    return(
        <View style={styles.container}>

            <View style={styles.row}>
                <View style={styles.boxone}>
                    <Text style={styles.textrowtwo}>COMPANY</Text>
                    <Text style={styles.textrow}>{settings.company}  </Text>
                    <Text style={styles.textrow}>{settings.address} </Text>
                    <Text style={styles.textrow}>{settings.city} {settings.state}, {settings.zipCode} </Text>
                </View>
                <View style={styles.boxtwo}>
                    <Text style={styles.textrowtwo}>VENDOR</Text>
                    <Text style={styles.textrow}>{values.vendor.value.vendorName}  </Text>
                    <Text style={styles.textrow}>{values.vendor.value.address}</Text>
                    <Text style={styles.textrow}>{values.vendor.value.city} {values.vendor.value.state}, {values.vendor.value.zipCode}</Text>
                    <Text style={styles.textrow}>{values.vendor.value.email}</Text>
                </View>
            </View>
            
            <View style={styles.row}>
                <View style={styles.boxone}>
                    <Text style={styles.textrow}>PO #:    {values.purchaseOrderNumber}  </Text>
                    <Text style={styles.textrow}>Date:    {date} </Text>
                </View>
                
            </View>
        </View>
      );
  };
  
  export default BillTo