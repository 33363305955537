import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import OrderContext from "../../../context/order/orderContext";

const Reports = () => {
  const orderContext = useContext(OrderContext);
  const { clearOrders } = orderContext;
  const handleSwitchReport = () => {
    clearOrders();
  };
  return (
    <Fragment>
      <div className="card">
        <div className="row">
          <h4>Inventory</h4>
        </div>
        <div className="row mt-4">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <Link onClick={() => handleSwitchReport()} to="/reports/inventory/overview">
              Inventory/Overview
            </Link>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <Link onClick={() => handleSwitchReport()} to="/reports/inventory/used">
              Inventory/Used
            </Link>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <Link onClick={() => handleSwitchReport()} to="/reports/inventory/purchased">
              Inventory/Purchased
            </Link>
            <hr />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <h4>Order</h4>
        </div>
        <div className="row mt-4">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <Link onClick={() => handleSwitchReport()} to="/reports/orders/detail">
              Orders by Shed/Detail
            </Link>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <Link onClick={() => handleSwitchReport()} to="/reports/orders/summary">
              Order by Model/Summary
            </Link>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <Link onClick={() => handleSwitchReport()} to="/reports/orders/labor">
              Orders/Labor
            </Link>
            <hr />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <h4>Invoices</h4>
        </div>
        <div className="row mt-4">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <Link to="/reports/invoices/open">Open Invoices</Link>

            <hr />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <h4>Payments</h4>
        </div>
        {/* <div className='row mt-4'>
          <div className='col-md-1'></div>
          <div className='col-md-4'>
            <Link to='/reports/orders'>Payments Total Report</Link>
            <hr />
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <Link to="/reports/payments/summary">Payments Report</Link>
            <hr />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Reports;
