import React, { Fragment } from "react";
import { useFieldArray, Controller, useWatch } from "react-hook-form";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";
import { Input } from "antd";

const Items = ({ product, productIndex, control, remove, setValue, getValues, settings }) => {
  // const setTotals = () => {
  //   const products = getValues('products');
  //   const labor = getValues('labor');
  //   const freight = getValues('freight');
  //   const retailPrice = getValues('retailPrice');
  //   const percentOfRetail = retailPrice * settings.retailPercent;
  //   const metal = getValues('metal');
  //   const trussOne = getValues('trussOne');
  //   const trussTwo = getValues('trussTwo');
  //   const door = getValues('door');
  //   const metalCount = getValues('metalCount');
  //   const trussOneCount = getValues('trussOneCount');
  //   const trussTwoCount = getValues('trussTwoCount');
  //   const doorCount = getValues('doorCount');
  //   const theProductCost = products && products.reduce((acc, curr) => acc + parseFloat(curr.total || 0), 0);
  //   const theMetalCost = metal && metal.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.totalFt || 0), 0);
  //   const totalMetalCost = (metalCount && theMetalCost * metalCount) || 0;
  //   const theTrussOneCost = trussOne && trussOne.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
  //   const totalTrussOneCost = (trussOneCount && theTrussOneCost * trussOneCount) || 0;
  //   const theTrussTwoCost = trussTwo && trussTwo.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
  //   const totalTrussTwoCost = (trussTwoCount && theTrussTwoCost * trussTwoCount) || 0;
  //   const theDoorCost = door && door.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
  //   const totalDoorCost = (doorCount && theDoorCost * doorCount) || 0;
  //   const theTotalMaterialCost = +theProductCost + +totalMetalCost + +totalTrussOneCost + +totalTrussTwoCost + +totalDoorCost;
  //   const totalWithLaborOverhead = +theTotalMaterialCost + +labor + +freight;
  //   setValue(`materialCost`, theTotalMaterialCost);
  //   setValue(`totalCost`, totalWithLaborOverhead);
  //   setValue(`grossProfit`, +percentOfRetail - +totalWithLaborOverhead);
  // };

  // const setItemCount = (count) => {
  //   const price = getValues(`products[${productIndex}].product.price`);
  //   setValue(`products[${productIndex}].count`, count);
  //   setValue(`products[${productIndex}].total`, count * price);
  //   setTotals();
  // };

  // const onRemoveItem = () => {
  //   setTotals();
  // };

  // const setItemChange = (e) => {
  //   setValue(`products[${productIndex}].product.label`, e.value.sku);
  //   setValue(`products[${productIndex}].product`, e.value);
  //   setValue(`products[${productIndex}].product.title`, e.value.title);
  //   setValue(`products[${productIndex}].count`, 0);
  //   setValue(`products[${productIndex}].total`, 0);
  //   setValue(`products[${productIndex}].product.price`, e.value.price);
  //   setTotals();
  // };

  setValue(`products[${productIndex}].product.label`, product.product && product.product.title);
  // setValue(`products[${productIndex}].total`, product.product && product.product.price * product.count);
  // setTotals();

  return (
    <Fragment>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      // options={productOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // onChange={(e) => setItemChange(e)}
                    />
                  )}
                  key={product.id}
                  name={`products[${productIndex}].product`}
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control"
                      type="number"
                      step={0.001}
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const count = e.target.value;
                        // setItemCount(count);
                      }}
                    />
                  )}
                  key={product.id}
                  control={control}
                  name={`products[${productIndex}].count`}
                />
              </div>
            </div>

            {/* <div className='col-md-3'>
              <div className='form-group'>
                <Controller
                  render={({ field, onChange, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className='form-group form-control'
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={'$ '}
                      displayType='input'
                      decimalScale={2}
                      fixedDecimalScale='true'
                    />
                  )}
                  name={`products[${productIndex}].product.price`}
                  control={control}
                />
              </div>
            </div> */}

            {/* <div className='col-md-3'>
              <div className='form-group'>
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className='form-group form-control'
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={'$ '}
                      displayType='input'
                      decimalScale={2}
                      fixedDecimalScale='true'
                    />
                  )}
                  name={`products[${productIndex}].total`}
                  control={control}
                />
              </div>
            </div> */}

            <div className="col-md-1">
              <div className="form-group float-end">
                {/* <button
                   className='btn btn-outline-danger mb-2'
                  type='button'
                  onClick={() => {
                    remove(productIndex);
                    // onRemoveItem();
                  }}
                >
                  Delete
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, settings }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  return (
    <Fragment>
      <div className="row mt-3">
        <div className="col-md-6">Items</div>
      </div>
      {fields.map((product, productIndex) => (
        <Items
          key={product.id}
          product={product}
          productIndex={productIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          // productOptions={productOptions}
          settings={settings}
        />
      ))}
      {/* <button
        className='btn btn-outline-info mb-2 mt-2 '
        onClick={(e) => {
          e.preventDefault();
          append({ value: '0' });
        }}
      >
        Add Item
      </button> */}
    </Fragment>
  );
}
