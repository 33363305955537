import {
  ADD_INVOICE,
  DELETE_INVOICE,
  CLEAR_INVOICES,
  SET_CURRENT_INVOICE,
  CLEAR_CURRENT_INVOICE,
  UPDATE_INVOICE,
  FILTER_INVOICES,
  CLEAR_FILTER_INVOICE,
  INVOICE_ERROR,
  GET_INVOICES,
  STATUS_CHANGE_INVOICE,
  SET_INVOICE_LOADING,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        loading: false,
      };
    case ADD_INVOICE:
      return {
        ...state,
        invoice: action.payload,
        loading: false,
      };
    case UPDATE_INVOICE:
      return {
        ...state,
        invoices: state.invoices && state.invoices.map((invoice) => (invoice._id === action.payload._id ? action.payload : invoice)),
        loading: false,
      };
    case DELETE_INVOICE:
      return {
        ...state,
        invoices: state.invoices.filter((invoice) => invoice._id !== action.payload),
        loading: false,
      };
    case CLEAR_INVOICES:
      return {
        ...state,
        invoices: null,
        filteredInvoice: null,
        error: null,
        currentInvoice: null,
      };
    case SET_CURRENT_INVOICE:
      return {
        ...state,
        currentInvoice: action.payload,
      };
    case CLEAR_CURRENT_INVOICE:
      return {
        ...state,
        currentInvoice: null,
      };
    case FILTER_INVOICES:
      return {
        ...state,
        filteredInvoice: state.invoices.filter((invoice) => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          return invoice.customer.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_INVOICE:
      return {
        ...state,
        filteredInvoice: null,
      };
    case INVOICE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case STATUS_CHANGE_INVOICE:
      return {
        ...state,
        invoices: state.invoices && state.invoices.filter((invoice) => invoice._id !== action.payload._id),
        invoiceLoading: false,
      };
    case SET_INVOICE_LOADING:
      return {
        ...state,
        invoiceLoading: action.payload,
      };
    default:
      return state;
  }
};
