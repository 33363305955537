import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import ShedContext from "../../../../context/shed/shedContext";
import ProductContext from "../../../../context/product/productContext";
import ShedLaborContext from "../../../../context/shedLabor/shedLaborContext";
import { Table } from "antd";

const ShedColumns = ({ sheds, theTitle }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const shedContext = useContext(ShedContext);
  const productContext = useContext(ProductContext);
  const shedLaborContext = useContext(ShedLaborContext);
  const { loadUser } = authContext;
  const { getSettings, settings } = settingsContext;
  const { deleteShed, setCurrentShed } = shedContext;
  const { getProducts, products } = productContext;
  const { getShopShedsLabor, shopShedsLabor } = shedLaborContext;

  useEffect(() => {
    loadUser();
    getSettings();
    getProducts();
    getShopShedsLabor(theTitle.toLowerCase());
  }, []);

  const handleRemove = (id) => {
    let answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      // console.log('send delete request', shed)
      deleteShed(id);
    }
  };

  const handleEdit = (shed) => {
    setCurrentShed(shed);
    navigate(`/shed/update`);
  };

  const getMaterialTotal = (record) => {
    let swappedShedProducts = record.products.map((item) => {
      let item2 = products.find((i2) => i2.myId === item.product.myId);
      // return item2 ? { ...item, ...item2 } : item;
      !item2 && console.log("item did not match");
      return item2 ? { ...item2, count: item.count } : item;
    });

    let swappedMetalProducts = record.metal.products.map((item) => {
      let item3 = products.find((i3) => i3.myId === item.product.myId);
      // return item3 ? { ...item, ...item3 } : item;
      !item3 && console.log("item did not match");
      return item3 ? { ...item3, totalFt: item.totalFt } : item;
    });

    let swappedTrussOneProducts = record.trussOne.products.map((item) => {
      let item4 = products.find((i4) => i4.myId === item.product.myId);
      // return item4 ? { ...item, ...item4 } : item;
      !item4 && console.log("item did not match");
      return item4 ? { ...item4, count: item.count } : item;
    });

    let swappedTrussTwoProducts = record.trussTwo.products.map((item) => {
      let item5 = products.find((i5) => i5.myId === item.product.myId);
      // return item5 ? { ...item, ...item5 } : item
      !item5 && console.log("item did not match");
      return item5 ? { ...item5, count: item.count } : item;
    });

    let swappedDoorProducts = record.door.products.map((item) => {
      let item6 = products.find((i6) => i6.myId === item.product.myId);
      // return item6 ? { ...item, ...item6 } : item;
      !item6 && console.log("item did not match");
      return item6 ? { ...item6, count: item.count } : item;
    });

    // let swappedShedProductsTotal = swappedShedProducts.reduce((a, p) => (a = a + p.price * p.count), 0)
    // let swappedMetalProductsTotal = swappedMetalProducts.reduce((c, d) => (c = c + d.price * d.totalFt * record.metalCount), 0)
    // let swappedTrussOneProductsTotal =  swappedTrussOneProducts.reduce((e, f) => (e = e + f.price * f.count * record.trussOneCount), 0)
    // let swappedTrussTwoProductsTotal = swappedTrussTwoProducts.reduce((g, h) => (g = g + h.price * h.count * record.trussTwoCount), 0)
    // let swappedDoorProductsTotal =  swappedDoorProducts.reduce((i, j) => (i = i + j.price * j.count * record.doorCount), 0);

    // console.log(
    //   'this is the material totals',
    //   record.size,
    //   swappedShedProductsTotal,
    //   swappedMetalProductsTotal,
    //   swappedTrussOneProductsTotal,
    //   swappedTrussTwoProductsTotal,
    //   swappedDoorProductsTotal
    // );

    let materialTotal =
      swappedShedProducts.reduce((a, p) => (a = a + p.price * p.count), 0) +
      swappedMetalProducts.reduce((c, d) => (c = c + d.price * d.totalFt * record.metalCount), 0) +
      swappedTrussOneProducts.reduce((e, f) => (e = e + f.price * f.count * record.trussOneCount), 0) +
      swappedTrussTwoProducts.reduce((g, h) => (g = g + h.price * h.count * record.trussTwoCount), 0) +
      swappedDoorProducts.reduce((i, j) => (i = i + j.price * j.count * record.doorCount), 0);
    return materialTotal;
  };

  const getLaborTotal = (record) => {
    let laborItem = shopShedsLabor.find((i) => i.name === record.labor.value.name);
    return laborItem.totalLabor;
  };

  const figureTotalCost = (record) => {
    let theMaterialTotal = getMaterialTotal(record);
    let laborItem = shopShedsLabor.find((i) => i.name === record.labor.value.name);
    let theTotalCost =
      record.retailPrice * settings.retailPercent * settings.overheadPercent + laborItem.totalLabor + +theMaterialTotal;
    return theTotalCost;
  };

  const figureGrossProfit = (record) => {
    let percentOfRetail = record.retailPrice * settings.retailPercent;
    let theTotalCost = figureTotalCost(record);
    let theGrossProfit = percentOfRetail - theTotalCost;
    return theGrossProfit;
  };

  const figureGrossProfitPercent = (record) => {
    let percentOfRetail = record.retailPrice * settings.retailPercent;
    let theGrossProfit = figureGrossProfit(record);
    let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
    return theGrossProfitPercent;
  };

  const columns = [
    {
      title: "Size",
      dataIndex: "size",
      // fixed: 'left',
      defaultSortOrder: "ascend",
      sorter: (a, b) => parseInt(a.size.toUpperCase().replace("X", "1")) - parseInt(b.size.toUpperCase().replace("X", "1")),
    },
    {
      title: "Retail Price",
      dataIndex: "retailPrice",
      sorter: (a, b) => a.retailPrice - b.retailPrice,
      render: (value, row, index) => {
        // do something like adding commas to the value or prefix
        return <span>$ {value.toLocaleString("en-US")}</span>;
      },
    },
    {
      title: `${settings.retailPercent * 100}% / Retail`,
      // title: '65% / Retail',
      render: (text, record) => <span>${(record.retailPrice * settings.retailPercent).toLocaleString("en-US")}</span>,
    },
    {
      title: "Labor",
      render: (text, record) => {
        return <span>${shopShedsLabor && getLaborTotal(record).toLocaleString("en-US")}</span>;
      },
    },
    {
      title: "Overhead",
      render: (text, record) => (
        <span>${(record.retailPrice * settings.retailPercent * settings.overheadPercent).toLocaleString("en-US")}</span>
      ),
    },

    {
      title: "Material Cost",
      render: (text, record) => <span>${products && getMaterialTotal(record).toLocaleString("en-US")}</span>,
    },

    {
      title: "Total Cost",
      render: (text, record) => <span>${products && figureTotalCost(record).toLocaleString("en-US")}</span>,
    },

    {
      title: "Gross Profit",
      render: (text, record) => <span>${products && figureGrossProfit(record).toLocaleString("en-US")}</span>,
    },

    {
      title: "GP %",
      render: (text, record) => <span>{products && figureGrossProfitPercent(record).toLocaleString("en-US")}%</span>,
    },

    {
      title: "Actions",
      fixed: "right",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleEdit(record)}>
            View
          </button>
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <h4 className="text-center">{theTitle}</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/* <Link className='btn btn-outline-info mb-2 float-end ' to='/shed'>
                  Add Shed
                </Link> */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Table
                  pagination={{ pageSize: 40 }}
                  loading={sheds ? false : true}
                  columns={columns}
                  dataSource={sheds}
                  rowKey="_id"
                  bordered={true}
                  onChange={onChange}
                  scroll={{ x: 1300 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShedColumns;
