import React, { useState, Fragment } from 'react';
import { DatePicker } from 'antd';
const dateFormat = 'MM/DD/YYYY';
const { RangePicker } = DatePicker;

const DateFilters = ({ getPaymentsByDateFilter, setLoadingPayments }) => {
  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);
  const [value, setValue] = useState(null);

  //   const disabledDate = (current) => {
  //     if (!dates) {
  //       return false;
  //     }

  //     const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
  //     const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
  //     return !!tooEarly || !!tooLate;
  //   };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  const handleDateSort = () => {
    // console.log('this is the dates', dates);
    setLoadingPayments(true);
    getPaymentsByDateFilter(dates);
    // console.log('this is the status', status);
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-2'>
          <RangePicker
            className='p-2'
            value={hackValue || value}
            // disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setValue(val)}
            onOpenChange={onOpenChange}
            format={dateFormat}
          />
        </div>

        <div className='col-md-1'>
          <button className='ml-2 btn btn-outline-primary ' type='button' disabled={(!dates && true) || false} onClick={handleDateSort}>
            Run Report
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default DateFilters;
