import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import ShedContext from "../../../context/shed/shedContext";
import SettingsContext from "../../../context/settings/settingsContext";
import ReactSelect from "react-select";
import { useForm, Controller } from "react-hook-form";
import ItemArray from "./RHFItemArray";

const defaultValues = {
  style: {},
  products: [
    {
      product: "",
    },
  ],
  labor: 0,
  freight: 0,
};

const CreateShed = () => {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const shedContext = useContext(ShedContext);

  const { loadUser, user } = authContext;
  const { getSettings, settings } = settingsContext;
  const { currentShed, clearCurrentShed } = shedContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    loadUser();
    getSettings();

    // reset(currentShed)
    currentShed && setValue("metal", currentShed.metal);
    currentShed && setValue("door", currentShed.door);
    currentShed && setValue("trussOne", currentShed.trussOne);
    currentShed && setValue("trussTwo", currentShed.trussTwo);
    currentShed && setValue("metalCount", currentShed.metalCount);
    currentShed && setValue("doorCount", currentShed.doorCount);
    currentShed && setValue("trussOneCount", currentShed.trussOneCount);
    currentShed && setValue("trussTwoCount", currentShed.trussTwoCount);
    currentShed && setValue("products", currentShed.products);
    currentShed && setValue("_id", currentShed._id);
    currentShed && setValue("style.label", currentShed.title);
    currentShed && setValue("style.value", currentShed.title);
    currentShed && setValue("metal.label", currentShed.metal.sku);
    currentShed && setValue("trussOne.label", currentShed.trussOne.sku);
    currentShed && setValue("trussTwo.label", currentShed.trussTwo.sku);
    currentShed && setValue("door.label", currentShed.door.sku);
    // const theProductCost =
    //   currentShed && currentShed.products.reduce((acc, curr) => acc + parseFloat(curr.count * curr.product.price || 0), 0);
    // const labor = currentShed && currentShed.labor;
    // const theMetalCost =
    //   currentShed &&
    //   currentShed.metal &&
    //   currentShed.metal.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.totalFt || 0), 0);
    // const totalMetalCost = (currentShed && currentShed.metalCount && theMetalCost * currentShed.metalCount) || 0;
    // const theTrussOneCost =
    //   currentShed &&
    //   currentShed.trussOne &&
    //   currentShed.trussOne.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    // const totalTrussOneCost = (currentShed && currentShed.trussOneCount && theTrussOneCost * currentShed.trussOneCount) || 0;
    // const theTrussTwoCost =
    //   currentShed &&
    //   currentShed.trussTwo &&
    //   currentShed.trussTwo.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    // const totalTrussTwoCost = (currentShed && currentShed.trussTwoCount && theTrussTwoCost * currentShed.trussTwoCount) || 0;
    // const theDoorCost =
    //   currentShed &&
    //   currentShed.door &&
    //   currentShed.door.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    // const totalDoorCost = (currentShed && currentShed.doorCount && theDoorCost * currentShed.doorCount) || 0;
    // const overHead = currentShed && +currentShed.retailPrice * settings.retailPercent * settings.overheadPercent;
    // const theTotalMaterialCost = theProductCost + totalMetalCost + totalTrussOneCost + totalTrussTwoCost + totalDoorCost;
    // const totalWithLaborOverhead = +theTotalMaterialCost + +labor + overHead;
    // const thePercentOfRetail = currentShed && currentShed.retailPrice * settings.retailPercent;
    // currentShed && setValue("labor", currentShed.labor);
    // currentShed && setValue("haul", currentShed.haul);
    currentShed && setValue("retailPrice", currentShed.retailPrice);
    currentShed && setValue("size", currentShed.size);
    currentShed && setValue("zone", currentShed.zone);
    // currentShed && setValue("percentOfRetail", thePercentOfRetail);
    // currentShed && setValue("freight", overHead);
    // currentShed && setValue("materialCost", theTotalMaterialCost);
    // currentShed && setValue("totalCost", totalWithLaborOverhead);
    // currentShed && setValue("grossProfit", thePercentOfRetail - totalWithLaborOverhead);
    // currentShed && setMetalTrussDoor();
  }, []);

  console.log("this is the current Shed", currentShed);

  // const setMetalTrussDoor = () => {
  //   const metal = getValues("metal");
  //   const trussOne = getValues("trussOne");
  //   const trussTwo = getValues("trussTwo");
  //   const door = getValues("door");
  //   const metalCount = getValues("metalCount");
  //   const trussOneCount = getValues("trussOneCount");
  //   const trussTwoCount = getValues("trussTwoCount");
  //   const doorCount = getValues("doorCount");
  //   const theMetalCost =
  //     metal && metal.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.totalFt || 0), 0);
  //   const totalMetalCost = (metalCount && theMetalCost * metalCount) || 0;
  //   const theTrussOneCost =
  //     trussOne && trussOne.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
  //   const totalTrussOneCost = (trussOneCount && theTrussOneCost * trussOneCount) || 0;
  //   const theTrussTwoCost =
  //     trussTwo && trussTwo.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
  //   const totalTrussTwoCost = (trussTwoCount && theTrussTwoCost * trussTwoCount) || 0;
  //   const theDoorCost = door && door.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
  //   const totalDoorCost = (doorCount && theDoorCost * doorCount) || 0;
  //   setValue("metalPrice", theMetalCost);
  //   setValue("trussOnePrice", theTrussOneCost);
  //   setValue("trussTwoPrice", theTrussTwoCost);
  //   setValue("doorPrice", theDoorCost);
  //   setValue(`totalMetalPrice`, totalMetalCost);
  //   setValue(`totalTrussOnePrice`, totalTrussOneCost);
  //   setValue(`totalTrussTwoPrice`, totalTrussTwoCost);
  //   setValue(`totalDoorPrice`, totalDoorCost);
  // };

  // const productOptions =
  //   products &&
  //   products.map((option) => {
  //     return { label: `${option.sku}`, value: option };
  //   });

  // const allLaborOptions =
  //   shedsLabor &&
  //   shedsLabor.map((option) => {
  //     return { label: `${option.name}`, value: option };
  //   });

  // const allHaulBaseOptions =
  //   haulBases &&
  //   haulBases.map((option) => {
  //     return { label: `${option.name}`, value: option };
  //   });

  const onSubmit = (data) => {
    // if (!currentShed) {
    //   addShed(data);
    //   navigate(-1);
    // } else {
    //   updateShed(data);
    //   navigate(-1);
    // }
  };

  // const handleCopyShed = () => {
  //   Modal.confirm({
  //     title: "Are you sure you want to copy this Shed? Make sure the Size is changed.",
  //     okText: "Yes",
  //     okType: "danger",
  //     onOk: () => {
  //       const data = getValues();
  //       copyShed(data);
  //     },
  //   });
  // };

  // const setRetailPrice = (retailPrice) => {
  //   const products = getValues("products");
  //   const theProductCost = products && products.reduce((acc, curr) => acc + parseFloat(curr.total || 0), 0);
  //   const labor = getValues("labor");
  //   const metal = getValues("metal");
  //   const trussOne = getValues("trussOne");
  //   const trussTwo = getValues("trussTwo");
  //   const door = getValues("door");
  //   const metalCount = getValues("metalCount");
  //   const trussOneCount = getValues("trussOneCount");
  //   const trussTwoCount = getValues("trussTwoCount");
  //   const doorCount = getValues("doorCount");
  //   const theMetalCost =
  //     metal && metal.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.totalFt || 0), 0);
  //   const totalMetalCost = (metalCount && theMetalCost * metalCount) || 0;
  //   const theTrussOneCost =
  //     trussOne && trussOne.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
  //   const totalTrussOneCost = (trussOneCount && theTrussOneCost * trussOneCount) || 0;
  //   const theTrussTwoCost =
  //     trussTwo && trussTwo.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
  //   const totalTrussTwoCost = (trussTwoCount && theTrussTwoCost * trussTwoCount) || 0;
  //   const theDoorCost = door && door.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
  //   const totalDoorCost = (doorCount && theDoorCost * doorCount) || 0;
  //   const overHead = retailPrice * settings.retailPercent * settings.overheadPercent;
  //   const theTotalMaterialCost = theProductCost + totalMetalCost + totalTrussOneCost + totalTrussTwoCost + totalDoorCost;
  //   const totalWithLaborOverhead =
  //     +theTotalMaterialCost + +labor + +retailPrice * settings.retailPercent * settings.overheadPercent;
  //   setValue("totalCost", totalWithLaborOverhead);
  //   setValue("retailPrice", retailPrice);
  //   setValue("percentOfRetail", retailPrice * settings.retailPercent);
  //   setValue("grossProfit", retailPrice * settings.retailPercent - totalWithLaborOverhead);
  //   setValue("freight", overHead);
  // };

  // const setLabor = (labor) => {
  //   setValue("labor", labor);
  // };

  // const setHaul = (haul) => {
  //   setValue("haul", haul);
  // };

  // const setStyleChange = (style) => {
  //   setValue("title", style.label);
  //   setValue("style.label", style.label);
  //   setValue("style.value", style.value);
  // };

  const RHFMetalTrussesDoor = ({ control, setValue, getValues }) => {
    // const metalOptions =
    //   metals &&
    //   metals.map((option) => {
    //     return { label: `${option.sku}`, value: option };
    //   });

    // const trussesOptions =
    //   trusses &&
    //   trusses.map((option) => {
    //     return { label: `${option.sku}`, value: option };
    //   });

    // const doorOptions =
    //   doors &&
    //   doors.map((option) => {
    //     return { label: `${option.sku}`, value: option };
    //   });

    // const setTotals = () => {
    //   const products = getValues("products");
    //   const metal = getValues("metal");
    //   const trussOne = getValues("trussOne");
    //   const trussTwo = getValues("trussTwo");
    //   const door = getValues("door");
    //   const metalCount = getValues("metalCount");
    //   const trussOneCount = getValues("trussOneCount");
    //   const trussTwoCount = getValues("trussTwoCount");
    //   const doorCount = getValues("doorCount");
    //   const labor = getValues("labor");
    //   const freight = getValues("freight");
    //   const percentOfRetail = getValues("percentOfRetail");
    //   const theMaterialCost =
    //     products && products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    //   const theMetalCost =
    //     metal && metal.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.totalFt || 0), 0);
    //   const totalMetalCost = (metalCount && theMetalCost * metalCount) || 0;
    //   const theTrussOneCost =
    //     trussOne && trussOne.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    //   const totalTrussOneCost = (trussOneCount && theTrussOneCost * trussOneCount) || 0;
    //   const theTrussTwoCost =
    //     trussTwo && trussTwo.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    //   const totalTrussTwoCost = (trussTwoCount && theTrussTwoCost * trussTwoCount) || 0;
    //   const theDoorCost = door && door.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    //   const totalDoorCost = (doorCount && theDoorCost * doorCount) || 0;
    //   const theTotalMaterialCost = +theMaterialCost + +totalMetalCost + +totalTrussOneCost + +totalTrussTwoCost + +totalDoorCost;
    //   const totalWithLaborOverhead = +theTotalMaterialCost + +labor + +freight;
    //   setValue(`materialCost`, theTotalMaterialCost);
    //   setValue("totalCost", totalWithLaborOverhead);
    //   setValue(`grossProfit`, percentOfRetail - totalWithLaborOverhead);
    //   setValue(`totalMetalPrice`, totalMetalCost);
    //   setValue(`totalTrussOnePrice`, totalTrussOneCost);
    //   setValue(`totalTrussTwoPrice`, totalTrussTwoCost);
    //   setValue(`totalDoorPrice`, totalDoorCost);
    // };

    // const setMetalChange = (metal) => {
    //   const metalCount = getValues("metalCount");
    //   const theMetalCost =
    //     metal && metal.value.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.totalFt || 0), 0);
    //   setValue(`metal`, metal.value);
    //   setValue(`metal.label`, metal.label);
    //   setValue(`metalPrice`, theMetalCost);
    //   setValue(`totalMetalPrice`, theMetalCost * metalCount);
    //   setTotals();
    // };
    // const setMetalCount = (count) => {
    //   setValue(`metalCount`, count);
    //   setTotals();
    // };

    // const setTrussOneChange = (trussOne) => {
    //   const trussOneCount = getValues("trussOneCount");
    //   const theTrussOneCost =
    //     trussOne && trussOne.value.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    //   setValue(`trussOne`, trussOne.value);
    //   setValue(`trussOne.label`, trussOne.label);
    //   setValue(`trussOnePrice`, theTrussOneCost);
    //   setValue(`totalTrussOnePrice`, theTrussOneCost * trussOneCount);
    //   setTotals();
    // };
    // const setTrussOneCount = (count) => {
    //   setValue(`trussOneCount`, count);
    //   setTotals();
    // };

    // const setTrussTwoChange = (trussTwo) => {
    //   const trussTwoCount = getValues("trussTwoCount");
    //   const theTrussTwoCost =
    //     trussTwo && trussTwo.value.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    //   setValue(`trussTwo`, trussTwo.value);
    //   setValue(`trussTwo.label`, trussTwo.label);
    //   setValue(`trussTwoPrice`, theTrussTwoCost);
    //   setValue(`totalTrussTwoPrice`, theTrussTwoCost * trussTwoCount);
    //   setTotals();
    // };
    // const setTrussTwoCount = (count) => {
    //   setValue(`trussTwoCount`, count);
    //   setTotals();
    // };

    // const setDoorChange = (door) => {
    //   const doorCount = getValues("doorCount");
    //   const theDoorCost =
    //     door && door.value.products.reduce((acc, curr) => acc + parseFloat(curr.product.price * curr.count || 0), 0);
    //   setValue(`door`, door.value);
    //   setValue(`door.label`, door.label);
    //   setValue(`doorPrice`, theDoorCost);
    //   setValue(`totalDoorPrice`, theDoorCost * doorCount);
    //   setTotals();
    // };
    // const setDoorCount = (count) => {
    //   setValue(`doorCount`, count);
    //   setTotals();
    // };

    return (
      <Fragment>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-3 ">
              <div className="form-group">
                <label>Metal Package</label>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      // options={metalOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // onChange={(e) => setMetalChange(e)}
                    />
                  )}
                  name="metal"
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-2 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control"
                      type="number"
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const count = e.target.value;
                        // setMetalCount(count);
                      }}
                    />
                  )}
                  control={control}
                  name={`metalCount`}
                />
              </div>
            </div>

            {/* <div className="col-md-3 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                      defaultValue={`metalPrice`}
                    />
                  )}
                  name={`metalPrice`}
                  control={control}
                  defaultValue={`metalPrice`}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                      defaultValue={`totalMetalPrice`}
                    />
                  )}
                  name={`totalMetalPrice`}
                  control={control}
                  defaultValue={`totalMetalPrice`}
                />
              </div>
            </div>*/}
          </div>
        </div>

        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-3 ">
              <div className="form-group">
                <label>Truss One</label>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      // options={trussesOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // onChange={(e) => setTrussOneChange(e)}
                    />
                  )}
                  name="trussOne"
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-2 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control"
                      type="number"
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const count = e.target.value;
                        // setTrussOneCount(count);
                      }}
                    />
                  )}
                  control={control}
                  name={`trussOneCount`}
                />
              </div>
            </div>

            {/* <div className="col-md-3 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                      defaultValue={`trussOnePrice`}
                    />
                  )}
                  name={`trussOnePrice`}
                  control={control}
                  defaultValue={`trussOnePrice`}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                      defaultValue={`totalTrussOnePrice`}
                    />
                  )}
                  name={`totalTrussOnePrice`}
                  control={control}
                  defaultValue={`totalTrussOnePrice`}
                />
              </div>
            </div> */}
          </div>
        </div>

        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-3 ">
              <div className="form-group">
                <label>Truss Two</label>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      // options={trussesOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // onChange={(e) => setTrussTwoChange(e)}
                    />
                  )}
                  name="trussTwo"
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-2 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control"
                      type="number"
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const count = e.target.value;
                        // setTrussTwoCount(count);
                      }}
                    />
                  )}
                  control={control}
                  name={`trussTwoCount`}
                />
              </div>
            </div>

            {/* <div className="col-md-3 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                      defaultValue={`trussTwoPrice`}
                    />
                  )}
                  name={`trussTwoPrice`}
                  control={control}
                  defaultValue={`trussTwoPrice`}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                      defaultValue={`totalTrussTwoPrice`}
                    />
                  )}
                  name={`totalTrussTwoPrice`}
                  control={control}
                  defaultValue={`totalTrussTwoPrice`}
                />
              </div>
            </div> */}
          </div>
        </div>

        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-3 ">
              <div className="form-group">
                <label>Standard Door</label>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      // options={doorOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // onChange={(e) => setDoorChange(e)}
                    />
                  )}
                  name="door"
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-2 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control"
                      type="number"
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const count = e.target.value;
                        // setDoorCount(count);
                      }}
                    />
                  )}
                  control={control}
                  name={`doorCount`}
                />
              </div>
            </div>

            {/* <div className="col-md-3 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                      defaultValue={`doorPrice`}
                    />
                  )}
                  name={`doorPrice`}
                  control={control}
                  defaultValue={`doorPrice`}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-4">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                      defaultValue={`totalDoorPrice`}
                    />
                  )}
                  name={`totalDoorPrice`}
                  control={control}
                  defaultValue={`totalDoorPrice`}
                />
              </div>
            </div> */}
          </div>
        </div>
      </Fragment>
    );
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentShed();
  };

  return (
    <div className="row">
      <div className="col-md-1"></div>

      <div className="col-md-10">
        <div className="row">
          <div className="col-md-12">
            <button className="float-end mt-2 mb-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid bidformbackground  border border-dark " style={{ borderRadius: "15px" }}>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 mt-4">
                      <div className="form-group"></div>
                    </div>

                    <div className="col-md-8"></div>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-10"></div>
                        <div className="col-md-2">
                          {/* <button className="btn btn-outline-success float-end " type="submit">
                          Save
                        </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 ">
                      <div className="form-group">
                        <label>Model</label>
                        <Controller
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              // options={shedNames}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              // onChange={(e) => setStyleChange(e)}
                            />
                          )}
                          name="style"
                          control={control}
                        />
                      </div>
                    </div>

                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Size</label>
                        <input className="form-control " name="size" {...register("size", { required: true })} />
                      </div>
                    </div>

                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Zone</label>
                        <input className="form-control " name="zone" {...register("zone", { required: true })} />
                      </div>
                    </div>

                    <div className="col-md-2"></div>

                    {/* <div className='col-md-2'>
                    <div className='form-group'>
                      <label>Material Cost</label>
                      <Controller
                        render={({ field, name }) => (
                          <NumberFormat
                            readOnly
                            customInput={Input}
                            className='form-group form-control'
                            style={{ fontSize: 16, padding: 6 }}
                            {...field}
                            name={name}
                            thousandSeparator={true}
                            prefix={'$ '}
                            displayType='input'
                            decimalScale={2}
                            fixedDecimalScale='true'
                          />
                        )}
                        name='materialCost'
                        control={control}
                      />
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label>Total Cost</label>
                      <Controller
                        render={({ field, name }) => (
                          <NumberFormat
                            readOnly
                            customInput={Input}
                            className='form-group form-control'
                            style={{ fontSize: 16, padding: 6 }}
                            {...field}
                            name={name}
                            thousandSeparator={true}
                            prefix={'$ '}
                            displayType='input'
                            decimalScale={2}
                            fixedDecimalScale='true'
                          />
                        )}
                        name='totalCost'
                        control={control}
                        defaultValue={0}
                      />
                    </div>
                  </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Retail Price</label>
                        <Controller
                          render={({ field, name }) => (
                            <input
                              className="form-control"
                              type="number"
                              step={0.001}
                              name={name}
                              {...field}
                              onChange={(e) => {
                                const retailPrice = e.target.value;
                                // setRetailPrice(retailPrice);
                              }}
                            />
                          )}
                          control={control}
                          name={`retailPrice`}
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-3">
                    <div className="form-group">
                      <label>Labor Item</label>
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            options={allLaborOptions}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            onChange={(e) => setLabor(e)}
                          />
                        )}
                        name="labor"
                        control={control}
                      />
                    </div>
                  </div> */}

                    {/* <div className="col-md-3">
                    <div className="form-group">
                      <label>Haul Base Item</label>
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            options={allHaulBaseOptions}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            onChange={(e) => setHaul(e)}
                          />
                        )}
                        name="haul"
                        control={control}
                      />
                    </div>
                  </div> */}

                    <div className="col-md-1">
                      <div className="form-group">
                        <label>OverHead</label>
                        <Controller
                          render={({ field, name }) => (
                            <input className="form-control" type="number" step={0.001} name={name} {...field} />
                          )}
                          control={control}
                          name={`freight`}
                        />
                      </div>
                    </div>

                    <div className="col-md-2"></div>

                    {/* <div className='col-md-2'>
                    <div className='form-group'>
                      <label>{settings.retailPercent * 100}% / Retail</label>
                      <Controller
                        render={({ field, name }) => (
                          <NumberFormat
                            readOnly
                            customInput={Input}
                            className='form-group form-control'
                            style={{ fontSize: 16, padding: 6 }}
                            {...field}
                            name={name}
                            thousandSeparator={true}
                            prefix={'$ '}
                            displayType='input'
                            decimalScale={2}
                            fixedDecimalScale='true'
                          />
                        )}
                        name='percentOfRetail'
                        control={control}
                      />
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label>Gross Profit</label>
                      <Controller
                        render={({ field, name }) => (
                          <NumberFormat
                            readOnly
                            customInput={Input}
                            className='form-group form-control'
                            style={{ fontSize: 16, padding: 6 }}
                            {...field}
                            name={name}
                            thousandSeparator={true}
                            prefix={'$ '}
                            displayType='input'
                            decimalScale={2}
                            fixedDecimalScale='true'
                          />
                        )}
                        name='grossProfit'
                        control={control}
                      />
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid border border-dark mt-2" style={{ borderRadius: "15px" }}>
              <div className="row mt-2 ">
                <div className="col-md-3 text-center">
                  <h5>SKU</h5>
                </div>

                <div className="col-md-2 text-center">
                  <h5>Count</h5>
                </div>
                {/* <div className="col-md-3 text-center">
                <h5>Price</h5>
              </div>
              <div className="col-md-3 text-center">
                <h5>Total</h5>
              </div>
              <div className="col-md-1">
                <h5>Actions</h5>
              </div> */}
              </div>

              <RHFMetalTrussesDoor
                control={control}
                // productOptions={productOptions}
                setValue={setValue}
                getValues={getValues}
                register={register}
              />

              <ItemArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  // productOptions,
                  settings,
                }}
              />
            </div>
          </div>
        </form>

        {/* <div className="row mt-2">
        <div className="col-md-10"></div>
        <div className="col-md-2">
          <button className="btn btn-outline-success float-end " type="button" onClick={handleCopyShed}>
            Copy Building
          </button>
        </div>
      </div> */}

        {/* <BidModal handleClose={handleClose} handleShow={handleShow} show={show}  />  */}
      </div>
    </div>
  );
};

export default CreateShed;
