import React, { useState, Fragment } from 'react';
import { DatePicker } from 'antd';
import Select from 'react-select';
import { orderReportStatus } from '../../../names/shedNames';
const dateFormat = 'MM/DD/YYYY';
const { RangePicker } = DatePicker;

export const dateOptions = [
  { label: 'Date Finished', value: 'dateFinished' },
  { label: 'Date Ordered', value: 'dateOrdered' },
];

const DateFilters = ({ getOrdersByDateFilter, setLoadingOrders, clearOrders }) => {
  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);
  const [value, setValue] = useState(null);
  const [status, setStatus] = useState();
  const [dateStatus, setDateStatus] = useState({ label: 'Date Finished', value: 'dateFinished' });
  const [model, setModel] = useState({ lable: 'All', value: 'All' });

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  const handleDateSort = () => {
    // console.log('this is the dates', dates);
    setLoadingOrders(true);
    clearOrders();
    getOrdersByDateFilter(dates, status, dateStatus, model);
    // console.log('this is the status', status);
  };

  const setOrderStatus = (theStatus) => {
    setStatus(theStatus);
  };
  const setDateOption = (dateOption) => {
    setDateStatus(dateOption);
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-2'>
          <RangePicker
            className='p-2'
            value={hackValue || value}
            // disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setValue(val)}
            onOpenChange={onOpenChange}
            format={dateFormat}
          />
        </div>

        <div className='col-md-2'>
          <Select
            className='basic-single'
            classNamePrefix='select'
            placeholder={'Select Status...'}
            value={status}
            options={orderReportStatus}
            onChange={setOrderStatus}
          />
        </div>
        <div className='col-md-2'>
          <Select
            className='basic-single'
            classNamePrefix='select'
            placeholder={'Select Status...'}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={dateStatus}
            options={dateOptions}
            onChange={setDateOption}
          />
        </div>

        <div className='col-md-2'>
          <button className='ml-2 btn btn-outline-primary ' type='button' disabled={(!status && true) || false} onClick={handleDateSort}>
            Run Report
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default DateFilters;
