import React, { Fragment } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

const CustomAddOns = ({ addOn, addOnIndex, control, setValue, getValues, register }) => {
  const setTotals = () => {
    const theAddOns = getValues(`addOns`);
    const theCustomAddOns = getValues(`customAddOns`);
    const theBasePrice = getValues('basePrice');
    const theShopPercentOfBasePrice = getValues('shopPercentOfBuildingBasePrice');
    const theStorMorAddOnsTotal = theAddOns && theAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorAddOnTotalSalePrice || 0), 0);
    const theShopsAddOnsTotal = theAddOns && theAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopAddOnTotalSalePrice || 0), 0);
    const theStorMorCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.storMorCustomAddOnsTotalSalePrice || 0), 0);
    const theShopsCustomAddOnsTotal =
      theCustomAddOns && theCustomAddOns.reduce((acc, curr) => acc + parseFloat(curr.shopCustomAddOnsTotalSalePrice || 0), 0);
    setValue('storMorCustomAddOnsTotalSalePrice', theStorMorCustomAddOnsTotal);
    setValue('shopCustomAddOnsTotalSalePrice', theShopsCustomAddOnsTotal);
    setValue('storMorTotalSalePrice', theBasePrice + theStorMorAddOnsTotal + theStorMorCustomAddOnsTotal);
    setValue('shopTotalSalePrice', theShopPercentOfBasePrice + theShopsAddOnsTotal + theShopsCustomAddOnsTotal);
  };

  const setChecked = (value) => {
    setValue(`addOns[${addOnIndex}].storMorSpecific`, value);

    if (value) {
      setValue(`customAddOns[${addOnIndex}].shopCustomAddOnsTotalSalePrice`, 0);
      setTotals();
    } else {
      setValue(
        `customAddOns[${addOnIndex}].shopCustomAddOnsTotalSalePrice`,
        addOn.storMorCustomAddOnsTotalSalePrice * addOn.shopCustomAddOnPercentOfRetail
      );
      setTotals();
    }
  };

  return (
    <Fragment>
      <div className='row border mb-2'>
        <div className='col-md-12 bglight'>
          <div className='row mb-2 mt-2 '>
            <div className='col-sm-6 '>
              <Controller
                render={({ field, name }) => <input readOnly className='form-control ' name={name} {...field} />}
                key={addOn.id}
                control={control}
                name={`customAddOns[${addOnIndex}].name`}
              />
            </div>
            <div className='col-sm-2'>
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={'$ '}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`customAddOns[${addOnIndex}].storMorCustomAddOnsTotalSalePrice`}
                control={control}
              />
            </div>
            <div className='col-sm-2'>
              {/* <Controller
                render={({ field, name }) => <input className='form-control' name={name} {...field} />}
                key={addOn.id}
                control={control}
                name={`customAddOns[${addOnIndex}].shopCustomAddOnPercentOfRetail`}
              /> */}
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    suffix={' %'}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`customAddOns[${addOnIndex}].shopCustomAddOnPercentOfRetail`}
                control={control}
              />
            </div>
            <div className='col-sm-2'>
              {/* <Controller
                render={({ field, name }) => <input className='form-control' name={name} {...field} />}
                key={addOn.id}
                control={control}
                name={`customAddOns[${addOnIndex}].shopCustomAddOnsTotalSalePrice`}
              /> */}
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className='form-group form-control'
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={'$ '}
                    displayType='input'
                    decimalScale={2}
                    fixedDecimalScale='true'
                  />
                )}
                name={`customAddOns[${addOnIndex}].shopCustomAddOnsTotalSalePrice`}
                control={control}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customAddOns',
  });

  const addOns = getValues('customAddOns');

  return (
    <div className='container-fluid border mt-4' style={{ borderRadius: '8px' }}>
      {addOns && addOns.length > 0 && (
        <div className='row'>
          <div className='col-md-6 text-center'>
            <h5 className='mt-2'>Custom Addon Name</h5>
          </div>
          <div className='col-md-2 text-center'>
            <h5 className='mt-2'>Retail Price</h5>
          </div>
          <div className='col-md-2 text-center'>
            <h5 className='mt-2'>Shops %</h5>
          </div>

          <div className='col-md-2 text-center'>
            <h5 className='mt-2'>Shop Total</h5>
          </div>
        </div>
      )}

      {fields.map((addOn, addOnIndex) => (
        <CustomAddOns
          key={addOn.id}
          addOn={addOn}
          addOnIndex={addOnIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      ))}
    </div>
  );
}
