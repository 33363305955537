import {
    ADD_DOOR,
    DELETE_DOOR,
    CLEAR_DOORS,
    SET_CURRENT_DOOR,
    CLEAR_CURRENT_DOOR,
    UPDATE_DOOR,
    FILTER_DOORS,
    CLEAR_FILTER_DOOR,
    DOOR_ERROR,
    GET_DOORS,
} from '../types'


export default ( state, action ) => {
    switch(action.type) {
        case GET_DOORS:
            return {
                ...state,
                doors: action.payload,
                loading: false
            }
        case ADD_DOOR:
            return {
                ...state,
                door: action.payload,
                loading: false
            };
        case UPDATE_DOOR:
            const updatedDoors = state.doors.map(door => door._id === action.payload._id ? action.payload : door)
            return {
                ...state,
                doors: updatedDoors,
                loading: false
            };
        case DELETE_DOOR:
            return {
                ...state,
                doors: state.doors.filter(door => door._id !== action.payload),
                loading: false
            };
        case CLEAR_DOORS:
            return {
                ...state,
                doors: null,
                filteredDoor: null,
                error: null,
                currentDoor: null,
            }
        case SET_CURRENT_DOOR:
            return {
                ...state,
                currentDoor: action.payload
            };
        case CLEAR_CURRENT_DOOR:
            return {
                ...state,
                currentDoor: null
            };
        case FILTER_DOORS:
            return {
                ...state,
                filteredDoor: state.doors.filter(door => {
                    const regex = new RegExp(`${action.payload}`, 'gi');
                    return door.customer.value.name.match(regex) 
                })
            }
        case CLEAR_FILTER_DOOR:
            return {
                ...state,
                filteredDoor: null
            };
        case DOOR_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default: 
            return state;
    }
}