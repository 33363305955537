import React, { Fragment } from "react";
import { useFieldArray, Controller, useWatch } from "react-hook-form";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";
import { Input } from "antd";

const ItemArray = ({ control, register, setValue, getValues, productOptions }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `items`,
  });

  return (
    <div className="container-fluid " style={{ borderRadius: "15px" }}>
      <div className="row mt-2 ">
        <div className="col-md-2 text-center">
          <h5>SKU</h5>
        </div>
        <div className="col-md-2 text-center">
          <h5>Quantity</h5>
        </div>
        <div className="col-md-2 text-center">
          <h5>Description</h5>
        </div>
        <div className="col-md-1 text-center">
          <h5>Price</h5>
        </div>
        <div className="col-md-1 text-center">
          <h5>New/Price</h5>
        </div>
        <div className="col-md-1 text-center">
          <h5>Freight</h5>
        </div>
        <div className="col-md-2 text-center">
          <h5>Total</h5>
        </div>
        <div className="col-md-1">
          <h5>Actions</h5>
        </div>
      </div>

      {fields.map((product, productIndex) => (
        <Items
          key={product.id}
          product={product}
          productIndex={productIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          productOptions={productOptions}
        />
      ))}
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <button
              className="btn btn-outline-info float-end"
              onClick={(e) => {
                e.preventDefault();
                append({ value: "0" });
              }}
            >
              Add Item
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Items = ({ product, productIndex, control, remove, setValue, productOptions, getValues }) => {
  const setItemDescription = (description) => {
    setValue(`items[${productIndex}].description`, description);
  };

  const setItemQuantity = (quantity) => {
    const price = getValues(`items[${productIndex}].newPrice`);
    setValue(`items[${productIndex}].quantity`, quantity);
    setValue(`items[${productIndex}].total`, quantity * price);
    setTotals();
  };

  const setItemChange = (e) => {
    let theProduct = { label: e.value.sku, value: e.value };
    setValue(`items[${productIndex}].product`, theProduct);
    setValue(`items[${productIndex}].product.subTotal`, e.value.subTotal);
    setValue(`items[${productIndex}].newPrice`, e.value.subTotal);
    setValue(`items[${productIndex}].total`, 0);
    setValue(`items[${productIndex}].quantity`, 0);
    setTotals();
  };

  const setTotals = () => {
    const items = getValues("items");
    const theItemsCost = items && items.reduce((acc, curr) => acc + parseFloat(curr.total || 0), 0);
    setValue(`grandTotal`, theItemsCost);
  };

  const onRemoveItem = () => {
    setTotals();
  };

  const setItemPrice = (value) => {
    const quantity = getValues(`items[${productIndex}].quantity`);
    setValue(`items[${productIndex}].newPrice`, value);
    setValue(`items[${productIndex}].total`, quantity * value);
    setTotals();
  };

  function FreightWatch({ control, productIndex }) {
    const ordersFreight = useWatch({
      control,
      name: "ordersFreight",
    });
    const newItemPrice = getValues(`items[${productIndex}].newPrice`);
    const grandTotal = getValues("grandTotal");
    const freightPercent = ordersFreight / grandTotal;
    const itemFreight = newItemPrice && freightPercent * newItemPrice;
    setValue(`items[${productIndex}].newFreight`, itemFreight);
    return <div></div>;
  }

  function GrandTotalWatch({ control, productIndex }) {
    const grandTotal = useWatch({
      control,
      name: "grandTotal",
    });
    const newItemPrice = getValues(`items[${productIndex}].newPrice`);
    const ordersFreight = getValues(`ordersFreight`);
    const freightPercent = ordersFreight / grandTotal;
    const itemFreight = newItemPrice && freightPercent * newItemPrice;
    setValue(`items[${productIndex}].newFreight`, itemFreight);
    return <div></div>;
  }

  // setValue(`items[${productIndex}].product.label`, product.product && product.product.sku);

  return (
    <Fragment>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <FreightWatch control={control} productIndex={productIndex} />
            <GrandTotalWatch control={control} productIndex={productIndex} />

            <div className="col-md-2 ">
              <div className="form-group">
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      options={productOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={(e) => setItemChange(e)}
                    />
                  )}
                  key={product.id}
                  name={`items[${productIndex}].product`}
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control inputbg"
                      type="number"
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const quantity = e.target.value;
                        setItemQuantity(quantity);
                      }}
                    />
                  )}
                  key={product.id}
                  control={control}
                  name={`items[${productIndex}].quantity`}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control inputbg"
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const description = e.target.value;
                        setItemDescription(description);
                      }}
                    />
                  )}
                  key={product.id}
                  control={control}
                  name={`items[${productIndex}].description`}
                />
              </div>
            </div>

            <div className="col-md-1">
              <div className="form-group">
                <Controller
                  render={({ field, onChange, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                    />
                  )}
                  name={`items[${productIndex}].product.subTotal`}
                  control={control}
                  key={product.id}
                />
              </div>
            </div>

            <div className="col-md-1">
              <div className="form-group">
                <Controller
                  render={({ value, field, onChange, name, ...restProps }) => (
                    <input
                      className="form-control"
                      type="number"
                      {...field}
                      name={name}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemPrice(value);
                      }}
                      {...restProps}
                    />
                  )}
                  control={control}
                  name={`items[${productIndex}].newPrice`}
                  key={product.id}
                />
              </div>
            </div>

            <div className="col-md-1">
              <div className="form-group">
                <Controller
                  render={({ field, onChange, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                    />
                  )}
                  name={`items[${productIndex}].newFreight`}
                  control={control}
                  key={product.id}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, onChange, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                    />
                  )}
                  name={`items[${productIndex}].total`}
                  control={control}
                  key={product.id}
                />
              </div>
            </div>

            <div className="col-md-1">
              <div className="form-group float-end">
                <button
                  className="btn btn-outline-danger mb-2"
                  type="button"
                  onClick={() => {
                    remove(productIndex);
                    onRemoveItem();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ItemArray;
