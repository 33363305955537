import React, { Fragment, useEffect, useContext } from 'react';
import AuthContext from '../../../../../context/auth/authContext';
import ShedLaborContext from '../../../../../context/shedLabor/shedLaborContext';
import ShedColumns from './ShedColumns';

const LoftedBarnLabor = () => {
  const authContext = useContext(AuthContext);

  const { loadUser } = authContext;
  const shedLaborContext = useContext(ShedLaborContext);
  const { getShopShedsLabor, getStandardShedsLabor, shopShedsLabor, standardShedsLabor, clearShedsLabor } = shedLaborContext;

  useEffect(() => {
    loadUser();
    clearShedsLabor();
    getShopShedsLabor('lofted-barn');
    getStandardShedsLabor('lofted-barn');
  }, []);

  const theTitle = 'Lofted-Barn';

  return (
    <Fragment>
      <div>
        <ShedColumns shopShedsLabor={shopShedsLabor} standardShedsLabor={standardShedsLabor} theTitle={theTitle} />
      </div>
    </Fragment>
  );
};

export default LoftedBarnLabor;
