import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import OrderContext from '../../../context/order/orderContext';
import InvoiceContext from '../../../context/invoice/invoiceContext';
import SettingsContext from '../../../context/settings/settingsContext'
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Input, DatePicker, Tag } from 'antd';
import moment from 'moment';
import PopConfirm from './PopConfirm';

const defaultValues = {};

const ViewInvoiceForm = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit,  setValue,  } = useForm({
    defaultValues,
  });

  const orderContext = useContext(OrderContext);
  const { setCurrentOrder } = orderContext;
  const invoiceContext = useContext(InvoiceContext);
  const { currentInvoice, updateInvoiceStatus } = invoiceContext;
  const settingsContext = useContext(SettingsContext)
  const {getSettings} = settingsContext

  useEffect(() => {
    getSettings();
    // currentInvoice && setValue('_id', currentInvoice._id);
    // currentInvoice && setValue('invoicedDate', moment(currentInvoice.invoicedDate));
    // currentInvoice && setValue('invoiceNumber', currentInvoice.invoiceNumber);
    // currentOrder
    //   ? setValue(`order.serialNumber`, currentOrder.serialNumber)
    //   : currentInvoice && setValue(`order.serialNumber`, currentInvoice.order.serialNumber);
    // currentOrder
    //   ? setValue(`order.shopTotalSalePrice`, currentOrder.shopTotalSalePrice)
    //   : currentInvoice && setValue(`order.shopTotalSalePrice`, currentInvoice.order.shopTotalSalePrice);
    currentInvoice && setValue('_id', currentInvoice._id);
    currentInvoice && setValue('invoicedDate', moment(currentInvoice.invoicedDate));
    currentInvoice && setValue('invoiceNumber', currentInvoice.invoiceNumber);
    currentInvoice && setValue(`order.serialNumber`, currentInvoice.order.serialNumber);
    currentInvoice && setValue(`order.shopTotalSalePrice`, currentInvoice.order.shopTotalSalePrice);

    currentInvoice && setValue(`order.dealerCommission`, currentInvoice.order.dealerCommission);
    currentInvoice && setValue(`order.salesRepCommission`, currentInvoice.order.salesRepCommission);
    currentInvoice && setValue(`order.escort`, currentInvoice.order.escort);
    currentInvoice && setValue(`order.haul`, currentInvoice.order.haul);
    currentInvoice && setValue(`order.storMorOverhead`, currentInvoice.order.storMorOverhead);
    currentInvoice && setValue(`order.storMorTotalCost`, currentInvoice.order.storMorTotalCost);
    currentInvoice && setValue(`order.storMorProfitDollars`, currentInvoice.order.storMorProfitDollars);
    currentInvoice && setValue(`order.storMorProfitPercent`, currentInvoice.order.storMorProfitPercent);

    currentInvoice && setCurrentOrder(currentInvoice.order);
  }, []);

  const onSubmit = (data) => {
    console.log('this is the form data', data);
    // addInvoice(data);
  };

  const setExit = () => {
    navigate(-1);
  };

  const handleViewOrder = () => {
    // alert('this is the order');
    // setCurrentOrder(currentInvoice.order);
    
    navigate('/order/view');
  };

  const dateFormat = 'MM/DD/YYYY';

  const handleStatusChange = () => {
    // let theDate = new Date();
    let newRecord = {
      ...currentInvoice,
      status: 'Adjusted',
      //   processingDate: theDate,
    };
    updateInvoiceStatus(newRecord);
    // statusChange(currentInvoice);
    // navigate(-1);
  };

  return (
    <div className='mt-2'>
      <div className='row mb-4'>
        <div className='col-md-12'>
          <button className='float-end mb-2 mt-2 btn btn-outline-danger ' onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
      <form onSubmit={handleSubmit(onSubmit)} className='mysize'>
        <div className='container-fluid bidformbackground border  ' style={{ borderRadius: '8px' }}>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-3 mt-4'>
                    <div className='form-group'></div>
                  </div>

                  <div className='col-md-7'></div>
                </div>

                <div className='row mb-4'>
                  <div className='col-md-4'>
                    <Tag className='mytag float-start ' color='green'>
                      {currentInvoice && currentInvoice.status}
                    </Tag>
                  </div>
                  <div className='col-md-4'>
                    <h3 className='text-center'>Invoice</h3>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>INVOICE #</label>
                      <div className='col-sm-9'>
                        <input className='form-control inputfield' name='invoiceNumber' {...register('invoiceNumber', { required: true })} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-7'></div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>DATE</label>
                      <div className='col-sm-9'>
                        <Controller
                          render={({ field, name }) => <DatePicker format={dateFormat} className='form-control inputfield' name={name} {...field} />}
                          control={control}
                          name={`invoicedDate`}
                       
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-7'></div>
                </div>

                <div className='row '>
                  <div className='col-md-5'>
                    <div className='row mb-2'>
                      <label className='col-sm-3 col-form-label'>BILL TO:</label>
                      <div className='col-sm-9'>
                        <input className='form-control inputfield' name='billTo' defaultValue={'StorMor'} {...register('billTo', { required: true })} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-7'></div>
                </div>

                <div className='row mt-4'>
                  <div className='col-md-6'>
                    <label>ITEM</label>
                  </div>
                  <div className='col-md-2'>
                    <label>QTY</label>
                  </div>
                  <div className='col-md-2'>
                    <label>RATE</label>
                  </div>
                  <div className='col-md-2'>
                    <label>AMOUNT</label>
                  </div>
                </div>

                <div className='row border bglight mb-2 '>
                  <div className='col-md-6'>
                    <Controller
                      render={({ field, name }) => <input readOnly className='form-control inputfield' name={name} {...field} />}
                      control={control}
                      name={`order.serialNumber`}
                    />
                  </div>

                  <div className='col-md-2'>
                    <Controller
                      render={({ field, name }) => <input readOnly className='form-control inputfield' name={name} {...field} />}
                      control={control}
                      name={`quantity`}
                      defaultValue={1}
                    />
                  </div>

                  <div className='col-md-2'>
                    <Controller
                      render={({ field, name }) => (
                        <NumberFormat
                          readOnly
                          customInput={Input}
                          className='form-group form-control inputfield'
                          style={{ fontSize: 16, padding: 6 }}
                          {...field}
                          name={name}
                          thousandSeparator={true}
                          prefix={'$ '}
                          displayType='input'
                          decimalScale={2}
                          fixedDecimalScale='true'
                        />
                      )}
                      name={`order.shopTotalSalePrice`}
                      control={control}
                    />
                  </div>

                  <div className='col-md-2'>
                    <Controller
                      render={({ field, name }) => (
                        <NumberFormat
                          readOnly
                          customInput={Input}
                          className='form-group form-control inputfield'
                          style={{ fontSize: 16, padding: 6 }}
                          {...field}
                          name={name}
                          thousandSeparator={true}
                          prefix={'$ '}
                          displayType='input'
                          decimalScale={2}
                          fixedDecimalScale='true'
                        />
                      )}
                      name={`order.shopTotalSalePrice`}
                      control={control}
                    />
                  </div>
                </div>
                <div className='row mb-4 mt-4'>
                  <div className='col-md-4'>
                    <button className='btn btn-outline-danger ' type='button' onClick={handleViewOrder}>
                      View Order
                      {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                    </button>
                  </div>
                  <div className='col-md-6'></div>
                  <div className='col-md-2'>
                    {currentInvoice && currentInvoice.status === 'Denied' && (
                      <div className='buttons float-end'>
                        <PopConfirm
                          msg={'Change Status to Adjusted?'}
                          btnText={'Adjusted'}
                          theStatus={'Adjusted'}
                          handleStatusChange={handleStatusChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
      </div>
    </div>
  );
};

export default ViewInvoiceForm;
