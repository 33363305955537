import React, { useContext } from 'react';
import AlertContext from '../../context/alert/alertContext';

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const Alerts = () => {
  const alertContext = useContext(AlertContext);
  const { alerts, updateInvoiceAlert } = alertContext;

  const handleClearAlert = (id) => {
    updateInvoiceAlert(id);
  };

  return (
    alerts &&
    alerts.length > 0 &&
    alerts.map((alert) => (
      <div key={alert.id} className={`alert alert-${alert.type}`}>
        <div className='row'>
          <div className='col-md-1'>
            <InfoIcon fontSize='medium' />
          </div>
          <div className='col-md-8 myFont'>{alert.msg}</div>

          <div className='col-md-3'>
            <CloseIcon onClick={() => handleClearAlert(alert.id)} className='float-end pointer' />
          </div>
        </div>
      </div>
    ))
  );
};

export default Alerts;
