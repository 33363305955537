import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import CommentDrawer from './components/pages/comments/CommentDrawer';
import Navbar from './components/layout/Navbar';
import UserNav from './components/layout/nav/UserNav';
import { ToastContainer } from 'react-toastify';
import { Layout } from 'antd';

const { Content, Sider, Footer } = Layout;

const MyLayout = () => {
  return (
    <Fragment>
      <Layout>
        <Navbar />

        <Layout>
          <Sider width={200} className='site-layout-background' theme='light'>
            <UserNav />
          </Sider>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Content
              className='site-layout-background'
              style={{
                padding: 24,
                margin: 0,
                // minHeight: '90vh',
                height: '85vh',
                overflow: 'auto',
              }}
            >
              <CommentDrawer />
              {/* <Alerts /> */}
              <ToastContainer />

              <Outlet />
            </Content>
            <Footer style={{ textAlign: 'center' }}>Shed Pro ©2022 Created by Tru-Tech</Footer>
          </Layout>
        </Layout>
      </Layout>
    </Fragment>
  );
};

export default MyLayout;
