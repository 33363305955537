import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PaymentContext from "../../../../context/payment/paymentContext";
import PaymentInvoiceArray from "./PaymentInvoiceArray";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Input, DatePicker } from "antd";
import moment from "moment";

const defaultValues = {};

const ViewPaymentForm = () => {
  const navigate = useNavigate();

  const { control, register, handleSubmit, getValues, errors, setValue, watch } = useForm({
    defaultValues,
  });

  const paymentContext = useContext(PaymentContext);
  const { currentPayment } = paymentContext;

  useEffect(() => {
    // currentInvoice && reset(currentInvoice);
    // clearCurrentOrder();

    currentPayment && setValue("_id", currentPayment._id);
    currentPayment && setValue("paymentDate", moment(currentPayment.paymentDate));
    currentPayment && setValue("paymentNumber", currentPayment.paymentNumber);
    currentPayment && setValue("invoices", currentPayment.invoices);
    currentPayment && setValue("shopsPaymentTotal", currentPayment.shopsPaymentTotal);

    currentPayment &&
      setValue("shopsShedsPercentOfBuildingBasePriceTotal", currentPayment.shopsShedsPercentOfBuildingBasePriceTotal);

    currentPayment && setValue("shopsOverheadTotal", currentPayment.shopsOverheadTotal);
    currentPayment && setValue("shopsShedsMaterialTotal", currentPayment.shopsShedsMaterialTotal);
    currentPayment && setValue("shopsShedsLaborTotal", currentPayment.shopsShedsLaborTotal);
    currentPayment && setValue("shopsBuilderLaborTotal", currentPayment.shopsBuilderLaborTotal);
    currentPayment && setValue("shopsRoofLaborTotal", currentPayment.shopsRoofLaborTotal);
    currentPayment && setValue("shopsTrussLaborTotal", currentPayment.shopsTrussLaborTotal);
    currentPayment && setValue("shopsDoorLaborTotal", currentPayment.shopsDoorLaborTotal);
    currentPayment && setValue("shopsPaintLaborTotal", currentPayment.shopsPaintLaborTotal);
    currentPayment && setValue("shopsShedsTotalCost", currentPayment.shopsShedsTotalCost);
    currentPayment && setValue("shopsShedsProfitDollars", currentPayment.shopsShedsProfitDollars);
    currentPayment && setValue("shopsShedsAverageProfitPercent", currentPayment.shopsShedsAverageProfitPercent);
    currentPayment && setValue("shopsAddOnsMaterialTotal", currentPayment.shopsAddOnsMaterialTotal);
    currentPayment && setValue("shopsAddOnsLaborTotal", currentPayment.shopsAddOnsLaborTotal);
    currentPayment && setValue("shopsAddOnsTotalCost", currentPayment.shopsAddOnsTotalCost);
    currentPayment && setValue("shopsAddOnsSalePriceTotal", currentPayment.shopsAddOnsSalePriceTotal);
    currentPayment && setValue("shopsAddOnsProfitDollars", currentPayment.shopsAddOnsProfitDollars);
    currentPayment && setValue("shopsAddOnsAverageProfitPercent", currentPayment.shopsAddOnsAverageProfitPercent);
    currentPayment && setValue("shopsCustomAddOnsTotalCost", currentPayment.shopsCustomAddOnsTotalCost);
    currentPayment && setValue("shopsCustomAddOnsSalePriceTotal", currentPayment.shopsCustomAddOnsSalePriceTotal);
    currentPayment && setValue("shopsCustomAddOnsProfitDollars", currentPayment.shopsCustomAddOnsProfitDollars);
    currentPayment && setValue("shopsCustomAddOnsAverageProfitPercent", currentPayment.shopsCustomAddOnsAverageProfitPercent);
    currentPayment && setValue("shopsMaterialTotalCost", currentPayment.shopsMaterialTotalCost);
    currentPayment && setValue("shopsLaborTotal", currentPayment.shopsLaborTotal);
    currentPayment && setValue("shopsTotalCost", currentPayment.shopsTotalCost);
    currentPayment && setValue("shopsProfitDollarsTotal", currentPayment.shopsProfitDollarsTotal);
    currentPayment && setValue("shopsAverageProfitPercent", currentPayment.shopsAverageProfitPercent);
  }, []);

  // currentPayment && console.log('this is the currentPyament', currentPayment);

  const onSubmit = (data) => {
    console.log("this is the form data", data);
    // addInvoice(data);
  };

  const setExit = () => {
    navigate(-1);
  };

  // const handleViewOrder = () => {
  //   // alert('this is the order');
  //   setCurrentOrder(currentInvoice.order);
  //   navigate('/order/view');
  // };

  const dateFormat = "MM/DD/YYYY";

  return (
    <div className="mt-2">
      <div className="row mb-4">
        <div className="col-md-12">
          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "8px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group"></div>
                      </div>

                      <div className="col-md-7"></div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-12">
                        <h3 className="text-center">Payment</h3>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-5">
                        <div className="row mb-2">
                          <label className="col-sm-3 col-form-label">PAYMENT #</label>
                          <div className="col-sm-9">
                            <input
                              readOnly
                              className="form-control inputfield"
                              name="paymentNumber"
                              {...register("paymentNumber", { required: true })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7"></div>
                    </div>

                    <div className="row ">
                      <div className="col-md-5">
                        <div className="row mb-2">
                          <label className="col-sm-3 col-form-label">DATE</label>
                          <div className="col-sm-9">
                            <Controller
                              render={({ field, name }) => (
                                <DatePicker format={dateFormat} className="form-control inputfield" name={name} {...field} />
                              )}
                              control={control}
                              name={`paymentDate`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7"></div>
                    </div>

                    <PaymentInvoiceArray
                      {...{
                        control,
                        watch,
                        register,
                        defaultValues,
                        getValues,
                        setValue,
                        errors,
                        getValues,
                      }}
                    />
                    <div className="row mt-4 mb-2">
                      <div className="col-md-4">
                        <h4 className="float-end">Expense Totals</h4>
                      </div>

                      <div className="col-md-4">
                        <h4 className=" float-end">Payment Totals</h4>
                      </div>

                      <div className="col-md-4">
                        <h4 className="float-end">Profit Totals</h4>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-12">
                        <div className="row mb-2">
                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">Sheds Material</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsShedsMaterialTotal`}
                              control={control}
                            />
                          </div>

                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">% of Sheds</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ value, field, onChange, name, ...restProps }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={`basePrice`}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                  // onValueChange={(i) => {
                                  //   setBasePrice(i.floatValue);
                                  // }}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                  isNumericString="true"
                                  {...restProps}
                                />
                              )}
                              name={`shopsShedsPercentOfBuildingBasePriceTotal`}
                              control={control}
                              defaultValue={0}
                            />
                          </div>
                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">Sheds Profit $</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  // suffix={' %'}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsShedsProfitDollars`}
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-12">
                        <div className="row mb-2">
                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">AddOns Material</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsAddOnsMaterialTotal`}
                              control={control}
                            />
                          </div>

                          <div className="col-md-4"></div>

                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">Sheds Average Profit %</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  suffix={" %"}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsShedsAverageProfitPercent`}
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-12">
                        <div className="row mb-2">
                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">Custom Addons Material</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsCustomAddOnsTotalCost`}
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-sm-2 col-form-label">
                            <h5 className="float-end">Material Total</h5>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsMaterialTotalCost`}
                              control={control}
                            />
                          </div>

                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">% of AddOns</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsAddOnsSalePriceTotal`}
                              control={control}
                            />
                          </div>

                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">AddOns Profit $</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  // suffix={' %'}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsAddOnsProfitDollars`}
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8"></div>
                      <div className="col-sm-2 col-form-label">
                        <label className="float-end">AddOns Average Profit %</label>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              // thousandSeparator={true}
                              // prefix={'$ '}
                              suffix={" %"}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsAddOnsAverageProfitPercent`}
                          control={control}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-sm-2 col-form-label ">
                        <label className="float-end">Builder Labor</label>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsBuilderLaborTotal`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-12">
                        <div className="row mb-2">
                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">Roof Labor</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsRoofLaborTotal`}
                              control={control}
                            />
                          </div>

                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">% of Custom AddOns</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name, value }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsCustomAddOnsSalePriceTotal`}
                              control={control}
                            />
                          </div>

                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">Custom AddOns Profit $</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name, value }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  // suffix={' %'}
                                  prefix={"$  "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsCustomAddOnsProfitDollars`}
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <div className="row ">
                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">
                              <b>Truss Labor</b>
                            </label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                  displayType="input"
                                  decimalScale={2}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsTrussLaborTotal`}
                              control={control}
                            />
                          </div>
                          <div className="col-md-4"></div>
                          <div className="col-sm-2 col-form-label">
                            <label className="float-end">Custom AddOn Average %</label>
                          </div>
                          <div className="col-sm-2">
                            <Controller
                              render={({ field, name }) => (
                                <NumberFormat
                                  readOnly
                                  customInput={Input}
                                  className="form-group form-control inputfield"
                                  style={{ fontSize: 16, padding: 6 }}
                                  {...field}
                                  name={name}
                                  // thousandSeparator={true}
                                  // prefix={'$ '}
                                  displayType="input"
                                  decimalScale={2}
                                  suffix={" %"}
                                  fixedDecimalScale="true"
                                />
                              )}
                              name={`shopsCustomAddOnsAverageProfitPercent`}
                              control={control}
                              defaultValue={0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-sm-2 col-form-label">
                        <label className="float-end">Door Labor</label>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsDoorLaborTotal`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-sm-2 col-form-label">
                        <label className="float-end">Paint Labor</label>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsPaintLaborTotal`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-sm-2 col-form-label">
                        <label className="float-end">Total Shed Labor</label>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsShedsLaborTotal`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>

                      <div className="col-sm-2 col-form-label">
                        <h5 className="float-end">Total Payment</h5>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsPaymentTotal`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>

                      <div className="col-sm-2 col-form-label">
                        <h5 className="float-end">Total Profit $</h5>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsProfitDollarsTotal`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-sm-2 col-form-label">
                        <label className="float-end">Total AddOn Labor</label>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsAddOnsLaborTotal`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>

                      <div className="col-md-4"></div>

                      <div className="col-sm-2 col-form-label">
                        <h5 className="float-end">Average Profit %</h5>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              // thousandSeparator={true}
                              // prefix={'$ '}
                              suffix={" %"}
                              displayType="input"
                              // decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsAverageProfitPercent`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 ">
                      <div className="col-sm-2 col-form-label">
                        <h5 className="float-end">Total Labor</h5>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsLaborTotal`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-4">
                      <div className="col-sm-2 col-form-label">
                        <label className="float-end">Total Overhead</label>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsOverheadTotal`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-4">
                      <div className="col-sm-2 col-form-label">
                        <label className="float-end">Base Sheds Total Cost</label>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsShedsTotalCost`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-sm-2 col-form-label">
                        <label className="float-end">Addons Total Cost</label>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsAddOnsTotalCost`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-4">
                      <div className="col-sm-2 col-form-label">
                        <h5 className="float-end">Total Cost</h5>
                      </div>
                      <div className="col-sm-2">
                        <Controller
                          render={({ field, name }) => (
                            <NumberFormat
                              readOnly
                              customInput={Input}
                              className="form-group form-control inputfield"
                              style={{ fontSize: 16, padding: 6 }}
                              {...field}
                              name={name}
                              thousandSeparator={true}
                              prefix={"$ "}
                              displayType="input"
                              decimalScale={2}
                              fixedDecimalScale="true"
                            />
                          )}
                          name={`shopsTotalCost`}
                          control={control}
                          defaultValue={0}
                        />
                      </div>
                    </div>

                    {/* <div className='row border bglight mb-2 '>
                  <div className='col-md-6'>
                    <Controller
                      render={({ field, name }) => <input readOnly className='form-control inputfield' name={name} {...field} />}
                      control={control}
                      name={`order.serialNumber`}
                    />
                  </div>

                  <div className='col-md-2'>
                    <Controller
                      render={({ field, name }) => <input readOnly className='form-control inputfield' name={name} {...field} />}
                      control={control}
                      name={`quantity`}
                      defaultValue={1}
                    />
                  </div>

                  <div className='col-md-2'>
                    <Controller
                      render={({ field, name }) => (
                        <NumberFormat
                          readOnly
                          customInput={Input}
                          className='form-group form-control'
                          style={{ fontSize: 16, padding: 6 }}
                          {...field}
                          name={name}
                          thousandSeparator={true}
                          prefix={'$ '}
                          displayType='input'
                          decimalScale={2}
                          fixedDecimalScale='true'
                        />
                      )}
                      name={`order.shopTotal`}
                      control={control}
                    />
                  </div>

                  <div className='col-md-2'>
                    <Controller
                      render={({ field, name }) => (
                        <NumberFormat
                          readOnly
                          customInput={Input}
                          className='form-group form-control'
                          style={{ fontSize: 16, padding: 6 }}
                          {...field}
                          name={name}
                          thousandSeparator={true}
                          prefix={'$ '}
                          displayType='input'
                          decimalScale={2}
                          fixedDecimalScale='true'
                        />
                      )}
                      name={`order.shopTotal`}
                      control={control}
                    />
                  </div>
                </div> */}
                    {/* <div className='row mb-4 mt-4'>
                  <div className='col-md-4'>
                    <button className='btn btn-outline-danger ' type='button' onClick={handleViewOrder}>
                      View Order
                    </button>
                  </div>
                  <div className='col-md-6'></div>
                  <div className='col-md-2'>
                    {currentInvoice && currentInvoice.status === 'Denied' && (
                      <div className='buttons float-end'>
                        <PopConfirm
                          msg={'Change Status to Adjusted?'}
                          btnText={'Adjusted'}
                          theStatus={'Adjusted'}
                          handleStatusChange={handleStatusChange}
                        />
                      </div>
                    )}
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ViewPaymentForm;
