import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import ProductHeader from './ProductHeader'
import InvoiceTableHeader from './InvoiceTableHeader'

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    blankrow: {
        flexDirection: 'row',
       
        alignItems: 'center',
        height: 34,
        fontStyle: 'bold',
    },
    sku: {
        width: '35%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    colorstwo: {
        width: '30%',
        textAlign: 'right',
       
        paddingRight: 8,
    },
    title: {
        width: '35%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    description: {
        width: '45%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingLeft: 8,
    },
    count: {
        width: '20%',
        borderRightColor: borderColor,
        
        textAlign: 'left',
        paddingLeft: 8,
    },
    colors: {
        width: '100%',
        borderRightColor: borderColor,
        paddingRight: 8,
        textAlign: 'right',
        
    },

    blanktext: {
        width: '100%',
        
        
    },
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
        borderColor: '#bff0fd',
        
        
    },
    
  });


const InvoiceTableRow = ({metalOrder}) => {
    console.log('this is the metalOrder', metalOrder)
    const rows = metalOrder.map( order =>
        <View key={order._id} wrap={false}>

            <View >
                <InvoiceTableHeader />

                <View style={styles.container}>
                    <View style={styles.row} >
                    <Text style={styles.sku}>{order.value.title}</Text>  
                    {order.value.serialNumber &&   <Text style={styles.sku}>{order.value.serialNumber}</Text>  }
                     <Text style={styles.colorstwo}>Roof: { order.value.roofColor &&  order.value.roofColor.value}</Text>
                    </View>
            
            
                    <View style={styles.row}>
                        {order.value.trimColor && <Text style={styles.colors}>Trim: {order.value.trimColor.value}</Text>}   
                    </View>
                    <View style={styles.row}>
                        {order.value.sidingColor && <Text style={styles.colors}>Siding: {order.value.sidingColor.value}</Text>}
                    </View>

                    <ProductHeader />

                        {order.value.products.map(product => 
                            <View style={styles.row} key={product._id}>
                                <Text style={styles.title}>{product.descriptionTwo}</Text>
                                <Text style={styles.description}>{product.description}</Text>
                                <Text style={styles.count}>{product.count}</Text>
                            
                        
                            </View >
                            
                            )}
                </View >


                <View style={styles.blankrow}>
                    <Text style={styles.blanktext}></Text>
                </View>



            </View >
        </View>
       
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default InvoiceTableRow