import React, { useReducer } from "react";
import OrderContext from "./orderContext";
import orderReducer from "./orderReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_ORDER,
  DELETE_ORDER,
  CLEAR_ORDERS,
  SET_CURRENT_ORDER,
  CLEAR_CURRENT_ORDER,
  UPDATE_ORDER,
  FILTER_ORDERS,
  CLEAR_FILTER_ORDER,
  ORDER_ERROR,
  GET_ORDERS,
  STATUS_CHANGE_ORDER,
  SET_LOADING_ORDERS,
  GET_ORDER_COUNT,
  CLEAR_ORDER_COUNT,
  SET_PAGINATION,
  UPDATE_ORDER_STATUS,
  SET_SEARCHED_ORDER,
  CLEAR_SEARCHED_ORDER,
  SET_SERIAL_NUMBER_SEARCH,
  GET_ORDERS_CHARTONE,
} from "../types";

const OrderState = (props) => {
  const initialState = {
    orders: null,
    currentOrder: null,
    filteredOrder: null,
    serialNumber: null,
    chartOneOrders: null,
    error: null,
    loadingOrders: false,
    orderCount: 0,
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  const [state, dispatch] = useReducer(orderReducer, initialState);

  const getOrdersByCategory = async (category, pagination) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(`/api/order/${category}`, { pagination }, config);
      setLoadingOrders(false);
      dispatch({ type: GET_ORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getOrdersForChartOne = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(`/api/order/charts/one`, config);
      setLoadingOrders(false);
      dispatch({ type: GET_ORDERS_CHARTONE, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getReportOrdersByCategory = async (category) => {
    try {
      setLoadingOrders(true);
      const res = await axios.get(`/api/order/report/${category}`);
      dispatch({ type: GET_ORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getOrderCount = async (category) => {
    try {
      const res = await axios.get(`/api/order/count/${category}`);
      let pagination = { total: res.data };
      setPagination(pagination);
      dispatch({ type: GET_ORDER_COUNT, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getOrderById = async (id) => {
    try {
      setLoadingOrders(true);
      const res = await axios.get(`/api/order/byid/${id}`);
      // console.log('thisis in the state', res.data);
      dispatch({ type: SET_CURRENT_ORDER, payload: res.data });
      setLoadingOrders(false);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getOrdersByDateFilter = async (dates, status, dateStatus, style, builder) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/order/datefilter/report", { dates, status, dateStatus, style, builder }, config);
      setLoadingOrders(false);
      dispatch({ type: GET_ORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getOrdersForUsedInventory = async (dates, status) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post("/api/order/inventory/used/report", { dates, status }, config);
      setLoadingOrders(false);
      dispatch({ type: GET_ORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getOrders = async () => {
    try {
      const res = await axios.get(`/api/order/`);
      dispatch({ type: GET_ORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  // add Order
  const addOrder = async (order) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/order", { order }, config);
      dispatch({ type: ADD_ORDER, payload: res.data });
      // dispatch({ type: STATUS_CHANGE_ORDER, payload: order });
      toast.success(`Order is created`);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      toast.error(`Order ${err}`);
    }
  };

  // delete Order
  const deleteOrder = async (order) => {
    try {
      const res = await axios.delete(`/api/order/delete/${order._id}`);
      dispatch({ type: DELETE_ORDER, payload: order._id });
      toast.success(`Order is deleted`);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      toast.error(`Order ${err}`);
    }
  };

  // update order
  const updateOrder = async (order) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/order/update/${order._id}`, order, config);
      dispatch({ type: UPDATE_ORDER, payload: res.data });
      toast.success(`Order is updated`);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      toast.error(`Order ${err}`);
    }
  };

  // update order
  const updateOrderStatus = async (order) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/order/update/${order._id}`, order, config);
      dispatch({ type: UPDATE_ORDER_STATUS, payload: res.data });
      toast.success(`Order is updated`);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      toast.error(`Order ${err}`);
    }
  };

  // update order
  const updateOrderToBuilding = async (order) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/order/update/building/${order._id}`, order, config);
      dispatch({ type: UPDATE_ORDER_STATUS, payload: res.data });
      toast.success(`Order is updated`);
      console.log("Order Is updated");
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      toast.error(`Order ${err}`);
    }
  };

  const findOrderBySerialNumber = async (serialNumber) => {
    try {
      setLoadingOrders(true);
      const res = await axios.get(`/api/order/search/${serialNumber}`);
      dispatch({ type: SET_SEARCHED_ORDER, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const clearSearchedOrder = () => {
    dispatch({ type: CLEAR_SEARCHED_ORDER });
  };

  const clearOrders = () => {
    dispatch({ type: CLEAR_ORDERS });
  };

  const setCurrentOrder = (order) => {
    dispatch({ type: SET_CURRENT_ORDER, payload: order });
  };

  const clearCurrentOrder = () => {
    dispatch({ type: CLEAR_CURRENT_ORDER });
  };

  const filterOrders = (text) => {
    dispatch({ type: FILTER_ORDERS, payload: text });
  };

  const clearFilterOrder = () => {
    dispatch({ type: CLEAR_FILTER_ORDER });
  };

  const updateHaulerOnOrder = (order) => {
    dispatch({ type: UPDATE_ORDER, payload: order });
  };

  const statusChange = (order) => {
    dispatch({ type: STATUS_CHANGE_ORDER, payload: order });
  };

  const setLoadingOrders = (bool) => {
    dispatch({ type: SET_LOADING_ORDERS, payload: bool });
  };

  const clearOrderCount = () => {
    dispatch({ type: CLEAR_ORDER_COUNT });
  };

  const setPagination = (pagination) => {
    dispatch({ type: SET_PAGINATION, payload: pagination });
  };

  const setSerialNumberSearch = (sn) => {
    dispatch({ type: SET_SERIAL_NUMBER_SEARCH, payload: sn });
  };

  return (
    <OrderContext.Provider
      value={{
        orders: state.orders,
        currentOrder: state.currentOrder,
        filteredOrder: state.filteredOrder,
        error: state.error,
        loadingOrders: state.loadingOrders,
        orderCount: state.orderCount,
        pagination: state.pagination,
        searchedOrder: state.searchedOrder,
        serialNumber: state.serialNumber,
        chartOneOrders: state.chartOneOrders,
        getOrdersByCategory,
        getReportOrdersByCategory,
        getOrders,
        addOrder,
        deleteOrder,
        setCurrentOrder,
        clearCurrentOrder,
        updateOrder,
        filterOrders,
        clearFilterOrder,
        clearOrders,
        statusChange,
        updateHaulerOnOrder,
        updateOrderToBuilding,
        getOrdersByDateFilter,
        setLoadingOrders,
        getOrderById,
        getOrderCount,
        clearOrderCount,
        setPagination,
        getOrdersForUsedInventory,
        updateOrderStatus,
        findOrderBySerialNumber,
        clearSearchedOrder,
        setSerialNumberSearch,
        getOrdersForChartOne,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderState;
