import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import AddOnContext from "../../../context/addon/addOnContext";
import ReactSelect from "react-select";
import { useForm, Controller } from "react-hook-form";
import ItemArray from "./RHFItemArray";
import NumberFormat from "react-number-format";
import { Input, Checkbox } from "antd";

const defaultValues = {};

const CreateAddOn = () => {
  const navigate = useNavigate();
  const [theType, setTheType] = useState();

  const { control, register, handleSubmit, getValues, errors, setValue, watch } = useForm({
    defaultValues,
  });

  const authContext = useContext(AuthContext);
  const addOnContext = useContext(AddOnContext);

  const { loadUser } = authContext;
  const { addAddOn, updateAddOn, currentAddOn, clearCurrentAddOn } = addOnContext;

  useEffect(() => {
    loadUser();

    currentAddOn && setTheType(currentAddOn.type.value);

    currentAddOn && setValue("_id", currentAddOn._id);
    currentAddOn && setValue("products", currentAddOn.products);
    currentAddOn && setValue("name", currentAddOn.name);
    currentAddOn && setValue("sku", currentAddOn.sku);
    currentAddOn && setValue("category", currentAddOn.category);
    currentAddOn && setValue("retailPrice", currentAddOn.retailPrice);
    currentAddOn && setValue("retailPercent", currentAddOn.retailPercent);
    currentAddOn && setValue("shopPercent", currentAddOn.shopPercent);
    currentAddOn && setValue("type", currentAddOn.type);
    currentAddOn && setValue("storMorSpecific", currentAddOn.storMorSpecific);
    currentAddOn && setValue("shopSpecific", currentAddOn.shopSpecific);
    currentAddOn && setValue("nonInventoryItem", currentAddOn.nonInventoryItem);
    currentAddOn && setValue("storMorAndDealerSplit", currentAddOn.storMorAndDealerSplit);
    currentAddOn && setValue("storMorDealerShopSplit", currentAddOn.storMorDealerShopSplit);
    currentAddOn && setValue("passThrough", currentAddOn.passThrough);
  }, []);

  const setExit = () => {
    navigate(-1);
    clearCurrentAddOn();
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>

      <form>
        <div className="container-fluid bidformbackground border border-dark " style={{ borderRadius: "15px" }}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="form-group"></div>
                  </div>

                  <div className="col-md-8"></div>

                  <div className="col-md-1">
                    <div className="buttons float-end  mr-3 mt-3">
                      <button className="btn btn-outline-primary " type="submit">
                        Submit
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-2 ">
                    <div className="form-group">
                      <label>Type</label>
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            // options={allTypes}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            inputRef={field.ref}
                            // onChange={(e) => setTypeChange(e)}
                          />
                        )}
                        name={`type`}
                        control={control}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 ">
                    <div className="form-group">
                      <label>Category</label>
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            // options={allCategories}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            inputRef={field.ref}
                            // onChange={(e) => setCategoryChange(e)}
                          />
                        )}
                        name={`category`}
                        control={control}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>

                  <div className="col-md-2 mt-4">
                    <div className="form-group">
                      <Controller
                        control={control}
                        name="storMorSpecific"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Checkbox
                            onBlur={onBlur} // notify when input is touched
                            // onChange={onChange} // send value to hook form
                            checked={value}
                            inputRef={ref}
                          >
                            StorMor Specific
                          </Checkbox>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mt-4">
                    <div className="form-group">
                      <Controller
                        control={control}
                        name="shopSpecific"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Checkbox
                            onBlur={onBlur} // notify when input is touched
                            // onChange={onChange} // send value to hook form
                            checked={value}
                            inputRef={ref}
                          >
                            Shop Specific
                          </Checkbox>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mt-4">
                    <div className="form-group">
                      <Controller
                        control={control}
                        name="storMorAndDealerSplit"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Checkbox
                            onBlur={onBlur} // notify when input is touched
                            // onChange={onChange} // send value to hook form
                            checked={value}
                            inputRef={ref}
                          >
                            StorMor/Dealer Split
                          </Checkbox>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mt-4">
                    <div className="form-group">
                      <Controller
                        control={control}
                        name="storMorDealerShopSplit"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Checkbox
                            readOnly
                            onBlur={onBlur} // notify when input is touched
                            // onChange={onChange} // send value to hook form
                            checked={value}
                            inputRef={ref}
                          >
                            StorMor/Dealer/Shop Split
                          </Checkbox>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-8"></div>
                  <div className="col-md-2 mt-4">
                    <div className="form-group">
                      <Controller
                        control={control}
                        name="nonInventoryItem"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Checkbox
                            onBlur={onBlur} // notify when input is touched
                            // onChange={onChange} // send value to hook form
                            checked={value}
                            inputRef={ref}
                          >
                            Non Inventory Item
                          </Checkbox>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mt-4">
                    <div className="form-group">
                      <Controller
                        control={control}
                        name="passThrough"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Checkbox
                            onBlur={onBlur} // notify when input is touched
                            // onChange={onChange} // send value to hook form
                            checked={value}
                            inputRef={ref}
                          >
                            Pass Through
                          </Checkbox>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control inputbg"
                        name="name.label"
                        readOnly
                        {...register("name.label", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label>SKU</label>
                      <input className="form-control inputbg" name="sku" readOnly {...register("sku", { required: true })} />
                    </div>
                  </div>

                  {(theType && theType === "piece") || theType === "sqft" || theType === "linearFt" ? (
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Retail Price</label>
                        <input
                          readOnly
                          className="form-control inputbg"
                          name="retailPrice"
                          type="number"
                          step={0.01}
                          {...register("retailPrice", { required: true })}
                        />
                      </div>
                    </div>
                  ) : theType === "percent" ? (
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Percent of Retail</label>
                        <input
                          readOnly
                          className="form-control inputbg"
                          name="retailPercent"
                          type="number"
                          step={0.01}
                          {...register("retailPercent", { required: true })}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-2"></div>
                  )}

                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Shops %</label>
                      <input
                        readOnly
                        className="form-control inputbg"
                        name="shopPercent"
                        type="number"
                        step={0.01}
                        {...register("shopPercent", { required: true })}
                        defaultValue={0}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="col-md-5"></div>
                  <div className="col-md-3 ">
                    {/* <div className="form-group"> */}
                    {/* <label>Labor Item</label>
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            // options={addOnsLaborOptions}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            inputRef={field.ref}
                            // onChange={(e) => setLaborChange(e)}
                          />
                        )}
                        name={`theLabor`}
                        control={control}
                        // rules={{ required: true }}
                      />
                    </div> */}
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-2 float-end">
                    {/* <div className="form-group">
                      <label>Addon Material Cost</label>
                      <Controller
                        render={({ field, name }) => (
                          <NumberFormat
                            readOnly
                            customInput={Input}
                            className="form-control inputbg"
                            style={{ fontSize: 16, padding: 6 }}
                            {...field}
                            name={name}
                            thousandSeparator={true}
                            prefix={"$ "}
                            displayType="input"
                            decimalScale={2}
                            fixedDecimalScale="true"
                          />
                        )}
                        name="totalCost"
                        control={control}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ItemArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              getValues,
              // productOptions,
            }}
          />
        </div>
      </form>
      {/* <BidModal handleClose={handleClose} handleShow={handleShow} show={show}  />  */}
    </div>
  );
};

export default CreateAddOn;
