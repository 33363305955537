import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import SettingsContext from "../../../context/settings/settingsContext";
import BuilderArray from "./BuilderArray";
import AddOnArray from "./AddOnArray";
import { useForm } from "react-hook-form";

const defaultValues = {
  nonInvAddOns: [],
};

const Settings = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const { loadUser } = authContext;
  const { getSettings, settings, addSettings, updateSettings, deleteInventoryAndLaborItems } = settingsContext;
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    loadUser();
    getSettings();
  }, []);

  useEffect(() => {
    settings && setValue("_id", settings._id);
    settings && setValue("company", settings.company);
    settings && setValue("retailPercent", settings.retailPercent);
    settings && setValue("overheadPercent", settings.overheadPercent);
    settings && setValue("defaultZone", settings.defaultZone);
    settings && setValue("address", settings.address);
    settings && setValue("city", settings.city);
    settings && setValue("state", settings.state);
    settings && setValue("zipCode", settings.zipCode);
    settings && setValue("builders", settings.builders);
    settings && setValue("nonInvAddOns", settings.nonInvAddOns);
  }, [settings]);

  const onSubmit = async (values) => {
    // console.log("this is the values", values);
    updateSettings(values);
    navigate(-1);
  };

  const updatePrice = () => {
    navigate("/price/change");
  };

  const deleteInvLabor = () => {
    deleteInventoryAndLaborItems();
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-12 text-center">
              <h4>Settings</h4>
            </div>
            <div className="col-md-6">
              {/* <button className="btn btn-outline-info float-end" type="button" onClick={() => updatePrice()}>
                Price Change
              </button> */}
            </div>
          </div>

          {settings && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-fluid bidformbackground border border-dark " style={{ borderRadius: "15px" }}>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3 mt-4">
                          <div className="form-group"></div>
                        </div>

                        <div className="col-md-8"></div>

                        <div className="col-md-1">
                          <div className="buttons float-end  mr-3 mt-3">
                            <button className="btn btn-outline-primary " type="submit">
                              Save
                              {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-5">
                          <div className="form-group">
                            <label>Company Name</label>
                            <input className="form-control inputbg" name="company" {...register("company", { required: true })} />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Shops % / Retail</label>
                            <input
                              className="form-control inputbg"
                              name="retailPercent"
                              type="number"
                              step={0.01}
                              {...register("retailPercent", { required: true })}
                              defaultValue={0}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Default Zone</label>
                            <input
                              className="form-control inputbg"
                              name="defaultZone"
                              {...register("defaultZone", { required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Overhead %</label>
                            <input
                              className="form-control inputbg"
                              name="overheadPercent"
                              {...register("overheadPercent", { required: true })}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-5">
                          <div className="form-group">
                            <label>Address</label>
                            <input className="form-control inputbg" name="address" {...register("address", { required: true })} />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <label>City</label>
                            <input className="form-control inputbg" name="city" {...register("city", { required: true })} />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <label>State</label>
                            <input className="form-control inputbg" name="state" {...register("state", { required: true })} />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Zip Code</label>
                            <input className="form-control inputbg" name="zipCode" {...register("zipCode", { required: true })} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <BuilderArray
                  {...{
                    control,
                    watch,
                    register,
                    defaultValues,
                    getValues,
                    setValue,
                    errors,
                    getValues,
                  }}
                />
                <AddOnArray
                  {...{
                    control,
                    watch,
                    register,
                    defaultValues,
                    getValues,
                    setValue,
                    errors,
                    getValues,
                  }}
                />
              </div>
            </form>
          )}
          {/* <div className="row mt-4">
            <div className="col-md-10"></div>
            <div className="col-md-2">
              <button className="btn  btn-outline-danger " type="button" onClick={() => deleteInvLabor()}>
                Delete Inventory & Labor Items
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Settings;
